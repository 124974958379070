import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { Link, useSearchParams } from "react-router-dom";

import { Each } from "../helper";
import UploadZ from "../components/UploadZ";

export default function PostIncorp() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [selectedType, setSelectedType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [serviceFee, setServiceFee] = useState(0);
  const [documents, setDocuments] = useState([]);

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }

  useEffect(() => {
    let service = searchParams.get("service");
    if (service) {
      setIsDisabled(true);
      let find = postIncorpType.find((t) => t.value == service);
      setSelectedType(find);
      getFeeDetails(service);
    }
  }, [searchParams]);

  const postIncorpType = [
    {
      value: "COI",
      label: "Certificate of Incumbency (COI)",
    },
    {
      value: "COGS",
      label: "Certificate of Good Standing (COGS)",
    },
    {
      value: "ROD",
      label: "Register of Directors",
    },
    {
      value: "SR",
      label: "Share Registry",
    },
    {
      value: "COD",
      label: "Change Of Director (COD)",
    },
    {
      value: "COSH",
      label: "Change of Shareholder (COSH)",
    },
    {
      value: "COA",
      label: "Change of Client / Administration (COA)",
    },
    {
      value: "COC",
      label: "Change of Contact Point (COC)",
    },
    {
      value: "TO",
      label: "Transfer Out (TO)",
    },
    {
      value: "CO",
      label: "Continuation Out (CO)",
    },
    {
      value: "AMAA",
      label: "Alteration to the Memorandum & Articles of Association",
    },
    {
      value: "ARMA",
      label: "Amended and Restated Memorandum & Articles",
    },
    { value: "Apostilles", label: "Apostilles" },
    {
      value: "CGS",
      label: "Certificate of Good Standing",
    },
    {
      value: "RCAD",
      label: "Registry Certified Copy of Any Document",
    },
    {
      value: "RUD",
      label: "Registry Uncertified or Duplicate Copy of Any Document",
    },
    {
      value: "RPEC",
      label: "Registry Physical Copy of any Electronic Certificate",
    },
    { value: "CI", label: "Certificate of Incumbency" },
    {
      value: "CCPD",
      label: "CCP Certified or Duplicate copies of documents",
    },
    {
      value: "ARD",
      label: "Amendments to Register of Directors",
    },
    {
      value: "ARM",
      label:
        "Amendments to Register of Members & Issuing New Share Certificate(s)",
    },
    { value: "Continuations", label: "Continuations" },
    { value: "DR", label: "Drafting Resolutions" },
    {
      value: "FRMC",
      label: "Filing Register of Mortgage & Charges",
    },
    {
      value: "FAR",
      label: "Filing Appointment of RO/RA",
    },
    { value: "FTO", label: "Filing Transfer Out" },
    { value: "IA", label: "Indemnity Agreements" },
    { value: "Notarizations", label: "Notarizations" },
    { value: "PA", label: "Powers of Attorney" },
    {
      value: "RSC",
      label: "Reactivation of struckoff companies",
    },
    {
      value: "RNR",
      label: "Registration of Notice of Resignation as Registered Agent",
    },
    {
      value: "FRRA",
      label: "Filing notice of Recission as Registered Agent",
    },
    {
      value: "RFC",
      label: "Registration as a Foreign Company in the BVI",
    },
    {
      value: "FNFC",
      label:
        "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
    },
    {
      value: "EFF",
      label: "Expedite Fee for Fast Track Due Diligence and Incorporation",
    },
  ];

  const docUploadSchema = {
    COI: [],
    COGS: [],
    ROD: [
      {
        label: "Latest Client information Sheet",
        slug: "latest-client-information-sheet",
      },
    ],
    SR: [
      {
        label: "Latest Client information Sheet",
        slug: "latest-client-information-sheet",
      },
    ],
    COD: [
      {
        label: "Resolution of Appointment",
        slug: "resolution-of-appointment",
      },
      {
        label: "Resolution of Resignation",
        slug: "resolution-of-resignation",
      },
      {
        label: "Consent to Act as a Director",
        slug: "consent-to-act-as-a-director",
      },
      {
        label: "Client Information Sheet",
        slug: "client-information-sheet",
      },
      {
        label: "Passport / ID Proof",
        slug: "passport-id-proof",
      },
      {
        label: "Address Proof",
        slug: "address-proof",
      },
      {
        label: "Letter of Resignation",
        slug: "letter-of-resignation",
      },
    ],
    COSH: [
      {
        label: "Updated Client Information Sheet",
        slug: "updated-client-information-sheet",
      },
      {
        label: "Instrument of Share Transfer",
        slug: "instrument-of-share-transfer",
      },
      {
        label: "Share Transfer Resolution ",
        slug: "share-transfer-resolution",
      },
      {
        label: "Passport / ID Proof",
        slug: "passport-id-proof",
      },
      {
        label: "Address Proof",
        slug: "address-proof",
      },
      {
        label: "Letter of Resignation",
        slug: "letter-of-resignation",
      },
    ],
    COA: [
      {
        label: "Resolution of Change in Administration",
        slug: "resolution-of-change-in-administration",
      },
      {
        label: "Client Information Sheet",
        slug: "client-information-sheet",
      },
      {
        label: "KYC 01",
        slug: "kyc-01",
      },
      {
        label: "KYC 02",
        slug: "kyc-02",
      },
      {
        label: "KYC 03",
        slug: "kyc-03",
      },
    ],
    COC: [
      {
        label: "Passport / ID Proof",
        slug: "passport-id-proof",
      },
      {
        label: "Address Proof",
        slug: "address-proof",
      },
      {
        label: "Letter of Appointment as Contact Point",
        slug: "letter-of-appointment-as-contact-point",
      },
    ],
    TO: [
      {
        label: "Letter of Request - Transfer Out",
        slug: "letter-of-request-transfer-out",
      },
      {
        label: "New RA Acceptance Letter",
        slug: "new-ra-acceptance-letter",
      },
    ],
    CO: [],
    AMAA: [],
    ARMA: [],
    Apostilles: [],
    CGS: [],
    RCAD: [],
    RUD: [],
    RPEC: [],
    CI: [],
    CCPD: [],
    ARD: [],
    ARM: [],
    Continuations: [],
    DR: [],
    FRMC: [],
    FAR: [],
    FTO: [],
    IA: [],
    Notarizations: [],
    PA: [],
    RSC: [],
    RNR: [],
    FRRA: [],
    RFC: [],
    FNFC: [],
    EFF: [],
  };

  const getSelectType = (value, obj) => {
    // console.log(obj);
    setSelectedType(obj);
    getFeeDetails(value);
  };

  const getFeeDetails = (value) => {
    switch (value) {
      case "COI":
        setServiceFee(60);
        break;
      case "COGS":
        setServiceFee(100);
        break;
      case "ROD":
        setServiceFee(50);
        break;
      case "SR":
        setServiceFee(120);
        break;
      case "COD":
        setServiceFee(150);
        break;
      case "COSH":
        setServiceFee(100);
        break;
      case "COA":
        setServiceFee(80);
        break;
      case "COC":
        setServiceFee(200);
        break;
      case "TO":
        setServiceFee(170);
        break;
      case "CO":
        setServiceFee(50);
        break;
      default:
        break;
    }
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <h1 className="page-title mb-4">Post Incorp</h1>
      <h2 className="primary-title mb-4">Post Incorp List</h2>
      <div className="row custom-form mb-4 align-items-end gap-ms-2">
        <div className="col-md-3">
          <label htmlFor="" className="me-3">
            Select Service
          </label>
          <Select
            className="custom-select w-100"
            placeholder="Select"
            value={selectedType}
            disabled={isDisabled}
            onChange={getSelectType}
            options={postIncorpType}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="" className="me-3">
            Service Fee
          </label>
          <input
            type="text"
            className="w-100"
            value={`$${serviceFee}`}
            disabled
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="" className="me-3">
            Bank Reference
          </label>
          <input
            type="text"
            className="w-100"
            placeholder="Enter bank reference"
          />
        </div>
      </div>
      <div className="row custom-form mb-4 align-items-end gap-ms-2">
        <div className="col-md-3">
          <label htmlFor="">Client Name</label>
          <input
            type="text"
            className="w-100"
            placeholder="Enter client name"
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="" className="me-3">
            Client Type
          </label>
          <Select
            className="custom-select w-100"
            placeholder="Select"
            onChange={handleChange}
            options={[
              {
                value: "IntermediaryClient",
                label: "Intermediary Client",
              },
              {
                value: "endUserClient",
                label: "End User Client",
              },
            ]}
          />
        </div>
      </div>
      <div className="row custom-form mb-4 align-items-end gap-ms-2">
        <div className="col-md-3">
          <label htmlFor="">Entity Name</label>
          <input
            type="text"
            className="w-100"
            placeholder="Enter entity name"
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="">Entity Code</label>
          <input
            type="text"
            className="w-100"
            placeholder="Enter entity code"
          />
        </div>
      </div>
      <div>
        <Link to={"/app/active-requests"} className="primary-btn">
          Submit Request
        </Link>
      </div>
      {selectedType && (
        <div className="container-fluid px-0 mt-5">
          <h2 className="primary-title mb-4">{selectedType?.label}</h2>
          <div className="custom-form">
            <div className="row">
              <Each
                array={docUploadSchema[selectedType?.value]}
                render={(item) => (
                  <div className="col-md-3 mb-4">
                    <label htmlFor={item.slug} className="me-3">
                      {item.label}
                    </label>
                    <UploadZ
                      onStoreFile={storeDocuments}
                      fileName={item.slug}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
