import { useState } from "react";
import { Checkbox } from "antd";

import { Each, focusOn, sendNotify, showCount } from "../../../helper";
import UploadZ from "../../../components/UploadZ";

export default function CorporateKYC(props) {
  let { getName, getDocument, clearUpload } = props;

  const [corporate, setCorporate] = useState([1]);

  const addNewCorporate = () => {
    let arr = [...corporate];
    if (arr.length < 10) {
      arr.push(corporate.length + 1);
      setCorporate(arr);
      focusOn("corporate" + (arr.length - 1));
    } else {
      sendNotify("error", "Your reach the maximum level of corporate entity");
    }
  };

  return (
    <div>
      <Each
        array={corporate}
        render={(item, index) => (
          <CorporateDocuments
            key={index}
            index={item}
            getName={getName}
            getDocument={getDocument}
            clearUpload={clearUpload}
          />
        )}
      />
      <button className="primary-btn mt-4" onClick={addNewCorporate}>
        <i className="fal fa-plus"></i> Add New Corporate Entity
      </button>
    </div>
  );
}

function CorporateDocuments(props) {
  let { index, getName, getDocument, clearUpload } = props;

  const [documents, setDocuments] = useState([]);
  const [docList, setDocList] = useState([
    "Incorporation Certificate",
    "M&AA",
    "Share Registry",
    "ROD",
    "Seal",
  ]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [formToggle, setFormToggle] = useState(true);

  function storeDocuments(file) {
    let doc = [...documents];
    let arrIndex = doc.findIndex((d) => d.document == file.document);
    if (arrIndex != -1) {
      doc[arrIndex] = file;
    } else {
      doc.push(file);
    }
    setDocuments(doc);
    getDocument(doc, "corporateEntity", `corporate_${showCount(index)}`);
  }

  const deleteDocument = (name) => {
    let doc = [...documents];
    let arrIndex = doc.findIndex((d) => d.document == name);
    if (arrIndex != -1) {
      doc.splice(arrIndex, 1);
    }
    setDocuments(doc);
    getDocument(doc, "corporateEntity", `corporate_${showCount(index)}`);
  };

  const directors = [
    "Consent to Act as Director",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Directors",
  ];
  const secretary = [
    "Secretary Consent to Act",
    "Secretary Resolution of Appointment",
    "Secretary Resolution of Resignation",
    "Secretary Letter of Resignation",
  ];
  const president = [
    "President Consent to Act",
    "President Resolution of Appointment",
    "President Resolution of Resignation",
    "President Letter of Resignation",
  ];
  const shareholders = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
    "Share Transfer Agreement",
  ];

  const ubos = [
    "UBO source of funds document",
    "Source of Wealth Proof",
    "Resolution of Shareholding",
  ];

  const poc = ["Contact Details"];

  const nominee = ["Nominee Agreement"];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i] === values[index]) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const addNewChange = (values, checkStatus, name) => {
    let arr = [...docList];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setDocList(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    let arr = [...docList];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(nominee, arr);
        setShareholderChecked(true);
        setNomineeChecked(false);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }
    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(shareholders, arr);
        setNomineeChecked(true);
        setShareholderChecked(false);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }

    setDocList(arr);
  };

  return (
    <div
      className={`custom-box mb-4 collapse-form ${!formToggle ? "closed" : ""}`}
    >
      <div className="d-flex justify-content-between">
        <span className="light-badge">
          <i className="fal fa-building"></i> Corporate Entity&nbsp;
          {showCount(index)}
        </span>
        <button
          className="collapse-arrow"
          onClick={() => setFormToggle(!formToggle)}
        >
          <i
            className={`fal fa-chevron-up ${
              !formToggle ? "fa-rotate-180" : ""
            }`}
          ></i>
        </button>
      </div>
      <div className="form-content">
        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4 my-4">
          <Checkbox
            className="custom-checkbox"
            checked={directorChecked && !reserveChecked}
            onChange={(e) =>
              addNewRow(directors, e.target.checked, "directors")
            }
          >
            Directors
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            checked={shareholderChecked && !nomineeChecked}
            onChange={(e) =>
              addNewRow(shareholders, e.target.checked, "shareholders")
            }
          >
            Shareholders
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            onChange={(e) => addNewChange(ubos, e.target.checked)}
          >
            UBOs
          </Checkbox>
          {/* <Checkbox
          className="custom-checkbox"
          onChange={(e) => addNewChange(poc, e.target.checked)}
        >
          POC
        </Checkbox> */}
          <Checkbox
            className="custom-checkbox"
            checked={nomineeChecked && !shareholderChecked}
            onChange={(e) => addNewRow(nominee, e.target.checked, "nominee")}
          >
            Nominee Shareholder
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            checked={reserveChecked && !directorChecked}
            onChange={(e) => addNewRow(directors, e.target.checked, "reserve")}
          >
            Reserve Director
          </Checkbox>

          <Checkbox
            className="custom-checkbox"
            onChange={(e) =>
              addNewChange(president, e.target.checked, "president")
            }
          >
            President
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            onChange={(e) =>
              addNewChange(secretary, e.target.checked, "secretary")
            }
          >
            Secretary
          </Checkbox>
        </div>
        <div className="row mb-4">
          <div className="col-md-4 custom-form">
            <label htmlFor="name">Corporate Entity Name</label>
            <input
              className="w-100"
              type="text"
              placeholder="Enter corporate entity name"
              name="name"
              id="name"
              onChange={(e) =>
                getName(
                  e.target.value,
                  "corporateEntity",
                  `corporate_${showCount(index)}`
                )
              }
            />
          </div>
        </div>
        <div className="row custom-form gx-4">
          <Each
            array={docList}
            render={(item) => (
              <div className="col-md-3">
                <label htmlFor={item} className="me-3">
                  {item}
                </label>
                <UploadZ
                  onStoreFile={storeDocuments}
                  fileName={item}
                  onDeleteFile={deleteDocument}
                  clearUpload={clearUpload}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}
