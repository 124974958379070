import { useState, useEffect, useRef } from "react";
import { Modal, Radio } from "antd";

import { useSelector, useDispatch } from "react-redux";

import {
  getSignature,
  deleteSignature,
} from "../store/signature/signatureSlice";

import SignatureCanvas from "react-signature-canvas";

import NoSignature from "../assets/img/no-signature.png";

import FormZ from "./FormZ";
import TabZ from "./TabZ";

import { sendNotify, fileToBase64, fetchApi } from "../helper";

import DeleteConfirmationModal from "../components/DeleteSignatureConfirmationModal";

export default function AddSignature({ open, onOk, onCancel, setvalue }) {
  const signFormRef = useRef();
  const signImgRef = useRef();

  let signPad = {};

  let signFormSchema = [
    {
      name: "fullName",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name",
      required: true,
    },
    {
      name: "jobTitle",
      value: "",
      type: "text",
      label: "Job Title",
      placeholder: "Enter job title",
      required: true,
    },
  ];
  const dispatch = useDispatch();
  const signature = useSelector((state) => state.signature);

  const [signModal, setSignModal] = useState(false);
  const [createSignModal, setCreateSignModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [signing, setSigning] = useState(false);
  const [signImgError, setSignImgError] = useState(false);
  const [signImgLoading, setSignImgLoading] = useState(false);
  const [newSign, setNewSign] = useState(null);
  const [previewSign, setPreviewSign] = useState("");
  const [signatureArray, setSignatureArray] = useState([]);
  const [queryParams, setQueryParams] = useState({
    _start: 0,
    _limit: 30,
    status: "active",
  });
  const [selectedSign, setSelectedSign] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [signatureToDelete, setSignatureToDelete] = useState(null);

  useEffect(() => {
    dispatch(getSignature(queryParams));
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [signature]);

  useEffect(() => {
    setSignModal(open);
  }, [open]);

  const handleRefresh = async () => {
    if (signature?.data?.rows.length) setSignatureArray(signature?.data?.rows);
  };
  const handleOk = () => {
    setSignModal(false);
    // setvalue(newSign);
    if (onOk !== undefined) {
      onOk(false);
    }
  };
  const handleCancel = () => {
    setSignModal(false);
    if (onCancel !== undefined) {
      onCancel(false);
    }
  };

  const handleRadioSelect = (signature) => {
    setSelectedSign(signature);
  };

  const showDeleteConfirmation = (signatureId, fullName) => {
    setSignatureToDelete({ id: signatureId, fullName: fullName });
    setDeleteConfirmationVisible(true);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteSignature(signatureToDelete.id));
      setDeleteConfirmationVisible(false);
      sendNotify("success", "Signature deleted successfully.");
    } catch (error) {
      sendNotify("error", "Error deleting signature.");
      setDeleteConfirmationVisible(false);
    }
  };

  const labels = [
    {
      key: 1,
      label: (
        <>
          <i className="far fa-pen"></i> DRAW
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <i className="far fa-arrow-to-top"></i> UPLOAD
        </>
      ),
    },
  ];

  const onTabChange = (value) => {
    clearSign();
    setActiveTab(value);
  };

  const clearSign = () => {
    if (activeTab == 1) {
      signPad.clear();
      setSigning(false);
    } else {
      setPreviewSign("");
    }
    setNewSign(null);
  };

  const saveSign = () => {
    let sign = signPad.toDataURL();
    console.log(sign, " :sign");
    setNewSign(sign);
  };
  const createSignature = async () => {
    const valid = signFormRef.current.validForm();
    if (!valid) {
      sendNotify("error", "Please enter required details");
      return;
    }
    const blob = base64ToBlob(newSign);
    const imgUrl = new File([blob], "image.png", { type: "image/png" });
    if (valid) {
      let formdata = signFormRef.current.getPayload();
      const bodyFormData = new FormData();
      bodyFormData.append("file", imgUrl);
      bodyFormData.append("folder", "test");
      bodyFormData.append("fullName", formdata.fullName);
      bodyFormData.append("jobTitle", formdata.jobTitle);

      let reportPayload = {
        method: "POST",
        url: `/signature`,
        data: bodyFormData,
      };

      fetchApi(reportPayload)
        .then((res) => {
          sendNotify("success", res?.message);
          dispatch(getSignature(queryParams));
          setCreateSignModal(false);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    }
  };

  const base64ToBlob = (base64, type = "image/png") => {
    const byteCharacters = atob(base64.split(",")[1]); // Decode the base64 string
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: type });
  };

  const onImgUpload = (e) => {
    setSignImgError(false);
    let file = e.target.files[0];
    if (file) {
      if (file?.size > 5242880) {
        setSignImgError(true);
        sendNotify("error", "Image is too big!, Upload below 5MB file.");
      } else {
        let type = file?.name.substring(file?.name.lastIndexOf(".") + 1);
        var regex = new RegExp("(.*?)(png|jpg|jpeg|svg)$");
        if (regex.test(type)) {
          setSignImgLoading(true);
          setPreviewSign(URL.createObjectURL(file));
          fileToBase64(file)
            .then((data) => {
              setNewSign(data);
              sendNotify("success", "Image uploaded successfully.");
              setSignImgLoading(false);
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        } else {
          setSignImgError(true);
          sendNotify(
            "error",
            "Only PNG, JPG, JPEG, or SVG images are supported."
          );
        }
      }
    }
  };

  return (
    <div>
      <DeleteConfirmationModal
        visible={deleteConfirmationVisible}
        onConfirm={handleDelete}
        onCancel={() => setDeleteConfirmationVisible(false)}
        signatureName={signatureToDelete?.fullName}
      />
      <Modal
        title={
          <>
            <h6>Choose Signature</h6>
            <p>
              Choose a saved signature to use in this document or create a new
              one.
            </p>
          </>
        }
        open={signModal}
        className="custom-modal-2"
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        footer={false}
      >
        {true ? (
          <div>
            <div className="signature-header">
              <div className="header-item">Name</div>
              <div className="header-item">Designation</div>
              <div className="header-item">Signature</div>
              <div className="header-item"></div>
            </div>
            <div className="signature-option">
              <Radio.Group className="w-100">
                <div className="custom-scroll">
                  <table className="w-100">
                    <tbody>
                      {signatureArray.map((a, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Radio
                                className="custom-radio"
                                checked={selectedSign === a}
                                onChange={() => handleRadioSelect(a)}
                                value={index + 1}
                              >
                                {a.fullName}
                              </Radio>
                            </td>
                            <td>
                              <label htmlFor="">{a.jobTitle}</label>
                            </td>
                            <td>
                              <img
                                src={`data:image/png;base64, ${a.url}`}
                                alt=""
                              />
                            </td>
                            {/* <td>
                              <button
                                className="delete-btn"
                                onClick={() =>
                                  showDeleteConfirmation(a._id, a.fullName)
                                }
                              >
                                Delete
                              </button>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Radio.Group>
            </div>
            <div className="d-flex justify-content-end gap-3 mt-2">
              <button
                className="outline-btn"
                onClick={() => setCreateSignModal(true)}
              >
                Create New Signature
              </button>
              <button
                className="primary-btn"
                onClick={() => setvalue(selectedSign)}
                disabled={!selectedSign}
              >
                Use Signature
              </button>
            </div>
          </div>
        ) : (
          <div className="no-sign">
            <img src={NoSignature} alt="" />
            <p>It looks like you don’t have any saved signatures yet.</p>
            <button
              className="primary-btn mx-auto mt-3"
              onClick={() => setCreateSignModal(true)}
            >
              Create New Signature
            </button>
          </div>
        )}
      </Modal>
      <Modal
        title={
          <>
            <h6>Create New Signature</h6>
            <p>Draw or upload an image of your signature.</p>
          </>
        }
        open={createSignModal}
        className="custom-modal-2"
        onOk={createSignature}
        okText={"Create Signature"}
        onCancel={() => setCreateSignModal(false)}
        width={500}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <TabZ
            labels={labels}
            defaultActive={activeTab}
            onChange={onTabChange}
          />
          <div
            className={`signature-wrapper mt-4 ${
              !signing && activeTab == 1 ? "sign-placeholder" : ""
            }`}
          >
            {activeTab == 1 ? (
              <SignatureCanvas
                onBegin={() => setSigning(true)}
                onEnd={saveSign}
                ref={(ref) => {
                  signPad = ref;
                }}
                canvasProps={{ width: 436, height: 120 }}
              />
            ) : previewSign ? (
              <img src={previewSign} className="sign-img" alt="sign" />
            ) : (
              <div className="sing-img-upload">
                <input
                  ref={signImgRef}
                  type="file"
                  name="sign-img"
                  onChange={onImgUpload}
                  accept=".png,.jpg,.jpeg,.svg"
                />
                <span className="icon">
                  {signImgLoading ? (
                    <i className="far fa-spinner-third fa-spin"></i>
                  ) : (
                    <>
                      {signImgError ? (
                        <i className="fal fa-times-circle color-red"></i>
                      ) : (
                        <i className="fal fa-arrow-from-bottom"></i>
                      )}
                    </>
                  )}
                </span>
                <p>PNG, JPG or SVG (max. 5MB)</p>
              </div>
            )}
            {(signing || previewSign) && (
              <button className="clear-btn" onClick={clearSign}>
                <i className="far fa-trash"></i>
              </button>
            )}
          </div>
          <FormZ
            formSchema={signFormSchema}
            ref={signFormRef}
            formClass="custom-form row mt-2 mb-4 gy-3 gx-4"
            childClass="col-md-12 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
        </div>
      </Modal>
    </div>
  );
}
