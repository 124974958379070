import React from "react";
import CorporateEntity from "../components/CorporateEntity";

export default function CorporateEntityAnalysis() {
  return (
    <div>
      <h1 className="page-title mb-3 mb-md-4">Corporate Entity Analysis</h1>
      <CorporateEntity actionShow={true} />
    </div>
  );
}
