import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";

export default function RequestResubmission({ id, documents, type }) {
  const [explainModel, setExplainModel] = useState(false);
  const [explainLoading, setExplainLoading] = useState(false);
  const [comments, setComments] = useState({});

  const handleResubmit = () => {
    let docs = type ? documents["documents"] : documents;
    let rejected = docs.filter((doc) => doc.rejected == true);
    if (rejected.length > 0) {
      setExplainModel(true);
    } else {
      sendNotify(
        "error",
        "Select any document before the request resubmission."
      );
    }
  };

  const handleUpdate = async (data, message) => {
    let payload = {
      method: "PUT",
      url: `/serviceRequest/update/${id}`,
      data,
    };
    await fetchApi(payload)
      .then((res) => {
        sendNotify("success", message ?? res?.message);
        // handleRefresh();
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const getComments = (data, key) => {
    let obj = { ...comments };
    obj[key] = data;
    setComments(obj);
  };

  const handleOk = () => {
    setExplainLoading(true);
    let data = {
      resubmissionComment: comments,
      resubmissionStatus: "Pending",
      resubmissionOriginAt: new Date(),
    };

    data[type ?? "documents"] = documents;

    handleUpdate(data, "Resubmission request sent").then((res) => {
      setExplainModel(false);
      setExplainLoading(false);
      setComments({});
    });
  };

  const handleCancel = () => {
    setExplainModel(false);
  };

  return (
    <div>
      <button className="secondary-btn" onClick={handleResubmit}>
        Request Resubmission
      </button>
      <Modal
        className="custom-modal-2"
        open={explainModel}
        width={800}
        onOk={handleOk}
        okButtonProps={{ loading: explainLoading }}
        onCancel={handleCancel}
        okText="Request Resubmission"
        cancelButtonProps={{ className: "d-none" }}
      >
        <Spin spinning={explainLoading}>
          <div className="custom-form mt-4 pt-2">
            <h6 className="input-title">Resolution of Appointment</h6>
            <p className="description mb-3">
              Explain why this document needs to be resubmitted.
            </p>
            <textarea
              name=""
              id=""
              className="mb-4"
              placeholder="Leave a comment..."
              value={comments?.appointment}
              onChange={(e) => getComments(e.target.value, "appointment")}
            ></textarea>

            <h6 className="input-title">Letter of Resignation</h6>
            <p className="description mb-3">
              Explain why this document needs to be resubmitted.
            </p>
            <textarea
              name=""
              id=""
              className="mb-4"
              placeholder="Leave a comment..."
              value={comments?.resignation}
              onChange={(e) => getComments(e.target.value, "resignation")}
            ></textarea>
          </div>
        </Spin>
      </Modal>
    </div>
  );
}
