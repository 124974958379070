import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { Popover } from "antd";

import { getNewNotifications } from "../store/newNotifications/newNotificationsSlice";
import { chatTime, eachChatTextTime, timeDiff } from "../helper";
import Logo from "../assets/img/logo-icon.png";
import NoDataImg from "../assets/img/no-notifications.jpg";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function NotificationBtn() {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.newNotification);
  let { isLogged, userDetails } = useSelector((state) => state.auth);
  const [notificationArray, setNotificationArray] = useState([]);

  useEffect(() => {
    dispatch(getNewNotifications({ read: "W" }));
    configureSocket();
  }, []);

  useEffect(() => {
    setNotificationArray(data?.rows);
  }, [data]);

  const configureSocket = () => {
    socket.on("connection", () => {
      console.log(" Connected backend");
    });
    socket.on("message", (message) => {
      console.log(message, "dzffhbzd");
      // if (message.chat_to === employer.employerId) {
      //     getContacts();
      //     dispatch(getUnreadMessageCount());
      // }
    });
    socket.on("notification", (notification) => {
      if (notification.userId === userDetails._id) {
        dispatch(getNewNotifications({ read: "W" }));
        checkUserNotification(notification);
      }
    });

    const checkUserNotification = (notification) => {
      const notifyContent = {
        title: "Notification from Galactic!",
        body: "You got a new message from company",
        icon: Logo,
        link: notification.link,
      };
      pushNotification(notifyContent);
      //  };
    };

    const pushNotification = (notifyContent) => {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        var messageNotify = new Notification(notifyContent.title, {
          body: notifyContent.body,
          icon: notifyContent.icon,
        });
        messageNotify.onclick = () => window.open(notifyContent.link);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            var messageNotify = new Notification(notifyContent.title, {
              body: notifyContent.body,
              icon: notifyContent.icon,
              color: "#2095f2",
              sound: "default",
            });
            messageNotify.onclick = () => window.open(notifyContent.link);
          }
        });
      }
    };
  };

  const text = (
    <div className="notification-header">
      <h6>Notification's</h6>
      {/* <div className="d-flex align-items-center gap-2">
        <p>Do not disturb</p>
        <Switch
          className="custom-switch"
          size="small"
          value={mute}
          onChange={toggleMute}
          checkedChildren="ON"
          unCheckedChildren="OFF"
        />
      </div> */}
    </div>
  );

  const content = (
    <div className="notification-content">
      <div className="notification-wrap">
        {notificationArray && notificationArray.length > 0 ? (
          notificationArray.map((m, index) => {
            if (index < 5) {
              return (
                <Link
                  to={"/app/notifications"}
                  className="notification-link"
                  key={index}
                >
                  <div
                    className={`notification-item unread ${
                      m.type == "alert" && "import"
                    }`}
                  >
                    <div className="notification-text">
                      <h6>{m.title}</h6>
                      <p>{timeDiff(m.createdAt, "hh:mm a - DD/MM/YYYY")}</p>
                    </div>
                  </div>
                </Link>
              );
            }
          })
        ) : (
          <div className="no-notification">
            <img src={NoDataImg} alt="" />
            <h4>No Notification Yet</h4>
          </div>
        )}
      </div>
      <Link to={"/app/notifications"} className="view-all-notify-btn">
        See all notifications
      </Link>
    </div>
  );

  return (
    <Popover className="notification-popover" title={text} content={content}>
      <button className="notification-btn">
        <i className="fal fa-bell"></i>
        {data?.count > 0 && (
          <span className="notification-count">{data?.count}</span>
        )}
      </button>
    </Popover>
  );
}
