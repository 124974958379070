import { useState } from "react";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default function SearchFilter(props) {
  let {
    dataIndex,
    inputType,
    options,
    queryParams,
    handleSearch,
    handleReset,
  } = props;

  const [searchFilters, setSearchFilters] = useState({});

  const onReset = (key) => {
    let obj = { ...searchFilters };
    delete obj[key];
    setSearchFilters(obj);
    handleReset(key);
  };

  const getSearchValue = (key, value) => {
    let obj = { ...searchFilters };
    obj[key] = value;
    setSearchFilters(obj);
  };

  return {
    filterDropdown: ({ close }) => {
      const getInputType = (key) => {
        switch (key) {
          case "text":
            return (
              <input
                type="text"
                placeholder="Search any"
                value={searchFilters[dataIndex] ?? ""}
                className="w-100 mb-2"
                onChange={(e) => getSearchValue(dataIndex, e.target.value)}
                onKeyPress={(e) =>
                  e.key === "Enter" && handleSearch(searchFilters)
                }
              />
            );
          case "select":
            return (
              <Select
                showSearch
                placeholder="Select any"
                value={searchFilters[dataIndex]}
                options={options}
                className="custom-select mb-2 w-100"
                onChange={(value) => getSearchValue(dataIndex, value)}
              />
            );
          case "dateRange":
            return (
              <RangePicker
                value={[
                  searchFilters?.[dataIndex]
                    ? dayjs(searchFilters?.[dataIndex]?.[0])
                    : null,
                  searchFilters?.[dataIndex]
                    ? dayjs(searchFilters?.[dataIndex]?.[1])
                    : null,
                ]}
                className="custom-datepicker mb-2"
                onChange={(date, dateString) =>
                  getSearchValue(dataIndex, dateString)
                }
              />
            );
        }
      };
      return (
        <div className="custom-form p-3" onKeyDown={(e) => e.stopPropagation()}>
          {getInputType(inputType)}
          <div className="d-flex gap-2 align-items-center">
            <button
              className="primary-btn w-100 justify-content-center py-2"
              onClick={() => handleSearch(searchFilters)}
            >
              Search
            </button>
            <button
              onClick={() => onReset(dataIndex)}
              className="outline-btn py-2"
            >
              Reset
            </button>
            <button
              onClick={() => {
                close();
              }}
              className="outline-btn red-btn py-2"
            >
              Close
            </button>
          </div>
        </div>
      );
    },
    filterIcon: () => (
      <span className={queryParams[dataIndex] ? "text-primary" : ""}>
        <i
          className={`fas fa-search${queryParams[dataIndex] ? "-plus" : ""}`}
        ></i>
      </span>
    ),
  };
}
