import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import TableSortArrows from "../../components/tableSortArrows";
import TagZ from "../../components/TagZ";
import {
  customPagination,
  getOptions,
  focusOn,
  fetchApi,
  sendNotify,
} from "../../helper";
import { Link } from "react-router-dom";
import { getBusinessPartners } from "../../store/businessPartners/businessPartnersSlice";
import TableFilterZ from "../../components/TableFilterZ";
import TabZ from "../../components/TabZ";
import InProgressIcon from "../../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../../assets/img/approved-icon.svg";
const { success, confirm } = Modal;

export default function BusinessPartners() {
  const dispatch = useDispatch();
  const businessPartners = useSelector((state) => state.businessPartners);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [data, setDate] = useState([]);
  const [selectedCatIds, setSelectedCatIds] = useState([]);
  const [dates, setDates] = useState([{ id: 1, value: "" }]);
  const [showLeavemodal, setShowLeavemodal] = useState(false);
  useEffect(() => {
    let params = { ...queryParams };

    dispatch(getBusinessPartners(params));
  }, []);
  useEffect(() => {
    handleRefresh();
  }, [businessPartners]);

  const columns = [
    {
      title: "Reference ID",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.referenceID - b.referenceID,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { referenceID, id }) => (
        <Link to={`/app/business-partners/edit/${id}`} className="table-link">
          {referenceID}
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "userName",
      filterType: "text",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      filterType: "text",
      sorter: (a, b) => a.email - b.email,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      sorter: (a, b) => a.phone - b.phone,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status ? "Active" : "Archived"}
        />
      ),
    },
  ];
  const handleRefresh = async () => {
    if (businessPartners?.data?.rows) {
      let finded = businessPartners?.data?.rows;

      let data = finded.map((a, index) => {
        let obj = {
          key: a._id,
          id: a._id,
          referenceID: a.code,
          name: a.userName,
          email: a.email,
          phone: a.phoneNumber,
          status: a.status == "Active" ? true : false,
        };
        return obj;
      });
      setDate(data);
    }
  };
  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getBusinessPartners(params));
    setQueryParams(params);
  };
  const getFilter = (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getBusinessPartners(params));
    setQueryParams(params);
  };
  const getProgress = (value) => {
    let params = { ...queryParams };
    params.status = value;
    dispatch(getBusinessPartners(params));
  };

  const handleOk = () => {
    success({
      title: <h5>Data Selection Successful</h5>,
      content: (
        <>
          <p>
            You have selected Business Partner data successfully. An email will
            be sent to you shortly.
          </p>
        </>
      ),
      className: "custom-confirm",
      centered: true,
      closable: true,
      okText: "Login",
      onOk() {
        sendMail();
      },
      onCancel() {
        //  showLeavemodal?true:false
      },
    });
  };
  const handleCancel = () => {
    setDates([{ id: 1, value: "" }]);
    setShowLeavemodal(false);
  };
  const saveDate = () => {};
  function transformHolidays(holidays) {
    const uniqueDates = Array.from(
      new Set(holidays.map((holiday) => holiday.value))
    ).sort();

    return uniqueDates;
  }

  const sendMail = async () => {
    let holidays = transformHolidays(dates);
    let obj = {
      ids: selectedCatIds,
      holidays,
    };
    let payload = {
      method: "post",
      url: "/admin/signup-request",
      data: obj,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        handleCancel();
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  // const data = [
  //   {
  //     key: "1",
  //     id: "1",
  //     referenceID: "BP0001",
  //     name: "Business Partner 1",
  //     email: "bp1@company.com",
  //     phone: "+1 (420) 9027 6393",
  //     status: true,
  //   },
  //   {
  //     key: "2",
  //     id: "2",
  //     referenceID: "BP0002",
  //     name: "Business Partner 2",
  //     email: "bp1@company.com",
  //     phone: "+1 (420) 9027 6393",
  //     status: false,
  //   },
  //   {
  //     key: "3",
  //     id: "3",
  //     referenceID: "BP0003",
  //     name: "Business Partner 3",
  //     email: "bp1@company.com",
  //     phone: "+1 (420) 9027 6393",
  //     status: true,
  //   },
  //   {
  //     key: "4",
  //     id: "4",
  //     referenceID: "BP0004",
  //     name: "Business Partner 4",
  //     email: "bp1@company.com",
  //     phone: "+1 (420) 9027 6393",
  //     status: true,
  //   },
  // ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCatIds(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },

    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const labels = [
    {
      key: "Active",
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> Active
        </>
      ),
    },
    {
      key: "Inactive",
      label: (
        <>
          <img src={InProgressIcon} alt="" /> Inactive
        </>
      ),
    },
  ];

  const addDate = () => {
    const newDate = { id: dates.length + 1, value: "" };
    setDates([...dates, newDate]);
  };

  const removeDate = (index) => {
    const newDates = dates.filter((_, i) => i !== index);
    setDates(newDates);
  };
  const handleDateChange = (index, date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
    setDates(
      dates.map((d, i) => (i === index ? { ...d, value: formattedDate } : d))
    );
  };

  return (
    <div>
      <div className="page-header">
        <h1>Business Partners</h1>
        <Link to="/app/business-partners/add" className="primary-btn">
          <i className="fal fa-plus"></i> Add Business Partner
        </Link>
      </div>

      <div className="page-content">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <TabZ
            labels={labels}
            defaultActive={"Active"}
            customClass={"table-tabs"}
            onChange={getProgress}
          />
          <div className="d-flex gap-3 align-items-center">
            <button
              className="primary-btn"
              onClick={() => setShowLeavemodal(true)}
            >
              Register
            </button>
            <TableFilterZ
              tableCol={columns}
              onChange={getFilter}
              onClick={""}
            />
          </div>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: businessPartners?.data?.totalCount,
            onChange: getPagination,
            itemRender: customPagination,
          }}
        />
      </div>
      <Modal
        title={"Holiday"}
        className="custom-modal"
        open={showLeavemodal}
        centered={true}
        width={400}
        onOk={handleOk}
        okText="Create"
        onCancel={handleCancel}
      >
        <div className="custom-form">
          <label>Add Public Holiday (optional)</label>
          {dates.map((date, index) => (
            <div key={date.id} className="d-flex align-items-center gap-3 mb-3">
              <DatePicker
                value={date.value ? dayjs(date.value, "YYYY-MM-DD") : null}
                onChange={(date) => handleDateChange(index, date)}
                className="custom-datepicker w-100"
                format="YYYY-MM-DD"
                style={{
                  flex: 1,
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
              {index !== 0 && (
                <button
                  className="delete-btn"
                  onClick={() => removeDate(index)}
                >
                  <i className="far fa-trash"></i>
                </button>
              )}
            </div>
          ))}
          <button onClick={addDate} className="primary-btn">
            <i class="fal fa-calendar-plus"></i> Add New Date
          </button>
        </div>
      </Modal>
    </div>
  );
}
