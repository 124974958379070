import { useState } from "react";
import { Checkbox } from "antd";

import { Each, focusOn, sendNotify, showCount } from "../../../helper";
import UploadZ from "../../../components/UploadZ";

export default function CorporateKYC(props) {
  let { data, getName, getDocument, clearUpload, removeElement } = props;

  const [corporate, setCorporate] = useState([1]);

  const addNewCorporate = () => {
    let arr = [...corporate];
    if (arr.length < 10) {
      arr.push(corporate.length + 1);
      setCorporate(arr);
      focusOn("corporate" + arr.length);
    } else {
      sendNotify("error", "Your reach the maximum level of corporate entity");
    }
  };

  const removeCorporate = (index) => {
    let arr = [...corporate];
    arr.splice(index, 1);
    removeElement("corporateEntity", index);
    setCorporate(arr);
  };

  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4 mb-4">
        <h2 className="primary-title mb-3 mb-md-0">
          Corporate Entity (Compressed)
        </h2>
        <button className="primary-btn" onClick={addNewCorporate}>
          <i className="fal fa-plus"></i> Add New Corporate Entity
        </button>
      </div>
      <div className="info-box mb-4">
        <h6>
          Where the beneficial owner, director, shareholder or officer is a body
          corporate (company), the following will need to be provided in support
          of the duly completed application form:
        </h6>
        <ol>
          <li>
            A certified or notarized copy of the Certificate of Incorporation or
            other equivalent governing constitution
          </li>
          <li>
            A certified or notarized copy of the Memorandum and Articles of
            Association or equivalent governing constitution
          </li>
          <li>An authorized signatory listing (bearing specimen signatures)</li>
          <li>An original Certificate of Good Standing</li>
          <li>
            Certified or notarized copies of the Registers of Directors,
            Shareholders, Beneficial Owners and Officers
          </li>
          <li>
            A statement signed by the company’s director(s) explaining the
            current nature of the company’s business and the usual source of the
            company’s funds (whether sales, loans, owner’s own funds, other
            –including combination of sources.)
          </li>
          <li>
            The following due diligence documentation on each director and each
            individual who owns ten or more percent of the company:
            <ul>
              <li>
                A certified or notarized copy of the identification page of a
                valid passport (certification wording to be the same as above).
              </li>
              <li>
                A certified/notarized copy or an original of a utility bill,
                bank statement or credit/debit card statement addressed to the
                respective individual at the mailing address given
              </li>
              <li>
                An original professional (e.g. accountant or lawyer) or bank
                reference.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className="row gy-2 gx-4">
        <Each
          array={corporate}
          render={(item, index) => (
            <CorporateDocuments
              key={index}
              element={index}
              index={item}
              data={data[index]}
              getName={getName}
              getDocument={getDocument}
              clearUpload={clearUpload}
              remove={removeCorporate}
            />
          )}
        />
      </div>
    </div>
  );
}

function CorporateDocuments(props) {
  let { index, element, data, getName, getDocument, clearUpload, remove } =
    props;

  const [documents, setDocuments] = useState([]);
  const [docList, setDocList] = useState([
    "Incorporation Certificate",
    "M&AA",
    "Share Registry",
    "ROD",
    "Seal",
  ]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [checkedList, setCheckedList] = useState({
    directors: false,
    shareholders: false,
    ubo: false,
    nominee: false,
    reserve: false,
    secretary: false,
    president: false,
  });
  function storeDocuments(file) {
    let doc = [...documents];
    doc.checkedList = checkedList;
    let arrIndex = doc?.findIndex((d) => d?.document == file?.document);
    if (arrIndex != -1) {
      doc[arrIndex] = file;
    } else {
      doc.push(file);
    }
    setDocuments(doc);
    getDocument(doc, "corporateEntity", `corporate_${showCount(index)}`);
  }

  const deleteDocument = (name) => {
    let doc = [...documents];
    doc.checkedList = checkedList;
    let arrIndex = doc?.findIndex((d) => d?.document == name);
    if (arrIndex != -1) {
      doc.splice(arrIndex, 1);
    }
    setDocuments(doc);
    getDocument(doc, "corporateEntity", `corporate_${showCount(index)}`);
  };
  function updateChecked(key, checked) {
    setCheckedList((prevCheckedList) => {
      const updatedCheckedList = { ...prevCheckedList };

      if (key === "nominee" && checked) {
        updatedCheckedList["shareholders"] = false;
      }
      if (key === "shareholders" && checked) {
        updatedCheckedList["nominee"] = false;
      }

      updatedCheckedList[key] = checked;

      return updatedCheckedList;
    });
  }
  const directors = [
    "Consent to Act as Director",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Directors",
  ];
  const secretary = [
    "Consent to Act as Secretary",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Secretarys",
  ];
  const president = [
    "Consent to Act as President",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Presidents",
  ];
  const shareholders = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
    "Share Transfer Agreement",
  ];

  const ubos = [
    "UBO source of funds document",
    "Source of Wealth Proof",
    "Resolution of Shareholding",
  ];

  const poc = ["Contact Details"];

  const nominee = ["Nominee Agreement"];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i] === values[index]) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const addNewChange = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);

    let arr = [...docList];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setDocList(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);

    let arr = [...docList];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(nominee, arr);
        setShareholderChecked(true);
        setNomineeChecked(false);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }
    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(shareholders, arr);
        setNomineeChecked(true);
        setShareholderChecked(false);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }

    setDocList(arr);
  };
  return (
    <div
      className="col-md-11 form-bordered position-relative my-3"
      id={`corporate${index}`}
    >
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4 mb-4">
        <h6 className="sub-title mb-0">Corporate Entity {showCount(index)}</h6>
        <Checkbox
          className="custom-checkbox"
          checked={directorChecked && !reserveChecked}
          onChange={(e) => addNewRow(directors, e.target.checked, "directors")}
        >
          Directors
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          checked={shareholderChecked && !nomineeChecked}
          onChange={(e) =>
            addNewRow(shareholders, e.target.checked, "shareholders")
          }
        >
          Shareholders
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) => addNewChange(ubos, e.target.checked, "ubo")}
        >
          UBOs
        </Checkbox>
        {/* <Checkbox
          className="custom-checkbox"
          onChange={(e) => addNewChange(poc, e.target.checked)}
        >
          POC
        </Checkbox> */}
        <Checkbox
          className="custom-checkbox"
          checked={nomineeChecked && !shareholderChecked}
          onChange={(e) => addNewRow(nominee, e.target.checked, "nominee")}
        >
          Nominee Shareholder
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          checked={reserveChecked && !directorChecked}
          onChange={(e) => addNewRow(directors, e.target.checked, "reserve")}
        >
          Reserve Director
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) =>
            addNewChange(president, e.target.checked, "president")
          }
        >
          President
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) =>
            addNewChange(secretary, e.target.checked, "secretary")
          }
        >
          Secretary
        </Checkbox>
        <button className="remove-btn" onClick={() => remove(element)}>
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 custom-form">
          <label htmlFor="name">Corporate Entity Name</label>
          <input
            className="w-100"
            type="text"
            placeholder="Enter corporate entity name"
            name="name"
            id="name"
            value={data?.name}
            onChange={(e) =>
              getName(
                e.target.value,
                "corporateEntity",
                `corporate_${showCount(index)}`
              )
            }
          />
        </div>
      </div>
      <div className="row custom-form gy-2 gx-4">
        <Each
          array={docList}
          render={(item) => (
            <div className="col-md-4">
              <label htmlFor={item} className="me-3">
                {item}
              </label>
              <UploadZ
                onStoreFile={storeDocuments}
                fileName={item}
                onDeleteFile={deleteDocument}
                clearUpload={clearUpload}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}
