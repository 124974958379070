import React, { useState } from "react";
import { Table, Button, Modal, Checkbox, Popconfirm, Input, Form } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const CustomizableTable = ({
  columns,
  initialData,
  showActionButton,
  showRowActions,
  onSave,
}) => {
  const defaultColumns = columns.filter((col) => col.isDefault);
  const nonDefaultColumns = columns.filter((col) => !col.isDefault);

  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const [data, setData] = useState(initialData);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNewColumns, setSelectedNewColumns] = useState(new Set());
  const [confirmedColumns, setConfirmedColumns] = useState(new Set());
  const [form] = Form.useForm();
  const [filteredData, setFilteredData] = useState(initialData);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => setEditingKey("");

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      setData(
        data.map((item) => (item.key === key ? { ...item, ...row } : item))
      );
      setEditingKey("");
    } catch (err) {
      console.log("Validation failed:", err);
    }
  };

  const handleDelete = (key) =>
    setData(data.filter((item) => item.key !== key));

  const handleColumnToggle = (checked, dataIndex) => {
    setSelectedNewColumns((prev) => {
      const newSet = new Set(prev);

      if (checked) {
        newSet.add(dataIndex);
      } else {
        newSet.delete(dataIndex);
      }

      const updatedFilteredData = data.map((row) => {
        const updatedRow = {};

        defaultColumns.forEach((col) => {
          updatedRow[col.dataIndex] = row[col.dataIndex];
        });

        newSet.forEach((col) => {
          if (row[col] !== undefined) {
            updatedRow[col] = row[col];
          }
        });

        return updatedRow;
      });

      setFilteredData(updatedFilteredData);

      if (onSave) {
        onSave(updatedFilteredData);
      }

      const updatedColumns = [
        ...defaultColumns,
        ...nonDefaultColumns.filter((col) => newSet.has(col.dataIndex)),
      ];
      setSelectedColumns(updatedColumns);

      return newSet;
    });
  };

  const EditableCell = ({ editing, dataIndex, children, ...restProps }) => (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );

  const modifiedColumns = [
    ...selectedColumns.map((col) => ({
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    })),
    ...(showRowActions
      ? [
          {
            title: "Actions",
            dataIndex: "operation",
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Button
                    onClick={() => save(record.key)}
                    icon={<SaveOutlined />}
                  />
                  <Popconfirm title="Cancel editing?" onConfirm={cancel}>
                    <Button icon={<CloseOutlined />} />
                  </Popconfirm>
                </span>
              ) : (
                <span>
                  <Button
                    disabled={editingKey !== ""}
                    onClick={() => edit(record)}
                    icon={<EditOutlined />}
                  />
                  <Popconfirm
                    title="Delete?"
                    onConfirm={() => handleDelete(record.key)}
                  >
                    <Button type="danger" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </span>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      {showActionButton && (
        <div className="input-table-add-row">
          <Button
            onClick={() => setIsModalOpen(true)}
            type="primary"
            className="add-row-button"
            icon={<PlusOutlined />}
          >
            Add new Entry
          </Button>
        </div>
      )}

      <Modal
        title="Select Columns to Display"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width="auto"
        style={{
          maxWidth: "50%",
          minWidth: "300px",
        }}
      >
        {nonDefaultColumns.map((col) => (
          <Checkbox
            key={col.dataIndex}
            onChange={(e) =>
              handleColumnToggle(e.target.checked, col.dataIndex)
            }
            checked={
              selectedNewColumns.has(col.dataIndex) ||
              confirmedColumns.has(col.dataIndex)
            }
          >
            {col.title}
          </Checkbox>
        ))}
      </Modal>

      <Form form={form} component={false}>
        <Table
          className="custom-table mb-4"
          components={{ body: { cell: EditableCell } }}
          bordered
          dataSource={data}
          columns={modifiedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </>
  );
};

export default CustomizableTable;
