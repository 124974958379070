import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, Checkbox } from "antd";
import { getClient } from "../store/client/clientSlice";
import { getIntroducerClient } from "../store/introducerClient/introducerClientSlice";
import { getParentClient } from "../store/parentClient/parentClientSlice";
import { getEndUserClient } from "../store/endUserClient/endUserClientSlice";
import { getEntity } from "../store/entity/entitySlice";
import { getCorporateEntity } from "../store/corporateEntity/corporateEntitySlice";
import { getIndividual } from "../store/individual/individualSlice";
import TableFilterZ from "../components/TableFilterZ";
import TabZ from "../components/TabZ";
import InProgressIcon from "../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../assets/img/approved-icon.svg";
import FormZ from "../components/FormZ";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

import {
  downloadAsCSV,
  getQuery,
  fetchApi,
  convertQueryParams,
  customPagination,
} from "../helper";

export default function MasterSearch() {
  let query = getQuery();
  const client = useSelector((state) => state.client);
  const parent = useSelector((state) => state.parentClient);
  const endUser = useSelector((state) => state.endUserClient);
  const entity = useSelector((state) => state.entity);
  const corporateEntity = useSelector((state) => state.corporateEntity);
  const individual = useSelector((state) => state.individual);
  const introducer = useSelector((state) => state.introducerClient);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [tableData, setTableData] = useState([]);
  const [action, setAction] = useState(null);
  const [filters, setFilters] = useState(null);
  const [entityShow, setEntityShow] = useState(false);
  const [totalCount, setTotalCount] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setAction(query.action ?? null);
      if (action == "client") {
        dispatch(getClient(queryParams));
      } else if (action == "introducerClient") {
        dispatch(getIntroducerClient(queryParams));
      } else if (action == "parent-client") {
        dispatch(getParentClient(queryParams));
      } else if (action == "end-user") {
        dispatch(getEndUserClient(queryParams));
      } else if (action == "entity") {
        dispatch(getEntity(queryParams));
      } else if (action == "corporateEntity") {
        dispatch(getCorporateEntity(queryParams));
      } else if (action == "individual") {
        dispatch(getIndividual(queryParams));
      } else {
        let params = { ...queryParams };
        params.isAll = true;
        let query = convertQueryParams(params);
        let payload = {
          method: "GET",
          url: `/client?${query}`,
        };
        let data = [];
        data = await fetchApi(payload).then((res) => {
          return res?.data;
        });
        setTotalCount(data.totalCount);
        setTableData(data.rows);
      }
    })();
  }, []);

  useEffect(() => {
    getData();
  }, [
    client,
    parent,
    endUser,
    entity,
    corporateEntity,
    individual,
    introducer,
  ]);
  const getData = async (value) => {
    let targetArray = [];
    if (action == "client") {
      setTableData(client?.data?.rows);
    } else if (action == "introducerClient") {
      setTableData(introducer?.data?.rows);
    } else if (action == "parent-client") {
      setTableData(parent?.data?.rows);
    } else if (action == "end-user") {
      setTableData(endUser?.data?.rows);
    } else if (action == "entity") {
      setTableData(entity?.data?.rows);
    } else if (action == "corporateEntity") {
      setTableData(corporateEntity?.data?.rows);
    } else if (action == "individual") {
      setTableData(individual?.data?.rows);
    } else {
      // params.alltype = ["client", "introducerClient", "parent-client", "end-user"]
      let params = { ...queryParams };
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data;
      });
      setTotalCount(data.totalCount);
      setTableData(data.rows);
    }
  };

  const handleRefresh = async (value) => {
    let params = { _limit: 10, _start: 0 };

    if (value == "client") {
      dispatch(getClient(params));
    } else if (value == "introducerClient") {
      dispatch(getIntroducerClient(params));
    } else if (value == "parent-client") {
      dispatch(getParentClient(params));
    } else if (value == "end-user") {
      dispatch(getEndUserClient(params));
    } else if (value == "entity") {
      dispatch(getEntity(params));
    } else if (value == "corporateEntity") {
      dispatch(getCorporateEntity(params));
    } else if (value == "individual") {
      dispatch(getIndividual(params));
    } else {
      let params = { ...queryParams };
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data;
      });
      setTotalCount(data.totalCount);
      setTableData(data.rows);
    }

    setAction(value);
  };
  const entityColumns = [
    {
      title: "Entity Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.entityType - b.entityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      // render: (_, { status }) => (
      //     <Select
      //         className="custom-select"
      //         placeholder="Select type"
      //         value={status}
      //         style={{
      //             width: 160
      //         }}
      //         options={[
      //             {
      //                 value: 'bvibc',
      //                 label: 'BVIBC'
      //             },
      //             {
      //                 value: 'trust',
      //                 label: 'Trust'
      //             },
      //             {
      //                 value: 'generalPartnership',
      //                 label: 'General Partnership'
      //             },
      //             {
      //                 value: 'limitedPartnership',
      //                 label: 'Limited Partnership'
      //             },
      //             {
      //                 value: 'foundation',
      //                 label: 'Foundation'
      //             },
      //         ]}
      //     />
      // ),
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "Inactive",
          label: "Inactive",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "active" ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)} //
        />
      ),
    },

    {
      title: "Related Entities",
      dataIndex: "relatedEntities",
      width: 200,
      render: (_, { status }) => (
        <div className="custom-form">
          <input type="text" placeholder="Enter related entities" />
        </div>
      ),
    },
  ];

  const masterFileColumns = [
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      //sorter: (a, b) => a.name - b.name,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Masterfile Type",
      dataIndex: "type",
      filterType: "text",
      // sorter: (a, b) => a.type - b.type,
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      // render: (_, { status }) => (
      //     <Select
      //         className="custom-select"
      //         placeholder="Select type"
      //         value={status}
      //         style={{
      //             width: 160
      //         }}
      //         options={[
      //             {
      //                 value: 'bvibc',
      //                 label: 'BVIBC'
      //             },
      //             {
      //                 value: 'trust',
      //                 label: 'Trust'
      //             },
      //             {
      //                 value: 'generalPartnership',
      //                 label: 'General Partnership'
      //             },
      //             {
      //                 value: 'limitedPartnership',
      //                 label: 'Limited Partnership'
      //             },
      //             {
      //                 value: 'foundation',
      //                 label: 'Foundation'
      //             },
      //         ]}
      //     />
      // ),
    },
    {
      title: "Masterfile Status",
      dataIndex: "status",
      filterType: "text",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status == "active" ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status?.slice(1)}
        />
      ),
    },

    {
      title: "Date Created",
      dataIndex: "createdAt",
      filterType: "date",
      /// sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,

      key: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      //  sortOrder: sortedInfo.columnKey === "date" && sortedInfo.order,
      render: (text) => moment(text).format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      filterType: "text",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const [tableColumn, setTableColumn] = useState(masterFileColumns);

  const clientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "pCode",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      filterType: "text",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
        // <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
        //   Low
        // </Tag>
      ),
    },
    {
      title: "Parent Client Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status.slice(1)}
        />
      ),
    },
  ];

  const parentClientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.parentClientType - b.parentClientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Admin Manager",
      dataIndex: "adminManager",
      sorter: (a, b) => a.adminManager - b.adminManager,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Administrator",
      dataIndex: "administrator",
      sorter: (a, b) => a.administrator - b.administrator,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Relationship Manager",
      dataIndex: "relationshipManager",
      sorter: (a, b) => a.relationshipManager - b.relationshipManager,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];
  const introducerClientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Introducer Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      filterType: "text",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status.slice(1)}
        />
      ),
    },
  ];
  const endUserClientColumns = [
    {
      title: "Masterfile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "End User Client Type",
      dataIndex: "type",
      filterType: "text",
      sorter: (a, b) => a.clientType - b.clientType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "pCode",
      filterType: "text",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",

      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "End User Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status?.charAt(0)?.toUpperCase() + status.slice(1)}
        />
      ),
    },
  ];

  const individualColumns = [
    {
      title: "MasterFile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.individualCode - b.individualCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Title",
      dataIndex: "title",
      filterType: "text",
      sorter: (a, b) => a.title - b.title,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      filterType: "text",
      sorter: (a, b) => a.firstName - b.firstName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Middle Name",
      dataIndex: "middleName",
      filterType: "text",
      sorter: (a, b) => a.middleName - b.middleName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 200,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      filterType: "text",
      sorter: (a, b) => a.lastName - b.lastName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Date of Birth",
      dataIndex: "DOB",
      filterType: "date",
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Country of Birth",
      dataIndex: "COB",
      sorter: (a, b) => a.countryOfBirth - b.countryOfBirth,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      sorter: (a, b) => a.nationality - b.nationality,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Tax Info Number",
      dataIndex: "taxInfo",
      filterType: "text",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address - b.address,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNo",
      filterType: "text",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      filterType: "text",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Low" : "High"}
        />
      ),
    },
    {
      title: "Pep",
      dataIndex: "pep",
      sorter: (a, b) => a.pep - b.pep,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={true ? "approved" : "rejected"}
          statusText={true ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Sanction",
      dataIndex: "sanction",
      sorter: (a, b) => a.sanction - b.sanction,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={false ? "approved" : "rejected"}
          statusText={false ? "Yes" : "No"}
        />
      ),
    },
  ];

  const corporateEntityColumns = [
    {
      title: "Corporate Entity Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityCode - b.corporateEntityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Corporate Entity Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.corporateEntityName - b.corporateEntityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Corporate Entity Type",
      dataIndex: "type",
      sorter: (a, b) => a.corporateEntityType - b.corporateEntityType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorporationDate",
      filterType: "date",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "incorporationNumber",
      dataIndex: "incorporationNumber",
      filterType: "text",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Country Of Incorporation",
      dataIndex: "incorporationCountry",
      sorter: (a, b) => a.countryOfIncorporation - b.countryOfIncorporation,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Geography of business",
      dataIndex: "busGeography",
      sorter: (a, b) => a.geographyOfBusiness - b.geographyOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Nature of Business",
      dataIndex: "busNature",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Description of Business",
      dataIndex: "busDesc",
      filterType: "text",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Corporate Tax Info Number",
      dataIndex: "corpInfoNo",
      filterType: "text",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Address",
      dataIndex: "address",
      filterType: "text",
      sorter: (a, b) => a.address - b.address,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      filterType: "text",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      filterType: "text",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onExportClick = () => {
    downloadAsCSV(tableData, "master-search-data-export");
  };

  const getPagination = async (current, pageSize) => {
    let params = { ...queryParams };
    const filter = encodeURIComponent(JSON.stringify(filters));
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    if (filters) {
      params.filters = filter;
    }
    if (action == "client") {
      //   params.masterType = "client"
      dispatch(getClient(params));
    } else if (action == "introducerClient") {
      dispatch(getIntroducerClient(params));
    } else if (action == "parent-client") {
      dispatch(getParentClient(params));
    } else if (action == "end-user") {
      dispatch(getEndUserClient(params));
    } else if (action == "entity") {
      dispatch(getEntity(params));
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(params));
    } else if (action == "individual") {
      dispatch(getIndividual(params));
    } else {
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data;
      });
      setTotalCount(data.totalCount);
      setTableData(data.rows);
    }
    setQueryParams(params);
  };

  const handleChange = (value) => {
    let tColumn = [];
    let eShow = false;
    switch (value) {
      case "client":
        tColumn = clientColumns;
        break;
      case "introducerClient":
        tColumn = introducerClientColumns;
        break;
      case "parent-client":
        tColumn = parentClientColumns;
        break;
      case "end-user":
        tColumn = endUserClientColumns;
        break;
      case "entity":
        tColumn = entityColumns;
        eShow = true;
        break;
      case "individual":
        tColumn = individualColumns;
        break;
      case "corporateEntity":
        tColumn = corporateEntityColumns;
        break;
      default:
        tColumn = masterFileColumns;
        eShow = false;
        break;
    }

    handleRefresh(value);

    setTableColumn(tColumn);
    setEntityShow(eShow);
  };
  const entityTypeOnChange = (value) => {
    let params = { ...queryParams };
    params.type = value;
    if (action == "entity") {
      dispatch(getEntity(params));
    }
  };

  const nameSearchOnChange = async (search) => {
    let params = { ...queryParams };
    params.name = search;
    if (action == "client") {
      dispatch(getClient(params));
    } else if (action == "introducerClient") {
      dispatch(getIntroducerClient(params));
    } else if (action == "parent-client") {
      dispatch(getParentClient(params));
    } else if (action == "end-user") {
      dispatch(getEndUserClient(params));
    } else if (action == "entity") {
      dispatch(getEntity(params));
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(params));
    } else if (action == "individual") {
      dispatch(getIndividual(params));
    } else {
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data;
      });
      setTotalCount(data.totalCount);
      setTableData(data.rows);
    }
    setName(search);
  };
  const codeSearchOnChange = async (search) => {
    let params = { ...queryParams };
    params.code = search;
    if (action == "client") {
      dispatch(getClient(params));
    } else if (action == "introducerClient") {
      dispatch(getIntroducerClient(params));
    } else if (action == "parent-client") {
      dispatch(getParentClient(params));
    } else if (action == "end-user") {
      dispatch(getEndUserClient(params));
    } else if (action == "entity") {
      dispatch(getEntity(params));
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(params));
    } else if (action == "individual") {
      dispatch(getIndividual(params));
    } else {
      params.isAll = true;
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data;
      });
      setTotalCount(data.totalCount);
      setTableData(data.rows);
    }
    setCode(search);
  };
  const getFilter = async (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    params.isAll = true;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = [];
    data = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    setTotalCount(data.totalCount);
    setTableData(data.rows);
    setQueryParams(params);
    setFilters(value);
  };
  const getProgress = async (value) => {
    let params = { ...queryParams };
    params.status = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = [];
    data = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    setTotalCount(data.totalCount);
    setTableData(data.rows);
    setQueryParams(params);
  };

  const labels = [
    {
      key: "Active",
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> Active
        </>
      ),
    },
    {
      key: "Inactive",
      label: (
        <>
          <img src={InProgressIcon} alt="" /> Inactive
        </>
      ),
    },
  ];
  return (
    <div>
      {/* <h6 className="primary-title mb-1 mb-md-4">Search Criteria</h6> */}
      <div className="custom-form mb-2 mb-md-3">
        <div className="row align-items-end">
          <div className="col-md-2">
            <label htmlFor="">Masterfile type</label>
            <Select
              className="custom-select w-100 ptb-1"
              placeholder="Select type"
              onChange={handleChange}
              allowClear
              value={action}
              options={[
                {
                  value: "client",
                  label: "Client",
                },
                {
                  value: "introducerClient",
                  label: "Introducer Client",
                },
                {
                  value: "parent-client",
                  label: "Parent Client",
                },
                {
                  value: "end-user",
                  label: "End User Client",
                },
                {
                  value: "entity",
                  label: "Entity",
                },
                {
                  value: "individual",
                  label: "Individual",
                },
                {
                  value: "corporateEntity",
                  label: "Corporate Entity",
                },
              ]}
            />
          </div>
          {/* <div className="col-md-2">
              <label htmlFor="">Name</label>
              <input
                type="text"
                className="w-100"
                placeholder="Enter name"
                name="name"
                value={name}
                onChange={(e) => nameSearchOnChange(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">Code</label>
              <input
                type="text"
                className="w-100"
                placeholder="Enter code"
                name="code"
                value={code}
                onChange={(e) => codeSearchOnChange(e.target.value)}
              />
            </div> */}
          {entityShow && (
            <div className="col-md-2">
              <label htmlFor="">Entity type</label>
              <Select
                className="custom-select w-100 ptb-1"
                placeholder="Select type"
                onChange={entityTypeOnChange}
                options={[
                  {
                    value: "bvibc",
                    label: "BVIBC",
                  },
                  {
                    value: "trust",
                    label: "Trust",
                  },
                  {
                    value: "generalPartnership",
                    label: "General Partnership",
                  },
                  {
                    value: "limitedPartnership",
                    label: "Limited Partnership",
                  },
                  {
                    value: "foundation",
                    label: "Foundation",
                  },
                  {
                    value: "",
                    label: "Blank",
                  },
                ]}
              />
            </div>
          )}
          {/* <div className="col-md-2">
              <button className="primary-btn mb-1">
                <i className="fal fa-search"></i> Search
              </button>
            </div> */}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TabZ
          labels={labels}
          defaultActive={"Active"}
          customClass={"table-tabs"}
          onChange={getProgress}
        />
        {/* <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button> */}
        <TableFilterZ tableCol={tableColumn} onChange={getFilter} />
      </div>

      <Table
        className="custom-table"
        loading={client?.isLoading}
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={tableColumn}
        rowKey={"_id"}
        scroll={{ x: action ? 3000 : 400 }}
        dataSource={tableData}
        pagination={{
          className: "custom-pagination",
          defaultCurrent: 1,
          pageSize: queryParams?._limit,
          total:
            action == "client"
              ? client?.data?.totalCount
              : action == "corporateEntity"
              ? corporateEntity?.data?.totalCount
              : action == "individual"
              ? individual?.data?.totalCount
              : action == "entity"
              ? entity?.data?.totalCount
              : action == "introducerClient"
              ? introducer?.data?.totalCount
              : action == "end-user"
              ? endUser?.data?.totalCount
              : action == "parent-client"
              ? parent?.data?.totalCount
              : totalCount,

          onChange: getPagination,
          itemRender: customPagination,
        }}
      />
    </div>
  );
}
