import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, DatePicker } from "antd";
import dayjs from "dayjs";

import { downloadAsCSV } from "../helper";

import FormZ from "../components/FormZ";
import UploadZ from "../components/UploadZ";

const { RangePicker } = DatePicker;

export default function ManualStorage() {
  const formRef1 = useRef();
  const formRef2 = useRef();

  const [uploadModal, setUploadModal] = useState(false);
  const [document, setDocument] = useState(null);

  function storeDocuments(file) {
    setDocument(file);
  }

  const columns = [
    {
      title: "Masterfile Code",
      dataIndex: "masterfileCode",
      sorter: (a, b) => a.masterfileCode - b.masterfileCode,
    },
    {
      title: "Masterfile Name",
      dataIndex: "masterfileName",
      sorter: (a, b) => a.masterfileName - b.masterfileName,
    },
    {
      title: "Masterfile Type",
      dataIndex: "masterfileType",
      sorter: (a, b) => a.masterfileType - b.masterfileType,
    },
    {
      title: "Document Date",
      dataIndex: "documentDate",
      sorter: (a, b) => a.documentDate - b.documentDate,
    },
    {
      title: "Document Name",
      dataIndex: "documentName",
      sorter: (a, b) => a.documentName - b.documentName,
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      sorter: (a, b) => a.documentType - b.documentType,
    },
    {
      title: "Cabinet",
      dataIndex: "cabinet",
      sorter: (a, b) => a.cabinet - b.cabinet,
    },
    {
      title: "Tagged Masterfile Code",
      dataIndex: "tags",
      sorter: (a, b) => a.tags - b.tags,
      render: (_, { tags }) => (
        <>
          {tags.map((tag, i) => {
            return (
              <Tag key={i} color={"orange"}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Storage Date",
      dataIndex: "storageDate",
      sorter: (a, b) => a.storageDate - b.storageDate,
    },
    {
      title: "User",
      dataIndex: "user",
      sorter: (a, b) => a.user - b.user,
    },
    {
      title: "",
      dataIndex: "action",
      // fixed: "right",
      // width: 150,
      render: (_, { status }) => (
        <Link to={"/app/storage"} className="action-btn">
          <i className="fal fa-eye"></i> View
        </Link>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      masterfileCode: "001",
      masterfileName: "Sample",
      masterfileType: "Sample",
      documentDate: "12/06/2020",
      documentName: "Passport",
      storageDate: "23/04/2023",
      documentType: "Passport",
      cabinet: "CB001",
      tags: ["EC02", "EC03"],
      userDetails: "Guru",
    },
    {
      key: "2",
      id: "2",
      masterfileCode: "002",
      masterfileName: "Sample",
      masterfileType: "Sample",
      documentDate: "12/06/2020",
      documentName: "Passport",
      storageDate: "23/04/2023",
      documentType: "Address Proof",
      cabinet: "CB002",
      tags: ["EC03"],
      userDetails: "Guru",
    },
    {
      key: "3",
      id: "3",
      masterfileCode: "003",
      masterfileName: "Sample",
      masterfileType: "Sample",
      documentDate: "12/06/2020",
      documentName: "Passport",
      storageDate: "23/04/2023",
      documentType: "Resolutions",
      cabinet: "CB003",
      tags: ["EC04", "EC05"],
      userDetails: "Hari",
    },
  ];

  const onExportClick = () => {
    downloadAsCSV(data, "master-search-data-export");
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  let formSchema1 = [
    {
      name: "masterfileCode",
      value: "",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
    },
    {
      name: "masterfileName",
      value: "",
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter masterfile name",
    },
  ];

  let formSchema2 = [
    {
      name: "documentDate",
      value: "",
      type: "date",
      label: "Document Date",
      placeholder: "Select document date",
    },
    {
      name: "documentName",
      value: "",
      type: "text",
      label: "Document Name",
      placeholder: "Enter document name",
    },
    {
      name: "documentType",
      value: "",
      type: "select",
      label: "Document Type",
      placeholder: "Select document type",
    },
    {
      name: "cabinet",
      value: "",
      type: "select",
      label: "Cabinet",
      placeholder: "Select cabinet",
    },
    {
      name: "tags",
      value: "",
      type: "select",
      label: "Tagged Masterfile Code",
      placeholder: "Select tags",
    },
    {
      name: "storedDate",
      value: "",
      type: "date",
      label: "Stored Date",
      placeholder: "Select stored date",
    },
    {
      name: "userDetails",
      value: "",
      type: "text",
      label: "User Details",
      placeholder: "Select user details",
    },
  ];

  const handleOk = () => {
    setUploadModal(false);
  };
  const handleCancel = () => {
    setUploadModal(false);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-3 mb-md-0">Manual Storage</h1>
        <button className="primary-btn" onClick={() => setUploadModal(true)}>
          <i className="fal fa-file-upload"></i> Upload New
        </button>
      </div>
      <h6 className="primary-title mb-1 mb-md-4">Search Storage</h6>
      <div className="custom-form mb-2 mb-md-3">
        <div className="row align-items-end">
          <div className="col-md-2">
            <label htmlFor="">Type of Masterfile</label>
            <Select
              className="custom-select w-100 ptb-1"
              placeholder="Select type"
              options={[
                {
                  value: "client",
                  label: "Client",
                },
                {
                  value: "parent-client",
                  label: "Parent Client",
                },
                {
                  value: "entity",
                  label: "Entity",
                },
                {
                  value: "individual",
                  label: "Individual",
                },
                {
                  value: "corporateEntity",
                  label: "Corporate Entity",
                },
              ]}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="">Name</label>
            <input
              type="text"
              className="w-100"
              placeholder="Enter name"
              name="name"
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="">Code</label>
            <input
              type="text"
              className="w-100"
              placeholder="Enter code"
              name="code"
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="">Date Stored</label>
            <RangePicker
              disabledDate={disabledDate}
              className="custom-datepicker"
            />
          </div>
          <div className="col-md-2">
            <button className="primary-btn mb-15">
              <i className="fal fa-search"></i> Initiate Search
            </button>
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-end">
        <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button>
      </div> */}
      <Table
        className="custom-table"
        columns={columns}
        dataSource={data}
        // scroll={{ x: 2500 }}
      />
      <Modal
        title={"Upload New Document"}
        className="custom-modal"
        open={uploadModal}
        width={800}
        onOk={handleOk}
        okText="Upload"
        onCancel={handleCancel}
      >
        <div className="custom-form">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="">Document</label>
              <UploadZ
                onStoreFile={storeDocuments}
                fileName={"document"}
                hideDownload={true}
              />
            </div>
            <div className="col-md-6 pt-2">
              <FormZ formSchema={formSchema1} ref={formRef1} />
            </div>
          </div>
          <FormZ
            formSchema={formSchema2}
            formClass="row"
            childClass="col-md-6"
            ref={formRef2}
          />
        </div>
      </Modal>
    </div>
  );
}
