import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { Table, Tag, Select, Modal, Checkbox } from "antd";
import { getClient } from "../store/client/clientSlice";
import { getIntroducerClient } from "../store/introducerClient/introducerClientSlice"
import { getParentClient } from "../store/parentClient/parentClientSlice";
import { getEndUserClient } from "../store/endUserClient/endUserClientSlice";
import { getEntity } from "../store/entity/entitySlice";
import { getCorporateEntity } from "../store/corporateEntity/corporateEntitySlice";
import { getIndividual } from "../store/individual/individualSlice";

import FormZ from "../components/FormZ";
import { downloadAsCSV } from "../helper";

export default function MasterSearch() {

  const client = useSelector((state) => state.client);
  const parent = useSelector((state) => state.parentClient);
  const endUser = useSelector((state) => state.endUserClient);
  const entity = useSelector((state) => state.entity);
  const corporateEntity = useSelector((state) => state.corporateEntity);
  const individual = useSelector((state) => state.individual);
  const introducer = useSelector((state) => state.introducerClient);



  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [tableData, setTableData] = useState([]);
  const [action, setAction] = useState('');

  const [entityShow, setEntityShow] = useState(false);
  const [totalCount, setTotalCount] = useState('')

  const dispatch = useDispatch();

  useEffect(() => {
    if (action == 'client') {
      dispatch(getClient(queryParams));
    } else if (action == 'introducerClient') {
      dispatch(getIntroducerClient(queryParams));
    } else if (action == 'parent-client') {
      dispatch(getParentClient(queryParams))
    } else if (action == "end-user") {
      dispatch(getEndUserClient(queryParams))
    } else if (action == "entity") {
      dispatch(getEntity(queryParams))
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(queryParams))
    } else if (action == "individual") {
      dispatch(getIndividual(queryParams))
    } else {
      dispatch(getClient(queryParams));
      dispatch(getIntroducerClient(queryParams));
      dispatch(getParentClient(queryParams))
      dispatch(getEndUserClient(queryParams))
      dispatch(getEntity(queryParams))
      dispatch(getCorporateEntity(queryParams))
      dispatch(getIndividual(queryParams))
    }
    // action == 'client' ? client?.data?.totalCount :
    // action == 'corporateEntity' ? corporateEntity?.data?.totalCount :
    // action == 'individual' ? individual?.data?.totalCount :
    // action == 'entity' ? entity?.data?.totalCount : 
    // action == 'introducerClient' ? introducer?.data?.totalCount :
    // action == 'end-user' ? endUser?.data?.totalCount :
    // action == 'parent-client' ? parent?.data?.totalCount : 0,

  }, []);
  useEffect(() => {
    getData()
  }, [client, parent, endUser, entity, corporateEntity, individual, introducer])
  const getData = async (value) => {
    let targetArray = [];
    if (action == 'client') {
      setTableData(client?.data?.rows)
    } else if (action == 'introducerClient') {
      setTableData(introducer?.data?.rows)
    } else if (action == 'parent-client') {
      setTableData(parent?.data?.rows)
    } else if (action == "end-user") {
      setTableData(endUser?.data?.rows)
    } else if (action == 'entity') {
      setTableData(entity?.data?.rows)
    } else if (action == 'corporateEntity') {
      setTableData(corporateEntity?.data?.rows)
    } else if (action == 'individual') {
      setTableData(individual?.data?.rows)
    } else {

      let cl = client && client?.data?.rows ? client?.data?.rows : []
      let pc = parent && parent?.data?.rows ? parent?.data?.rows : []
      let euc = endUser && endUser?.data?.rows ? endUser?.data?.rows : []
      let en = entity && entity?.data?.rows ? entity?.data?.rows : []
      let cor = corporateEntity && corporateEntity?.data?.rows ? corporateEntity?.data?.rows : []
      let inv = individual && individual?.data?.rows ? individual?.data?.rows : []
      let ic = introducer && introducer?.data?.rows ? introducer?.data?.rows : []

      let cl_count = client?.data?.totalCount ?? 0;
      let pc_count = parent?.data?.totalCount ?? 0;
      let euc_count = endUser?.data?.totalCount ?? 0;
      let en_count = entity?.data?.totalCount ?? 0;
      let cor_count = corporateEntity?.data?.totalCount ?? 0;
      let inv_count = individual?.data?.totalCount ?? 0;
      let ic_count = introducer?.data?.totalCount ?? 0;


      let totalCount = cl_count + pc_count + euc_count + en_count + cor_count + inv_count + ic_count;

      targetArray.push(...cl, ...pc, ...euc, ...en, ...cor, ...inv, ...ic);
      if (targetArray.length) {
        setTotalCount(totalCount)
        setTableData(targetArray)
      }
    }
  }

  const handleRefresh = async (value) => {
    let params = { ...queryParams };
    if (value == 'client') {
      dispatch(getClient(params));
    } else if (value == 'introducerClient') {
      dispatch(getIntroducerClient(queryParams));
    }
    else if (value == 'parent-client') {
      dispatch(getParentClient(params))
    } else if (value == 'end-user') {
      dispatch(getEndUserClient(params))
    } else if (value == "entity") {
      dispatch(getEntity(params))
    } else if (value == "corporateEntity") {
      dispatch(getCorporateEntity(params))
    } else if (value == "individual") {
      dispatch(getIndividual(params))
    } else {
      dispatch(getClient(params));
      dispatch(getIntroducerClient(params));
      dispatch(getParentClient(params))
      dispatch(getEndUserClient(params))
      dispatch(getEntity(params))
      dispatch(getCorporateEntity(params))
      dispatch(getIndividual(params))
    }

    setAction(value);

  }
  const entityColumns = [
    {
      title: "Entity Name",
      dataIndex: "name",
      sorter: (a, b) => a.entityName - b.entityName,
    },
    {
      title: "Entity Code",
      dataIndex: "code",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Entity Type",
      dataIndex: "type",
      sorter: (a, b) => a.entityType - b.entityType,
      // render: (_, { status }) => (
      //     <Select
      //         className="custom-select"
      //         placeholder="Select type"
      //         value={status}
      //         style={{
      //             width: 160
      //         }}
      //         options={[
      //             {
      //                 value: 'bvibc',
      //                 label: 'BVIBC'
      //             },
      //             {
      //                 value: 'trust',
      //                 label: 'Trust'
      //             },
      //             {
      //                 value: 'generalPartnership',
      //                 label: 'General Partnership'
      //             },
      //             {
      //                 value: 'limitedPartnership',
      //                 label: 'Limited Partnership'
      //             },
      //             {
      //                 value: 'foundation',
      //                 label: 'Foundation'
      //             },
      //         ]}
      //     />
      // ),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: (a, b) => a.clientType - b.clientType,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Low
        </Tag>
      ),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"red"}>
          Inactive
        </Tag>
      ),
    },
    {
      title: "Related Entities",
      dataIndex: "relatedEntities",
      width: 200,
      render: (_, { status }) => (
        <div className="custom-form">
          <input type="text" placeholder="Enter related entities" />
        </div>
      ),
    },
    {
      title: "Entity Overview",
      dataIndex: "entityOverview",
      // fixed: "right",
      // width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];

  const masterFileColumns = [
    {
      title: "Masterfile Code",
      dataIndex: "code",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Masterfile Name",
      dataIndex: "name",
      sorter: (a, b) => a.entityName - b.entityName,
    },

    {
      title: "Masterfile Type",
      dataIndex: "type",
      sorter: (a, b) => a.entityType - b.entityType,
      // render: (_, { status }) => (
      //     <Select
      //         className="custom-select"
      //         placeholder="Select type"
      //         value={status}
      //         style={{
      //             width: 160
      //         }}
      //         options={[
      //             {
      //                 value: 'bvibc',
      //                 label: 'BVIBC'
      //             },
      //             {
      //                 value: 'trust',
      //                 label: 'Trust'
      //             },
      //             {
      //                 value: 'generalPartnership',
      //                 label: 'General Partnership'
      //             },
      //             {
      //                 value: 'limitedPartnership',
      //                 label: 'Limited Partnership'
      //             },
      //             {
      //                 value: 'foundation',
      //                 label: 'Foundation'
      //             },
      //         ]}
      //     />
      // ),
    },
    {
      title: "Masterfile Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"red"}>
          Inactive
        </Tag>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "File View",
      dataIndex: "entityOverview",
      // fixed: "right",
      // width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];

  const [tableColumn, setTableColumn] = useState(masterFileColumns);

  const clientColumns = [
    {
      title: "Client Name",
      dataIndex: "name",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Client Code",
      dataIndex: "code",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Client Type",
      dataIndex: "type",
      sorter: (a, b) => a.clientType - b.clientType,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
    },
    {
      title: "Parent Client Code",
      dataIndex: "pCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Low
        </Tag>
      ),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"red"}>
          Inactive
        </Tag>
      ),
    },
    {
      title: "Entity Overview",
      dataIndex: "entityOverview",
      fixed: "right",
      width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];

  const parentClientColumns = [
    {
      title: "Parent Client Name",
      dataIndex: "name",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
    },
    {
      title: "Parent Client Code",
      dataIndex: "code",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    },
    {
      title: "Parent Client Type",
      dataIndex: "type",
      sorter: (a, b) => a.parentClientType - b.parentClientType,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
    },
    {
      title: "Admin Manager",
      dataIndex: "adminManager",
      sorter: (a, b) => a.adminManager - b.adminManager,
    },
    {
      title: "Administrator",
      dataIndex: "administrator",
      sorter: (a, b) => a.administrator - b.administrator,
    },
    {
      title: "Relationship Manager",
      dataIndex: "relationshipManager",
      sorter: (a, b) => a.relationshipManager - b.relationshipManager,
    },
    {
      title: "Entity Overview",
      dataIndex: "entityOverview",
      fixed: "right",
      width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];
  const introducerClientColumns = [
    {
      title: "Introducer Client Name",
      dataIndex: "name",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Introducer Client Code",
      dataIndex: "code",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Introducer Client Type",
      dataIndex: "type",
      sorter: (a, b) => a.clientType - b.clientType,
    },

    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Low
        </Tag>
      ),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"red"}>
          Inactive
        </Tag>
      ),
    },
    {
      title: "Entity Overview",
      dataIndex: "entityOverview",
      fixed: "right",
      width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];
  const endUserClientColumns = [
    {
      title: "End User Client Name",
      dataIndex: "name",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "End User Client Code",
      dataIndex: "code",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "End User Client Type",
      dataIndex: "type",
      sorter: (a, b) => a.clientType - b.clientType,
    },
    {
      title: "Parent Client Code",
      dataIndex: "pCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    },
    {
      title: "Service Fee Category",
      dataIndex: "serviceFeeCategory",
      sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
      width: 200,
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    },
    {
      title: "Incorp Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Low
        </Tag>
      ),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"red"}>
          Inactive
        </Tag>
      ),
    },
    {
      title: "Entity Overview",
      dataIndex: "entityOverview",
      fixed: "right",
      width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];

  const individualColumns = [
    {
      title: "Individual Code",
      dataIndex: "code",
      sorter: (a, b) => a.individualCode - b.individualCode,
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.title - b.title,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      sorter: (a, b) => a.firstName - b.firstName,
    },
    {
      title: "Middle Name",
      dataIndex: "middleName",
      sorter: (a, b) => a.middleName - b.middleName,
      width: 200,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      sorter: (a, b) => a.lastName - b.lastName,
    },
    {
      title: "Date of Birth",
      dataIndex: "DOB",
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
    },
    {
      title: "Country of Birth",
      dataIndex: "COB",
      sorter: (a, b) => a.countryOfBirth - b.countryOfBirth,
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      sorter: (a, b) => a.nationality - b.nationality,
    },
    {
      title: "Tax Info Number",
      dataIndex: "taxInfo",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address - b.address,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNo",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Low
        </Tag>
      ),
    },
    {
      title: "Pep",
      dataIndex: "pep",
      sorter: (a, b) => a.pep - b.pep,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"red"}>
          No
        </Tag>
      ),
    },
    {
      title: "Sanction",
      dataIndex: "sanction",
      sorter: (a, b) => a.sanction - b.sanction,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Yes
        </Tag>
      ),
    },
    {
      title: "Entity Overview",
      dataIndex: "entityOverview",
      fixed: "right",
      width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];

  const corporateEntityColumns = [
    {
      title: "Corporate Entity Code",
      dataIndex: "code",
      sorter: (a, b) => a.corporateEntityCode - b.corporateEntityCode,
    },
    {
      title: "Corporate Entity Name",
      dataIndex: "name",
      sorter: (a, b) => a.corporateEntityName - b.corporateEntityName,
    },
    {
      title: "Corporate Entity Type",
      dataIndex: "type",
      sorter: (a, b) => a.corporateEntityType - b.corporateEntityType,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorporationDate",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
    },
    {
      title: "incorporationNumber",
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
    },
    {
      title: "Country Of Incorporation",
      dataIndex: "incorporationCountry",
      sorter: (a, b) => a.countryOfIncorporation - b.countryOfIncorporation,
    },
    {
      title: "Geography of business",
      dataIndex: "busGeography",
      sorter: (a, b) => a.geographyOfBusiness - b.geographyOfBusiness,
    },
    {
      title: "Nature of Business",
      dataIndex: "busNature",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
    },
    {
      title: "Description of Business",
      dataIndex: "busDesc",
      sorter: (a, b) => a.natureOfBusiness - b.natureOfBusiness,
    },
    {
      title: "Corporate Tax Info Number",
      dataIndex: "corpInfoNo",
      sorter: (a, b) => a.taxInfoNumber - b.taxInfoNumber,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address - b.address,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      sorter: (a, b) => a.emailAddress - b.emailAddress,
    },
    {
      title: "Entity Overview",
      dataIndex: "entityOverview",
      fixed: "right",
      width: 150,
      render: (_, { status }) => (
        <Link to={"/app/entity-overview/list"} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];
  console.log(tableData, "tableData")
  const data = [
    {
      key: "1",
      id: "1",
      entityName: "Sample1",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Intermediary Client",
      parentClientName: "Hari",
      parentClientCode: "632",
      serviceFeeCategory: "OCR",
      jurisdiction: "BVI",
      incorpNumber: "9238",
      incorpDate: "23/04/2023",
      incorpBatch: "Sample",
      individualCode: "001",
      title: "Mr",
      firstName: "Guru",
      middleName: "K",
      lastName: "Sami",
      dateOfBirth: "09-03-1998",
      countryOfBirth: "India",
      nationality: "India",
      taxInfoNumber: "0901231",
      address: "22, 3rd cross street, chennai, Tamil Nadu, India",
      contactNumber: "9023235333",
      emailAddress: "guruk1998@gmail.com",
      corporateEntityCode: "CE001",
      corporateEntityName: "Sample",
      corporateEntityType: "Trust",
      incorporationDate: "09-04-2023",
      incorporationNumber: "8234",
      countryOfIncorporation: "India",
      geographyOfBusiness: "Sample",
      natureOfBusiness: "Manufacturing",
      descriptionBusiness: "Lorem ipsum dolor sit amet.",
    },
    {
      key: "2",
      id: "2",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Intermediary Client",
      parentClientName: "Hari",
      parentClientCode: "632",
      serviceFeeCategory: "OCR",
      jurisdiction: "BVI",
      incorpNumber: "9238",
      incorpDate: "23/04/2023",
      incorpBatch: "Sample",
      individualCode: "002",
      title: "Mr",
      firstName: "Guru",
      middleName: "K",
      lastName: "Sami",
      dateOfBirth: "09-03-1998",
      countryOfBirth: "India",
      nationality: "India",
      taxInfoNumber: "0901231",
      address: "22, 3rd cross street, chennai, Tamil Nadu, India",
      contactNumber: "9023235333",
      emailAddress: "guruk1998@gmail.com",
      corporateEntityCode: "CE002",
      corporateEntityName: "Sample",
      corporateEntityType: "Trust",
      incorporationDate: "09-04-2023",
      incorporationNumber: "8234",
      countryOfIncorporation: "India",
      geographyOfBusiness: "Sample",
      natureOfBusiness: "Manufacturing",
      descriptionBusiness: "Lorem ipsum dolor sit amet.",
    },
    {
      key: "3",
      id: "3",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Intermediary Client",
      parentClientName: "Hari",
      parentClientCode: "632",
      serviceFeeCategory: "OCR",
      jurisdiction: "BVI",
      incorpNumber: "9238",
      incorpDate: "23/04/2023",
      incorpBatch: "Sample",
      individualCode: "003",
      title: "Mr",
      firstName: "Guru",
      middleName: "K",
      lastName: "Sami",
      dateOfBirth: "09-03-1998",
      countryOfBirth: "India",
      nationality: "India",
      taxInfoNumber: "0901231",
      address: "22, 3rd cross street, chennai, Tamil Nadu, India",
      contactNumber: "9023235333",
      emailAddress: "guruk1998@gmail.com",
      corporateEntityCode: "CE003",
      corporateEntityName: "Sample",
      corporateEntityType: "Trust",
      incorporationDate: "09-04-2023",
      incorporationNumber: "8234",
      countryOfIncorporation: "India",
      geographyOfBusiness: "Sample",
      natureOfBusiness: "Manufacturing",
      descriptionBusiness: "Lorem ipsum dolor sit amet.",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onExportClick = () => {
    downloadAsCSV(tableData, "master-search-data-export");
  };

  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    if (action == 'client') {
      //   params.masterType = "client"
      dispatch(getClient(params));
    } else if (action == 'introducerClient') {
      dispatch(getIntroducerClient(params));
    } else if (action == 'parent-client') {
      dispatch(getParentClient(params))
    } else if (action == 'end-user') {
      dispatch(getEndUserClient(params))
    } else if (action == "entity") {
      dispatch(getEntity(params))
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(params))
    } else if (action == "individual") {
      dispatch(getIndividual(params))
    } else {
      dispatch(getClient(params));
      dispatch(getIntroducerClient(params));
      dispatch(getParentClient(params))
      dispatch(getEndUserClient(params))
      dispatch(getEntity(params))
      dispatch(getCorporateEntity(params))
      dispatch(getIndividual(params))
    }
    setQueryParams(params);

  };

  const handleChange = (value) => {
    console.log(value, "value-0")
    let tColumn = [];
    let eShow = false;
    switch (value) {
      case "client":
        tColumn = clientColumns;
        break;
      case "introducerClient":
        tColumn = introducerClientColumns;
        break;
      case "parent-client":
        tColumn = parentClientColumns;
        break;
      case "end-user":
        tColumn = endUserClientColumns;
        break;
      case "entity":
        tColumn = entityColumns;
        eShow = true;
        break;
      case "individual":
        tColumn = individualColumns;
        break;
      case "corporateEntity":
        tColumn = corporateEntityColumns;
        break;
      default:
        tColumn = masterFileColumns;
        eShow = false;
        break;
    }

    handleRefresh(value)

    setTableColumn(tColumn);
    setEntityShow(eShow);

  };
  const entityTypeOnChange = (value) => {
    let params = { ...queryParams };
    params.type = value
    if (action == "entity") {
      dispatch(getEntity(params))
    }
  }

  const nameSearchOnChange = (search) => {
    let params = { ...queryParams };
    params.name = search;
    if (action == 'client') {
      dispatch(getClient(params));
    } else if (action == 'introducerClient') {
      dispatch(getIntroducerClient(params));
    } else if (action == 'parent-client') {
      dispatch(getParentClient(params))
    } else if (action == 'end-user') {
      dispatch(getEndUserClient(params))
    } else if (action == "entity") {
      dispatch(getEntity(params))
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(params))
    } else if (action == "individual") {
      dispatch(getIndividual(params))
    } else {
      dispatch(getClient(params));
      dispatch(getIntroducerClient(params));
      dispatch(getParentClient(params))
      dispatch(getEndUserClient(params))
      dispatch(getEntity(params))
      dispatch(getCorporateEntity(params))
      dispatch(getIndividual(params))
    }
    setName(search);
  }
  const codeSearchOnChange = (search) => {
    let params = { ...queryParams };
    params.code = search;
    if (action == 'client') {
      dispatch(getClient(params));
    } else if (action == 'introducerClient') {
      dispatch(getIntroducerClient(params));
    } else if (action == 'parent-client') {
      dispatch(getParentClient(params))
    } else if (action == 'end-user') {
      dispatch(getEndUserClient(params))
    } else if (action == "entity") {
      dispatch(getEntity(params))
    } else if (action == "corporateEntity") {
      dispatch(getCorporateEntity(params))
    } else if (action == "individual") {
      dispatch(getIndividual(params))
    } else {
      dispatch(getClient(params));
      dispatch(getIntroducerClient(params));
      dispatch(getParentClient(params))
      dispatch(getEndUserClient(params))
      dispatch(getEntity(params))
      dispatch(getCorporateEntity(params))
      dispatch(getIndividual(params))
    }
    setCode(search);
  }
  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-5">
        <h1 className="page-title mb-3 mb-md-0">Master Search</h1>
        <Link to={"/app/master-file-creation"} className="primary-btn">
          Create Master File
        </Link>
      </div>
      <h6 className="primary-title mb-1 mb-md-4">Search Criteria</h6>
      <div className="custom-form mb-2 mb-md-3">
        <div className="row align-items-end">
          <div className="col-md-2">
            <label htmlFor="">Masterfile type</label>
            <Select
              className="custom-select w-100 ptb-1"
              placeholder="Select type"
              onChange={handleChange}
              value={action}
              options={[
                {
                  value: "client",
                  label: "Client",
                },
                {
                  value: "introducerClient",
                  label: "Introducer Client",
                },
                {
                  value: "parent-client",
                  label: "Parent Client",
                },
                {
                  value: "end-user",
                  label: "End User Client",
                },
                {
                  value: "entity",
                  label: "Entity",
                },
                {
                  value: "individual",
                  label: "Individual",
                },
                {
                  value: "corporateEntity",
                  label: "Corporate Entity",
                },
              ]}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="">Name</label>
            <input
              type="text"
              className="w-100"
              placeholder="Enter name"
              name="name"
              value={name}
              onChange={(e) => nameSearchOnChange(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="">Code</label>
            <input
              type="text"
              className="w-100"
              placeholder="Enter code"
              name="code"
              value={code}
              onChange={(e) => codeSearchOnChange(e.target.value)}

            />
          </div>
          {entityShow && (
            <div className="col-md-2">
              <label htmlFor="">Entity type</label>
              <Select
                className="custom-select w-100 ptb-1"
                placeholder="Select type"
                onChange={entityTypeOnChange}
                options={[
                  {
                    value: "bvibc",
                    label: "BVIBC",
                  },
                  {
                    value: "trust",
                    label: "Trust",
                  },
                  {
                    value: "generalPartnership",
                    label: "General Partnership",
                  },
                  {
                    value: "limitedPartnership",
                    label: "Limited Partnership",
                  },
                  {
                    value: "foundation",
                    label: "Foundation",
                  },
                  {
                    value: "",
                    label: "Blank",
                  },
                ]}
              />
            </div>
          )}
          <div className="col-md-2">
            <button className="primary-btn mb-15">
              <i className="fal fa-search"></i> Search
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button>
      </div>
      <Table
        className="custom-table"
        loading={client?.isLoading}
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={tableColumn}
        rowKey={"_id"}
        scroll={{ x: action == '' ? 1000 : 3000 }}
        dataSource={tableData}
        pagination={{
          className: "custom-pagination",
          defaultCurrent: 1,
          pageSize: queryParams?._limit,
          total: action == 'client' ? client?.data?.totalCount :
            action == 'corporateEntity' ? corporateEntity?.data?.totalCount :
              action == 'individual' ? individual?.data?.totalCount :
                action == 'entity' ? entity?.data?.totalCount :
                  action == 'introducerClient' ? introducer?.data?.totalCount :
                    action == 'end-user' ? endUser?.data?.totalCount :
                      action == 'parent-client' ? parent?.data?.totalCount : totalCount,
          onChange: getPagination,
        }}
      />
    </div>
  );
}
