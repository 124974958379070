import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Select, TreeSelect, Switch, Checkbox, Table } from "antd";
import dayjs from "dayjs";

import FormZ from "../../../components/FormZ";

import { sendNotify, getOptions, focusOn } from "../../../helper";

import countries from "../../../helper/countries.json";

const { SHOW_PARENT } = TreeSelect;

export default function Corporate(props) {
  const [corporateEntityShow, setCorporateEntityShow] = useState(false);

  const [checkedList, setCheckedList] = useState({
    shareholders: false,
    ubo: false,
    nominee: false,
  });
  const [historyShow, setHistoryShow] = useState(false);

  const [countriesOption, setCountriesOption] = useState([]);
  const [value, setValue] = useState([]);
  const [sanction, setSanction] = useState("Y");

  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);

  let { data, isLoading } = props;

  const [forms, setForms] = useState({
    individual: [1],
    directors: [1],
    shareholders: [1],
    ubo: [1],
    financialRecord: [],
    corporateEntity: [1],
  });

  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    // console.log(options);
    setCountriesOption(options);
  };

  const onChange = (newValue) => {
    console.log("onChange ", newValue);
    setValue(newValue);
  };

  const tProps = {
    treeData: countriesOption,
    value,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };

  const formRef = useRef();
  const adsFormRef = useRef();
  const riskFormRef = useRef();

  const handleChange = (value) => {
    setSanction(value);
  };

  let adsFormSchema = [
    {
      name: "addressLine1",
      value: "",
      type: "text",
      label: "Address Line 01",
      placeholder: "Enter address",
    },
    {
      name: "addressLine2",
      value: "",
      type: "text",
      label: "Address Line 02",
      placeholder: "Enter address",
    },
    {
      name: "addressLine3",
      value: "",
      type: "text",
      label: "Address Line 03",
      placeholder: "Enter address",
    },
    {
      name: "country",
      value: "",
      type: "select",
      label: "Country",
      placeholder: "Select country",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      labelClass: "mb-1",
      customClass: "col-md-3 flex-column align-items-start",
    },
    {
      name: "Post Code",
      value: "",
      type: "number",
      label: "Post/Zip code",
      labelClass: "mb-1",
      placeholder: "Enter post/zip code",
      customClass: "col-md-3 flex-column align-items-start",
    },
  ];

  let riskFormSchema = [
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Rating",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      name: "listed or regulated",
      value: "",
      type: "select",
      label: "Listed or Regulated",
      placeholder: "Select",
      options: [
        {
          value: "Y",
          label: "Yes",
        },
        {
          value: "N",
          label: "No",
        },
      ],
      customClass: "w-150px",
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input type="text" className="w-100" placeholder="Enter sanction" />
          )}
        </div>
      ),
    },
    // {
    //   customElement: fileCreation ? (
    //     <div></div>
    //   ) : (
    //     <button className="primary-btn mb-15">Confirm</button>
    //   ),
    // },
  ];

  const [individualFormSchema, setIndividualFormSchema] = useState([]);

  useEffect(() => {
    setIndividualFormSchema([
      {
        name: "corporateEntityCode",
        value: "",
        type: "text",
        label: "Corporate Entity Code",
        placeholder: "Enter corporate entity code",
      },
      {
        name: "corporateEntityName",
        value: "",
        type: "text",
        label: "Corporate Entity Name",
        placeholder: "Enter corporate entity name",
      },
      {
        name: "corporateEntityType",
        value: "",
        type: "select",
        label: "Corporate Entity Type",
        placeholder: "Enter corporate entity type",
        options: [
          {
            value: "BVIBC",
            label: "BVIBC",
          },
          {
            value: "trust",
            label: "Trust",
          },
          {
            value: "foundation",
            label: "Foundation",
          },
          {
            value: "general-partnership",
            label: "General Partnership",
          },
          {
            value: "limited-partnership",
            label: "Limited Partnership",
          },
        ],
      },
      {
        name: "incorporationDate",
        value: "",
        type: "date",
        label: "Incorporation Date",
        placeholder: "Enter Date (DD/MM/YYYY)",
        format: "DD/MM/YYYY",
      },
      {
        name: "incorporationNumber",
        value: "",
        type: "text",
        label: "Incorporation Number",
        placeholder: "Enter incorporation number",
      },
      {
        name: "countryOfIncorporation",
        value: "",
        type: "select",
        label: "Country Of Incorporation",
        placeholder: "Select Country Of Incorporation",
        showSearch: true,
        options: getOptions(data),
        loading: isLoading,
      },
      {
        label: "Geography of business",
        customElement: <TreeSelect {...tProps} placement="topLeft" />,
      },
      {
        name: "natureOfBusiness",
        value: "",
        type: "select",
        label: "Nature of Business",
        placeholder: "Select nature of business",
        showSearch: true,
        options: [
          {
            value: "manufacturing",
            label: "Manufacturing",
          },
          {
            value: "retail",
            label: "Retail",
          },
          {
            value: "wholesale",
            label: "Wholesale",
          },
          {
            value: "services",
            label: "Services",
          },
          {
            value: "agriculture",
            label: "Agriculture",
          },
          {
            value: "realEstate",
            label: "Real Estate",
          },
          {
            value: "construction",
            label: "Construction",
          },
          {
            value: "transportation",
            label: "Transportation",
          },
          {
            value: "healthcare",
            label: "Healthcare",
          },
          {
            value: "education",
            label: "Education",
          },
          {
            value: "financialServices",
            label: "Financial Services",
          },
          {
            value: "informationTechnology",
            label: "Information Technology",
          },
          {
            value: "entertainment",
            label: "Entertainment",
          },
          {
            value: "hospitality",
            label: "Hospitality",
          },
          {
            value: "utilities",
            label: "Utilities",
          },
          {
            value: "miningAndDrilling",
            label: "Mining and Drilling",
          },
          {
            value: "rAndD",
            label: "R&D",
          },
          {
            value: "nonProfit",
            label: "Non-Profit",
          },
          {
            value: "eCommerce",
            label: "E-commerce",
          },
          {
            value: "freelancing",
            label: "Freelancing",
          },
          {
            value: "gambling",
            label: "Gambling",
          },
          {
            value: "other",
            label: "Other",
          },
        ],
      },
      {
        name: "descriptionBusiness",
        value: "",
        type: "text",
        label: "Description of Business",
        placeholder: "Enter description of your business",
      },
      {
        name: "taxInfoNumber",
        value: "",
        type: "text",
        label: "Corporate Tax Info Number",
        placeholder: "Enter corporate tax info number",
      },
      {
        name: "Related Entity Code",
        value: "",
        type: "select",
        label: "Related Entity Code",
        placeholder: "Enter related entity code",
        options: [
          {
            value: "E203",
            label: "E203",
          },
          {
            value: "E204",
            label: "E204",
          },
          {
            value: "E205",
            label: "E205",
          },
        ],
      },
      // {
      //   name: "dateOfCreation",
      //   value: "",
      //   type: "date",
      //   label: "Date of Creation",
      //   placeholder: "Select date",
      // },
    ]);
  }, [isLoading]);

  useEffect(() => {
    convertToOptions();
  }, []);

  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: dayjs("04-12-2022", "DD/MM/YYYY"),
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "dateResignation",
      value: dayjs("16-08-2023", "DD/MM/YYYY"),
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: 2,
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
  ];

  let uboFormSchema = [
    {
      name: "ownershipPercentage",
      value: "46%",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "totalSharesHeld",
      value: 10,
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
  ];

  let pocFormSchema = [
    {
      name: "contactDetails",
      value: "9023423423",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
    },
    {
      name: "email",
      value: "info@gmail.com",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeAgreementDate",
      value: dayjs("16-08-2023", "DD/MM/YYYY"),
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
  ];

  const columns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Number of Shares",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Issued Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "issuedDate",
      sorter: (a, b) => a.issuedDate - b.issuedDate,
    },
    {
      title: "Total No. of Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.sharesHeld - b.sharesHeld,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Transfer Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "transferDate",
      sorter: (a, b) => a.transferDate - b.transferDate,
    },
    {
      title: "Transfer Amount",
      dataIndex: "transferAmount",
      sorter: (a, b) => a.transferAmount - b.transferAmount,
    },
    {
      title: "Par value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Date Ceased to be a Member",
      dataIndex: "dateCeased",
      sorter: (a, b) => a.dateCeased - b.dateCeased,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
    },
    {
      title: "",
      render: (_, { id }) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button className="action-btn delete-btn">
            <i className="far fa-trash-alt"></i>
          </button>
          <button className="action-btn">
            <i className="far fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];
  const tableData = [
    {
      key: "1",
      id: "1",
      shareNumber: 55,
      noShares: 100,
      shareType: "Type 1",
      issuedDate: "11-12-2023",
      sharesHeld: "02",
      transferDate: "12-12-2023",
      transferAmount: 500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "2",
      id: "2",
      shareNumber: 56,
      noShares: 500,
      shareType: "Type 2",
      issuedDate: "13-12-2023",
      sharesHeld: "05",
      transferDate: "14-12-2023",
      transferAmount: 800,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "3",
      id: "3",
      shareNumber: 57,
      noShares: 250,
      shareType: "Type 1",
      issuedDate: "15-12-2023",
      sharesHeld: "05",
      transferDate: "17-12-2023",
      transferAmount: 400,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "4",
      id: "4",
      shareNumber: 58,
      noShares: 120,
      shareType: "Type 2",
      issuedDate: "18-12-2023",
      sharesHeld: "05",
      transferDate: "19-12-2023",
      transferAmount: 1500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
  ];

  const addNewCol = (key, { focus }) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.push(arr.length + 1);
    obj[key] = arr;
    setForms(obj);
    // console.log(focus);
    if (focus) {
      focusOn(key + (arr.length - 1));
    }
  };

  const removeNewCol = (key, index) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.splice(index, 1);
    obj[key] = arr;
    setForms(obj);
  };

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }

  function updateChecked(key, checked) {
    let obj = { ...checkedList };
    if (key == "nominee" && checked) {
      obj["shareholders"] = false;
    }
    if (key == "shareholders" && checked) {
      obj["nominee"] = false;
    }
    obj[key] = checked;
    console.log(obj);
    setCheckedList(obj);
  }

  const addNewChange = (values, checkStatus, name) => {
    updateChecked("ubo", checkStatus);

    let arr = [...individualFormSchema];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setIndividualFormSchema(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    let arr = [...individualFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualFormSchema];

    updateChecked(name, checkStatus);

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        removeArrItem(shareholderFormSchema, arr);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  return (
    <div>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => setCorporateEntityShow(value)}
        />
        <h4 className="primary-title">Corporate Entity</h4>
        {corporateEntityShow && (
          <button
            className="primary-btn"
            onClick={() => addNewCol("corporateEntity", { focus: true })}
          >
            <i className="fal fa-plus-circle"></i> Add New
          </button>
        )}
      </div>
      {corporateEntityShow && (
        <div className="mb-md-4">
          {forms.corporateEntity.map((a, i) => {
            return (
              <div key={i} id={`corporateEntity${i}`} className="mb-4">
                <div className="d-flex gap-3 mb-3 align-items-center">
                  <h3 className="sub-title">
                    Corporate Entity 0{i + 1} &nbsp;
                    <i className="fal fa-question-circle"></i>
                  </h3>
                  {i !== 0 && (
                    <button
                      className="outline-btn red-btn"
                      onClick={() => removeNewCol("corporateEntity", i)}
                    >
                      <i className="fal fa-trash-alt"></i> Remove
                    </button>
                  )}
                  <div className="d-flex gap-3 align-items-center">
                    <Checkbox
                      className="custom-checkbox"
                      checked={directorChecked && !reserveChecked}
                      onChange={(e) =>
                        addNewRow(
                          directorFormSchema,
                          e.target.checked,
                          "directors"
                        )
                      }
                    >
                      Directors
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      checked={shareholderChecked && !nomineeChecked}
                      onChange={(e) =>
                        addNewCustom(
                          shareholderFormSchema,
                          "shareholders",
                          e.target.checked
                        )
                      }
                    >
                      Shareholders
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        addNewChange(uboFormSchema, e.target.checked)
                      }
                    >
                      UBOs
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      checked={nomineeChecked && !shareholderChecked}
                      onChange={(e) =>
                        addNewCustom(
                          nomineeShareholder,
                          "nominee",
                          e.target.checked
                        )
                      }
                    >
                      Nominee Shareholder
                    </Checkbox>
                    <Checkbox
                      className="custom-checkbox"
                      checked={reserveChecked && !directorChecked}
                      onChange={(e) =>
                        addNewRow(
                          directorFormSchema,
                          e.target.checked,
                          "reserve"
                        )
                      }
                    >
                      Reserve Director
                    </Checkbox>
                  </div>
                </div>
                <FormZ
                  formSchema={individualFormSchema}
                  ref={formRef}
                  formClass="row custom-form col-md-9 mb-3 mb-md-4 gy-3 gx-4"
                  childClass="col-md-4 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                <FormZ
                  formSchema={adsFormSchema}
                  ref={adsFormRef}
                  formClass="custom-form row"
                  childClass="form-row mb-4"
                  labelClass="me-3 mb-0"
                  inputClass="w-100"
                />
                <FormZ
                  formSchema={riskFormSchema}
                  ref={riskFormRef}
                  formClass="custom-form row gy-3 align-items-end"
                  childClass="col-md-3 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
                <button className="primary-btn mt-4">Update</button>
              </div>
            );
          })}
          {Object.keys(checkedList).some((k) => checkedList[k]) && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <h6
                className="hyper-link mb-0"
                onClick={() => setHistoryShow(!historyShow)}
              >
                Detailed History{" "}
                <i
                  className={`fas fa-chevron-down ${
                    historyShow ? "fa-rotate-180" : ""
                  }`}
                ></i>
              </h6>
              {historyShow ? (
                <button className="primary-btn ms-auto">Export</button>
              ) : (
                <span className="py-2">&nbsp;</span>
              )}
            </div>
          )}
          {historyShow ? (
            <Table
              className="custom-table-light mt-md-1 mb-3"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{ x: 1800 }}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
