import React from "react";
import { Tooltip } from "antd";

import Avatar1 from "../../assets/img/admin-avatar.png";
import Avatar2 from "../../assets/img/user-avatar.png";

import SampleDoc from "../../assets/pdf/sample.pdf";

import UploadZ from "../../components/UploadZ";

import { formatBytes, Each } from "../../helper";

export default function Mail() {
  const documents = [
    {
      url: SampleDoc,
      name: "Inaugural Resolution",
      size: formatBytes(153333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Appointment of First Director",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
  ];

  return (
    <div className="mail-box">
      <div className="mail-box-menu">
        <div className="mail-author">
          <img src={Avatar1} alt="" />
          <div>
            <h6>Username</h6>
            <p>Admin</p>
          </div>
        </div>
        <button className="compose-mail-btn">Compose Mail</button>
        <ul>
          <li className="active">
            <i className="far fa-inbox"></i>
            <span>Inbox</span>
          </li>
          <li>
            <i className="far fa-paper-plane"></i>
            <span>Sent Mails</span>
          </li>
          <li>
            <i className="far fa-file-edit"></i>
            <span>Drafts</span>
          </li>
          <li>
            <i className="far fa-trash"></i>
            <span>Deleted</span>
          </li>
        </ul>
      </div>
      <div className="mail-box-list">
        <div className="mail-box-list-scroll">
          <div className="mail-box-item unread">
            <img src={Avatar2} alt="" />
            <div className="ps-3">
              <h4>Narasimman</h4>
              <h6>Meeting at 5PM!</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
                at? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam, quod?
              </p>
            </div>
            <span className="time">35 sec ago</span>
          </div>
          <div className="mail-box-item unread opened">
            <img src={Avatar2} alt="" />
            <div className="ps-3">
              <h4>Narasimman</h4>
              <h6>Sample Documents</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
                at? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam, quod?
              </p>
            </div>
            <span className="time">2 mins ago</span>
          </div>
          <div className="time-stripe">
            <p>Yesterday</p>
          </div>
          <div className="mail-box-item">
            <img src={Avatar2} alt="" />
            <div className="ps-3">
              <h4>Narasimman</h4>
              <h6>Meeting at 5PM!</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
                at? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam, quod?
              </p>
            </div>
            <span className="time">1 days ago</span>
          </div>
          <div className="time-stripe">
            <p>Last Week</p>
          </div>
          <div className="mail-box-item">
            <img src={Avatar2} alt="" />
            <div className="ps-3">
              <h4>Narasimman</h4>
              <h6>Sample Documents</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
                at? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam, quod?
              </p>
            </div>
            <span className="time">2 days ago</span>
          </div>
          <div className="mail-box-item">
            <img src={Avatar2} alt="" />
            <div className="ps-3">
              <h4>Narasimman</h4>
              <h6>Meeting at 5PM!</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
                at? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam, quod?
              </p>
            </div>
            <span className="time">5 days ago</span>
          </div>
          <div className="mail-box-item">
            <img src={Avatar2} alt="" />
            <div className="ps-3">
              <h4>Narasimman</h4>
              <h6>Sample Documents</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
                at? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam, quod?
              </p>
            </div>
            <span className="time">5 days ago</span>
          </div>
        </div>
      </div>
      <div className="mail-box-content mail-box-list-scroll">
        <h2 className="subject-text">Sample Documents</h2>
        <div className="mail-box-content-item">
          <div className="d-flex justify-content-between align-items-center">
            <div className="another">
              <img src={Avatar2} alt="" />
              <div className="ps-3">
                <Tooltip title="narasimman@gmail.com">
                  <h4>Narasimman</h4>
                </Tooltip>
                <h6>
                  <span>To:</span>username@mail.com
                </h6>
                <h6>
                  <span>Cc:</span>guru@mail.com
                </h6>
              </div>
            </div>
            <div>
              <div className="d-flex gap-3">
                <button className="mail-btn">
                  <i className="far fa-reply"></i>
                  Replay
                </button>
                <button className="mail-btn">
                  <i className="far fa-reply-all"></i>
                  Replay All
                </button>
                <button className="mail-btn">
                  <i className="far fa-share"></i> Forward
                </button>
                <button className="mail-btn delete-btn">
                  <i className="far fa-trash-alt"></i> Delete
                </button>
                <button className="mail-btn icon-btn">
                  <i className="far fa-ellipsis-v"></i>
                </button>
              </div>
              <p className="mail-date-time text-end">Thu 14-3-2024 2:41 PM</p>
            </div>
          </div>
          <div className="mail-attachments my-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6>4 Attachments</h6>
              <div className="d-flex gap-3">
                <button className="mail-btn">
                  <i className="far fa-chevron-double-down"></i> View All
                </button>
                <button className="mail-btn">
                  <i className="far fa-arrow-to-bottom"></i> Download All (
                  {formatBytes(153333 + 293333)})
                </button>
              </div>
            </div>
            <div className="row">
              <Each
                array={documents}
                render={(item) => (
                  <div className="col-md-4">
                    <UploadZ file={item} downloadOnly={true} />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="mail-content px-4">
            <p>
              Hi team,
              <br />
              <br />
              <br />
              <br />
              PFA,
              <br />
              <br />
              <br />
              <br />
              Thanks, <br /> Narasimman S
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
