import React, { useState, useEffect, useRef } from "react";

import { Select, Modal, Spin, Table, Popover } from "antd";
import { useSelector } from "react-redux";

import { fetchApi, sendNotify, customPagination } from "../../helper";

import { languagesArray } from "../../store/language/languageSlice";

import MasterFileCreation from "../../helper/translations/MasterFileCreation.json";
var fileDownload = require("js-file-download");

export default function Creation() {
  const bulkUploadRef = useRef();
  const [selectedMFT, setSelectedMFT] = useState(null);
  const [selectedENOption, setSelectedENOption] = useState(null);

  const { language } = useSelector((state) => state.language);

  const [loader, setLoader] = useState(false);

  const [showBulkModal, setShowbulkModal] = useState(false);
  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [showBulkUploadData, setShowBulkUploadData] = useState(false);
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);

  let m =
    MasterFileCreation.masterFile[selctedLanguage] ||
    MasterFileCreation.masterFile.en;

  const MFTOptions = [
    {
      value: "introducer-client",
      label: m.IntroducerClient,
    },
    {
      value: "end-user",
      label: m.EndUserClient,
    },
    {
      value: "parent-client",
      label: m.ParentClient,
    },
    {
      value: "client",
      label: m.Client,
    },
    {
      value: "entity",
      label: m.Entity,
    },
    {
      value: "individual",
      label: m.Individual,
    },
    {
      value: "corporate-entity",
      label: m.CorporateEntity,
    },
  ];
  const EntityOptions = [
    {
      value: "PC",
      label: "Primary Contact",
    },
    {
      value: "RA",
      label: "Registered Address",
    },
    {
      value: "CDD",
      label: "CDD Information",
    },
    {
      value: "FRAD",
      label: "Financial Record Address & Details",
    },
    {
      value: "ROC",
      label: "Register Of Charges",
    },
  ];

  const downloadSampleExcel = async (value) => {
    let payload = {
      method: "GET",
      responseType: "blob",
    };
    let exportFileName = ``;
    if (value == "Master") {
      exportFileName = `${selectedMFT}_Sample.xls`;
      if (selectedMFT == "client") {
        payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
      } else if (selectedMFT == "introducer-client") {
        payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
      } else if (selectedMFT == "parent-client") {
        payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
      } else if (selectedMFT == "end-user-client") {
        payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
      } else if (selectedMFT == "entity") {
        payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
      } else if (selectedMFT == "corporate-entity") {
        payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
      } else if (selectedMFT == "individual") {
        payload.url = `/client/get/excel-file/?type=${selectedMFT}`;
      }
    } else {
      exportFileName = `${selectedENOption}_Sample.xls`;
      if (selectedENOption == "RA") {
        payload.url = `/client/get/excel-file/?type=${selectedENOption}`;
      } else if (selectedENOption == "PC") {
        payload.url = `/client/get/excel-file/?type=${selectedENOption}`;
      } else if (selectedENOption == "CDD") {
        payload.url = `/client/get/excel-file/?type=${selectedENOption}`;
      } else if (selectedENOption == "ROC") {
        payload.url = `/client/get/excel-file/?type=${selectedENOption}`;
      } else if (selectedENOption == "FRAD") {
        payload.url = `/client/get/excel-file/?type=${selectedENOption}`;
      }
    }
    let result = await fetchApi(payload).then((res) => {
      return res;
    });

    fileDownload(result, exportFileName);
  };

  const bulkAddModalOnClick = async () => {
    setShowbulkModal(true);
  };

  const closeBulkModalOnClick = () => {
    setShowBulkUploadData(false);
    setBulkUploadData([]);
    setShowbulkModal(false);
  };

  const bulkUploadOnClick = () => {
    bulkUploadRef.current.click();
  };

  const bulkUploadOnChange = async (file) => {
    setLoader(true);
    setShowBulkUploadData(false);
    const bodyFormData = new FormData();
    bodyFormData.append("excel", file);
    let payload = {
      method: "POST",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (selectedMFT == "client") {
      payload.url = `/client/read/excel-file/`;
    } else if (selectedMFT == "introducer-client") {
      payload.url = `/client/read/excel-file/`;
    } else if (selectedMFT == "parent-client") {
      payload.url = `/client/read/excel-file/`;
    } else if (selectedMFT == "end-user-client") {
      payload.url = `/client/read/excel-file/`;
    } else if (selectedMFT == "entity") {
      payload.url = `/client/read/excel-file/`;
    } else if (selectedMFT == "corporate-entity") {
      payload.url = `/client/read/excel-file/`;
    } else if (selectedMFT == "individual") {
      payload.url = `/client/read/excel-file/`;
    }

    await fetchApi(payload)
      .then((res) => {
        if (res && res.xlData && res.xlData.length) {
          setBulkUploadData(res.xlData);
        } else {
          setBulkUploadData([]);
        }
      })
      .catch((error) => {
        console.error(error, "onStoreFile-error");
        sendNotify("error", error.message || "File upload failed.");
      });

    setShowBulkUploadData(true);
    setLoader(false);
  };

  const bulkSaveOnClick = async () => {
    let uploadObj = {};

    uploadObj.data = bulkUploadData;
    uploadObj.masterType = selectedMFT;

    let payload = {
      method: "POST",
      url: `/client/createmany`,
      data: uploadObj,
    };

    await fetchApi(payload)
      .then((res) => {
        if (res?.data?.statusCode == 201) {
          if (res?.data?.status) {
            sendNotify("success", res?.data?.message);
            closeBulkModalOnClick();
          } else {
            sendNotify("error", res?.data?.message);
            closeBulkModalOnClick();
          }
        } else {
          sendNotify("error", res?.data?.message);
        }
      })
      .catch((error) => {
        console.error(error, "onStoreFile-error");
        sendNotify("error", error.message || "File upload failed.");
      });
  };

  const getPagination = async (current, pageSize) => {};

  const getMFT = (value) => {
    setSelectedMFT(value);
  };
  const getENOptions = (value) => {
    setSelectedENOption(value);
  };

  const clientColumns = [
    {
      title: "Client Name",
      dataIndex: "Client Name",
    },
    {
      title: "Client Type",
      dataIndex: "Client Type",
    },
    {
      title: "Client Status",
      dataIndex: "Client Status",
    },
    {
      title: "Parent Client Code",
      dataIndex: "Parent Client Code",
    },
    {
      title: "Relationship Manager",
      dataIndex: "Relationship Manager",
    },
    {
      title: "Administrator",
      dataIndex: "Administrator",
    },
    {
      title: "Admin Manager",
      dataIndex: "Admin Manager",
    },
    {
      title: "Jurisdiction",
      dataIndex: "Jurisdiction",
    },
    {
      title: "Fee Schedule Name",
      dataIndex: "Fee Schedule Name",
    },
    {
      title: "Fee Type",
      dataIndex: "Fee Type",
    },

    {
      title: "Fee Sub Category",
      dataIndex: "Fee Sub Category",
    },
    {
      title: "Contact Person's Name",
      dataIndex: "Contact Person's Name",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Contact Number",
      dataIndex: "Contact Number",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h1>Bulk Upload</h1>
      </div>
      <div className="page-content">
        <h1 className="primary-title mb-3">Master File Upload</h1>

        <div className="custom-form">
          <div className="mb-4">
            <label htmlFor="" className="me-3 mb-1">
              {m.MasterfileType}
            </label>
            <Select
              className="custom-select mw-300"
              placeholder={m.SelectAny}
              onChange={getMFT}
              options={MFTOptions}
            />
          </div>
        </div>

        <div className="d-flex gap-2">
          {selectedMFT && (
            <button
              onClick={() => downloadSampleExcel("master")}
              className="primary-btn  justify-content-center py-2"
            >
              <i className="fal fa-file-download"></i> Sample Excel
            </button>
          )}

          {selectedMFT && (
            <button
              onClick={bulkAddModalOnClick}
              className="primary-btn  justify-content-center py-2"
            >
              <i className="fal fa-plus" /> Bulk Upload
            </button>
          )}

          {selectedMFT && (
            <Popover
              content={
                <div className="popover-content-max">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Culpa fugiat enim repellendus velit eaque optio quidem
                  asperiores assumenda veniam eveniet.
                </div>
              }
            >
              <button className="outline-btn">
                <i class="fal fa-question-circle"></i>
              </button>
            </Popover>
          )}
        </div>

        <h1 className="primary-title mt-4 mb-3">Entity Overview</h1>
        <div className="custom-form">
          <div className="mb-4">
            <label htmlFor="" className="me-3 mb-1">
              {"Entity Sub Data"}
            </label>
            <Select
              className="custom-select mw-300"
              placeholder={m.SelectAny}
              onChange={getENOptions}
              options={EntityOptions}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          {selectedENOption && (
            <button
              onClick={() => downloadSampleExcel("sub")}
              className="primary-btn  justify-content-center py-2"
            >
              <i className="fal fa-file-download"></i> Sample Excel
            </button>
          )}

          {selectedENOption && (
            <button
              onClick={bulkAddModalOnClick}
              className="primary-btn  justify-content-center py-2"
            >
              <i className="fal fa-plus" /> Bulk Upload
            </button>
          )}

          {selectedENOption && (
            <Popover
              content={
                <div className="popover-content-max">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Culpa fugiat enim repellendus velit eaque optio quidem
                  asperiores assumenda veniam eveniet.
                </div>
              }
            >
              <button className="outline-btn">
                <i class="fal fa-question-circle"></i>
              </button>
            </Popover>
          )}
        </div>
      </div>
      <Modal
        className={"custom-modal"}
        visible={showBulkModal}
        okText={"Upload"}
        onCancel={closeBulkModalOnClick}
        title={`Add New ${selectedMFT}`}
        width={1300}
        onOk={bulkSaveOnClick}
      >
        <Spin spinning={loader} tip={"Loading..."}>
          <div
            className="row"
            style={{
              padding: 10,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-lg-3">
              <button
                className="btn btn-primary"
                type="button"
                onClick={bulkUploadOnClick}
                style={{ height: 37 }}
              >
                <i className="fas fa-download" />
                <span>{"  Import"}</span>
              </button>
              <input
                ref={bulkUploadRef}
                type={"file"}
                style={{ display: "none" }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => bulkUploadOnChange(e.target.files[0])}
              />
            </div>
          </div>
          {showBulkUploadData && (
            <div>
              <Table
                className="custom-table"
                columns={clientColumns}
                rowKey={"_id"}
                scroll={{
                  x: 1200,
                }}
                dataSource={bulkUploadData}
                pagination={{
                  className: "custom-pagination",
                  defaultCurrent: 1,
                  pageSize: 10,
                  total: bulkUploadData.length,
                  onChange: getPagination,
                  itemRender: customPagination,
                }}
              />
            </div>
          )}
        </Spin>
      </Modal>
    </div>
  );
}
