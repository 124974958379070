import React from 'react'
import { Table, Tag, Select } from 'antd';
import { Link } from 'react-router-dom';

export default function OnboardingTeamListing() {
    const columns = [
        {
            title: 'Requestor Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Requestor Ref',
            dataIndex: 'ref',
            key: 'ref',
            sorter: (a, b) => a.ref - b.ref,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (_, { status }) => (
                <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={status == 'Y' ? 'gold' : 'processing'}>
                    {status == 'Y' ? 'Pending' : 'In Progress'}
                </Tag>
            ),
            filters: [
                {
                    text: 'Pending',
                    value: 'Y',
                },
                {
                    text: 'In Progress',
                    value: 'N',
                },
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
            title: 'RM Name',
            dataIndex: 'rmName',
            key: 'rmName',
            sorter: (a, b) => a.rmName - b.rmName,
            filters: [
                {
                    text: 'Guru Nath',
                    value: 'Guru Nath',
                },
                {
                    text: 'Peter Parker',
                    value: 'Peter Parker',
                },
                {
                    text: 'John Doe',
                    value: 'John Doe',
                },
            ],
            onFilter: (value, record) => record.rmName.indexOf(value) === 0,
        },
        {
            title: 'Jurisdiction',
            dataIndex: 'jurisdiction',
            key: 'jurisdiction',
            sorter: (a, b) => a.jurisdiction - b.jurisdiction,
            filters: [
                {
                    text: 'India',
                    value: 'India',
                },
                {
                    text: 'Sri Lanka',
                    value: 'Sri Lanka',
                },
            ],
            onFilter: (value, record) => record.jurisdiction.indexOf(value) === 0,
        },
        {
            title: 'Assignee 1',
            dataIndex: 'assignee1',
            sorter: (a, b) => a.assignee1 - b.assignee1,
            render: (_, { verified }) => (
                <Select
                    className="custom-select"
                    style={{ width: 100 }}
                    placeholder="Select Assignee"
                    defaultValue={'user1'}
                    options={[
                        {
                            value: 'user1',
                            label: 'User 1'
                        },
                        {
                            value: 'user2',
                            label: 'User 2'
                        },
                        {
                            value: 'user3',
                            label: 'User 3'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Assignee 2',
            dataIndex: 'assignee2',
            sorter: (a, b) => a.assignee2 - b.assignee2,
            render: (_, { verified }) => (
                <Select
                    className="custom-select"
                    style={{ width: 100 }}
                    placeholder="Select Assignee"
                    options={[
                        {
                            value: 'user1',
                            label: 'User 1'
                        },
                        {
                            value: 'user2',
                            label: 'User 2'
                        },
                        {
                            value: 'user3',
                            label: 'User 3'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a, b) => a.createdDate - b.createdDate,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, { id }) => (
                <div className="d-flex gap-2 justify-content-end align-items-center">
                    <button className='action-btn'>Assign</button>
                    <button className='action-btn'>Re-assign</button>
                    <Link to={`/app/onboarding-team/edit/${id}`} className='action-btn'>View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i></Link>
                </div>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            id: '1',
            name: 'Request #1',
            ref: "PR001",
            status: 'Y',
            rmName: 'Guru Nath',
            jurisdiction: 'India',
            createdDate: '07/11/2023 04:56PM',
        },
        {
            key: '2',
            id: '2',
            name: 'Request #2',
            ref: "PR002",
            status: 'N',
            rmName: 'Peter Parker',
            jurisdiction: 'Sri Lanka',
            createdDate: '06/11/2023 06:08PM',
        },
        {
            key: '3',
            id: '3',
            name: 'Request #3',
            ref: "PR003",
            status: 'N',
            rmName: 'John Doe',
            jurisdiction: 'Sri Lanka',
            createdDate: '06/11/2023 02:23PM',
        },
        {
            key: '4',
            id: '4',
            name: 'Request #4',
            ref: "PR004",
            status: 'Y',
            rmName: 'Guru Nath',
            jurisdiction: 'India',
            createdDate: '04/11/2023 02:12PM',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <h1 className='page-title mb-4'>Onboarding Team Name Check</h1>
            <div className="mb-3 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                <h2 className='primary-title mb-3 mb-md-0'>Select Name Check Request</h2>
                <div className="d-flex gap-3 flex-column flex-md-row">
                    <button className='outline-btn'><i className="far fa-arrow-to-bottom"></i> Download Selected</button>
                    <button className='outline-btn'><i className="far fa-check-double"></i> Assign Selected</button>
                </div>
            </div>
            <Table className='custom-table' rowSelection={{ type: 'checkbox', ...rowSelection }} columns={columns} dataSource={data} />

            {/* <h2 className='primary-title mb-4'>Select Relationship Manager</h2>
            <p className='mb-4'>Please select a relationship manager to view their name check requests</p>
            <div className="custom-form d-flex gap-4 flex-column flex-md-row mb-4">
                <div>
                    <label htmlFor="" className="me-3">Relationship Manager Name</label>
                    <input type="text" placeholder="Enter Name" />
                </div>
                <div>
                    <label htmlFor="" className="me-3">Relationship Manager Code</label>
                    <input type="text" placeholder="Enter Code" />
                </div>
            </div>
            <button className='primary-btn'>Filter Name Check Requests</button> */}
        </div>
    )
}
