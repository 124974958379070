import { useState, useEffect } from "react";
import { Table, Switch, Popover } from "antd";
import {
  getQuery,
  convertQueryParams,
  fetchApi,
  downloadAsCSV,
} from "../../../helper";
import TagZ from "../../../components/TagZ";

export default function IndividualForm(props) {
  let query = getQuery();
  const [detailedHistoryShow, setDetailedHistoryShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 5 });
  const [totalCount, setTotalCount] = useState(0);
  const [clientData, setClientData] = useState([]);
  let { id } = props;

  useEffect(() => {
    if (id || query.id) {
      let params = { enId: id || query.id };
    }
  }, [id, query.id]);

  useEffect(() => {
    if (detailedHistoryShow) {
      handleRefresh();
    }
  }, [id]);

  const columns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Shareholder Code",
      dataIndex: "senderMasterId",
      sorter: (a, b) => (a.senderMasterId || 0) - (b.senderMasterId || 0),
    },
    {
      title: "Shareholder Name",
      dataIndex: "senderMasterName",
      sorter: (a, b) => (a.senderMasterName || 0) - (b.senderMasterName || 0),
    },

    {
      title: "No. of Share held",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "To-From",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
      render: (_, { _id, from, to }, index) => <span>{`${from} - ${to}`}</span>,
    },

    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },

    {
      title: "Authorised share capital",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.sharesHeld - b.sharesHeld,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (_, { status }) => (
        <TagZ
          status={status == "active" ? "approved" : "rejected"}
          statusText={status == "active" ? "Active" : "Inactive"}
        />
      ),
    },
    {
      title: "Issued Date",
      dataIndex: "createdAt",
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },

    {
      title: "Par value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Date Ceased to be a Member",
      dataIndex: "ceasedDate",
      sorter: (a, b) =>
        new Date(a.dateCeased).getTime() - new Date(b.dateCeased).getTime(),
    },
    {
      title: "Comment",
      render: (_, { _id, comment }, index) => (
        <Popover
          className="custom-popover"
          placement="rightBottom"
          content={<p className="mb-0">{comment}</p>}
        >
          <button className="primary-btn">View</button>
        </Popover>
      ),
    },
  ];
  const getPagination = async (current, pageSize) => {
    let params = {
      ...queryParams,
      _start: (current - 1) * pageSize,
      _limit: pageSize,
      enId: id || query.id,
    };

    const queryString = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/transaction?${queryString}`,
    };

    const historyData = await fetchApi(payload).then((res) => {
      setTotalCount(res?.data?.totalCount || 0);
      return res?.data?.rows || [];
    });
    const clientDataMap = new Map(
      clientData.map((item) => [item.value, item.label])
    );

    let namelist = clientData
      ? clientData?.map((d) => {
          let obj = {
            value: d._id,

            label: d?.name
              ? d?.name
              : ` ${
                  d?.firstName && d?.lastName
                    ? `${d?.firstName} ${
                        d?.middleName && d?.middleName !== "."
                          ? d?.middleName + " "
                          : ""
                      }${d?.lastName}`
                    : ""
                }`,
          };
          return obj;
        })
      : [];
    const clientNameDataMap = new Map(
      namelist.map((item) => [item.value, item.label])
    );
    const transformedResponse = {
      rows: historyData.map((row) => ({
        ...row,
        senderMasterId: row.senderForm?.length
          ? clientDataMap.get(row.senderForm[0]?.masterId)
          : row.allocationForm?.length
          ? clientDataMap.get(row.allocationForm[0]?.masterId)
          : null,
        senderMasterName: row.senderForm?.length
          ? clientNameDataMap.get(row.senderForm[0]?.masterId)
          : row.allocationForm?.length
          ? clientNameDataMap.get(row.allocationForm[0]?.masterId)
          : null,
        shareNumber: row.senderForm?.length
          ? row.senderForm[0]?.certificateNumber
          : row.receiverForm?.length
          ? row.receiverForm[0]?.certificateNumber
          : row.allocationForm?.length
          ? row.allocationForm[0]?.certificateNumber
          : null,
        from: row.senderForm?.length
          ? row.senderForm[0]?.from
          : row.receiverForm?.length
          ? row.receiverForm[0]?.from
          : row.allocationForm?.length
          ? row.allocationForm[0]?.from
          : null,
        to: row.senderForm?.length
          ? row.senderForm[0]?.to
          : row.receiverForm?.length
          ? row.receiverForm[0]?.to
          : row.allocationForm?.length
          ? row.allocationForm[0]?.to
          : null,
        noShares: row.senderForm?.length
          ? row.senderForm[0]?.numberOfShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.numberOfShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.numberOfShares
          : null,

        sharesHeld: row.senderForm?.length
          ? row.senderForm[0]?.shareCapital
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareCapital
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareCapital
          : null,

        parValue: row.senderForm?.length
          ? row.senderForm[0]?.parValue
          : row.receiverForm?.length
          ? row.receiverForm[0]?.parValue
          : row.allocationForm?.length
          ? row.allocationForm[0]?.parValue
          : null,

        ceasedDate: row.senderForm?.length
          ? row.senderForm[0]?.ceasedDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.ceasedDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.ceasedDate
          : "-",

        effectiveDate: row.senderForm?.length
          ? row.senderForm[0]?.effectiveDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.effectiveDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.effectiveDate
          : null,

        totalShares: row.senderForm?.length
          ? row.senderForm[0]?.totalShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.totalShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.totalShares
          : null,

        percentage: row.senderForm?.length
          ? row.senderForm[0]?.percentage
          : row.receiverForm?.length
          ? row.receiverForm[0]?.percentage
          : row.allocationForm?.length
          ? row.allocationForm[0]?.percentage
          : null,

        comment: row.allocationForm?.length
          ? row.allocationForm[0]?.comment
          : null,

        currency: row.allocationForm?.length
          ? row.allocationForm[0]?.currency
          : null,

        shareType: row.senderForm?.length
          ? row.senderForm[0]?.shareType
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareType
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareType
          : null,

        combinedForm: {
          sender: row.senderForm || [],
          receiver: row.receiverForm || [],
          allocation: row.allocationForm || [],
        },
      })),
    };
    console.log(transformedResponse.rows, "transformedResponse");
    setTableData(transformedResponse.rows);

    setQueryParams(params);
  };

  const handleRefresh = async () => {
    try {
      let params = { enId: id, ...queryParams }; //all: true

      const fetchData = async (url) => {
        const payload = { method: "GET", url };
        const response = await fetchApi(payload);
        setTotalCount(response?.data?.totalCount);
        return response?.data?.rows || [];
      };

      const clientParams = convertQueryParams({
        isAll: true,
        exportAll: true,
      });
      const clientData = await fetchData(`/client?${clientParams}`);
      let namelist = clientData
        ? clientData?.map((d) => {
            let obj = {
              value: d._id,

              label: d?.name
                ? d?.name
                : ` ${
                    d?.firstName && d?.lastName
                      ? `${d?.firstName} ${
                          d?.middleName && d?.middleName !== "."
                            ? d?.middleName + " "
                            : ""
                        }${d?.lastName}`
                      : ""
                  }`,
            };
            return obj;
          })
        : [];
      const clientDataMap = new Map(
        clientData.map((item) => [item._id, item.code])
      );
      const clientNameDataMap = new Map(
        namelist.map((item) => [item.value, item.label])
      );

      setClientData(clientData);
      const queryString = convertQueryParams(params);
      const historyData = await fetchData(`/transaction?${queryString}`);
      const transformedResponse = {
        rows: historyData.map((row) => ({
          ...row,
          senderMasterId: row.senderForm?.length
            ? clientDataMap.get(row.senderForm[0]?.masterId)
            : row.allocationForm?.length
            ? clientDataMap.get(row.allocationForm[0]?.masterId)
            : null,
          senderMasterName: row.senderForm?.length
            ? clientNameDataMap.get(row.senderForm[0]?.masterId)
            : row.allocationForm?.length
            ? clientNameDataMap.get(row.allocationForm[0]?.masterId)
            : null,
          shareNumber: row.senderForm?.length
            ? row.senderForm[0]?.certificateNumber
            : row.receiverForm?.length
            ? row.receiverForm[0]?.certificateNumber
            : row.allocationForm?.length
            ? row.allocationForm[0]?.certificateNumber
            : null,

          noShares: row.senderForm?.length
            ? row.senderForm[0]?.numberOfShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.numberOfShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.numberOfShares
            : null,

          sharesHeld: row.senderForm?.length
            ? row.senderForm[0]?.shareCapital
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareCapital
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareCapital
            : null,

          parValue: row.senderForm?.length
            ? row.senderForm[0]?.parValue
            : row.receiverForm?.length
            ? row.receiverForm[0]?.parValue
            : row.allocationForm?.length
            ? row.allocationForm[0]?.parValue
            : null,

          ceasedDate: row.senderForm?.length
            ? row.senderForm[0]?.ceasedDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.ceasedDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.ceasedDate
            : null,

          effectiveDate: row.senderForm?.length
            ? row.senderForm[0]?.effectiveDate
            : row.receiverForm?.length
            ? row.receiverForm[0]?.effectiveDate
            : row.allocationForm?.length
            ? row.allocationForm[0]?.effectiveDate
            : null,

          totalShares: row.senderForm?.length
            ? row.senderForm[0]?.totalShares
            : row.receiverForm?.length
            ? row.receiverForm[0]?.totalShares
            : row.allocationForm?.length
            ? row.allocationForm[0]?.totalShares
            : null,

          percentage: row.senderForm?.length
            ? row.senderForm[0]?.percentage
            : row.receiverForm?.length
            ? row.receiverForm[0]?.percentage
            : row.allocationForm?.length
            ? row.allocationForm[0]?.percentage
            : null,

          comment: row.allocationForm?.length
            ? row.allocationForm[0]?.comment
            : null,

          currency: row.allocationForm?.length
            ? row.allocationForm[0]?.currency
            : null,
          from: row.senderForm?.length
            ? row.senderForm[0]?.from
            : row.receiverForm?.length
            ? row.receiverForm[0]?.from
            : row.allocationForm?.length
            ? row.allocationForm[0]?.from
            : null,
          to: row.senderForm?.length
            ? row.senderForm[0]?.to
            : row.receiverForm?.length
            ? row.receiverForm[0]?.to
            : row.allocationForm?.length
            ? row.allocationForm[0]?.to
            : null,
          shareType: row.senderForm?.length
            ? row.senderForm[0]?.shareType
            : row.receiverForm?.length
            ? row.receiverForm[0]?.shareType
            : row.allocationForm?.length
            ? row.allocationForm[0]?.shareType
            : null,

          combinedForm: {
            sender: row.senderForm || [],
            receiver: row.receiverForm || [],
            allocation: row.allocationForm || [],
          },
        })),
      };
      console.log(transformedResponse.rows, "transformedResponse");
      setTableData(transformedResponse.rows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onExportClick = () => {
    downloadAsCSV(tableData, "detailed-history");
  };

  return (
    <div>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => setDetailedHistoryShow(value)}
        />
        <h4 className="primary-title">Share Registry</h4>

        {detailedHistoryShow && (
          <div className="d-flex gap-3 ms-auto">
            <button className="primary-btn" onClick={handleRefresh}>
              Reload
            </button>
            <button className="primary-btn" onClick={onExportClick}>
              Export
            </button>
          </div>
        )}
      </div>
      {detailedHistoryShow && (
        <div className="d-flex justify-content-between align-items-center">
          <Table
            className="custom-table-light mt-md-1 mb-3"
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 1800 }}
            pagination={{
              className: "custom-pagination",
              defaultCurrent: 1,
              pageSize: queryParams?._limit,
              total: totalCount,
              onChange: getPagination,
            }}
          />
        </div>
      )}
    </div>
  );
}
