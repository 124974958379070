import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal, Checkbox, Select, DatePicker } from "antd";
import dayjs from "dayjs";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

import COI_PDF from "../assets/pdf/certificate-of-incumbency.pdf";
import SignatureImg from "../assets/img/signature.png";

import { downloadFile, dateOptions, monthOptions } from "../helper";

import AddSignature from "../components/AddSignature";

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    downloadFile(COI_PDF, "certificate-of-incumbency");
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function CertificateOfShares() {
  let { isLogged, userDetails } = useSelector((state) => state.auth);

  const [explainModel, setExplainModel] = useState(false);
  const [rejectModel, setRejectModel] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [value, setvalue] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOk = () => {
    setExplainModel(false);
    setRejectModel(false);
  };
  const handleCancel = () => {
    setExplainModel(false);
    setRejectModel(false);
  };
  // {jai -Certificate of Incumbency}
  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Certificate of Shares
          </h1>
        </Link>
        <div className="d-flex gap-3">
          {/* <Link
            to={`/app/entity-overview/list?id=${data?.entityId}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link> */}
          <button className="primary-btn reject-btn">Reject</button>
          <button className="primary-btn">Send for Review</button>
        </div>
      </div>
      <div className="page-content">
        <div className="pdf-zoom-wrapper">
          <TransformWrapper
            initialScale={1}
            centerOnInit
            panning={{ excluded: ["input"] }}
            doubleClick={{ excluded: ["input"] }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <Controls />
                <TransformComponent wrapperClass="w-100 pdf-transform">
                  <div className="custom-pdf-wrapper">
                    <h1>CERTIFICATE OF SHARES</h1>
                    <div className="text-center">
                      <input
                        type="text"
                        placeholder="Sample Entity"
                        className="pdf-input"
                        style={{ width: 100 }}
                      />
                    </div>
                    <p className="text-center">
                      (Incorporated under the laws of the British Virgin
                      Islands)
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>
                        ​No. of Certificate:
                        <input
                          type="number"
                          placeholder="1"
                          className="pdf-input"
                          style={{ width: 50 }}
                        />
                      </p>
                      <p>
                        No. of Shares:
                        <input
                          type="number"
                          placeholder="200"
                          className="pdf-input"
                          style={{ width: 60 }}
                        />
                      </p>
                    </div>
                    <p className="text-center mb-0">
                      <b>
                        <i>Authorized Share Capital of US$50,000.00</i>
                      </b>
                    </p>
                    <p className="text-center">
                      <i>
                        DIVIDED INTO 50,000 Ordinary Shares of par value US$1.00
                        each
                      </i>
                    </p>
                    <h3>This is to certify that</h3>
                    <div className="text-center mb-2">
                      <input
                        type="text"
                        placeholder="XXXXXXXXXXXXXXXXXX"
                        className="pdf-input"
                        style={{ width: 160 }}
                      />
                    </div>
                    <p className="text-center">
                      of
                      <input
                        type="text"
                        placeholder="XXXXXXXXXXXXXXXXXX"
                        className="pdf-input"
                        style={{ width: 160 }}
                      />
                      , is the registered Holder of
                      <input
                        type="number"
                        placeholder="1"
                        className="pdf-input"
                        style={{ width: 50 }}
                      />
                      share fully paid, numbered <br />
                      <input
                        type="number"
                        placeholder="1"
                        className="pdf-input"
                        style={{ width: 50 }}
                      />
                      in the above Named Company subject to the Memorandum and
                      Articles of Association of the Company.
                    </p>
                    <div className="d-flex justify-content-end">
                      <p>
                        <b>Given</b> <i>under the Common Seal of the Company</i>{" "}
                        <br />
                        <i>This</i>{" "}
                        <Select
                          className="custom-select pdf-select"
                          defaultValue={12}
                          placeholder="Select"
                          options={dateOptions}
                        />
                        &nbsp; <b>day of</b> &nbsp;
                        <Select
                          className="custom-select pdf-select"
                          defaultValue={"May"}
                          placeholder="Select"
                          options={monthOptions}
                          style={{ width: 100 }}
                        />
                        , &nbsp;
                        <DatePicker
                          className="custom-datepicker pdf-datepicker"
                          picker="year"
                          defaultValue={dayjs("2024", "YYYY")}
                          style={{ width: 80 }}
                        />
                      </p>
                    </div>
                    <div className="row justify-content-end">
                      <div className="col-md-6">
                        <div>
                          <p className="mb-0">
                            {value?.url ? (
                              <div className="signature-wrapper w-50">
                                {" "}
                                <img
                                  src={`data:image/png;base64, ${value.url}`}
                                  alt="Signature"
                                />{" "}
                              </div>
                            ) : (
                              <div className="signature-content">
                                {/* <b>[Signature]</b> */}
                                <div className="add-signature-badge">
                                  Add Signature
                                </div>
                                <div
                                  className="signature-wrapper"
                                  onClick={() => setSignModal(true)}
                                >
                                  <span className="icon">
                                    <i className="fal fa-signature"></i>
                                  </span>
                                  <label
                                    htmlFor=""
                                    className="custom-label req"
                                  >
                                    Click here to sign
                                  </label>
                                </div>
                              </div>
                            )}
                            <hr />
                            By: <b>{value?.fullName ? value.fullName : ""}</b>
                            <br />
                            Authorized Signatory: <b>{userDetails.userName}</b>
                            <br />
                            <b>Registered Agent</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
      <Modal
        className="custom-modal-2"
        open={explainModel}
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Request Resubmission"
        cancelButtonProps={{ className: "d-none" }}
      >
        <div className="custom-form mt-4 pt-2">
          <h6 className="input-title">Resolution of Appointment</h6>
          <p className="description mb-3">
            Explain why this document needs to be resubmitted.
          </p>
          <textarea
            name=""
            id=""
            className="mb-4"
            placeholder="Leave a comment..."
          ></textarea>

          <h6 className="input-title">Letter of Resignation</h6>
          <p className="description mb-3">
            Explain why this document needs to be resubmitted.
          </p>
          <textarea
            name=""
            id=""
            className="mb-4"
            placeholder="Leave a comment..."
          ></textarea>
        </div>
      </Modal>
      <Modal
        className="custom-modal-2"
        open={rejectModel}
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Reject"
        okButtonProps={{ className: "reject-btn" }}
        cancelButtonProps={{ className: "d-none" }}
      >
        <div className="mt-4 pt-2">
          <h6 className="input-title">Reject Request</h6>
          <p className="description mb-3">
            Select reason(s) as to why this request should be rejected.
          </p>
          <Checkbox className="custom-checkbox py-1 font-md">
            Incomplete or inaccurate information provided in the documents
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Insufficient supporting documentation or evidence
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            The requesting entity does not have the legal authority to do so
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Lack of authorization or signature from the appropriate authority
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Failure to pay required fees or charges associated with the request
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Issues with the authenticity or validity of the requesting entity or
            individuals involved
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Suspected fraud or misrepresentation in the application
          </Checkbox>
        </div>
      </Modal>
      <AddSignature
        open={signModal}
        setvalue={setvalue}
        onOk={() => setSignModal(false)}
        onCancel={() => setSignModal(false)}
      />
    </div>
  );
}
