import { useState } from "react";

import { Each } from "../../../helper";
import UploadZ from "../../../components/UploadZ";

import SampleDoc from "../../../assets/pdf/sample.pdf";

export default function Trusts(props) {
  let { getDocument, clearUpload } = props;

  const [documents, setDocuments] = useState([]);
  const [upload, setUpload] = useState([]);

  function storeDocuments(file) {
    let doc = [...documents];
    let arrIndex = doc.findIndex((d) => d.document == file.document);
    if (arrIndex != -1) {
      doc[arrIndex] = file;
    } else {
      doc.push(file);
    }
    setDocuments(doc);
    getDocument(doc);
  }

  const uploadDocs = [
    "Certificate of Incorporation",
    "M&AA",
    "Seal",
    "VIRRGIN Report",
    "COGS",
  ];

  const sampDocuments = {
    url: SampleDoc,
    name: "Sample Document.pdf",
    size: "200 KB",
    type: "pdf",
    base64: "",
  };

  const addNewUpload = () => {
    let arr = [...upload];
    arr.push(`Enter document name ${arr.length + 1}`);
    setUpload(arr);
  };

  const removeUpload = (index) => {
    let arr = [...upload];
    arr.splice(index, 1);
    setUpload(arr);
  };

  const getLabelName = (value, index) => {
    let arr = [...upload];
    arr[index] = value;
    setUpload(arr);
  };

  const deleteDocument = (name) => {
    let doc = [...documents];
    let arrIndex = doc.findIndex((d) => d.document == name);
    if (arrIndex != -1) {
      doc.splice(arrIndex, 1);
    }
    setDocuments(doc);
    getDocument(doc);
  };

  return (
    <div className="custom-form">
      <h6 className="primary-title mb-4">Incorp Documents</h6>
      {/* <div className="info-box mb-4">
        <h6>
          Where the beneficial owner, director, shareholder or officer is a
          Trust, the following will need to be provided in support of the duly
          completed application form:
        </h6>
        <ol>
          <li>The name of the trust;</li>
          <li>The date and country of establishment of the trust;</li>
          <li>
            Where there is an agent acting for the trust, the name and address
            of the agent;
          </li>
          <li>The nature and purpose of the trust;</li>
          <li>
            Identifying information in relation to any Person appointed as a
            trustee, settlor (grantor) or protector of the trust. (See A.
            Individuals and B. Corporate)
          </li>
          <li>
            Identity of beneficiaries of the trust. (See A. Individuals and B.
            Corporate)
          </li>
        </ol>
        <p>
          Items 1-4 would need to be verified. A signed letter from the
          professional trustee duly notarised would suffice as acceptable
          verification for these items.
        </p>
      </div> */}
      <div className="row gy-2 gx-4">
        <Each
          array={uploadDocs}
          render={(item) => (
            <div className="col-md-3">
              <label htmlFor={item} className="me-3">
                {item}
              </label>
              <UploadZ
                file={sampDocuments}
                onStoreFile={storeDocuments}
                fileName={item}
              />
            </div>
          )}
        />
        <Each
          array={upload}
          render={(item, i) => (
            <div className="col-md-3">
              <div className="position-relative">
                <div className="d-flex justify-content-between align-items-center mb-1px">
                  <label
                    contentEditable={true}
                    key={i}
                    id={i}
                    onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                    className="mb-0"
                    suppressContentEditableWarning={true}
                  >
                    {item}
                  </label>
                  <button
                    className="del-upload-btn"
                    onClick={() => removeUpload(i)}
                  >
                    <i className="far fa-trash-alt"></i>
                  </button>
                </div>
                <UploadZ
                  onStoreFile={storeDocuments}
                  onDeleteFile={deleteDocument}
                  clearUpload={clearUpload}
                  fileName={item}
                />
              </div>
            </div>
          )}
        />
        <div className="col-md-3 mb-4">
          <label htmlFor="">&nbsp;</label>
          <div className="add-new-upload" onClick={addNewUpload}>
            <span className="icon">
              <i className="fal fa-plus-circle"></i>
            </span>
            <h6>Add New Upload</h6>
            <p>Click to create new upload field</p>
          </div>
        </div>
      </div>
    </div>
  );
}
