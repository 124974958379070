import { useState, useEffect } from "react";
import { Modal, Table, Switch, Select, Popover } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

import { getPreIncorpRequests } from "../store/preIncorpRequests/preIncorpSlice";
import { getPostIncorpRequests } from "../store/postIncorpRequests/postIncorpSlice";
import { getUser } from "../store/user/userSlice";
import TableFilterZ from "../components/TableFilterZ";
import {
  showCount,
  customPagination,
  downloadFile,
  sendNotify,
  fetchApi,
} from "../helper";
import InProgressIcon from "../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../assets/img/approved-icon.svg";
import searchFilter from "../components/searchFilter";
import TagZ from "../components/TagZ";
import TabZ from "../components/TabZ";
import TableSortArrows from "../components/tableSortArrows";

import AssigneeImg from "../assets/img/notify-avatar.png";
import SampleDoc from "../assets/pdf/sample.pdf";

const { confirm } = Modal;

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    downloadFile(SampleDoc, "certificate-of-incumbency");
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function ActiveRequests() {
  const preIncorp = useSelector((state) => state.preIncorp);
  const postIncorp = useSelector((state) => state.postIncorp);
  const user = useSelector((state) => state.user);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [preData, setPreData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");

  const [searchIds, setSearchIds] = useState({});
  const [searchIds2, setSearchIds2] = useState({});
  const [userArray, setUserArray] = useState([]);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let params = { ...queryParams };
    // params.all = true;
    dispatch(getPreIncorpRequests(queryParams));
    dispatch(getPostIncorpRequests(queryParams));
    dispatch(getUser(params));
  }, []);

  useEffect(() => {
    setPreData(preIncorp?.data?.rows);
    setPostData(postIncorp?.data?.rows);
    handleRefresh();
  }, [preIncorp, postIncorp, user]);

  const handleRefresh = async () => {
    if (user?.data?.data?.length) {
      let dataArray = user?.data?.data ?? [];
      let userArray = dataArray?.map((a) => {
        let obj = {
          value: a?._id,
          label: (
            <div key={a?._id} className="assignee-list">
              <div key={a?._id} className="assignee-item align-items-center">
                <h5 className="assign-profile mb-0">
                  {a?.firstName[0]?.toUpperCase() +
                    a?.lastName[0]?.toUpperCase()}
                </h5>
                <p>{`${a?.firstName} ${a?.lastName}`}</p>
              </div>
            </div>
          ),
        };
        return obj;
      });

      setUserArray(userArray);
    }
  };
  const handleSearch = (searchFilters) => {
    let query = { ...queryParams, ...searchFilters };
    dispatch(getPreIncorpRequests(query));
    setQueryParams(query);
  };

  const handleReset = (key) => {
    let query = { ...queryParams };
    delete query[key];
    dispatch(getPreIncorpRequests(query));
    setQueryParams(query);
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const viewDocument = (docName) => {
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const searchChange = (value, id, assignee) => {
    if (assignee == "assignee1") {
      setSearchIds((prev) => ({
        ...prev,
        [id]: value,
      }));
    } else {
      setSearchIds2((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  const confirmRemove = (id, assignee, assign, incorp, type) => {
    confirm({
      title: <h5>Confirm remove assign</h5>,
      content: <p>Are you sure you want to remove this assignee?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Remove",
      onOk() {
        removeonClick(id, assignee, assign, incorp, type);
      },
    });
  };

  const removeonClick = (id, assignee, assign, incorp, type) => {
    let assigneeArray = [];

    if (assignee == "assignee1") {
      assigneeArray = assign?.map((a) => {
        if (a.type === "assignee_1") {
          return { ...a, status: "inactive" };
        }
        return a;
      });
      setSearchIds((prev) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    } else {
      assigneeArray = assign?.map((a) => {
        if (a.type === "assignee_2") {
          return { ...a, status: "inactive" };
        }
        return a;
      });
      setSearchIds2((prev) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    }
    console.log(id, assignee, assign, incorp, type);

    if (assign && assign?.length !== 0) {
      setLoading(true);
      const payload = {
        method: "PUT",
        url:
          incorp == "post"
            ? `/serviceRequest/update/${id}`
            : type == "Name Check"
            ? `incorporation/assignee/${id}`
            : `relationshipManager/update/${id}`,
        data: { assign: assigneeArray },
      };
      fetchApi(payload)
        .then((res) => {
          setLoading(false);

          if (res?.status == 200 || res?.status == 201) {
            sendNotify("success", res?.message);

            dispatch(getPreIncorpRequests(queryParams));
            dispatch(getPostIncorpRequests(queryParams));
            dispatch(getUser(queryParams));
          } else {
            sendNotify("error", res?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          sendNotify("error", error?.message);
        });
    }
  };

  const transformData = (searchIds, searchIds2) => {
    const result = {};

    Object.entries(searchIds).forEach(([key, userId]) => {
      if (!result[key]) result[key] = [];
      result[key].push({ userId, type: 1 });
    });

    Object.entries(searchIds2).forEach(([key, userId]) => {
      if (!result[key]) result[key] = [];
      result[key].push({ userId, type: 2 });
    });

    return result;
  };
  const transformAndFilterData = (searchIds, searchIds2, filterKey, type) => {
    const result = {};

    Object.entries(searchIds).forEach(([key, userId]) => {
      if (key == filterKey) {
        if (!result[key]) result[key] = [];
        result[key].push({
          userId,
          type: "assignee_1",
          assignType: type,
          status: "active",
        });
      }
    });

    Object.entries(searchIds2).forEach(([key, userId]) => {
      if (key == filterKey) {
        if (!result[key]) result[key] = [];
        result[key].push({
          userId,
          type: "assignee_2",
          assignType: type,
          status: "active",
        });
      }
    });

    return result;
  };
  function categorizeCode(code) {
    if (code.startsWith("FC")) {
      return "feeCat";
    } else if (code.startsWith("SER")) {
      return "serviceRequest";
    } else if (code.startsWith("INCR") || code.startsWith("INC")) {
      return "incorpe";
    } else {
      return "unknown";
    }
  }
  const assignOnClick = (id, incorp, type, code) => {
    setLoading(true);
    if (incorp == "post") {
      const transformedData = transformAndFilterData(
        searchIds,
        searchIds2,
        id,
        "assign"
      );
      const firstKey = Object.keys(transformedData)[0];
      const firstElement = transformedData[firstKey];

      let obj = {
        assign: firstElement,
      };
      let findApi = categorizeCode(code);
      console.log(findApi, "findApi");
      const payload = {
        method: "PUT",
        url:
          findApi == "serviceRequest"
            ? `/serviceRequest/update/${id}`
            : findApi == "feeCat"
            ? `/feeCategory/update/${id}`
            : null,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          setLoading(false);
          if (res?.status == 200 || res?.status == 201) {
            sendNotify("success", res?.message);
          } else {
            sendNotify("error", res?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          sendNotify("error", error?.message);
        });
    } else {
      const transformedData = transformAndFilterData(
        searchIds,
        searchIds2,
        id,
        "assign"
      );
      const firstKey = Object.keys(transformedData)[0];
      const firstElement = transformedData[firstKey];

      let obj = {
        assign: firstElement,
      };
      const payload = {
        method: "PUT",
        url:
          type == "Name Check"
            ? `incorporation/assignee/${id}`
            : `/relationshipManager/update/${id}`,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          setLoading(false);
          if (res?.status == 200 || res?.status == 201) {
            sendNotify("success", res?.message);
          } else {
            sendNotify("error", res?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          sendNotify("error", error?.message);
        });
    }
  };
  const reAssignOnClick = (id, incorp, type, assign, code) => {
    setLoading(true);
    if (incorp == "post") {
      const transformedData = transformAndFilterData(
        searchIds,
        searchIds2,
        id,
        "re-assign"
      );
      const firstKey = Object.keys(transformedData)[0];
      const firstElement = transformedData[firstKey];
      console.log(firstElement, assign, "ytuil0");
      let obj = {
        assign: [...firstElement, ...assign],
      };
      console.log(obj, "ytuil1S");

      const payload = {
        method: "PUT",
        url: `/serviceRequest/update/${id}`,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          setLoading(false);
          if (res?.status == 200 || res?.status == 201) {
            sendNotify("success", res?.message);
          } else {
            sendNotify("error", res?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          sendNotify("error", error?.message);
        });
    } else {
      const transformedData = transformAndFilterData(
        searchIds,
        searchIds2,
        id,
        "re-assign"
      );
      const firstKey = Object.keys(transformedData)[0];
      const firstElement = transformedData[firstKey];

      let obj = {
        assign: [...firstElement, ...assign],
      };

      const payload = {
        method: "PUT",
        url:
          type == "Name Check"
            ? `incorporation/assignee/${id}`
            : `/relationshipManager/update/${id}`,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          setLoading(false);
          if (res?.status == 200 || res?.status == 201) {
            sendNotify("success", res?.message);
          } else {
            sendNotify("error", res?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          sendNotify("error", error?.message);
        });
    }
  };
  const assigneeListOnChange = (id, assignee) => {
    return (
      <div className="assignee-list">
        <div className="ms-auto">
          <div className="input-box w-100">
            <Select
              id="searchUser"
              className="custom-select"
              style={{ width: 300 }}
              placeholder="Select Search User"
              value={searchIds[id] || searchIds2[id] || null}
              onChange={(value) => searchChange(value, id, assignee)}
              options={userArray}
              showSearch={true}
              allowClear={true}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </div>
        </div>
      </div>
    );
  };
  const getPreLink = (type, requestType, _id, link) => {
    if (type === "Incorporation" || type === "Transfer-In") return link;
    if (requestType === "Name Check") {
      return `/app/new-incorporation-name-check/${_id}`;
    }
    return "/app/document-review";
  };
  const preColumns = [
    {
      title: "Request Ref",
      dataIndex: "refNumber",
      sorter: (a, b) => a.refNumber - b.refNumber,
      render: (_, { refNumber, code }) => (
        <span>
          {refNumber} {code}
        </span>
      ),
      filterType: "text",
      ...searchFilter({
        dataIndex: "refNumber",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Requestor Name",
      dataIndex: "requestedBy",
      filterType: "text",
      sorter: (a, b) => a.requestedBy - b.requestedBy,
      ...searchFilter({
        dataIndex: "requestedBy",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      filterType: "text",
      render: (_, { clientName, entityName }) => (
        <span>
          {clientName} {entityName}
        </span>
      ),
      sorter: (a, b) => a.clientName - b.clientName,
      ...searchFilter({
        dataIndex: "clientName",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.status - b.status,
      ...searchFilter({
        dataIndex: "status",
        inputType: "select",
        options: [
          {
            label: "Pending",
            value: "Pending",
          },
          {
            label: "Submitted",
            value: "Submitted",
          },
        ],
        queryParams,
        handleSearch,
        handleReset,
      }),
      render: (_, { status }) => (
        <TagZ
          status={status == "Submitted" ? "approved" : "in-progress"}
          statusText={status == "Submitted" ? "Submitted" : "Pending"}
        />
      ),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request type",
      dataIndex: "requestType",

      render: (_, { requestType, type }) => (
        <span>
          {requestType} {type}
        </span>
      ),
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.requestType - b.requestType,
      ...searchFilter({
        dataIndex: "requestType",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Resubmission Status",
      dataIndex: "resubmission",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.resubmission - b.resubmission,

      render: (_, { resubmission }) => (
        <TagZ
          status={resubmission == "Submitted" ? "resubmitted" : "in-progress"}
          statusText={resubmission == "Submitted" ? "Resubmitted" : "Pending"}
        />
      ),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Origin Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.createdAt - b.createdAt,
      ...searchFilter({
        dataIndex: "requestedBy",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      render: (_, { createdAt }) => {
        return dayjs(createdAt).format("DD/MM/YYYY - hh:mm A");
      },
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Assignee 1",
      dataIndex: "assignee1",
      sorter: (a, b) => a.assignee1 - b.assignee1,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (
        _,
        { _id, assign, assignee1type, type, requestType, serviceType }
      ) => {
        let finded = assign?.filter((a) => a?.status === "active");
        let selectedAssignee = finded?.find((a) => a?.type === "assignee_1");

        let userId = searchIds[_id] || selectedAssignee?.userId;
        if (userId) {
          const lastName = new Map(
            user?.data?.data.map((a) => [a._id, a.lastName])
          );
          const firstName = new Map(
            user?.data?.data.map((a) => [a._id, a.firstName])
          );

          let userFirstName = firstName.get(userId) || "";
          let userLastName = lastName.get(userId) || "";

          return (
            <div className="assignee">
              <span className="position-relative">
                <button
                  className="remove-btn"
                  onClick={() =>
                    confirmRemove(
                      _id,
                      "assignee1",
                      assign,
                      "pre",
                      type
                        ? type
                        : requestType
                        ? requestType
                        : serviceType
                        ? "servicereq"
                        : null
                    )
                  }
                >
                  <i className="fas fa-times-circle"></i>
                </button>

                <Popover
                  className="custom-popover"
                  placement="rightBottom"
                  content={
                    <p className="mb-0 text-capitalize">{assignee1type}</p>
                  }
                >
                  <h5 className="assign-profile">
                    {userFirstName[0]?.toUpperCase() || ""}
                    {userLastName[0]?.toUpperCase() || ""}
                  </h5>
                </Popover>
              </span>
              <p>{`${userFirstName} ${userLastName}`}</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={() => assigneeListOnChange(_id, "assignee1")}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },

    {
      title: "Assignee 2",
      dataIndex: "assignee2",
      sorter: (a, b) => a.assignee2 - b.assignee2,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (
        _,
        { _id, assign, assignee2type, type, requestType, serviceType }
      ) => {
        let finded = assign?.filter((a) => a?.status === "active");
        let selectedAssignee = finded?.find((a) => a?.type === "assignee_2");

        let userId = searchIds2[_id] || selectedAssignee?.userId;
        if (userId) {
          const lastName = new Map(
            user?.data?.data.map((a) => [a._id, a.lastName])
          );
          const firstName = new Map(
            user?.data?.data.map((a) => [a._id, a.firstName])
          );

          let userFirstName = firstName.get(userId) || "";
          let userLastName = lastName.get(userId) || "";

          return (
            <div className="assignee">
              <span className="position-relative">
                <button
                  className="remove-btn"
                  onClick={() =>
                    confirmRemove(
                      _id,
                      "assignee2",
                      assign,
                      "pre",
                      type
                        ? type
                        : requestType
                        ? requestType
                        : serviceType
                        ? "servicereq"
                        : null
                    )
                  }
                >
                  <i className="fas fa-times-circle"></i>
                </button>

                <Popover
                  className="custom-popover"
                  placement="rightBottom"
                  content={
                    <p className="mb-0 text-capitalize">{assignee2type}</p>
                  }
                >
                  <h5 className="assign-profile">
                    {userFirstName[0]?.toUpperCase() || ""}
                    {userLastName[0]?.toUpperCase() || ""}
                  </h5>
                </Popover>
              </span>
              <p>{`${userFirstName} ${userLastName}`}</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={() => assigneeListOnChange(_id, "assignee2")}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },

    {
      title: "Actions",
      render: (
        _,
        { _id, code, type, requestType, link, serviceType, assign }
      ) => (
        <div className="d-flex align-items-center gap-2">
          <button
            className="action-btn"
            onClick={() =>
              assignOnClick(
                _id,
                "pre",
                type
                  ? type
                  : requestType
                  ? requestType
                  : serviceType
                  ? "servicereq"
                  : null,
                code
              )
            }
            disabled={assign?.length ? true : false || !searchIds[_id]}
          >
            Assign
          </button>
          <button
            className="action-btn"
            onClick={() =>
              reAssignOnClick(
                _id,
                "pre",
                type
                  ? type
                  : requestType
                  ? requestType
                  : serviceType
                  ? "servicereq"
                  : null,
                assign,
                code
              )
            }
            disabled={assign?.length ? false : true}
          >
            Re-assign
          </button>
          <Link
            to={getPreLink(type, requestType, _id, link)}
            className="action-btn"
          >
            {requestType === "Name Check" ? "Name Check" : "View Request"}{" "}
            &nbsp;
            <i className="far fa-long-arrow-right me-0"></i>
          </Link>
          {/* <Link
            to={
              type == "Incorporation"
                ? link
                : requestType == "Name Check"
                ? `/app/new-incorporation-name-check/${_id}`
                : "/app/document-review"
            }
            className="action-btn"
          >
            {requestType == "Name Check" ? "Name Check" : "View Request"} &nbsp;{" "}
            <i className="far fa-long-arrow-right me-0"></i>
          </Link> */}
        </div>
      ),
    },
  ];
  const postColumns = [
    {
      title: "Request Ref",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.code - b.code,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { code, pageLink }) => (
        <Link className="table-link" to={"/app/" + pageLink}>
          {code}
        </Link>
      ),
    },
    {
      title: "Requestor Name",
      dataIndex: "requestorName",
      filterType: "text",
      sorter: (a, b) => a.requestorName - b.requestorName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Request type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType.localeCompare(b.serviceType),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { serviceType }) => {
        const options = [
          { value: "COI", label: "Certificate of Incumbency (COI)" },
          { value: "COGS", label: "Certificate of Good Standing (COGS)" },
          { value: "ROD", label: "Register of Directors" },
          { value: "SR", label: "Share Registry" },
          { value: "COD", label: "Change Of Director (COD)" },
          { value: "COSH", label: "Change of Shareholder (COSH)" },
          { value: "COA", label: "Change of Client / Administration (COA)" },
          { value: "COC", label: "Change of Contact Point (COC)" },
          { value: "TO", label: "Transfer Out (TO)" },
          { value: "CO", label: "Continuation Out (CO)" },
          {
            value: "AMAA",
            label: "Alteration to the Memorandum & Articles of Association",
          },
          { value: "MNC", label: "M&AA Name Change" },
          { value: "NOD", label: "Notarization of Documents" },
          { value: "AOD", label: "Apostille of Documents" },
          {
            value: "ARMA",
            label: "Amended and Restated Memorandum & Articles",
          },
          { value: "Apostilles", label: "Apostilles" },
          { value: "CGS", label: "Certificate of Good Standing" },
          { value: "RCAD", label: "Registry Certified Copy of Any Document" },
          {
            value: "RUD",
            label: "Registry Uncertified or Duplicate Copy of Any Document",
          },
          {
            value: "RPEC",
            label: "Registry Physical Copy of any Electronic Certificate",
          },
          { value: "CI", label: "Certificate of Incumbency" },
          {
            value: "CD",
            label: "Corporate Documents (Internal Certification)",
          },
          {
            value: "CCPD",
            label: "CCP Certified or Duplicate copies of documents",
          },
          { value: "ARD", label: "Amendments to Register of Directors" },
          {
            value: "ARM",
            label:
              "Amendments to Register of Members & Issuing New Share Certificate(s)",
          },
          { value: "Continuations", label: "Continuations" },
          { value: "DR", label: "Drafting Resolutions" },
          { value: "FRMC", label: "Filing Register of Mortgage & Charges" },
          { value: "FAR", label: "Filing Appointment of RO/RA" },
          { value: "FTO", label: "Filing Transfer Out" },
          { value: "IA", label: "Indemnity Agreements" },
          { value: "Notarizations", label: "Notarizations" },
          { value: "PA", label: "Powers of Attorney" },
          { value: "RSC", label: "Reactivation of struckoff companies" },
          {
            value: "RNR",
            label: "Registration of Notice of Resignation as Registered Agent",
          },
          {
            value: "FRRA",
            label: "Filing notice of Recission as Registered Agent",
          },
          {
            value: "RFC",
            label: "Registration as a Foreign Company in the BVI",
          },
          {
            value: "FNFC",
            label:
              "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
          },
          {
            value: "EFF",
            label:
              "Expedite Fee for Fast Track Due Diligence and Incorporation",
          },
          { value: "SL", label: "Solvent Liquidation" },
          { value: "other", label: "Other" },
        ];

        const selectedOption = options.find(
          (option) => option.value === serviceType
        );

        return (
          <Popover content={selectedOption?.label || "Unknown"} trigger="hover">
            <div
              style={{ cursor: "pointer", width: "160px", textAlign: "left" }}
            >
              {serviceType}
            </div>
          </Popover>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status, rejectReason }) => {
        let statusIcon;
        switch (status) {
          case "Pending":
            statusIcon = "in-progress";
            break;
          case "In progress":
            statusIcon = "in-progress";
            break;
          case "Reject":
            statusIcon = "rejected";
            break;
          case "Completed":
            statusIcon = "approved";
            break;
        }
        if (status == "Reject") {
          return (
            <Popover
              content={
                <div className="popover-content-max">{rejectReason}</div>
              }
              trigger="hover"
            >
              <div>
                <TagZ status={statusIcon} statusText={status} />
              </div>
            </Popover>
          );
        } else {
          return <TagZ status={statusIcon} statusText={status} />;
        }
      },
    },
    // {
    //   title: "Pending received",
    //   dataIndex: "received",
    //   sorter: (a, b) => a.received - b.received,
    // },
    {
      title: "Resubmission Status",
      dataIndex: "resubmission",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.resubmission - b.resubmission,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { resubmission }) => (
        <TagZ
          status={resubmission == "Y" ? "resubmitted" : "in-progress"}
          statusText={resubmission == "Y" ? "Resubmitted" : "Pending"}
        />
      ),
    },
    {
      title: "Origin Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      filterType: "text",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Entity Code",
    //   dataIndex: "entityCode",
    //   sorter: (a, b) => a.entityCode - b.entityCode,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      filterType: "text",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      filterType: "text",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved Date & Time",
      dataIndex: "approvedDateTime",
      sorter: (a, b) => a.approvedDateTime - b.approvedDateTime,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 220,
    },
    {
      title: "Approved Status",
      dataIndex: "approvedStatus",
      filterType: "select",
      filterOptions: [
        {
          value: "approved",
          label: "Approved",
        },
        {
          value: "in-progress",
          label: "In Progress",
        },
      ],
      sorter: (a, b) => a.approvedStatus - b.approvedStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { approvedStatus }) => (
        <TagZ
          status={approvedStatus == "Y" ? "resubmitted" : "in-progress"}
          statusText={approvedStatus == "Y" ? "Resubmitted" : "Pending"}
        />
      ),
    },
    {
      title: "Update By",
      dataIndex: "updateBy",
      filterType: "text",
      sorter: (a, b) => a.updateBy - b.updateBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Verified",
      dataIndex: "verified",
      sorter: (a, b) => a.verified - b.verified,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { verified }) => (
        <div className="d-flex gap-2">
          <Switch
            checked={verified}
            className="custom-switch"
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </div>
      ),
    },
    {
      title: "Assignee 1",
      dataIndex: "assignee1",
      sorter: (a, b) => a.assignee1 - b.assignee1,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, assign, assignee1type }) => {
        let finded = assign?.filter((a) => a?.status === "active");
        let selectedAssignee = finded?.find((a) => a?.type === "assignee_1");

        let userId = searchIds[_id] || selectedAssignee?.userId;
        if (userId) {
          const lastName = new Map(
            user?.data?.data.map((a) => [a._id, a.lastName])
          );
          const firstName = new Map(
            user?.data?.data.map((a) => [a._id, a.firstName])
          );

          let userFirstName = firstName.get(userId) || "";
          let userLastName = lastName.get(userId) || "";

          return (
            <div className="assignee">
              <span className="position-relative">
                <button
                  className="remove-btn"
                  onClick={() =>
                    confirmRemove(_id, "assignee1", assign, "post")
                  }
                >
                  <i className="fas fa-times-circle"></i>
                </button>

                <Popover
                  className="custom-popover"
                  placement="rightBottom"
                  content={
                    <p className="mb-0 text-capitalize">{assignee1type}</p>
                  }
                >
                  <h5 className="assign-profile">
                    {userFirstName[0]?.toUpperCase() || ""}
                    {userLastName[0]?.toUpperCase() || ""}
                  </h5>
                </Popover>
              </span>
              <p>{`${userFirstName} ${userLastName}`}</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={() => assigneeListOnChange(_id, "assignee1")}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },

    {
      title: "Assignee 2",
      dataIndex: "assignee2",
      sorter: (a, b) => a.assignee2 - b.assignee2,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, assign, assignee2type }) => {
        let finded = assign?.filter((a) => a?.status === "active");
        let selectedAssignee = finded?.find((a) => a?.type === "assignee_2");

        let userId = searchIds2[_id] || selectedAssignee?.userId;
        if (userId) {
          const lastName = new Map(
            user?.data?.data.map((a) => [a._id, a.lastName])
          );
          const firstName = new Map(
            user?.data?.data.map((a) => [a._id, a.firstName])
          );

          let userFirstName = firstName.get(userId) || "";
          let userLastName = lastName.get(userId) || "";

          return (
            <div className="assignee">
              <span className="position-relative">
                <button
                  className="remove-btn"
                  onClick={() =>
                    confirmRemove(_id, "assignee2", assign, "post")
                  }
                >
                  <i className="fas fa-times-circle"></i>
                </button>

                <Popover
                  className="custom-popover"
                  placement="rightBottom"
                  content={
                    <p className="mb-0 text-capitalize">{assignee2type}</p>
                  }
                >
                  <h5 className="assign-profile">
                    {userFirstName[0]?.toUpperCase() || ""}
                    {userLastName[0]?.toUpperCase() || ""}
                  </h5>
                </Popover>
              </span>
              <p>{`${userFirstName} ${userLastName}`}</p>
            </div>
          );
        } else {
          return (
            <Popover
              content={() => assigneeListOnChange(_id, "assignee2")}
              trigger="click"
              className="custom-popover"
            >
              <div className="assignee">
                <i className="fal fa-plus-circle"></i> <p>Add Assignee</p>
              </div>
            </Popover>
          );
        }
      },
    },

    {
      title: "Actions",
      render: (
        _,
        { _id, code, type, requestType, link, serviceType, assign }
      ) => (
        <div className="d-flex align-items-center gap-2">
          <button
            className="action-btn"
            onClick={() =>
              assignOnClick(
                _id,
                "post",
                type
                  ? type
                  : requestType
                  ? requestType
                  : serviceType
                  ? "servicereq"
                  : null,
                code
              )
            }
            disabled={assign?.length ? true : false || !searchIds[_id]}
          >
            Assign
          </button>
          <button
            className="action-btn"
            onClick={() =>
              reAssignOnClick(
                _id,
                "post",
                type
                  ? type
                  : requestType
                  ? requestType
                  : serviceType
                  ? "servicereq"
                  : null,
                assign,
                code
              )
            }
            disabled={assign?.length ? false : true}
          >
            Re-assign
          </button>
          {link ? (
            <Link
              // to={`/app/document-review/${_id}?type=${type}`}
              to={`/${link}&taskId=${code}`}
              className="action-btn"
            >
              View Request &nbsp;{" "}
              <i className="far fa-long-arrow-right me-0"></i>
            </Link>
          ) : (
            <span>
              {" "}
              View Request &nbsp;{" "}
              <i className="far fa-long-arrow-right me-0"></i>
            </span>
          )}
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const getPrePagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    dispatch(getPreIncorpRequests(params));
    setQueryParams(params);
  };
  const getPostPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    dispatch(getPostIncorpRequests(params));
    setQueryParams(params);
  };
  const labels = [
    {
      key: 1,
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> Completed
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <img src={InProgressIcon} alt="" /> In Progress
        </>
      ),
    },
  ];
  const getFilter = (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getPreIncorpRequests(params));
    setQueryParams(params);
  };
  const getPostFilter = (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getPostIncorpRequests(params));
    setQueryParams(params);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Active Requests</h1>
        <Link to={"/app/document-review"} className="primary-btn">
          Submit
        </Link>
      </div>
      {console.log(searchIds)}

      <div className="page-content">
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
          <h4 className="primary-title text-capitalize mb-3 mb-md-0">
            Pre-Incorp Requests ({showCount(preIncorp?.data?.totalCount)})
          </h4>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <button className="outline-btn">
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn">
              <i className="far fa-check-double"></i> Assign Selected
            </button>
            {/* <TabZ
            labels={labels}
            defaultActive={"2"}
            customClass={"table-tabs"}
          /> */}
            <TableFilterZ tableCol={preColumns} onChange={getFilter} />
          </div>
        </div>
        <Table
          rowKey={"_id"}
          className="custom-table mb-4"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={preColumns}
          dataSource={preData}
          scroll={{ x: 2000 }}
          loading={preIncorp?.isLoading || loading}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: preIncorp?.data?.totalCount,
            onChange: getPrePagination,
            itemRender: customPagination,
          }}
        />

        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
          <h4 className="primary-title text-capitalize mb-3 mb-md-0">
            Post-Incorp Requests ({showCount(postIncorp?.data?.totalCount)})
          </h4>
          <div className="d-flex flex-column flex-md-row align-items-center gap-3 justify-content-end">
            <button className="outline-btn">
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn">
              <i className="far fa-check-double"></i> Assign Selected
            </button>
            <TableFilterZ tableCol={postColumns} onChange={getPostFilter} />
          </div>
        </div>
        <Table
          rowKey={"_id"}
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={postColumns}
          dataSource={postIncorp?.data?.rows}
          scroll={{ x: 3000 }}
          loading={postIncorp?.isLoading || loading}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: postIncorp?.data?.totalCount,
            onChange: getPostPagination,
            itemRender: customPagination,
          }}
        />
      </div>
      <Modal
        title={viewDocName}
        className="custom-modal-2"
        open={docModal}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="pdf-zoom-wrapper">
          <TransformWrapper initialScale={1} centerOnInit>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div>
                <Controls />
                <TransformComponent wrapperClass="w-100 pdf-transform">
                  <div className="custom-pdf-wrapper">
                    <div className="document-view">
                      <iframe
                        src={SampleDoc}
                        frameBorder="0"
                        height="100%"
                        width="100%"
                      ></iframe>
                    </div>
                  </div>
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </Modal>
    </div>
  );
}
