import React, { useState, useEffect, useRef } from "react";


import { Table, Tag, Select, Modal, DatePicker, Checkbox } from "antd";
import dayjs from "dayjs";
import FormZ from "../../components/FormZ";
import { Each, getOptions, downloadAsCSV, fetchApi, isArrayEmpty } from "../../helper";
import { Link } from "react-router-dom";

export default function FeeSchedule() {
  const [editMode, setEditMode] = useState(false);
  const [clientArray, setClientArray] = useState([]);
  const searchFormRef = useRef();

  useEffect(() => {
    getClient()
  }, []);
  const getClient = async () => {
    let payload = {
      method: "GET",
      url: `/client?_start=0&_limit=1000&types=endUser%2CIntermediary`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows
    });
    setClientArray(data)
  }
  console.log(clientArray, "sdfGDza")
  let searchFormSchema = [
    {
      name: "endUserClient",
      value: "",
      type: "select",
      label: "End User Client/Intermediary Client",
      placeholder: "Select end user client",
      options: getOptions(clientArray),
      showSearch: true
    },
    {
      name: "entityName",
      value: "",
      type: "text",
      label: "Client/Entity Name",
      placeholder: "Select entity name",
    },
    {
      name: "entityCode",
      value: "",
      type: "text",
      label: "Client/Entity Code",
      placeholder: "Select entity code",
      //   options: [
      //     {
      //       value: "00444",
      //       label: "00444",
      //     },
      //     {
      //       value: "00437",
      //       label: "00437",
      //     },
      //   ],
    },
    {
      label: "",
      customElement: (
        <button className="primary-btn mb-15">
          <i className="fal fa-search"></i> Initiate Search
        </button>
      ),
    },
  ];

  const [shares, setShares] = useState([
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
  ]);

  const getShareChange = (index, key, value) => {
    // console.log(key);
    let arr = [...shares];
    arr[index][key] = value;
    setShares(arr);
  };

  const columns = [
    {
      title: "Request Type",
      dataIndex: "reqType",
      sorter: (a, b) => a.reqType - b.reqType,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
    },
    {
      title: "Service Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
    },
    {
      title: "Standard Fee",
      children: [
        {
          title: <div className="text-center">Up to 50,000 Shares</div>,
          dataIndex: "standardUpTo",
          key: "standardUpTo",
          sorter: (a, b) => a.standardUpTo - b.standardUpTo,
          render: (_, { id, standardUpTo }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].standardUpTo}
                onChange={(e) =>
                  getShareChange(id, "standardUpTo", e.target.value)
                }
              />
            ) : (
              `$ ${standardUpTo}`
            ),
        },
        {
          title: <div className="text-center">Above 50,000 Shares</div>,
          dataIndex: "standardAbove",
          key: "standardAbove",
          sorter: (a, b) => a.standardAbove - b.standardAbove,
          render: (_, { id, standardAbove }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].standardAbove}
                onChange={(e) =>
                  getShareChange(id, "standardAbove", e.target.value)
                }
              />
            ) : (
              `$ ${standardAbove}`
            ),
        },
      ],
    },
    {
      title: "Discounted Fee",
      children: [
        {
          title: <div className="text-center">Up to 50,000 Shares</div>,
          dataIndex: "discountedUpTo",
          key: "discountedUpTo",
          sorter: (a, b) => a.discountedUpTo - b.discountedUpTo,
          render: (_, { id, discountedUpTo }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].discountedUpTo}
                onChange={(e) =>
                  getShareChange(id, "discountedUpTo", e.target.value)
                }
              />
            ) : (
              `$ ${discountedUpTo}`
            ),
        },
        {
          title: <div className="text-center">Above 50,000 Shares</div>,
          dataIndex: "discountedAbove",
          key: "discountedAbove",
          sorter: (a, b) => a.discountedAbove - b.discountedAbove,
          render: (_, { id, discountedAbove }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].discountedAbove}
                onChange={(e) =>
                  getShareChange(id, "discountedAbove", e.target.value)
                }
              />
            ) : (
              `$ ${discountedAbove}`
            ),
        },
      ],
    },
  ];

  const data = [
    {
      key: "1",
      id: 0,
      reqType: "Pre-incrop",
      task: "Change Of Director",
      serviceCode: "0028",
      ...shares[0],
    },
    {
      key: "2",
      id: 1,
      reqType: "Post-incrop",
      task: "Name Check",
      serviceCode: "0028",
      ...shares[1],
    },
    {
      key: "3",
      id: 2,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[2],
    },
    {
      key: "4",
      id: 3,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[3],
    },
    {
      key: "5",
      id: 4,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[4],
    },
    {
      key: "6",
      id: 5,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[5],
    },
    {
      key: "7",
      id: 6,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[6],
    },
  ];

  return (

    
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-0">Fee Schedule</h1>
        <Link to={"/app/fee-schedule-create"} className="primary-btn">
          <i className="fal fa-plus"></i> Create New
        </Link>
      </div>
      <FormZ
        formClass="row custom-form mb-2 mb-md-5 align-items-end gy-3"
        childClass="col-md-2 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
        formSchema={searchFormSchema}
        ref={searchFormRef}
      />
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="primary-title mb-0">
          Intermediary Client/End User Client
        </h1>
        <div className="d-flex gap-4">
          <h6>Fee Category</h6>
          <h6>Post Paid/Pre Paid</h6>
        </div>
      </div>
      <div className="table-box custom-form">
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          scroll={{
            y: 240,
          }}
          pagination={false}
        />
        <div className="d-flex justify-content-between align-items-center mt-md-4">
          <p>
            Last Changed By: <span>Guru</span>
          </p>
          <p>
            Last Changed Date: <span>09-03-2024</span>
          </p>
          <p>
            Initial Onboarding Date: <span>12-03-2024</span>
          </p>
          <button
            className={editMode ? "primary-btn" : "outline-btn"}
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? "Update" : "Modify"}
          </button>
        </div>
      </div>
    </div>
  );
}
