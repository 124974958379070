import React, { useState } from "react";
import { Table, Select, Modal } from "antd";

import SampleDoc from "../../../assets/pdf/sample.pdf";

export default function InhouseOcr() {
  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");

  const columns = [
    {
      title: "Document Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      render: (_, data) => (
        <div className="table-upload">
          <label htmlFor="doc" className="outline-btn py-2">
            Upload
          </label>
          <input type="file" name="doc" id="doc" />
        </div>
      ),
    },
    {
      title: "Document Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      render: (_, data) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          style={{
            width: 160,
          }}
          options={[
            {
              value: "trustee",
              label: "Trustee",
            },
            {
              value: "beneficiary",
              label: "Beneficiary",
            },
            {
              value: "settlor",
              label: "Settlor",
            },
            {
              value: "protector",
              label: "Protector",
            },
            {
              value: "power-of-attorney",
              label: "Power of attorney",
            },
            {
              value: "point-of-contact",
              label: "Point of contact",
            },
            {
              value: "authorized-signatory",
              label: "Authorized signatory",
            },
            {
              value: "authorized-agent",
              label: "Authorized agent",
            },
          ]}
        />
      ),
    },
    // {
    //     title: 'Entity Name',
    //     dataIndex: 'entityName',
    //     sorter: (a, b) => a.entityName - b.entityName,
    // },
    // {
    //     title: 'Entity Code',
    //     dataIndex: 'entityCode',
    //     sorter: (a, b) => a.entityCode - b.entityCode,
    // },
    {
      title: "Assignee",
      dataIndex: "assignee",
      sorter: (a, b) => a.assignee - b.assignee,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 100 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "user1",
              label: "User 1",
            },
            {
              value: "user2",
              label: "User 2",
            },
            {
              value: "user3",
              label: "User 3",
            },
          ]}
        />
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      sorter: (a, b) => a.dateTime - b.dateTime,
    },
    {
      title: "Actions",
      render: (_, { name }) => (
        <div className="d-flex gap-2 justify-content-end align-items-center">
          <button className="action-btn">Extract</button>
          <button className="action-btn">Store</button>
          <button
            className="transparent-btn"
            title="Preview"
            onClick={() => viewDocument(name || "Document")}
          >
            <i className="far fa-eye"></i>
          </button>
          <button className="transparent-btn" title="Export">
            <i className="far fa-file-upload"></i>
          </button>
          <button className="transparent-btn red-btn" title="delete">
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      name: "",
      type: "",
      entityName: "",
      entityCode: "",
      dateTime: "12/04/2023 - 05:30PM",
    },
    {
      key: "2",
      id: "2",
      name: "",
      type: "",
      entityName: "",
      entityCode: "",
      dateTime: "12/04/2023 - 04:00PM",
    },
    {
      key: "3",
      id: "3",
      name: "",
      type: "",
      entityName: "",
      entityCode: "",
      dateTime: "12/04/2023 - 08:30PM",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const viewDocument = (docName) => {
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  return (
    <div>
      <button className="primary-btn ms-auto">Bulk Export</button>
      <Table
        className="custom-table"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1000 }}
      />
      <Modal
        title={viewDocName}
        className="custom-modal"
        open={docModal}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="document-view">
          <iframe
            src={SampleDoc}
            frameBorder="0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}
