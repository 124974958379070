import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, Spin, Popover } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import html2pdf from "html2pdf.js";
import COI_PDF from "../assets/pdf/certificate-of-incumbency.pdf";
import SignatureItem from "../components/SignatureItem";
import { Link } from "react-router-dom";

import { sendNotify, getQuery, fetchApi } from "../helper";
import { createEmailTemplateParams } from "../helper/emailTemplateHelpers";
import AddSignature from "../components/AddSignature";
import { useSelector } from "react-redux";
import {
  generateClientNameDataMap,
  generateAddressMap,
  filterAndSortData,
  mapDirectorDetails,
  mapShareholderDetails,
  extractShareTypeAndInvId,
} from "../helper/coi_helper";

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const Controls = (props) => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls();

  const downloadPDF = () => {
    if (props.signature.length > 0) {
      props.generatePdf();
    } else {
      sendNotify(
        "error",
        "Signature missing. Please provide a valid signature to continue."
      );
    }
    //downloadFile(COI_PDF, "certificate-of-incumbency");
  };

  return (
    <div className="control-tools d-flex gap-3">
      <button onClick={() => zoomIn()}>
        <i className="far fa-search-plus"></i>
      </button>
      <button onClick={() => zoomOut()}>
        <i className="far fa-search-minus"></i>
      </button>
      <button onClick={downloadPDF}>
        <i className="far fa-arrow-to-bottom"></i>
      </button>
      {/* <button onClick={() => resetTransform()}>
        <i className="fal fa-times-circle"></i>
      </button>
      <button onClick={() => centerView()}>
        <i className="fal fa-arrows"></i>
      </button> */}
    </div>
  );
};

export default function CertificateOfIncumbency() {
  let query = getQuery();

  const [serviceData, setServiceData] = useState(null);
  const [signModal, setSignModal] = useState(false);
  const [value, setvalue] = useState(false);
  const [copEntityCategories, setCopEntityCategories] = useState({});
  const [individualCategories, setIndividualCategories] = useState({});
  const [shareholderData, setShareholderData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [uboData, setUboData] = useState([]);
  const [data, setData] = useState(null);

  const pdfRef = useRef();

  const [directorDetailsForEmailTemplate, setDirectorDetailsForEmailTemplate] =
    useState({});
  const [
    shareholderDetailsForEmailTemplate,
    setShareholderDetailsForEmailTemplate,
  ] = useState({});
  const [
    copEntityDetailsForEmailTemplate,
    setCopEntityDetailsForEmailTemplate,
  ] = useState({});
  const [
    individualDetailsForEmailTemplate,
    setIndividualDetailsForEmailTemplate,
  ] = useState({});
  const [registerOfCharges, setRegisterOfCharges] = useState({});
  const [emailCategory, setEmailCategory] = useState("");
  const [emailParams, setEmailParams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signatures, setSignatures] = useState([]);
  const [recieverMail, setRecieverMail] = useState([]);
  const [entityObj, setEntityObj] = useState([]);

  const location = useLocation();
  let entity = location.state?.entity;

  const { userDetails } = useSelector((state) => state.auth);

  const registeredAgentName = userDetails.otherDetails.companyName;
  const registeredAgentEmail = userDetails.parentEmail;
  const registeredAgentAddress = userDetails.otherDetails.address;
  let withRegisterOfCharges = query.withCharges === "true";
  let selectedEmailCategory = query.category;

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  useEffect(() => {
    if (signatures.length > 0) {
      collectSignaturesData();
    }
  }, [signatures]);

  useEffect(() => {
    if (recieverMail.length > 0) {
      handleSendEmail();
    }
  }, [recieverMail]);

  const handleRefresh = async () => {
    if (!query) return;
    setLoading(true);
    const fetchData = async (url) => {
      let payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows || []);
    };

    const serviceData = query?.id
      ? await fetchData(`/serviceRequest?id=${query.id}`)
      : [];

    const entityId = query?.id
      ? serviceData[0]?.entityId
      : query?.entityId
      ? query?.entityId
      : query?.id;
    let data = serviceData[0];
    setData(data);
    try {
      setLoading(true);

      setEmailCategory(selectedEmailCategory);
      let registerOfChargesDetails;
      if (withRegisterOfCharges) {
        registerOfChargesDetails = await fetchData(
          `/registerOfCharges?entityId=${entityId}&all=true`
        );

        setRegisterOfCharges(registerOfChargesDetails);
      }

      const [
        clientData,
        individualDetails,
        relatedEntityDetails,
        copEntityDetails,
        historyData,
        entityData,
      ] = await Promise.all([
        fetchData(`/client?isAll=true&exportAll=true`),
        fetchData(`/individualDetails?enId=${entityId}&all=true`),
        fetchData(`/relatedEntityDetails?enId=${entityId}&all=true`),
        fetchData(`/copEntityDetails?enId=${entityId}&all=true`),
        fetchData(`/transaction?enId=${entityId}`),
        fetchData(`/entity?masterType=entity&id=${entityId}`),
      ]);
      setEntityObj(entityData[0]);
      const allData = [
        ...individualDetails,
        ...relatedEntityDetails,
        ...copEntityDetails,
      ];

      const filteredData = filterAndSortData(allData);

      const shareholderTypeandInv = extractShareTypeAndInvId(allData);

      const clientDataMap = generateClientNameDataMap(clientData);
      const addressMap = generateAddressMap(clientData);

      const directorDetails = mapDirectorDetails(
        filteredData,
        clientDataMap,
        addressMap
      );

      const clientIdToCodeMap = new Map(
        clientData.map((item) => [item._id, item.code])
      );

      const clientNameDataMap = new Map(
        clientData.map((item) => [item._id, item.name])
      );

      const shareholderDetails = mapShareholderDetails(
        historyData,
        clientIdToCodeMap,
        clientNameDataMap,
        shareholderTypeandInv
      );

      setDirectorDetailsForEmailTemplate(
        directorDetails.map((director) => ({
          name: director.name,
          dateOfAppointment: director.dateOfAppointment,
        }))
      );
      setShareholderDetailsForEmailTemplate(
        shareholderDetails.map((shareholder) => ({
          senderMasterName: shareholder.senderMasterName,
          noShares: shareholder.noShares,
        }))
      );

      setCopEntityDetailsForEmailTemplate(copEntityDetails);
      setIndividualDetailsForEmailTemplate(individualDetails);

      const processDocuments = (docs) => {
        const categories = {
          directors: [],
          shareholders: [],
          ubo: [],
        };

        docs.forEach((doc) => {
          if (doc?.checkedList?.directors || doc?.checkedList?.reserve)
            categories.directors.push(doc);
          if (doc?.checkedList?.nominee || doc?.checkedList?.shareholders)
            categories.shareholders.push(doc);
          if (doc?.checkedList?.ubo) categories.ubo.push(doc);
        });

        return categories;
      };

      const processCategory = async (categoryDocs, entityType) => {
        const allData = {
          allDirectors: [],
          allShareholders: [],
          allUbo: [],
        };

        const mapToObj = (oneDoc, index, type) => ({
          key: index,
          id: oneDoc._id,
          type,
          name:
            type === "individual"
              ? `${oneDoc?.individualArray?.firstName} ${oneDoc?.individualArray?.middleName} ${oneDoc?.individualArray?.lastName}`
              : oneDoc.copEntityName,
          total: oneDoc.totalShares ? oneDoc.noOfShares : "",
          percentage: oneDoc.ownershipPer
            ? `${parseFloat(oneDoc.ownershipPer).toFixed(2)}%`
            : null,
          date: oneDoc.appointment
            ? moment(oneDoc.appointment).format("DD-MMM-YYYY")
            : null,
        });

        const { directors, shareholders, ubo } = categoryDocs;

        await asyncForEach(directors, async (oneDoc, index) => {
          allData.allDirectors.push(mapToObj(oneDoc, index, entityType));
        });

        await asyncForEach(shareholders, async (oneDoc, index) => {
          allData.allShareholders.push(mapToObj(oneDoc, index, entityType));
        });

        await asyncForEach(ubo, async (oneDoc, index) => {
          allData.allUbo.push(mapToObj(oneDoc, index, entityType));
        });

        return allData;
      };

      const individual = processDocuments(individualDetails);
      const copEntity = processDocuments(copEntityDetails);

      const individualData = await processCategory(individual, "individual");
      const copEntityData = await processCategory(copEntity, "copEntity");
      const { allDirectors, allShareholders, allUbo } = {
        allDirectors: [
          ...individualData.allDirectors,
          ...copEntityData.allDirectors,
        ],
        allShareholders: [
          ...individualData.allShareholders,
          ...copEntityData.allShareholders,
        ],
        allUbo: [...individualData.allUbo, ...copEntityData.allUbo],
      };

      setServiceData(serviceData[0]);
      setIndividualCategories(individual);
      setCopEntityCategories(copEntity);
      setDirectorData(allDirectors);
      setShareholderData(allShareholders);
      setUboData(allUbo);

      const params = createEmailTemplateParams(
        registeredAgentName,
        registeredAgentAddress,
        entity,
        individualDetails,
        copEntityDetails,
        emailCategory,
        registerOfChargesDetails,
        signatures,
        recieverMail,
        directorDetails,
        shareholderDetails
      );
      setEmailParams(params);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const directorCol = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Appointment Date",
      dataIndex: "date",
    },
  ];

  // const directorData = [
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mr. John Doe",
  //     date: "28 May 2024",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mr. John Doe",
  //     date: "28 May 2024",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mr. John Doe",
  //     date: "28 May 2024",
  //   },
  // ];

  const shareholderCol = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Total Shares Held",
      dataIndex: "total",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
    },
  ];

  // const shareholderData = [
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mrs. Sophia",
  //     total: "25,000",
  //     percentage: "50%",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mrs. Sophia",
  //     total: "15,000",
  //     percentage: "30%",
  //   },
  //   {
  //     key: "1",
  //     id: "1",
  //     name: "Mrs. Sophia",
  //     total: "10,000",
  //     percentage: "20%",
  //   },
  // ];
  // const downloadPDF = async () => {
  //   const data = pdfRef.current;
  //   const specificDate = moment(Date.now()).format("YYYY-MM-DD:HH:mm:ss");
  //   const options = {
  //     margin: 0.5,
  //     filename: `CorporateEntityAnalysisReport-${specificDate}.pdf`,
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { dpi: 192, letterRendering: true },
  //     jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  //   };

  //   try {
  //     let doc = {};
  //     const pdfBlob = await html2pdf()
  //       .from(data)
  //       .set(options)
  //       // .outputPdf("blob");
  //       downloadFile(pdfBlob)
  //

  //   } catch (error) {

  //
  //   }
  // }

  const generatePdf = () => {
    const element = pdfRef.current.cloneNode(true);
    const unwantedElements = element.querySelectorAll(".new-sign");
    unwantedElements.forEach((el) => el.remove());
    const deleteButtons = element.querySelectorAll(".sign-delete-btn");
    deleteButtons.forEach((btn) => (btn.style.display = "none"));

    const opt = {
      margin: 0.5,
      filename: "certificate-of-incumbency.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        deleteButtons.forEach((btn) => (btn.style.display = "block"));
      });

    return opt;
  };

  const upDateSignature = () => {
    Modal.confirm({
      title: "Confirmation Required",
      content: "Please confirm if you would like to update your signature.",
      okText: "Yes",
      onOk: () => cleardata(),
      cancelText: "No",
    });
  };

  const cleardata = () => {
    setvalue(false);
  };

  const handleSendEmail = async () => {
    setLoading(true);
    const registeredAgentName = userDetails.otherDetails.companyName;
    const registeredAgentAddress = userDetails.otherDetails.address;

    const params = createEmailTemplateParams(
      registeredAgentName,
      registeredAgentAddress,
      entity,
      individualDetailsForEmailTemplate,
      copEntityDetailsForEmailTemplate,
      emailCategory,
      registerOfCharges,
      signatures,
      recieverMail,
      directorDetailsForEmailTemplate,
      shareholderDetailsForEmailTemplate
    );
    setEmailParams(params);

    const payload = {
      method: "POST",
      url: `/general-email/send-email`,
      data: params,
    };
    const res = await fetchApi(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          sendNotify("success", res?.message);
        } else {
          setLoading(false);
          sendNotify("error", res?.error?.response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        sendNotify("error", error?.message);
      });
    return res;
  };

  const fetchAnalystEmails = async () => {
    setLoading(true);
    try {
      const payload = { method: "GET", url: "/auth?_start=0&_limit=50" };
      const response = await fetchApi(payload);

      const analysts = response?.data?.data || [];
      const analystEmails = analysts
        .filter((analyst) => analyst.role === "analyst")
        .map((analyst) => analyst.mail);
      setLoading(false);
      return analystEmails;
    } catch (error) {
      console.error("Error fetching analysts:", error);
      return [];
    }
  };

  const renderSubOptions = () => (
    <div
      className="certificate-links"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <button
        className="primary-btn"
        onClick={async () => {
          const emails = [entity?.primaryContact[0]?.data?.email] || [""];
          setRecieverMail(emails);
        }}
      >
        Send to Client
      </button>
      <button
        className="primary-btn"
        onClick={async () => {
          const emails = await fetchAnalystEmails();
          setRecieverMail(emails);
        }}
      >
        Send to Analayst
      </button>
    </div>
  );

  const addSignature = (newSignature) => {
    if (signatures.length < 4) {
      setSignatures([...signatures, newSignature]);
    }
    setSignModal(false);
  };

  const deleteSignature = (index) => {
    const updatedSignatures = signatures.filter((_, i) => i !== index);
    setSignatures(updatedSignatures);
  };

  const collectSignaturesData = () => {
    const signatureData = signatures.map((signature) => ({
      url: signature.url,
      fullName: signature.fullName,
      jobTitle: signature.jobTitle,
      registeredAgent: emailParams?.registeredAgentName,
    }));

    setEmailParams((prevParams) => ({
      ...prevParams,
      authorizedSignatory: signatureData,
    }));
  };
  return (
    <div>
      <div className="page-header">
        <h1>Certificate of Incumbency</h1>
        <div className="d-flex gap-3">
          <Link
            to={`/app/entity-overview/list?id=${entityObj?._id}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          <button
            className="primary-btn"
            onClick={async () => {
              const emails = [registeredAgentEmail];
              setEmailParams((prevParams) => ({
                ...prevParams,
                recieverEmail: emails,
              }));
              setRecieverMail(emails);
            }}
          >
            Send to RA
          </button>
          <button
            className="primary-btn"
            disabled={!(signatures.length > 0)}
            onClick={async () => {
              const emails = [entity?.primaryContact[0]?.data?.email ?? ""] || [
                "",
              ];
              setEmailParams((prevParams) => ({
                ...prevParams,
                recieverEmail: emails,
              }));
              setRecieverMail(emails);
            }}
          >
            Send to Client
          </button>
        </div>
        {/* <Popover
          destroyOnClose={false}
          className="custom-popover"
          placement="top"
          content={signatures.length > 0 ? renderSubOptions() : null}
        >
          <button
            className="primary-btn"
            aria-label="Generate Certificate of Incumbency"
            disabled={!(signatures.length > 0)}
          >
            Execute and Send to client
          </button>
        </Popover> */}
      </div>
      <Spin spinning={loading}>
        {emailParams ? (
          <div className="pdf-zoom-wrapper mt-4">
            <TransformWrapper
              initialScale={1}
              centerOnInit
              panning={{ excluded: ["input"] }}
              doubleClick={{ excluded: ["input"] }}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <div>
                  <Controls generatePdf={generatePdf} signature={signatures} />
                  <TransformComponent wrapperClass="w-100 pdf-transform">
                    <div className="custom-pdf-wrapper" ref={pdfRef}>
                      <h1>CERTIFICATE OF INCUMBENCY</h1>
                      <p>
                        WE, <b>{emailParams.registeredAgentName}</b>, REGISTERED
                        AGENT of &nbsp;
                        <b>{emailParams.companyName}</b>&nbsp;("the company"), a
                        company existing under the laws of the British Virgin
                        Islands, hereby
                      </p>
                      <hr />
                      <h5>CERTIFY:</h5>
                      <ol>
                        <li>
                          {emailCategory === "re-registered" ? (
                            <>
                              That the Company was duly incorporated on the
                              &nbsp;
                              <b>{emailParams.incorporationDate}</b>
                              &nbsp;{" "}
                              <b>
                                day of {emailParams.incorporationMonth}, &nbsp;
                                {emailParams.incorporationYear}
                              </b>
                              &nbsp; under{" "}
                              <b>The Companies Act (Cap 243) with CAP No. .</b>
                              <span style={{ color: "red" }}>
                                {" "}
                                Effective {emailParams.effectiveDate}{" "}
                                {emailParams.effectiveMonth}{" "}
                                {emailParams.effectiveYear}
                              </span>
                              <span style={{ color: "red" }}>
                                {" "}
                                the company was re-registered under the BVI
                                Business Companies Act 2004 with BVI BC No.{" "}
                                {emailParams.companyNumber}
                              </span>
                              &nbsp;as is recorded in the Register of Companies
                              of the British Virgin Islands and is currently in
                              good standing.
                            </>
                          ) : emailCategory === "standard" ? (
                            <>
                              That the Company was duly incorporated on the
                              &nbsp;
                              <b>{emailParams.incorporationDate}</b>
                              &nbsp;{" "}
                              <b>
                                day of {emailParams.incorporationMonth}, &nbsp;
                                {emailParams.incorporationYear}
                              </b>
                              &nbsp; under{" "}
                              <span>
                                BVI BC No. {emailParams.companyNumber}
                              </span>
                              <span>
                                {" "}
                                as is recorded in the Register of Companies of
                                the British Virgin Islands and is currently in
                                good standing.{" "}
                              </span>
                            </>
                          ) : emailCategory === "continued" ? (
                            <>
                              That the Company was duly incorporated on the
                              &nbsp;
                              <b>{emailParams.incorporationDate}</b>
                              &nbsp;{" "}
                              <b>
                                day of {emailParams.incorporationMonth}, &nbsp;
                                {emailParams.incorporationYear}
                              </b>
                              &nbsp; and
                              <span style={{ color: "red" }}>
                                {" "}
                                Continued under the BVI Business Companies Act,
                                2004 on the {emailParams.continueDate} day of{" "}
                                {emailParams.continueMonth},{" "}
                                {emailParams.continueYear} under BVI BC No.{" "}
                                {emailParams.companyNumber}{" "}
                              </span>
                              <span>
                                {" "}
                                in the Register of Companies of the British
                                Virgin Islands and is currently in good
                                standing.{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                        <li>
                          That the Company's Registered Agent is &nbsp;
                          <b>{emailParams.registeredAgentName}</b>&nbsp; whose
                          physical address is located at{" "}
                          <b>{emailParams.registeredAgentAddress}.</b>
                        </li>
                        <li>
                          That the Company's Registered Office is located at{" "}
                          <b>{emailParams.companyAddress}.</b>
                        </li>
                        <li>
                          That insofar as we are aware, there are no legal,
                          winding up, arbitration or administrative proceedings
                          pending or threatened against the Company and that no
                          Receiver has been appointed over its assets.
                        </li>
                        <li>
                          The entries in the Company’s Register of Charges held
                          at its Registered Office are as follows.
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                              maxWidth: "100%",
                              fontFamily: "Arial, sans-serif",
                              fontSize: "10px",
                              margin: "0 auto",
                              padding: "0 10px",
                              tableLayout: "fixed",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Description of Charge
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Liability Secured
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Property Charged
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Trustee or Chargee
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Holder of Charge
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Prohibitions or Restrictions
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Date of Discharge
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {emailParams.registerOfCharges &&
                              emailParams.registerOfCharges.length > 0 ? (
                                emailParams.registerOfCharges.map(
                                  (entry, index) => (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.descriptionOfCharge}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.liabilitySecured}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.propertyCharged}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.trusteeOrChargee}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.holderOfCharge}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.prohibitionsOrRestrictions}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.dateOfDischarge}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {entry.status}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan="8">
                                    No charge entries available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </li>
                        <li>
                          That insofar as we are aware there are no measures
                          taken or about to be taken, to have the Company
                          dissolved or otherwise removed from the Register of
                          Companies.
                        </li>
                        <li>
                          That the Company's <b>Director</b> at the date hereof
                          is/are:
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "auto",
                              maxWidth: "100%",
                              fontFamily: "Arial, sans-serif",
                              fontSize: "12px",
                              margin: "0 auto",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Date of Appointment
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {emailParams.directors &&
                              emailParams.directors.length > 0 ? (
                                emailParams.directors.map((director, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        border: "1px solid #ddd",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {director.name}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ddd",
                                        padding: "8px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {director.dateOfAppointment}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2">No directors available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </li>
                        <li>
                          That the Company's <b>Shareholder</b> at the date
                          hereof is/are: <br /> &emsp; &emsp;
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "auto",
                              maxWidth: "100%",
                              fontFamily: "Arial, sans-serif",
                              fontSize: "12px",
                              margin: "0 auto",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                >
                                  Number of shares
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {emailParams.shareholders &&
                              emailParams.shareholders.length > 0 ? (
                                emailParams.shareholders.map(
                                  (shareholder, index) => (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {shareholder.senderMasterName}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {shareholder.noShares}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan="2">No shareholders available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </li>
                      </ol>
                      <p>
                        <b>IN WITNESS WHEREOF</b>, we have hereunto affixed our
                        signature this {emailParams.signatureDate}&nbsp;day
                        of&nbsp;{emailParams.signatureMonth},
                        {emailParams.signatureYear}
                      </p>

                      <div className="signatures-container">
                        {signatures.map((signature, index) => (
                          <SignatureItem
                            key={index}
                            signature={signature}
                            index={index}
                            deleteSignature={deleteSignature}
                            registeredAgent={emailParams?.registeredAgentName}
                          />
                        ))}
                        {signatures.length < 4 && (
                          <div className="signature-content new-sign">
                            <div className="add-signature-badge">
                              Add Signature
                            </div>
                            <div
                              className="signature-wrapper"
                              onClick={() => setSignModal(true)}
                            >
                              <span className="icon">
                                <i className="fal fa-signature"></i>
                              </span>
                              <label className="custom-label req">
                                Click here to sign
                              </label>
                            </div>
                            <hr />
                            <p>
                              By: <br />
                              Designation: <br />
                              Authorized Signatory:{" "}
                              <b>{emailParams?.registeredAgentName ?? ""}</b>
                              <br /> Registered Agent
                            </p>
                          </div>
                        )}
                      </div>
                      <br />
                      <br />
                    </div>
                  </TransformComponent>
                </div>
              )}
            </TransformWrapper>
          </div>
        ) : (
          // <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="page-content">
            <div className="custom-form row gy-4 mb-4">
              <div className="col-md-3">
                <label htmlFor="">Entity Code</label>
                <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
              </div>
              <div className="col-md-3">
                <label htmlFor="">Entity Name</label>
                <span className="light-badge">{data?.entityName ?? "N/A"}</span>
              </div>
              <div className="col-md-3">
                <label htmlFor="">Client Code</label>
                <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
              </div>
              <div className="col-md-3">
                <label htmlFor="">Client Name</label>
                <span className="light-badge">{data?.clientName ?? "N/A"}</span>
              </div>
            </div>
          </div>
        )}
      </Spin>
      <AddSignature
        open={signModal}
        setvalue={addSignature}
        onOk={() => setSignModal(false)}
        onCancel={() => setSignModal(false)}
      />
    </div>
  );
}
