import React, { useState, useRef, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Select } from "antd";

import Individual from "./components/individual";
import CorporateKYC from "./components/corporate-kyc";
import Trusts from "./components/trusts";
import Foundations from "./components/foundations";
import Others from "./components/others";

import UploadZ from "../../components/UploadZ";
import FormZ from "../../components/FormZ";

import { Each, focusOn, fetchApi, sendNotify } from "../../helper";

export default function RelationshipManager() {
  const [activeTab, setActiveTab] = useState("individual");
  const [documents, setDocuments] = useState({
    basicDoc: [],
    individual: [],
    corporateEntity: [],
    incorpDocuments: [],
  });
  const [upload, setUpload] = useState([]);
  const [mode, setMode] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [clearDocuments, setClearDocuments] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let mode = searchParams.get("mode");
    setMode(mode);
    if (mode == "edit") {
      setActiveTab("trusts");
      focusOn("tab");
    }
  }, []);

  function storeDocuments(file) {
    let doc = { ...documents };
    doc["basicDoc"].push(file);
    console.log(doc);
    setDocuments(doc);
  }

  let documentsArr = [
    "Inaugural Resolution",
    "Appointment of First Director",
    "Resolution of Share Allotment",
    "Corporate Seal Draft",
    // "Directors Consent Letter",
    "Customized M&AA",
    "UBO Source of Funds Declaration",
    "Incorporation Application Form",
    "Transfer-In Form for BVIBC",
    "Written Confirmations-Virtual Assets",
    <>
      Passwords <span>(In case there is any password used)</span>
    </>,
  ];

  const addNewUpload = () => {
    let arr = [...upload];
    arr.push(`Enter document name ${arr.length + 1}`);
    setUpload(arr);
  };

  const removeUpload = (index) => {
    let arr = [...upload];
    arr.splice(index, 1);
    setUpload(arr);
  };

  const formRef = useRef();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  let formSchema = [
    {
      name: "entityName",
      value: "",
      type: "text",
      label: (
        <>
          Entity Name <span>(With any special characters)</span>
        </>
      ),
      placeholder: "Enter entity name",
    },
    {
      name: "clientName",
      value: "",
      type: "text",
      label: (
        <>
          Client Name <span>(With any special characters)</span>
        </>
      ),
      placeholder: "Enter client name",
    },
    {
      name: "clientCode",
      value: "",
      type: "text",
      label: "Client Code",
      placeholder: "Enter client code",
    },
    // {
    //   name: "title",
    //   value: "",
    //   type: "select",
    //   label: "Title",
    //   placeholder: "Enter title",
    //   options: [
    //     {
    //       value: "mr",
    //       label: "Mr",
    //     },
    //     {
    //       value: "mrs",
    //       label: "Mrs",
    //     },
    //     {
    //       value: "miss",
    //       label: "Miss",
    //     },
    //     {
    //       value: "ms",
    //       label: "Ms",
    //     },
    //     {
    //       value: "dr",
    //       label: "Dr",
    //     },
    //     {
    //       value: "rev",
    //       label: "Rev",
    //     },
    //   ],
    // },
    // {
    //   name: "firstName",
    //   value: "",
    //   type: "text",
    //   label: "First Name",
    //   placeholder: "Enter first name",
    // },
    // {
    //   name: "middleName",
    //   value: "",
    //   type: "text",
    //   label: "Middle Name",
    //   placeholder: "Enter Middle name",
    // },
    // {
    //   name: "lastName",
    //   value: "",
    //   type: "text",
    //   label: "Last Name",
    //   placeholder: "Enter last name",
    // },
  ];

  const getLabelName = (value, index) => {
    let arr = [...upload];
    arr[index] = value;
    setUpload(arr);
  };

  const onUploadDoc = (docs, name, key) => {
    let arr = { ...documents };
    let list = [...arr[name]];
    let obj = list.find((l) => l.index == key);
    let index = list.findIndex((l) => l.index == key);
    console.log(obj);
    if (!obj) {
      obj = {};
      obj["index"] = key;
      obj["documents"] = docs;
      list.push(obj);
    } else {
      obj["documents"] = docs;
      list[index] = obj;
    }

    arr[name] = list;
    console.log(arr);
    setDocuments(arr);
  };

  const onNameChange = (value, name, key) => {
    let arr = { ...documents };
    let list = [...arr[name]];
    let obj = list.find((l) => l.index == key);
    let index = list.findIndex((l) => l.index == key);
    if (obj) {
      obj["name"] = value;
      list[index] = obj;
    } else {
      obj = {};
      obj["index"] = key;
      obj["name"] = value;
      list.push(obj);
    }
    arr[name] = list;
    console.log(arr);
    setDocuments(arr);
  };

  const getIncorpDocs = (data) => {
    let arr = { ...documents };
    arr["incorpDocuments"] = data;
    setDocuments(arr);
    console.log(arr);
  };

  const onSubmitHandle = () => {
    setSubmitLoading(true);
    let data = {};
    let from = formRef.current.getPayload();
    console.log(documents);
    data = { ...from, ...documents };

    let payload = {
      method: "POST",
      url: `/relationshipManager`,
      data,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        setSubmitLoading(false);
        formRef.current.clearForm();
        setClearDocuments(true);
        setDocuments({
          basicDoc: [],
          individual: [],
          corporateEntity: [],
          incorpDocuments: [],
        });
      })
      .catch((error) => {
        console.log(error);
        sendNotify("error", error?.message);
        setSubmitLoading(false);
      });
    setClearDocuments(false);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-3 mb-md-0">
          End User / Relationship Manager
        </h1>
        <button
          className={`primary-btn ${submitLoading ? "btn-loading" : ""}`}
          onClick={onSubmitHandle}
        >
          {submitLoading ? (
            <i className="far fa-spinner-third fa-spin"></i>
          ) : (
            ""
          )}
          Submit
        </button>
      </div>
      <FormZ
        ref={formRef}
        formSchema={formSchema}
        formClass="custom-form row my-4 gy-3"
        childClass="col-md-3 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      <h2 className="primary-title mb-4">Documents</h2>
      <div className="custom-form">
        <div className="row gy-2 gx-4">
          <Each
            array={documentsArr}
            render={(item) => (
              <div className="col-md-3">
                <label htmlFor="inaugural-resolution" className="me-3">
                  {item}
                </label>
                <UploadZ
                  onStoreFile={storeDocuments}
                  clearUpload={clearDocuments}
                  fileName={item}
                />
              </div>
            )}
          />
          <Each
            array={upload}
            render={(item, i) => (
              <div className="col-md-3">
                <div className="position-relative">
                  <div className="d-flex justify-content-between align-items-center mb-1px">
                    <label
                      contentEditable={true}
                      key={i}
                      id={i}
                      onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                      className="mb-0"
                      suppressContentEditableWarning={true}
                    >
                      {item}
                    </label>
                    <button
                      className="del-upload-btn"
                      onClick={() => removeUpload(i)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </button>
                  </div>
                  <UploadZ
                    onStoreFile={storeDocuments}
                    clearUpload={clearDocuments}
                    fileName={item}
                  />
                </div>
              </div>
            )}
          />
          <div className="col-md-3 mb-4">
            <label htmlFor="">&nbsp;</label>
            <div className="add-new-upload" onClick={addNewUpload}>
              <span className="icon">
                <i className="fal fa-plus-circle"></i>
              </span>
              <h6>Add New Upload</h6>
              <p>Click to create new upload field</p>
            </div>
          </div>
        </div>
      </div>
      <div className="tab-wrapper my-3 my-md-4" id="tab">
        <button
          className={`tab-btn ${activeTab == "individual" ? "active" : ""}`}
          onClick={() => setActiveTab("individual")}
        >
          Individual
        </button>
        <button
          className={`tab-btn ${activeTab == "corporateKYC" ? "active" : ""}`}
          onClick={() => setActiveTab("corporateKYC")}
        >
          Corporate Entity
        </button>
        <button
          className={`tab-btn ${activeTab == "trusts" ? "active" : ""}`}
          onClick={() => setActiveTab("trusts")}
        >
          Incorp Documents
        </button>
        {/* <button
          className={`tab-btn ${activeTab == "foundations" ? "active" : ""}`}
          onClick={() => setActiveTab("foundations")}
        >
          Foundations
        </button>
        <button
          className={`tab-btn ${activeTab == "others" ? "active" : ""}`}
          onClick={() => setActiveTab("others")}
        >
          Others
        </button> */}
      </div>
      <div
        className={`hide-content ${activeTab == "individual" ? "show" : ""}`}
      >
        <Individual
          getName={onNameChange}
          clearUpload={clearDocuments}
          getDocument={onUploadDoc}
        />
      </div>
      <div
        className={`hide-content ${activeTab == "corporateKYC" ? "show" : ""}`}
      >
        <CorporateKYC
          getName={onNameChange}
          clearUpload={clearDocuments}
          getDocument={onUploadDoc}
        />
      </div>
      <div className={`hide-content ${activeTab == "trusts" ? "show" : ""}`}>
        <Trusts
          mode={mode}
          clearUpload={clearDocuments}
          getDocument={getIncorpDocs}
        />
      </div>
      <div
        className={`hide-content ${activeTab == "foundations" ? "show" : ""}`}
      >
        <Foundations />
      </div>
      <div className={`hide-content ${activeTab == "others" ? "show" : ""}`}>
        <Others />
      </div>
    </div>
  );
}
