import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, TreeSelect, Modal, Table } from "antd";

import FormZ from "./FormZ";
import { getEntity } from "../store/entity/entitySlice";
import { getCorporateEntity } from "../store/corporateEntity/corporateEntitySlice";

import socketIOClient from "socket.io-client";

import { sendNotify, getOptions, fetchApi, generateRandom, getQuery } from "../helper";
import SampleDoc from "../assets/pdf/sample.pdf";

import countries from "../helper/countries.json";

const { SHOW_PARENT } = TreeSelect;

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);



export default function CorporateEntity(props) {
  let { actionShow, fileCreation, setCopEntityClicked, setselectedPage, copEntityClicked, refCode } = props;
  const dispatch = useDispatch();

  const [countriesOption, setCountriesOption] = useState([]);
  const [value, setValue] = useState([]);
  const [sanction, setSanction] = useState("Y");
  const [masterId, setMasterId] = useState({})

  const { data, isLoading } = useSelector((state) => state.country);
  const entity = useSelector((state) => state.entity);
  const corporateEntity = useSelector((state) => state.corporateEntity);
  const [nameCheck, setNameCheck] = useState("");
  const [openRes, setOpenRes] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const [schema1, setSchema1] = useState({});
  const [schema2, setSchema2] = useState({});
  const [schema3, setSchema3] = useState({});
  useEffect(() => {

    dispatch(getEntity({ _start: 0, _limit: 100 }))
    dispatch(getCorporateEntity({ _start: 0, _limit: 100 }));
    convertToOptions();
    updateMastercode()
    configureSocket()
    setSanction(fileCreation ? "N" : "Y");
  }, []);

  useEffect(() => {

  }, [entity, corporateEntity]);

  const configureSocket = () => {

    // socket.on("connection", () => {
    //   console.log(" Connected backend");
    // });
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode()
      }
    });

  }
  const codeOnChange = async (value) => {
    if (!setselectedPage) {
      let { code } = value;
      let payload = {
        method: "GET",
        url: `/cop-entity?_start=0&_limit=1&code=${code}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.rows[0];
      });
      let val,
        val2,
        val3 = {};
      if (data) {
        console.log(data, "rxdryfguhioj")

        val = {
          code: data.code,
          corpInfoNo: data.corpInfoNo,
          name: data.name,
          type: data.type,
          incorporationDate: data.incorporationDate,
          incorporationNumber: data.incorporationNumber,
          incorporationCountry: data.incorporationCountry,
          busNature: data.busNature,
          busGeography: data.busGeography,
          busDesc: data.busDesc,
          relatedEntityCode: data.relatedEntityCode,
          createdAt: data.createdAt,
        };
        val2 = {
          residentialAddress: data.residentialAddress,
          serviceAddress: data.serviceAddress,
          line_1: data.details.address.line_1,
          line_2: data.details.address.line_2,
          line_3: data.details.address.line_3,
          zipCode: data.details.address.zipCode,
          country: data.details.address.country,
        };
        val3 = {
          riskRating: data.details.riskRating,
          pep: data.details.pep,
          sanction: data.details.sanction,
        };
      }
      setSchema1(val);
      setSchema2(val2);
      setSchema3(val3);
    }
  };

  const checkCorporateEntityValidity = () => {
    if (
      formRef.current.validFrom() ||
      riskFormRef.current.validFrom() ||
      adsFormRef.current.validFrom()
    ) {
      let options1 = formRef.current.getPayload();
      let options2 = adsFormRef.current.getPayload();
      let options3 = riskFormRef.current.getPayload();

      // let data = {
      //   code: options1.code,
      //   title: options1.title,
      //   firstName: options1.firstName,
      //   middleName: options1.middleName,
      //   lastName: options1.lastName,
      //   DOB: options1.DOB,
      //   COB: options1.COB,
      //   nationality: options1.nationality,
      //   passportNo: options1.passportNo,
      //   passportIssue: options1.passportIssue,
      //   passportExp: options1.passportExp,
      //   idNo: options1.idNo,
      //   idIssue: options1.idIssue,
      //   idExp: options1.idExp,
      //   taxInfo: options1.taxInfo,
      //   residentialAddress: options2.residentialAddress,
      //   serviceAddress: options2.serviceAddress,
      //   associateBus: options1.associateBus,
      //   relatedEntities: null,
      //   contactNumber: options1.contactNumber,
      //   email: options1.email,
      //   details: {
      //     address: {
      //       line_1: options2.line_1,
      //       line_2: options2.line_2,
      //       line_3: options2.line_3,
      //       zipCode: options2.zipCode,
      //       country: options2.country,
      //     },
      //     riskRating: options3.riskRating,
      //     // pep: pep,
      //     sanction: sanction,
      //   },
      //   createdAt: options1.createdAt,
      // };

      let data = {
        code: options1.code,
        corpInfoNo: options1.corpInfoNo,
        name: options1.name,
        type: options1.type,
        incorporationDate: options1.incorporationDate,
        incorporationNumber: options1.incorporationNumber,
        incorporationCountry: options1.incorporationCountry,
        busNature: options1.busNature,
        busGeography: options1.busGeography,
        busDesc: options1.busDesc,
        relatedEntityCode: options1.relatedEntityCode,
        createdAt: options1.createdAt,
        residentialAddress: options2.residentialAddress,
        serviceAddress: options2.serviceAddress,
        details: {
          address: {
            line_1: options2.line_1,
            line_2: options2.line_2,
            line_3: options2.line_3,
            zipCode: options2.zipCode,
            country: options2.country,
          },
          riskRating: options3.riskRating,
          pep: options3.pep,
          sanction: options3.sanction,
        },





      }
      data.masterType = "CorporateEntity";
      let payload = {
        method: "POST",
        url: `/nameCheck/search-initiate`,
        data: data,
      };
      //  let url = `/app/pre-incorp-resolution`

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
          if (res?.data?.data && res?.data?.success) {
            setNameCheck(res?.data?.data)
            setOpenRes(true)
          }
          // let data = await fetchApi(payload).then((res) => {
          //   return res?.data?.data
          // });
          // setTimeout(() => {
          //   window.location.href = url;
          // }, 1000);
        })
        .catch((error) => {
          console.log(error);
          sendNotify("error", error?.message);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };


  const updateMastercode = async () => {

    if (setselectedPage) {

      let payload = {
        method: "GET",
        url: `/admin/masterCode?name=cop-entity`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data?.data
      });
      let originalString = data?.seq;
      let numericPart = originalString?.match(/\d+$/)[0];
      let incrementedNumeric = parseInt(numericPart, 10) + 1;
      let newNumericPart = String(incrementedNumeric).padStart(numericPart?.length, '0');
      let newString = originalString?.replace(/\d+$/, newNumericPart);
      console.log(newString);
      setMasterId(newString ? newString : 'COR00001')
      setSchema1({ code: newString ? newString : 'INV00001' })
    }
  }
  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    // console.log(options);
    setCountriesOption(options);
  };

  const onChange = (newValue) => {
    console.log("onChange ", newValue);
    setValue(newValue);
  };
  const tProps = {
    treeData: countriesOption,
    value,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };

  const formRef = useRef();
  const adsFormRef = useRef();
  const riskFormRef = useRef();

  const handleChange = (value) => {
    setSanction(value);
  };

  let formSchema = [
    // {
    //   name: "code",
    //   value: '',
    //   type: "text",
    //   label: "Corporate Entity Code",
    //   placeholder: "Enter corporate entity code",
    //   disabled: true,
    // },
    {
      name: "code",
      value: '',
      type: "select",
      label: "Corporate Code",
      placeholder: "Enter Corporate code",
      disabled: setselectedPage ? true : false,
      options: getOptions(corporateEntity?.data?.rows),
      loading: isLoading,
      showSearch: true
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Corporate Entity Name",
      placeholder: "Enter corporate entity name",
    },
    {
      name: "type",
      value: "",
      type: "select",
      label: "Corporate Entity Type",
      placeholder: "Enter corporate entity type",
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "incorporationDate",
      value: "",
      type: "date",
      label: "Incorporation Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      // format: "DD/MM/YYYY",
    },
    {
      name: "incorporationNumber",
      value: "",
      type: "text",
      label: "Incorporation Number",
      placeholder: "Enter incorporation number",
    },
    {
      name: "incorporationCountry",
      value: "",
      type: "select",
      label: "Country Of Incorporation",
      placeholder: "Select Country Of Incorporation",
      showSearch: true,
      options: getOptions(data),
      loading: isLoading,
    },
    {
      label: "Geography of business",
      customElement: <TreeSelect {...tProps} placement="topLeft" />,
    },
    {
      name: "busNature",
      value: "",
      type: "select",
      label: "Nature of Business",
      placeholder: "Select nature of business",
      showSearch: true,
      options: [
        {
          value: "manufacturing",
          label: "Manufacturing",
        },
        {
          value: "retail",
          label: "Retail",
        },
        {
          value: "wholesale",
          label: "Wholesale",
        },
        {
          value: "services",
          label: "Services",
        },
        {
          value: "agriculture",
          label: "Agriculture",
        },
        {
          value: "realEstate",
          label: "Real Estate",
        },
        {
          value: "construction",
          label: "Construction",
        },
        {
          value: "transportation",
          label: "Transportation",
        },
        {
          value: "healthcare",
          label: "Healthcare",
        },
        {
          value: "education",
          label: "Education",
        },
        {
          value: "financialServices",
          label: "Financial Services",
        },
        {
          value: "informationTechnology",
          label: "Information Technology",
        },
        {
          value: "entertainment",
          label: "Entertainment",
        },
        {
          value: "hospitality",
          label: "Hospitality",
        },
        {
          value: "utilities",
          label: "Utilities",
        },
        {
          value: "miningAndDrilling",
          label: "Mining and Drilling",
        },
        {
          value: "rAndD",
          label: "R&D",
        },
        {
          value: "nonProfit",
          label: "Non-Profit",
        },
        {
          value: "eCommerce",
          label: "E-commerce",
        },
        {
          value: "freelancing",
          label: "Freelancing",
        },
        {
          value: "gambling",
          label: "Gambling",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
    },
    {
      name: "busDesc",
      value: "",
      type: "text",
      label: "Description of Business",
      placeholder: "Enter description of your business",
    },
    {
      name: "corpInfoNo",
      value: "",
      type: "text",
      label: "Corporate Tax Info Number",
      placeholder: "Enter corporate tax info number",
    },
    {
      name: "relatedEntityCode",
      value: "",
      type: "select",
      label: "Related Entity Code",
      placeholder: "Enter related entity code",
      options: getOptions(entity?.data?.rows),
      // options: [
      //   {
      //     value: "E203",
      //     label: "E203",
      //   },
      //   {
      //     value: "E204",
      //     label: "E204",
      //   },
      //   {
      //     value: "E205",
      //     label: "E205",
      //   },
      // ],
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of Creation",
      placeholder: "Select date",
    },
  ];

  let adsFormSchema = [
    {
      name: "line_1",
      value: "",
      type: "text",
      label: "Address Line 01",
      placeholder: "Enter address",
    },
    {
      name: "line_2",
      value: "",
      type: "text",
      label: "Address Line 02",
      placeholder: "Enter address",
    },
    {
      name: "line_3",
      value: "",
      type: "text",
      label: "Address Line 03",
      placeholder: "Enter address",
    },
    {
      name: "country",
      value: "",
      type: "select",
      label: "Country",
      placeholder: "Select country",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      labelClass: "mb-1",
      customClass: "col-md-3 flex-column align-items-start",
    },
    {
      name: "zipCode",
      value: "",
      type: "number",
      label: "Post/Zip code",
      labelClass: "mb-1",
      placeholder: "Enter post/zip code",
      customClass: "col-md-3 flex-column align-items-start",
    },
  ];

  let riskFormSchema = [
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Rating",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      name: "listed",
      value: "",
      type: "select",
      label: "Listed or Regulated",
      placeholder: "Select listed or regulated",
      options: [
        {
          value: "Y",
          label: "Yes",
        },
        {
          value: "N",
          label: "No",
        },
      ],
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            onChange={handleChange}
            disabled={fileCreation}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input type="text" className="w-100" placeholder="Enter sanction" />
          )}
        </div>
      ),
    },
    {
      customElement: fileCreation ? (
        <div></div>
      ) : (
        // <button className="primary-btn mb-15">Confirm</button>
        <div className="d-flex gap-4">
          <button className="primary-btn" onClick={() => setOpenHistory(true)}>
            History
          </button>
          <button className="primary-btn mb-15">Confirm</button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (copEntityClicked) {
      onSubmit();
    }
  }, [copEntityClicked]);

  const onSubmit = () => {
    if (formRef.current.validFrom() || riskFormRef.current.validFrom() || adsFormRef.current.validFrom()) {
      let options1 = formRef.current.getPayload();
      let options2 = adsFormRef.current.getPayload();
      let options3 = riskFormRef.current.getPayload();
      let data = {
        code: options1.code,
        name: options1.name,
        type: options1.type,
        incorporationDate: options1.incorporationDate,
        incorporationNumber: options1.incorporationNumber,
        incorporationCountry: options1.incorporationCountry,
        busGeography: value,
        busNature: options1.busNature,
        busDesc: options1.busDesc,
        corpInfoNo: options1.corpInfoNo,
        relatedEntityCode: options1.relatedEntityCode,
        details: {
          address: {
            line_1: options2.line_1,
            line_2: options2.line_2,
            line_3: options2.line_3,
            zipCode: options2.zipCode,
            country: options2.country,
          },
          riskRating: options3.riskRating,
          listed: options3.listed,
          sanction: options1.sanction
        },
        createdAt: options1.createdAt
      }

      data.masterType = 'cop-entity';
      data.nic = "COR";

      let payload = {
        method: "POST",
        url: `/cop-entity`,
        data: data,
      };
      let url = `/app/pre-incorp-resolution`

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
          setCopEntityClicked(false)
          // setTimeout(() => {
          //   window.location.href = url;
          // }, 1000);

        })
        .catch((error) => {
          console.log(error);
          sendNotify("error", error?.message);
          setCopEntityClicked(false)
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  if (setselectedPage) {
    setselectedPage('corporate-entity')
  }
  const tableColumns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: "RAF",
      dataIndex: "raf",
      sorter: (a, b) => a.raf - b.raf,
      render: (_, { status }) => {
        return (
          <button
            className="outline-btn py-1"
            onClick={() => setOpenDocument(true)}
          >
            View
          </button>
        );
      },
    },
    {
      title: "Analysis Report",
      dataIndex: "analysisReport",
      sorter: (a, b) => a.analysisReport - b.analysisReport,
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy - b.updatedBy,
    },
  ];

  const tableData = [
    {
      id: "001",
      date: "12/04/2024 - 03:24PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "002",
      date: "11/04/2024 - 02:53PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "003",
      date: "10/04/2024 - 10:01AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "004",
      date: "10/04/2024 - 09:24AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "005",
      date: "05/04/2024 - 11:12AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
  ];

  return (
    <div className="custom-form">
      <FormZ
        formSchema={formSchema}
        // formData={{ code: masterId }}
        formData={schema1}
        onChange={codeOnChange}
        ref={formRef}
        formClass="row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      <FormZ
        formSchema={adsFormSchema}
        formData={schema2}
        ref={adsFormRef}
        formClass="custom-form row"
        childClass="form-row mb-4"
        labelClass="me-3 mb-0"
        inputClass="w-100"
      />
      <FormZ
        formSchema={riskFormSchema}
        ref={riskFormRef}
        formData={schema3}
        formClass="custom-form row mb-md-5 gy-3 align-items-end"
        childClass="col-md-3 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
        disabled={fileCreation ? true : false}
      />
      {actionShow && (
        <div>
          <div className="d-flex flex-wrap gap-3 mt-3 mb-5 mb-md-0">
            <button className="primary-btn"
              onClick={() => checkCorporateEntityValidity("reservation/confirmation")}
            >Analyze</button>
            <button className="outline-btn">Web Search</button>
            <button className="outline-btn">Analysis Result</button>
            <button className="outline-btn">Store Result</button>
          </div>
          <div className="d-flex flex-wrap gap-3 mt-3">
            <button className="primary-btn">RAF</button>
            <button className="outline-btn">Approve RAF</button>
            <button className="outline-btn">Store RAF</button>
          </div>
        </div>
      )}
      <Modal
        title={"History"}
        className="custom-modal"
        open={openHistory}
        width={800}
        onOk={() => setOpenHistory(false)}
        okText="Submit"
        onCancel={() => setOpenHistory(false)}
      >
        <div>
          <Table
            className="custom-table"
            columns={tableColumns}
            dataSource={tableData}
            pagination={false}
          />
        </div>
      </Modal>

      <Modal
        title={"Raf Document"}
        className="custom-modal"
        open={openDocument}
        width={1000}
        onOk={() => setOpenDocument(false)}
        onCancel={() => setOpenDocument(false)}
      >
        <div className="document-view">
          <iframe
            src={SampleDoc}
            frameBorder="0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </Modal>

      <Modal
        title={"Corporate Entity Report"}
        className="custom-modal"
        open={openRes}
        width={1000}
        onOk={() => setOpenRes(false)}
        onCancel={() => setOpenRes(false)}
      >
        <div className="document-view">
          <iframe
            title="HTML Content"
            width="100%"
            height="100%"
            srcDoc={nameCheck}
            frameBorder="0"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}
