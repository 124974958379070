import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo-icon.png";

export default function Home() {
  let { isLogged, userDetails } = useSelector((state) => state.auth);

  return (
    <div>
      <h1 className="page-title">
        Galactic - <span>Client Name</span>
      </h1>
      <div className="welcome-section mt-4 mt-md-0">
        <div className="w-md-50 text-center">
          <img className="logo" src={Logo} alt="" />
          <h2>Welcome, {userDetails.userName}</h2>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ratione
            earum perferendis vitae ipsam perspiciatis sapiente, laborum illo
            cumque quas recusandae nemo ab consectetur aut voluptatibus
            doloribus unde
          </p>
          {/* <Link to={'/app/incorporation'}>
            <button className='primary-btn mx-auto mb-4'><i className="fal fa-plus"></i> Request New Incorporation</button>
          </Link>
          <Link to={'/app/post-incorp'}>
            <button className='primary-btn mx-auto'><i className="fal fa-plus"></i> Request Post Incorporation</button>
          </Link> */}
        </div>
      </div>
    </div>
  );
}
