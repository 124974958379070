import { useState } from "react";
import { Link } from "react-router-dom";

import { Table, Select, Tag, Modal } from "antd";

import { Each } from "../../helper";
import UploadZ from "../../components/UploadZ";

import SampleDoc from "../../assets/pdf/sample.pdf";

export default function ReviewUpload() {
  const [documents, setDocuments] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [customUpload, setCustomUpload] = useState([]);

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Task Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
    },
    {
      title: "Approved date",
      dataIndex: "approvedDate",
      sorter: (a, b) => a.approvedDate - b.approvedDate,
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      width: 200,
      sorter: (a, b) => a.status - b.status,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Approved
        </Tag>
        // <Select
        //   className="custom-select"
        //   placeholder="Select type"
        //   value={status}
        //   style={{
        //     width: 160,
        //   }}
        //   options={[
        //     {
        //       value: "in-progress",
        //       label: "In Progress",
        //     },
        //     {
        //       value: "approved",
        //       label: "Approved",
        //     },
        //     {
        //       value: "rejected",
        //       label: "Rejected",
        //     },
        //     {
        //       value: "pending",
        //       label: "Pending",
        //     },
        //   ]}
        // />
      ),
    },
    {
      title: "Created Date and Time",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      width: 200,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy - b.createdBy,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      key: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      key: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Requestor ID",
      dataIndex: "requestorID",
      sorter: (a, b) => a.requestorID - b.requestorID,
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      sorter: (a, b) => a.reviewStatus - b.reviewStatus,
      // width: 200,
      render: (_, { reviewStatus }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"gold"}>
          Pending
        </Tag>
        // <Select
        //   className="custom-select"
        //   placeholder="Select type"
        //   value={reviewStatus}
        //   style={{
        //     width: 160,
        //   }}
        //   options={[
        //     {
        //       value: "in-progress",
        //       label: "In Progress",
        //     },
        //     {
        //       value: "completed",
        //       label: "Completed",
        //     },
        //     {
        //       value: "pending-info",
        //       label: "Pending Info",
        //     },
        //   ]}
        // />
      ),
    },
    {
      title: "Processor",
      dataIndex: "processor",
      sorter: (a, b) => a.processor - b.processor,
    },
    {
      title: "Pack Upload",
      dataIndex: "packUpload",
      sorter: (a, b) => a.packUpload - b.packUpload,
      render: (_, { id }) =>
        id == 2 ? (
          <button
            className="outline-btn justify-content-center py-2 w-75"
            onClick={() => {
              setViewMode(true);
              setUploadModal(true);
            }}
          >
            <i className="far fa-eye"></i> View
          </button>
        ) : (
          <button
            className="outline-btn justify-content-center py-2 w-75"
            onClick={() => {
              setViewMode(false);
              setUploadModal(true);
            }}
          >
            <i className="far fa-arrow-to-top"></i> Upload
          </button>
        ),
      // <div className="table-upload">
      //   <label htmlFor="doc" className="outline-btn py-2">
      //     Upload
      //   </label>
      //   <input type="file" name="doc" id="doc" />
      // </div>
    },
    {
      title: "Actions",
      // fixed: "right",
      width: 250,
      render: (_, { id }) => (
        <div className="d-flex gap-3">
          <button className="action-btn">Store</button>
          <Link to={"/app/entity-overview/edit"} className="action-btn">
            Update
          </Link>
          <Link to={"/app/entity-overview/edit"} className="action-btn">
            Store & Update
          </Link>
        </div>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      id: "1",
      name: "Task #1",
      type: "Active",
      approvedBy: "Guru",
      approvedDate: "10/10/2023",
      status: "approved",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "completed",
      processor: "Hari",
    },
    {
      key: "2",
      id: "2",
      name: "Task #2",
      type: "Active",
      approvedBy: "Guru",
      approvedDate: "10/10/2023",
      status: "pending",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "pending-info",
      processor: "Hari",
    },
    {
      key: "3",
      id: "3",
      name: "Task #3",
      type: "Active",
      approvedBy: "Guru",
      approvedDate: "10/10/2023",
      status: "in-progress",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "in-progress",
      processor: "Hari",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const docList = [
    "Certificate of Incorporation",
    "M&AA",
    "Approved RAF",
    "Compliance Pack",
  ];

  const handleOk = () => {
    setUploadModal(false);
  };

  const handleCancel = () => {
    setUploadModal(false);
  };

  const addNewUploadDoc = () => {
    let arr = [...customUpload];
    let index = arr.length + 1;
    arr.push(`Enter Document Name ${index}`);
    setCustomUpload(arr);
  };

  const getLabelName = (value, index) => {
    let arr = [...customUpload];
    arr[index] = value;
    setCustomUpload(arr);
  };

  const removeUploadDoc = (index) => {
    let arr = [...customUpload];
    arr.splice(index, 1);
    setCustomUpload(arr);
  };

  const sampDocuments = {
    url: SampleDoc,
    name: "Sample Document.pdf",
    size: "200 KB",
    type: "pdf",
    base64: "",
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
        <h1 className="page-title mb-3 mb-md-0">New Business Onboard</h1>
        <div className="d-flex gap-3 flex-column flex-md-row">
          <Link to={"/app/storage"} className="outline-btn">
            Store
          </Link>
          {/* <Link to={"/app/entity-overview/edit"} className="primary-btn">
            Send out to storage and Update
          </Link> */}
        </div>
      </div>
      <Table
        className="custom-table"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 2500 }}
      />
      {/* <div className="custom-form">
        <h4 className="primary-title mb-4"></h4>
      </div> */}
      <Modal
        title={"Complete Incorporation Process"}
        className="custom-modal"
        open={uploadModal}
        width={1000}
        onOk={handleOk}
        okText="Upload"
        onCancel={handleCancel}
      >
        <div className="row custom-form gy-2 gx-4">
          <Each
            array={docList}
            render={(item) => (
              <div className="col-md-4">
                <label htmlFor={item}>{item}</label>
                <UploadZ
                  onStoreFile={storeDocuments}
                  fileName={item}
                  file={viewMode ? sampDocuments : null}
                />
              </div>
            )}
          />
          {customUpload.length > 0 && (
            <Each
              array={customUpload}
              render={(item, i) => (
                <div className="col-md-4">
                  <div className="d-flex justify-content-between align-items-center mb-1px">
                    <label
                      contentEditable={true}
                      key={i}
                      id={i}
                      onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                      className="mb-0"
                      suppressContentEditableWarning={true}
                    >
                      {item}
                    </label>
                    <button
                      className="del-upload-btn"
                      onClick={() => removeUploadDoc(i)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </button>
                  </div>
                  <UploadZ onStoreFile={storeDocuments} fileName={item} />
                </div>
              )}
            />
          )}
          <div className="col-md-4 mb-4">
            <label htmlFor="">&nbsp;</label>
            <div className="add-new-upload" onClick={addNewUploadDoc}>
              <span className="icon">
                <i className="fal fa-plus-circle"></i>
              </span>
              <h6>Add New Upload</h6>
              <p>Click to create new upload field</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
