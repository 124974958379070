import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getFilingSetup = createAsyncThunk(
  "getFilingSetup",
  async (params) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/masterSetup/filingSetup?${query}`,
    };

    let res = await fetchApi(payload).then((res) => {
      return res.data;
    });
    return res;
  }
);

export const getPeriodicRiskReviewSetup = createAsyncThunk(
  "getPeriodicRiskReviewSetup",
  async (params) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/masterSetup/periodicRiskReviewSetup?${query}`,
    };

    let res = await fetchApi(payload).then((res) => {
      return res.data;
    });
    return res;
  }
);

export const getdocumentExpiryNotify = createAsyncThunk(
  "getdocumentExpiryNotify",
  async (params) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/masterSetup/documentExpiryNotify?${query}`,
    };

    let res = await fetchApi(payload).then((res) => {
      return res.data;
    });
    return res;
  }
);

const incorpSlice = createSlice({
  name: "masterSetup",
  initialState: {
    isLoading: false,
    filingSetup: null,
    periodicRiskReviewSetup: null,
    documentExpiryNotify: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getFilingSetup.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getFilingSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filingSetup = action.payload;
    });
    builder.addCase(getFilingSetup.rejected, (state, action) => {
      console.error("Error:", action.error.message);
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(getPeriodicRiskReviewSetup.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getPeriodicRiskReviewSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.periodicRiskReviewSetup = action.payload;
    });
    builder.addCase(getPeriodicRiskReviewSetup.rejected, (state, action) => {
      console.error("Error:", action.error.message);
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(getdocumentExpiryNotify.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getdocumentExpiryNotify.fulfilled, (state, action) => {
      state.isLoading = false;
      state.documentExpiryNotify = action.payload;
    });
    builder.addCase(getdocumentExpiryNotify.rejected, (state, action) => {
      console.error("Error:", action.error.message);
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default incorpSlice.reducer;
