import React, { useState } from "react";
import { Table, Modal, Select, Tooltip, Tag } from "antd";
import { Link } from "react-router-dom";

import AdminAvatarImg from "../../assets/img/admin-avatar.png";
import UserAvatarImg from "../../assets/img/user-avatar.png";
import SampleDoc from "../../assets/pdf/sample.pdf";

import { Each, formatBytes, focusOn } from "../../helper";
import UploadZ from "../../components/UploadZ";

export default function ReviewListing() {
  const [commentModal, setCommentModal] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([
    {
      from: "admin",
      name: "Hariharan",
      time: "12:45 PM",
      message: "Hi, welcome to SimpleChat! Go ahead and send me a message.",
    },
    {
      from: "client",
      name: "Guru",
      time: "12:47 PM",
      message: "You can change your name in JS section!",
    },
    {
      from: "admin",
      name: "Hariharan",
      time: "12:48 PM",
      message: "Yeah sure... Thank you",
    },
  ]);
  const [requestModal, setRequestModal] = useState(false);

  const columns = [
    {
      title: "Task Ref",
      dataIndex: "ref",
      sorter: (a, b) => a.ref - b.ref,
    },
    {
      title: "Task Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Task Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: "Created Date and Time",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy - b.createdBy,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      key: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      key: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Requestor ID",
      dataIndex: "requestorID",
      sorter: (a, b) => a.requestorID - b.requestorID,
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      sorter: (a, b) => a.reviewStatus - b.reviewStatus,
      render: (_, { reviewStatus }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          value={reviewStatus}
          style={{
            width: 130,
          }}
          options={[
            {
              value: "in-progress",
              label: "In Progress",
            },
            {
              value: "completed",
              label: "Completed",
            },
            {
              value: "pending-info",
              label: "Pending Info",
            },
          ]}
        />
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (_, { status }) => {
        let text;
        let color;
        switch (status) {
          case "O":
            text = "On Going";
            color = "gold";
            break;
          case "A":
            text = "Approved";
            color = "green";
            break;
          case "R":
            text = "Rejected";
            color = "red";
            break;
          default:
            text = "";
            break;
        }
        return (
          <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={color}>
            {text}
          </Tag>
        );
        // <Select
        //   className="custom-select"
        //   placeholder="Select type"
        //   value={status}
        //   style={{
        //     width: 160,
        //   }}
        //   options={[
        //     {
        //       value: "in-progress",
        //       label: "In Progress",
        //     },
        //     {
        //       value: "approved",
        //       label: "Approved",
        //     },
        //     {
        //       value: "rejected",
        //       label: "Rejected",
        //     },
        //     {
        //       value: "pending",
        //       label: "Pending",
        //     },
        //   ]}
        // />
      },
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
      render: (_, data) => (
        <button className="outline-btn" onClick={() => setCommentModal(true)}>
          <i className="far fa-comment-exclamation"></i>
        </button>
      ),
    },
    {
      title: "MLRO",
      dataIndex: "processor",
      sorter: (a, b) => a.processor - b.processor,
    },
    {
      title: "RAF",
      dataIndex: "ref",
      sorter: (a, b) => a.ref - b.ref,
      render: (_, data) => (
        <div className="table-upload">
          <label htmlFor="doc" className="outline-btn py-2">
            Upload
          </label>
          <input type="file" name="doc" id="doc" />
        </div>
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      width: 220,
      render: (_, { status }) => (
        <button
          onClick={() => setRequestModal(true)}
          className="d-flex justify-content-between action-btn w-100"
        >
          {status == "A" ? "Ready to Incorporate" : "View Request"} &nbsp;{" "}
          <i className="far fa-long-arrow-right me-0"></i>
        </button>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      id: "1",
      ref: "#task001",
      name: "Task #1",
      type: "Active",
      status: "O",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "completed",
      processor: "Hari",
    },
    {
      key: "2",
      id: "2",
      ref: "#task002",
      name: "Task #2",
      type: "Active",
      status: "A",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "pending-info",
      processor: "Hari",
    },
    {
      key: "3",
      id: "3",
      ref: "#task003",
      name: "Task #3",
      type: "Active",
      status: "R",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "in-progress",
      processor: "Hari",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleOk = () => {
    setCommentModal(false);
  };

  const handleCancel = () => {
    setCommentModal(false);
  };

  const sendComment = () => {
    let arr = [...comments];

    let comment = {
      from: "client",
      name: "Guru",
      time: "12:49 PM",
      message: newComment,
    };

    arr.push(comment);
    setComments(arr);
    setNewComment("");
    focusOn("comment" + (comments.length - 1));
  };

  const documents = [
    {
      url: SampleDoc,
      name: "Inaugural Resolution",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Appointment of First Director",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Resolution of Share Allotment",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Corporate Seal Draft",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Directors Consent Letter",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Customized M&AA",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "UBO Source of Funds Declaration",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Incorporation Application Form",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "AML Verification Guidelines",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Transfer-In Form for BVIBC",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Written Confirmations-Virtual Assets",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Terms Of Business Agreement",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-5">
        <h1 className="page-title mb-3 mb-md-0">MLRO Sign Off Dashboard</h1>
        <Link to={"/app/mlro"} className="primary-btn">
          Send to MLRO
        </Link>
      </div>
      <div className="mb-md-3 d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
        <h2 className="primary-title mb-3 mb-md-0">MLRO Approval Status</h2>
        <div className="d-flex gap-3 flex-column flex-md-row">
          <button className="outline-btn">
            <i className="far fa-arrow-to-bottom"></i> Download Selected
          </button>
          <button className="outline-btn">
            <i className="far fa-paper-plane"></i> Send for approval
          </button>
        </div>
      </div>
      <Table
        className="custom-table"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 2500 }}
      />
      <Modal
        title={"View Request"}
        className="custom-modal"
        open={requestModal}
        width={1000}
        onOk={() => setRequestModal(false)}
        okText="Ok"
        onCancel={() => setRequestModal(false)}
      >
        <div className="row gy-3">
          <Each
            array={documents}
            render={(item) => (
              <div className="custom-form col-md-4">
                <label>{item.name}</label>
                <UploadZ file={item} downloadOnly={true} />
              </div>
            )}
          />
        </div>
      </Modal>
      <Modal
        title={"Comment"}
        className="custom-modal"
        open={commentModal}
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <section className="msger w-100">
          <main className="msger-chat">
            <h6 className="msger-date">Today</h6>
            <Each
              array={comments}
              render={(item, i) => (
                <div
                  id={`comment${i}`}
                  className={`msg ${
                    item?.from == "admin" ? "left-msg" : "right-msg"
                  }`}
                >
                  <div className="msg-img">
                    <img
                      src={
                        item?.from == "admin" ? AdminAvatarImg : UserAvatarImg
                      }
                    />
                  </div>
                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-name">{item?.name}</div>
                      <div className="msg-info-time">{item?.time}</div>
                    </div>
                    <div className="msg-text">{item?.message}</div>
                  </div>
                </div>
              )}
            />
          </main>

          <div className="d-flex gap-2 mt-3 custom-form">
            <input
              type="text"
              className="w-100"
              placeholder="Enter your message..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && sendComment()}
            />
            <button
              type="submit"
              className={`primary-btn`}
              disabled={!newComment}
              onClick={sendComment}
            >
              <i className="fal fa-paper-plane"></i>
              Send
            </button>
          </div>
        </section>
      </Modal>
    </div>
  );
}
