import React, { useState } from "react";
import { Checkbox } from "antd";

import { Each, focusOn, sendNotify, showCount } from "../../../helper";
import UploadZ from "../../../components/UploadZ";

export default function Individual(props) {
  let { data, getName, getDocument, clearUpload, removeElement } = props;

  const [individuals, setIndividuals] = useState([1]);

  const addNewIndividual = () => {
    let arr = [...individuals];
    if (arr.length < 10) {
      arr.push(individuals.length + 1);
      setIndividuals(arr);
      focusOn("individual" + arr.length);
    } else {
      sendNotify("error", "Your reach the maximum level of individual");
    }
  };

  const removeIndividual = (index) => {
    let arr = [...individuals];
    arr.splice(index, 1);
    removeElement("individual", index);
    setIndividuals(arr);
  };

  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4">
        <h2 className="primary-title mb-3 mb-md-0">Individual</h2>
        <button className="primary-btn" onClick={addNewIndividual}>
          <i className="fal fa-plus"></i> Add New Individual
        </button>
      </div>
      <Each
        array={individuals}
        render={(individual, index) => (
          <IndividualDocuments
            key={index}
            element={index}
            index={individual}
            data={data[index]}
            getName={getName}
            getDocument={getDocument}
            clearUpload={clearUpload}
            remove={removeIndividual}
          />
        )}
      />
    </div>
  );
}

function IndividualDocuments(props) {
  let { index, element, data, getName, getDocument, clearUpload, remove } =
    props;

  const [documents, setDocuments] = useState([]);
  const [docList, setDocList] = useState(["Passport / ID", "Address Proof"]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);

  function storeDocuments(file) {
    let doc = [...documents];
    let arrIndex = doc?.findIndex((d) => d?.document == file?.document);
    if (arrIndex != -1) {
      doc[arrIndex] = file;
    } else {
      doc.push(file);
    }
    setDocuments(doc);
    getDocument(doc, "individual", `individual_${showCount(index)}`);
  }

  const deleteDocument = (name) => {
    let doc = [...documents];
    let arrIndex = doc?.findIndex((d) => d?.document == name);
    if (arrIndex != -1) {
      doc.splice(arrIndex, 1);
    }
    setDocuments(doc);
    getDocument(doc, "individual", `individual_${showCount(index)}`);
  };

  const directors = [
    "Consent to Act as Director",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Directors",
  ];
  const secretary = [
    "Consent to Act as Secretary",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Secretarys",
  ];
  const president = [
    "Consent to Act as President",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Presidents",
  ];
  const shareholders = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
    "Share Transfer Agreement",
  ];

  const ubos = [
    "UBO source of funds document",
    "Source of Wealth Proof",
    "Resolution of Shareholding",
  ];

  const poc = ["Contact Details"];

  const nominee = ["Nominee Agreement"];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i] === values[index]) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const addNewChange = (values, checkStatus, name) => {
    let arr = [...docList];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setDocList(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    let arr = [...docList];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(nominee, arr);
        setShareholderChecked(true);
        setNomineeChecked(false);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }
    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(shareholders, arr);
        setNomineeChecked(true);
        setShareholderChecked(false);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }

    setDocList(arr);
  };
  return (
    <div
      className="col-md-11 form-bordered position-relative my-4"
      id={`individual${index}`}
    >
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4 mb-4">
        <h6 className="sub-title mb-0">Individual {showCount(index)}</h6>
        <Checkbox
          className="custom-checkbox"
          checked={directorChecked && !reserveChecked}
          onChange={(e) => addNewRow(directors, e.target.checked, "directors")}
        >
          Directors
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          checked={shareholderChecked && !nomineeChecked}
          onChange={(e) =>
            addNewRow(shareholders, e.target.checked, "shareholders")
          }
        >
          Shareholders
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) => addNewChange(ubos, e.target.checked, "ubos")}
        >
          UBOs
        </Checkbox>
        {/* <Checkbox
          className="custom-checkbox"
          onChange={(e) => addNewChange(poc, e.target.checked)}
        >
          POC
        </Checkbox> */}
        <Checkbox
          className="custom-checkbox"
          checked={nomineeChecked && !shareholderChecked}
          onChange={(e) => addNewRow(nominee, e.target.checked, "nominee")}
        >
          Nominee Shareholder
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          checked={reserveChecked && !directorChecked}
          onChange={(e) => addNewRow(directors, e.target.checked, "reserve")}
        >
          Reserve Director
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) =>
            addNewChange(president, e.target.checked, "president")
          }
        >
          President
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) =>
            addNewChange(secretary, e.target.checked, "secretary")
          }
        >
          Secretary
        </Checkbox>
        <button className="remove-btn" onClick={() => remove(element)}>
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 custom-form">
          <label htmlFor="name">Individual Name</label>
          <input
            className="w-100"
            type="text"
            placeholder="Enter individual name"
            name="name"
            id="name"
            value={data?.name}
            onChange={(e) =>
              getName(
                e.target.value,
                "individual",
                `individual_${showCount(index)}`
              )
            }
          />
        </div>
      </div>
      <div className="row custom-form gy-2 gx-4">
        <Each
          array={docList}
          render={(item) => (
            <div className="col-md-4">
              <label htmlFor={item} className="me-3">
                {item}
              </label>
              <UploadZ
                onStoreFile={storeDocuments}
                fileName={item}
                onDeleteFile={deleteDocument}
                clearUpload={clearUpload}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}
