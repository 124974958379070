import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../../assets/img/logo.png";

export default function Index() {
  const { isLogged, userDetails } = useSelector((state) => state.auth);
  let location = useLocation();

  let from = location.state?.from?.pathname || null;

  if (isLogged) {
    if (from) {
      return <Navigate to={from} replace />;
    } else {
      return <Navigate to="/app/home" replace />;
    }
  }

  return (
    <div className="auth-layout">
      <span>&nbsp;</span>
      <div className="auth-card">
        <img className="logo" src={Logo} alt="Galactic - NorthLark" />
        <Outlet />
      </div>
      <p className="auth-copyright">
        NorthLark © Galactic 2025. <a href="https://northlark.com/privacy-policy/">Privacy Policy</a>
      </p>
    </div>
  );
}
