import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";
import { useSelector } from "react-redux";
import { useGeneralEmail } from "../hooks/useGeneralEmail";
import ServiceRequestEmailSender from "../components/ServiceRequestEmaiSendButtons";
import UploadZ from "../components/UploadZ";
import RequestResubmission from "../components/RequestResubmission";
import RejectTask from "../components/RejectTask";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";

export default function AlterationToMAA() {
  let query = getQuery();
  const [docs, setDocs] = useState(document);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        let data = res?.data?.rows?.[0];
        setData(data);
        setDocs(data?.documents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const getRejectDocs = (checked, docId) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc._id === docId);
    if (checked) {
      arr[index]["rejected"] = true;
    } else {
      delete arr[index]["rejected"];
    }
    setDocs(arr);
  };

  const onChangeApproval = (file, bool) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index].isApproved = bool;
    setDocs(arr);
  };

  const { isSendingEmail } = useGeneralEmail();
  const { userDetails: businessPartnerDetails } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSendingEmail) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSendingEmail]);
  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Alteration to the
            Memorandum & Articles of Association
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <RequestResubmission id={query?.id} documents={docs} />
          {/* target="_blank" */}
          <Link
            to={`/app/entity-overview/list?id=${data?.entityId}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          <ServiceRequestEmailSender
            serviceRequestName="AMAA"
            serviceRequestData={data}
            businessPartnerDetails={businessPartnerDetails}
          />
          <RejectTask id={query?.id} reason={data?.rejectReason} />
          {/* <button className="primary-btn" disabled={true}>
          <Link className="outline-btn" to={"/app/mail"}>
            Check Mailbox
          </Link>
          <button className="primary-btn" disabled={true}>
            Send to Client
          </button> */}
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Type of Alteration</label>
              <div className="d-flex gap-2">
                <Each
                  array={data?.types}
                  render={(type, i) => {
                    return (
                      <span key={i} className="light-badge">
                        {type}
                      </span>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row gy-2 gx-4">
            <Each
              array={data?.documents}
              render={(doc, index) => (
                <div className="col-md-3" key={index}>
                  <Checkbox
                    className="custom-checkbox"
                    onChange={(e) => getRejectDocs(e.target.checked, doc?._id)}
                  >
                    {doc?.document}
                  </Checkbox>
                  <UploadZ
                    file={doc}
                    downloadOnly={true}
                    showApprove={true}
                    onFileApprove={onChangeApproval}
                    showTranslation={true}
                    showExtraction={true}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
