import React, { useState, useEffect, useRef } from "react";
import { Table, Tag, Select, Modal, DatePicker, Checkbox } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import FormZ from "../../components/FormZ";
import { Each, getOptions, sendNotify, fetchApi, downloadAsCSV, isArrayEmpty } from "../../helper";

export default function FeeScheduleCreate() {
  const [editMode, setEditMode] = useState(false);
  const [clientArray, setClientArray] = useState([]);

  useEffect(() => {
    getClient()
  }, []);
  const getClient = async () => {
    let payload = {
      method: "GET",
      url: `/client?_start=0&_limit=1000&types=endUser%2CIntermediary`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res?.data?.rows
    });
    setClientArray(data)
  }
  const [checkedList, setCheckedList] = useState({
    standard: {
      checked: false,
      child: { up: false, above: false },
    },
    discounted: {
      checked: false,
      child: { up: false, above: false },
    },
  });

  const searchFormRef = useRef();

  let searchFormSchema = [
    {
      name: "clientCode",
      value: "",
      type: "select",
      label: "End User Client/Intermediary Client",
      placeholder: "Select end user client",
      showSearch: true,
      options: getOptions(clientArray)
    },
    {
      name: "feeScheduleName",
      value: "",
      type: "text",
      label: "Fee Schedule Name",
      placeholder: "Select schedule name",
    },
    {
      name: "paymentCategory",
      value: "",
      type: "select",
      label: "Payment Category",
      placeholder: "Select payment category",
      options: [
        {
          value: "prePaid",
          label: "Pre-paid",
        },
        {
          value: "postPaid",
          label: "Post-paid",
        },
      ],
    },
  ];

  const [shares, setShares] = useState([
    {
      task: "Incorporation of a company",
      type:"Pre-incrop",
      code:"0001",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Certificate of Incumbency",
      type:"Pre-incrop",
      code:"0002",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Good Standing",
      type:"Pre-incrop",
      code:"0003",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Change of Director",
      type:"Pre-incrop",
      code:"0004",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Change of Shareholders",
      type:"Pre-incrop",
      code:"0005",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Alteration to M&A",
      type:"Pre-incrop",
      code:"0006",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "M&A Name Change",
      type:"Pre-incrop",
      code:"0007",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Notarization of a documents",
      type:"Pre-incrop",
      code:"0008",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Apostille of a document",
      type:"Pre-incrop",
      code:"0009",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Drafting Resolution",
      type:"Pre-incrop",
      code:"0010",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Request to provide Corporate documents certified",
      type:"Pre-incrop",
      code:"0011",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    },
    {
      task: "Resignation of a company",
      type:"Pre-incrop",
      code:"0012",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
    }
  ]);

  const getShareChange = (index, key, value) => {
    // console.log(key);
    let arr = [...shares];
    arr[index][key] = value;
    setShares(arr);
  };

  const getChecked = (checked, key, child) => {
    let obj = { ...checkedList };
    if (child) {
      obj[key]["child"][child] = checked;
    } else {
      obj[key]["checked"] = checked;
      if (!checked) {
        obj[key]["child"]["up"] = false;
        obj[key]["child"]["above"] = false;
      }
    }
    setCheckedList(obj);
  };

  const columns = [
    {
      title: "Request Type",
      dataIndex: "reqType",
      sorter: (a, b) => a.reqType - b.reqType,
      width: 130,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
      width: 160,
    },
    {
      title: "Service Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
      width: 160,
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["standard"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "standard")}
        >
          Standard Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standard"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standard"]["child"]["up"]}
                onChange={(e) => getChecked(e.target.checked, "standard", "up")}
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardUpTo",
          key: "standardUpTo",
          render: (_, { id, standardUpTo }) => (
            <div className="custom-form">
              {checkedList["standard"]["child"]["up"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].standardUpTo}
                  onChange={(e) =>
                    getShareChange(id, "standardUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standard"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standard"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "standard", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardAbove",
          key: "standardAbove",
          render: (_, { id, standardAbove }) => (
            <div className="custom-form">
              {checkedList["standard"]["child"]["above"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].standardAbove}
                  onChange={(e) =>
                    getShareChange(id, "standardAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["discounted"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "discounted")}
        >
          Discounted Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["discounted"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["discounted"]["child"]["up"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "discounted", "up")
                }
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "discountedUpTo",
          key: "discountedUpTo",
          render: (_, { id, discountedUpTo }) => (
            <div className="custom-form">
              {checkedList["discounted"]["child"]["up"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].discountedUpTo}
                  onChange={(e) =>
                    getShareChange(id, "discountedUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["discounted"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["discounted"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "discounted", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "discountedAbove",
          key: "discountedAbove",
          render: (_, { id, discountedAbove }) => (
            <div className="custom-form">
              {checkedList["discounted"]["child"]["above"] ? (
                <input
                  type="number"
                  className="w-75"
                  value={shares[id].discountedAbove}
                  onChange={(e) =>
                    getShareChange(id, "discountedAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
        },
      ],
    },
  ];

  const data = [
    {
      key: "1",
      id: 0,
      reqType: "Pre-incrop",
      task: "Incorporation of a company",
      serviceCode: "0001",
      ...shares[0],
    },
    {
      key: "2",
      id: 1,
      reqType: "Post-incrop",
      task: "Certificate of Incumbency",
      serviceCode: "0002",
      ...shares[1],
    },
    {
      key: "3",
      id: 2,
      reqType: "Post-incrop",
      task: "Good Standing",
      serviceCode: "0003",
      ...shares[2],
    },
    {
      key: "4",
      id: 3,
      reqType: "Post-incrop",
      task: "Change of Director",
      serviceCode: "0004",
      ...shares[3],
    },
    {
      key: "5",
      id: 4,
      reqType: "Post-incrop",
      task: "Change of Shareholders",
      serviceCode: "0005",
      ...shares[4],
    },
    {
      key: "6",
      id: 5,
      reqType: "Post-incrop",
      task: "Alteration to M&A",
      serviceCode: "0006",
      ...shares[5],
    },
    {
      key: "7",
      id: 6,
      reqType: "Post-incrop",
      task: "M&A Name Change",
      serviceCode: "0007",
      ...shares[6],
    },
    {
      key: "8",
      id: 7,
      reqType: "Post-incrop",
      task: "Notarization of a documents",
      serviceCode: "0008",
      ...shares[7],
    },
    {
      key: "9",
      id: 8,
      reqType: "Post-incrop",
      task: "Apostille of a document",
      serviceCode: "0009",
      ...shares[8],
    },
    {
      key: "10",
      id: 9,
      reqType: "Post-incrop",
      task: "Drafting Resolution",
      serviceCode: "0010",
      ...shares[9],
    },
    {
      key: "11",
      id: 10,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "0011",
      ...shares[10],
    },
    {
      key: "12",
      id: 11,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "0012",
      ...shares[11],
    },
  ];

  const onSubmit = () => {
    if (searchFormRef.current.validFrom()) {
      let options = searchFormRef.current.getPayload();
     
      let obj = {
        clientCode:options.clientCode,
        name:options.feeScheduleName,
        requestType:options.paymentCategory,
        fee:shares,
        standard:checkedList.standard,
        discounted:checkedList.discounted
      };
    console.log(obj,"aestydtfyguhijo")
      let payload = {
        method: "POST",
        url: `/feeCategory`,
        data: obj,
      };
      let url = `/app/pre-incorp-resolution`

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message ? res?.message : "success");
        })
        .catch((error) => {
          sendNotify("error", error?.message);

        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-0">Create Fee Schedule</h1>
        <Link to={"/app/fee-schedule"} className="primary-btn">
          <i className="fal fa-backward"></i> Back to list
        </Link>
      </div>
      <FormZ
        formClass="row custom-form mb-2 mb-md-5 align-items-end gy-3"
        childClass="col-md-2 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
        formSchema={searchFormSchema}
        ref={searchFormRef}
      />
      <div className="table-box">
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          scroll={{
            y: 240,
          }}
          pagination={false}
        />
        <div className="d-flex justify-content-end align-items-center mt-md-4">
          <button
            className="primary-btn px-4"
            onClick={() => onSubmit()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
