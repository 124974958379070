import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";
import RejectTask from "../components/RejectTask";

import { Each, fetchApi, getQuery } from "../helper";
import { useSelector } from "react-redux";
import { useGeneralEmail } from "../hooks/useGeneralEmail";
import ServiceRequestEmailSender from "../components/ServiceRequestEmaiSendButtons";

export default function ResignationRegisteredAgent() {
  const [docs, setDocs] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  let query = getQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  function getData() {
    setLoading(true);
    let payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload).then((res) => {
      setData(res?.data?.rows?.[0]);
      setDocs(res?.data?.rows?.[0]?.documents);
      setLoading(false);
    });
  }

  const { isSendingEmail } = useGeneralEmail();
  const { userDetails: businessPartnerDetails } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSendingEmail) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSendingEmail]);
  const postIncorpType = [
    {
      value: "COI",
      label: "Certificate of Incumbency (COI)",
    },
    {
      value: "COGS",
      label: "Certificate of Good Standing (COGS)",
    },
    {
      value: "ROD",
      label: "Register of Directors",
    },
    {
      value: "SR",
      label: "Share Registry",
    },
    {
      value: "COD",
      label: "Change Of Director (COD)",
    },
    {
      value: "COSH",
      label: "Change of Shareholder (COSH)",
    },
    {
      value: "COA",
      label: "Change of Client / Administration (COA)",
    },
    {
      value: "COC",
      label: "Change of Contact Point (COC)",
    },
    {
      value: "TO",
      label: "Transfer Out (TO)",
    },
    {
      value: "CO",
      label: "Continuation Out (CO)",
    },
    {
      value: "AMAA",
      label: "Alteration to the Memorandum & Articles of Association",
    },
    {
      value: "MNC",
      label: "M&AA Name Change",
    },
    {
      value: "NOD",
      label: "Notarization of Documents",
    },
    {
      value: "AOD",
      label: "Apostille of Documents",
    },
    {
      value: "ARMA",
      label: "Amended and Restated Memorandum & Articles",
    },
    { value: "Apostilles", label: "Apostilles" },
    {
      value: "CGS",
      label: "Certificate of Good Standing",
    },
    {
      value: "RCAD",
      label: "Registry Certified Copy of Any Document",
    },
    {
      value: "RUD",
      label: "Registry Uncertified or Duplicate Copy of Any Document",
    },
    {
      value: "RPEC",
      label: "Registry Physical Copy of any Electronic Certificate",
    },
    { value: "CI", label: "Certificate of Incumbency" },
    { value: "CD", label: "Corporate Documents (Internal Certification)" },
    {
      value: "CCPD",
      label: "CCP Certified or Duplicate copies of documents",
    },
    {
      value: "ARD",
      label: "Amendments to Register of Directors",
    },
    {
      value: "ARM",
      label:
        "Amendments to Register of Members & Issuing New Share Certificate(s)",
    },
    { value: "Continuations", label: "Continuations" },
    { value: "DR", label: "Drafting Resolutions" },
    {
      value: "FRMC",
      label: "Filing Register of Mortgage & Charges",
    },
    {
      value: "FAR",
      label: "Filing Appointment of RO/RA",
    },
    { value: "FTO", label: "Filing Transfer Out" },
    { value: "IA", label: "Indemnity Agreements" },
    { value: "Notarizations", label: "Notarizations" },
    { value: "PA", label: "Powers of Attorney" },
    {
      value: "RSC",
      label: "Reactivation of struckoff companies",
    },
    {
      value: "RNR",
      label: "Registration of Notice of Resignation as Registered Agent",
    },
    {
      value: "FRRA",
      label: "Filing notice of Recission as Registered Agent",
    },
    {
      value: "RFC",
      label: "Registration as a Foreign Company in the BVI",
    },
    {
      value: "FNFC",
      label:
        "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
    },
    {
      value: "EFF",
      label: "Expedite Fee for Fast Track Due Diligence and Incorporation",
    },
    {
      value: "SL",
      label: "Solvent Liquidation",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const taskType = new Map(postIncorpType.map((a) => [a.value, a.label]));
  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i>
            {taskType.get(data?.serviceType)}
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <Link
            to={`/app/entity-overview/list?id=${data?.entityId}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          <RejectTask id={query?.id} reason={data?.rejectReason} />
          <Link className="outline-btn" to={"/app/mail"}>
            Check Mailbox
          </Link>
          <ServiceRequestEmailSender
            serviceRequestName="NOD"
            serviceRequestData={data}
            businessPartnerDetails={businessPartnerDetails}
          />
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <div className="page-content">
        <Spin spinning={loading}>
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
          </div>
          <h2 className="form-title mb-4">Documents</h2>
          <div className="row gy-2 gx-4">
            <Each
              array={docs}
              render={(item, index) => (
                <div className="col-md-3">
                  <div>
                    <UploadZ file={item} />
                  </div>
                </div>
              )}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
}
