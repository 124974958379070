import React, { useState, useRef } from "react";
import { Checkbox } from "antd";

import UploadZ from "../../../components/UploadZ";
import FormZ from "../../../components/FormZ";

import { Each, focusOn, sendNotify, showCount } from "../../../helper";

export default function Individual(props) {
  let { getName, getDocument, clearUpload } = props;

  const [individuals, setIndividuals] = useState([1]);

  const addNewIndividual = () => {
    let arr = [...individuals];
    if (arr.length < 10) {
      arr.push(individuals.length + 1);
      setIndividuals(arr);
      focusOn("individual" + (arr.length - 1));
    } else {
      sendNotify("error", "Your reach the maximum level of individual");
    }
  };

  return (
    <div>
      <Each
        array={individuals}
        render={(individual, index) => (
          <IndividualDocuments
            key={index}
            index={individual}
            getName={getName}
            getDocument={getDocument}
            clearUpload={clearUpload}
          />
        )}
      />
      <button className="primary-btn mt-4" onClick={addNewIndividual}>
        <i className="fal fa-plus"></i> Add New Individual
      </button>
    </div>
  );
}

function IndividualDocuments(props) {
  let { index, getName, getDocument, clearUpload } = props;

  const formRef = useRef();

  let formSchema = [
    {
      name: "name",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name of individual",
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address of individual",
    },
    {
      name: "Contact Number",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number of individual",
    },
  ];

  const [documents, setDocuments] = useState([]);
  const [docList, setDocList] = useState(["Passport / ID", "Address Proof"]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [formToggle, setFormToggle] = useState(true);
  const [clearDocuments, setClearDocuments] = useState(false);
  const [upload, setUpload] = useState([]);
  const [customUpload, setCustomUpload] = useState({ others: [] });
  const [selectedFiles, setSelectedFiles] = useState([]);

  function storeDocuments(file) {
    let doc = [...documents, ...customUpload.others];
    let arrIndex = doc.findIndex((d) => d.document == file.document);
    if (arrIndex != -1) {
      doc[arrIndex] = file;
    } else {
      doc.push(file);
    }
    setDocuments(doc);
    getDocument(doc, "individual", `individual_${showCount(index)}`);
  }
  function storeDocumentMany(file) {
    let doc = { ...customUpload };
    doc["others"].push(file);
    setDocuments(doc);
    getDocument(doc, "individual", `individual_${showCount(index)}`);
  }
  const deleteDocument = (name) => {
    let doc = [...documents];
    let arrIndex = doc.findIndex((d) => d.document == name);
    if (arrIndex != -1) {
      doc.splice(arrIndex, 1);
    }
    setDocuments(doc);
    getDocument(doc, "individual", `individual_${showCount(index)}`);
  };
  const getLabelName = (value, index) => {
    let arr = [...upload];
    arr[index] = value;
    setUpload(arr);
  };
  const addNewUpload = () => {
    const newArr = [...upload];
    let newName = `Enter document name ${newArr.length + 1}`;
    const nameExists = newArr.includes(newName);

    if (nameExists) {
      let count = 1;
      let uniq = `Enter document name ${count}`;

      while (newArr.includes(uniq)) {
        count++;
        uniq = `Enter document name ${count}`;
      }

      newName = uniq;
    }

    newArr.push(newName);

    setUpload(newArr);
  };
  function removeDocument(docName) {
    if (docName) {
      let allDoc = { ...customUpload };
      let doc = allDoc["others"];
      let index = doc?.findIndex((d) => d?.document == docName);
      if (index != -1) {
        doc.splice(index, 1);
        allDoc["others"] = doc;
        setCustomUpload(allDoc);
      }
    }
  }
  const directors = [
    "Consent to Act as Director",
    "Resolution of Appointment",
    "Letter of Resignation",
    "Resolution of Resignation",
    // "Register Of Directors",
  ];
  const secretary = [
    "Secretary Consent to Act",
    "Secretary Resolution of Appointment",
    "Secretary Resolution of Resignation",
    "Secretary Letter of Resignation",
  ];
  const president = [
    "President Consent to Act",
    "President Resolution of Appointment",
    "President Resolution of Resignation",
    "President Letter of Resignation",
  ];
  const shareholders = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
    "Share Transfer Agreement",
  ];

  const ubos = [
    "UBO source of funds document",
    "Source of Wealth Proof",
    "Resolution of Shareholding",
  ];

  const poc = ["Contact Details"];

  const nominee = ["Nominee Agreement"];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i] === values[index]) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const addNewChange = (values, checkStatus, name) => {
    let arr = [...docList];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setDocList(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    let arr = [...docList];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(nominee, arr);
        setShareholderChecked(true);
        setNomineeChecked(false);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }
    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(shareholders, arr);
        setNomineeChecked(true);
        setShareholderChecked(false);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }

    setDocList(arr);
  };

  return (
    <div
      className={`custom-box mb-4 collapse-form ${!formToggle ? "closed" : ""}`}
    >
      <div className="d-flex justify-content-between">
        <span className="light-badge">
          <i className="fal fa-user-circle"></i> Individual {showCount(index)}
        </span>
        <button
          className="collapse-arrow"
          onClick={() => setFormToggle(!formToggle)}
        >
          <i
            className={`fal fa-chevron-up ${
              !formToggle ? "fa-rotate-180" : ""
            }`}
          ></i>
        </button>
      </div>
      <div className="form-content">
        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4 my-4">
          <Checkbox
            className="custom-checkbox"
            checked={directorChecked && !reserveChecked}
            onChange={(e) =>
              addNewRow(directors, e.target.checked, "directors")
            }
          >
            Directors
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            checked={shareholderChecked && !nomineeChecked}
            onChange={(e) =>
              addNewRow(shareholders, e.target.checked, "shareholders")
            }
          >
            Shareholders
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            onChange={(e) => addNewChange(ubos, e.target.checked)}
          >
            UBOs
          </Checkbox>
          {/* <Checkbox
            className="custom-checkbox"
            onChange={(e) => addNewChange(poc, e.target.checked)}
          >
            POC
          </Checkbox> */}
          <Checkbox
            className="custom-checkbox"
            checked={nomineeChecked && !shareholderChecked}
            onChange={(e) => addNewRow(nominee, e.target.checked, "nominee")}
          >
            Nominee Shareholder
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            checked={reserveChecked && !directorChecked}
            onChange={(e) => addNewRow(directors, e.target.checked, "reserve")}
          >
            Reserve Director
          </Checkbox>

          <Checkbox
            className="custom-checkbox"
            onChange={(e) =>
              addNewChange(president, e.target.checked, "president")
            }
          >
            President
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            onChange={(e) =>
              addNewChange(secretary, e.target.checked, "secretary")
            }
          >
            Secretary
          </Checkbox>
        </div>
        <FormZ
          ref={formRef}
          formSchema={formSchema}
          formClass="custom-form row mb-4 gy-3"
          childClass="col-md-4 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
        <div className="row custom-form gy-2 gx-4">
          <Each
            array={docList}
            render={(item) => (
              <div className="col-md-3  ">
                <label htmlFor={item} className="me-3">
                  {item}
                </label>
                <UploadZ
                  onStoreFile={storeDocuments}
                  fileName={item}
                  onDeleteFile={deleteDocument}
                  clearUpload={clearUpload}
                />
              </div>
            )}
          />
          <Each
            array={
              customUpload["others"].length !== 0
                ? customUpload["others"]
                : upload
            }
            render={(item, i) => (
              <div className="col-md-4">
                <div className="position-relative">
                  <div className="d-flex justify-content-between align-items-center mb-1px">
                    <label
                      contentEditable={true}
                      key={i}
                      id={i}
                      onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                      className="mb-0"
                      suppressContentEditableWarning={true}
                    >
                      {item?.document ?? "Select files to upload"}
                    </label>
                    <button
                      className="del-upload-btn"
                      onClick={() => removeDocument(item?.document)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </button>
                  </div>
                  <UploadZ
                    onStoreFile={storeDocumentMany}
                    onDeleteFile={removeDocument}
                    clearUpload={clearDocuments}
                    file={customUpload["others"].find(
                      (d) => d.document == item?.document
                    )}
                    isMultiple={true}
                    fileName={item}
                    setSelectedFiles={setSelectedFiles}
                  />
                </div>
              </div>
            )}
          />
          <div className="col-md-4 mb-4">
            <label htmlFor="">&nbsp;</label>
            <div className="add-new-upload" onClick={addNewUpload}>
              <span className="icon">
                <i className="fal fa-plus-circle"></i>
              </span>
              <h6>Add New Upload</h6>
              {/* <p>Click to create new upload field</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
