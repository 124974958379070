import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

//Action
export const getCorporateEntity = createAsyncThunk(
  "getCorporateEntity",
  async (params) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/cop-entity?masterType=entity&${query}`,
    };

    let res = await fetchApi(payload).then((res) => {
      return res.data;
    });
    return res;
  }
);

const incorpSlice = createSlice({
  name: "corporateEntity",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getCorporateEntity.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getCorporateEntity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getCorporateEntity.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default incorpSlice.reducer;
