import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import countrySlice from "./country/countrySlice";
import incorpSlice from "./incorporation/incorpSlice";
import preIncorpSlice from "./preIncorpRequests/preIncorpSlice";
import postIncorpSlice from "./postIncorpRequests/postIncorpSlice";
import notificationsSlice from "./notifications/notificationsSlice";
import newNotificationsSlice from "./newNotifications/newNotificationsSlice";
import clientSlice from "./client/clientSlice";
import parentClientSlice from "./parentClient/parentClientSlice";
import endUserClientSlice from "./endUserClient/endUserClientSlice";
import entitySlice from "./entity/entitySlice";
import corporateEntitySlice from "./corporateEntity/corporateEntitySlice";
import individualSlice from "./individual/individualSlice";
import introducerClientSlice from "./introducerClient/introducerClientSlice";
import masterCodeSlice from "./masterCode/masterCodeSlice";


export const store = configureStore({
  reducer: {
    auth: authSlice,
    country: countrySlice,
    incorp: incorpSlice,
    preIncorp: preIncorpSlice,
    postIncorp: postIncorpSlice,
    notification: notificationsSlice,
    newNotification: newNotificationsSlice,
    client: clientSlice,
    parentClient: parentClientSlice,
    endUserClient: endUserClientSlice,
    entity: entitySlice,
    corporateEntity: corporateEntitySlice,
    individual:individualSlice,
    introducerClient:introducerClientSlice,
    masterCode:masterCodeSlice
  },
});
