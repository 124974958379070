import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import {
  Each,
  getQuery,
  fetchApi,
  sendNotify,
  postIncorpType,
} from "../helper";

import RejectTask from "../components/RejectTask";

import { useSelector } from "react-redux";
import { useGeneralEmail } from "../hooks/useGeneralEmail";
import ServiceRequestEmailSender from "../components/ServiceRequestEmaiSendButtons";

export default function NotarizationOfDocuments() {
  let query = getQuery();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        setData(res?.data?.rows?.[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const getDocName = (code) => {
    let name = postIncorpType.find((type) => type.value == code)?.label;
    return name ?? "N/A";
  };

  const { isSendingEmail } = useGeneralEmail();
  const { userDetails: businessPartnerDetails } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSendingEmail) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSendingEmail]);

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Corporate Documents
          </h1>
        </Link>
        <div className="d-flex gap-3 align-items-center">
          <Link
            to={`/app/entity-overview/list?id=${data?.entityArray?._id}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          <ServiceRequestEmailSender
            serviceRequestName="Corporate Documents"
            serviceRequestData={data}
            businessPartnerDetails={businessPartnerDetails}
          />
          <RejectTask id={query?.id} reason={data?.rejectReason} />
          <button className="primary-btn">Send for Review</button>
          {/* <Link className="outline-btn" to={"/app/mail"}>
            Check Mailbox
          </Link> */}
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Documents to be Notarized</label>
              <div className="d-flex gap-2 flex-wrap">
                <Each
                  array={data?.documentType}
                  render={(docType, index) => (
                    <span key={index} className="light-badge">
                      {getDocName(docType)}
                    </span>
                  )}
                />
              </div>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Receive as</label>
              <span className="light-badge">
                {data?.receiveAs == "hard-copy"
                  ? "Hard copy with physical courier"
                  : "Soft copy"}
              </span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Address</label>
              <span className="light-badge">{data?.address}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Special Notes</label>
              <span className="light-badge">{data?.note}</span>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
