import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, DatePicker, Modal, Table, Button } from "antd";
import socketIOClient from "socket.io-client";
import Jurisdiction from "../components/Jurisdiction";
import FormZ from "../components/FormZ";
import { getEntity } from "../store/entity/entitySlice";
import { getIndividual } from "../store/individual/individualSlice";
import moment from "moment/moment";
import html2pdf from "html2pdf.js";

import {
  sendNotify,
  getOptions,
  fetchApi,
  generateRandom,
  getQuery,
} from "../helper";

import SampleDoc from "../assets/pdf/sample.pdf";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function IndividualAnalysis(props) {
  let {
    actionShow,
    fileCreation,
    setIndividualClicked,
    setselectedPage,
    individualClicked,
    refCode,
  } = props;
  const dispatch = useDispatch();

  const { data, isLoading } = useSelector((state) => state.country);
  const entity = useSelector((state) => state.entity);
  const individual = useSelector((state) => state.individual);
  const [schema1, setSchema1] = useState({});
  const [schema2, setSchema2] = useState({});
  const [schema3, setSchema3] = useState({});

  const [masterId, setMasterId] = useState({});

  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");
  const [openHistory, setOpenHistory] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [openRes, setOpenRes] = useState(false);
  const [nameCheck, setNameCheck] = useState("");
  const formRef = useRef();
  const adsFormRef = useRef();
  const riskFormRef = useRef();

  useEffect(() => {
    dispatch(getEntity({ _start: 0, _limit: 100 }));
    dispatch(getIndividual({ _start: 0, _limit: 100 }));
    setSanction(fileCreation ? "N" : "Y");
    setPep(fileCreation ? "N" : "Y");
    updateMastercode();
    configureSocket();
  }, []);
  useEffect(() => {}, [entity, individual]);
  const handleChange = (value) => {
    setSanction(value);
  };
  const codeOnChange = async (value) => {
    if (!setselectedPage) {
      let { code } = value;
      let payload = {
        method: "GET",
        url: `/individual?_start=0&_limit=1&code=${code}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.rows[0];
      });
      let val,
        val2,
        val3 = {};
      if (data) {
        console.log(data, "rxdryfguhioj");
        val = {
          _id: data._id,
          code: data.code,
          title: data.title,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          DOB: data.DOB,
          COB: data.COB,
          nationality: data.nationality,
          passportNo: data.passportNo,
          passportIssue: data.passportIssue,
          passportExp: data.passportExp,
          idNo: data.idNo,
          idIssue: data.idIssue,
          idExp: data.idExp,
          taxInfo: data.taxInfo,
          associateBus: data.associateBus,
          relatedEntities: null,
          contactNumber: data.contactNumber,
          email: data.email,
          createdAt: data.createdAt,
        };
        val2 = {
          residentialAddress: data.residentialAddress,
          serviceAddress: data.serviceAddress,
          line_1: data.details.address.line_1,
          line_2: data.details.address.line_2,
          line_3: data.details.address.line_3,
          zipCode: data.details.address.zipCode,
          country: data.details.address.country,
        };
        val3 = {
          riskRating: data.details.riskRating,
          pep: data.details.pep,
          sanction: data.details.sanction,
        };
      }
      setSchema1(val);
      setSchema2(val2);
      setSchema3(val3);
    }
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  const configureSocket = () => {
    // socket.on("connection", () => {
    //   console.log(" Connected backend");
    // });
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode();
      }
    });
  };

  const updateMastercode = async () => {
    if (setselectedPage) {
      let payload = {
        method: "GET",
        url: `/admin/masterCode?name=individual`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res?.data?.data;
      });
      let originalString = data?.seq;
      let numericPart = originalString?.match(/\d+$/)[0];
      let incrementedNumeric = parseInt(numericPart, 10) + 1;
      let newNumericPart = String(incrementedNumeric).padStart(
        numericPart?.length,
        "0"
      );
      let newString = originalString?.replace(/\d+$/, newNumericPart);
      console.log(newString);
      setMasterId(newString);
      setSchema1({ code: newString ? newString : "INV00001" });
    }
  };
  console.log(individual, "individual-1");
  let formSchema = [
    {
      name: "code",
      value: "",
      type: "select",
      label: "Individual Code",
      placeholder: "Enter individual code",
      disabled: setselectedPage ? true : false,
      options: getOptions(individual?.data?.rows),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Title",
      placeholder: "Enter title",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    // {
    //   name: "individualRole",
    //   value: "",
    //   type: "select",
    //   label: "Individual Role",
    //   placeholder: "Enter client type",
    //   inputClass: "ptb-1",
    //   options: [
    //     {
    //       value: "director",
    //       label: "Director",
    //     },
    //     {
    //       value: "shareholder",
    //       label: "Shareholder",
    //     },
    //     {
    //       value: "ubo",
    //       label: "UBO",
    //     },
    //     {
    //       value: "poc",
    //       label: "POC",
    //     },
    //     {
    //       value: "trustee",
    //       label: "Trustee",
    //     },
    //     {
    //       value: "settlor",
    //       label: "Settlor",
    //     },
    //     {
    //       value: "beneficiary",
    //       label: "Beneficiary",
    //     },
    //     {
    //       value: "frHolder",
    //       label: "FR Holder",
    //     },
    //     {
    //       value: "reserveDirector",
    //       label: "Reserve Director",
    //     },
    //     {
    //       value: "nomineeShareholder",
    //       label: "Nominee Shareholder",
    //     },
    //   ],
    //   mode: "multiple",
    //   showSearch: true,
    //   allowClear: true,
    // },
    {
      customElement: <div className="col-md-4"></div>,
    },
    {
      name: "firstName",
      value: "",
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
    },
    {
      name: "middleName",
      value: "",
      type: "text",
      label: "Middle Name",
      placeholder: "Enter Middle name",
    },
    {
      name: "lastName",
      value: "",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
    },
    {
      name: "DOB",
      value: "",
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (DD/MM/YYYY)",
      // format: "DD/MM/YYYY",
    },
    {
      name: "COB",
      value: "",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "nationality",
      value: "",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "passportNo",
      value: "",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
    },
    {
      name: "passportIssue",
      value: "",
      type: "date",
      label: "Issue Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      //   format: "DD/MM/YYYY",
    },
    {
      name: "passportExp",
      value: "",
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      //    format: "DD/MM/YYYY",
    },
    {
      name: "idNo",
      value: "",
      type: "text",
      label: "ID Number",
      placeholder: "Enter id number",
    },
    {
      name: "idIssue",
      value: "",
      type: "date",
      label: "Issue Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      //  format: "DD/MM/YYYY",
    },
    {
      name: "idExp",
      value: "",
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      // format: "DD/MM/YYYY",
    },
    {
      name: "taxInfo",
      value: "",
      type: "text",
      label: "Tax Info Number",
      placeholder: "Enter tax info number",
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
    },
    {
      name: "contactNumber",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number",
      customClass: "hide-arrows",
    },
    {
      name: "associateBus",
      value: "",
      type: "text",
      label: "Associate business",
      placeholder: "Enter associate business",
    },
    {
      name: "relatedEntities",
      value: "",
      type: "text",
      label: "Related Entities",
      placeholder: "Enter related entities",
      disabled: true,
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of Creation",
      placeholder: "Select date",
    },
    // {
    //   name: "riskRating",
    //   value: "low",
    //   type: "select",
    //   label: "Risk Rating",
    //   placeholder: "Select risk level",
    //   options: [
    //     {
    //       value: "low",
    //       label: "Low",
    //     },
    //     {
    //       value: "medium",
    //       label: "Medium",
    //     },
    //     {
    //       value: "high",
    //       label: "High",
    //     },
    //   ],
    // },
  ];

  let adsFormSchema = [
    {
      name: "serviceAddress",
      value: "",
      type: "textarea",
      label: "Service Address",
      placeholder: "Enter service address",
    },
    {
      name: "residentialAddress",
      value: "",
      type: "textarea",
      label: "Residential Address",
      placeholder: "Enter residential address",
    },
    // {
    //   label: "",
    //   customElement: <></>,
    // },
    // {
    //   name: "country",
    //   value: "",
    //   type: "select",
    //   label: "Country",
    //   placeholder: "Select country",
    //   options: getOptions(data),
    //   loading: isLoading,
    //   showSearch: true,
    //   labelClass: "mb-1",
    //   customClass: "col-md-3 flex-column align-items-start",
    // },
    // {
    //   name: "zipCode",
    //   value: "",
    //   type: "number",
    //   label: "Post/Zip code",
    //   labelClass: "mb-1",
    //   placeholder: "Enter post/zip code",
    //   customClass: "col-md-3 flex-column align-items-start",
    // },
  ];

  let riskFormSchema = [
    {
      name: "riskRating",
      value: "low",
      type: "select",
      label: "Risk Rating",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      // name: "pep",
      // value: "",
      // type: "select",
      // label: "PEP",
      // placeholder: "Enter pep",
      // options: [
      //   {
      //     value: "Y",
      //     label: "Yes",
      //   },
      //   {
      //     value: "N",
      //     label: "No",
      //   },
      // ],
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            disabled={fileCreation}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input type="text" className="w-100" placeholder="Enter pep" />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={fileCreation}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input type="text" className="w-100" placeholder="Enter sanction" />
          )}
        </div>
      ),
    },
    {
      customClass: "col-lg-2",
      customElement: fileCreation ? (
        <div></div>
      ) : (
        <div className="d-flex gap-4">
          <button className="primary-btn" onClick={() => setOpenHistory(true)}>
            History
          </button>
          <button className="primary-btn mb-15">Confirm</button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (individualClicked) {
      onSubmit();
    }
  }, [individualClicked]);

  const generatePDF = (data) => {
    const specificDate = moment(Date.now()).format("YYYY-MM-DD:HH:mm:ss");
    html2pdf(data, {
      margin: 0.5,
      filename: `IndividualAnalysisReport-${specificDate}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    })
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        try {
          const base64 = btoa(pdf.output());
          console.log(base64, "needed");

          let data = {
            name: schema1.code,
            code: schema1.code,
            masterId: schema1._id,
            masterType: "Individual",

            reportDoc: [
              {
                document: "gffd",
                size: 123,
                type: "rr",
                base64: base64,
              },
            ],
          };
          console.log(data, " : cytuhijo");
          let payload = {
            method: "POST",
            url: `/searchReport`,
            data: data,
          };

          fetchApi(payload)
            .then((res) => {
              sendNotify("success", res?.message);
            })
            .catch((error) => {
              console.log(error);
              sendNotify("error", error?.message);
            });
        } catch (err) {
          console.error("Error generating PDF:", err);
        }
      });
  };

  const onSubmit = () => {
    if (
      formRef.current.validFrom() ||
      riskFormRef.current.validFrom() ||
      adsFormRef.current.validFrom()
    ) {
      let options1 = formRef.current.getPayload();
      let options2 = adsFormRef.current.getPayload();
      let options3 = riskFormRef.current.getPayload();

      let data = {
        code: options1.code,
        title: options1.title,
        firstName: options1.firstName,
        middleName: options1.middleName,
        lastName: options1.lastName,
        DOB: options1.DOB,
        COB: options1.COB,
        nationality: options1.nationality,
        passportNo: options1.passportNo,
        passportIssue: options1.passportIssue,
        passportExp: options1.passportExp,
        idNo: options1.idNo,
        idIssue: options1.idIssue,
        idExp: options1.idExp,
        taxInfo: options1.taxInfo,
        associateBus: options1.associateBus,
        residentialAddress: options2.residentialAddress,
        serviceAddress: options2.serviceAddress,
        relatedEntities: null,
        details: {
          address: {
            line_1: options2.line_1,
            line_2: options2.line_2,
            line_3: options2.line_3,
            zipCode: options2.zipCode,
            country: options2.country,
          },
          riskRating: options3.riskRating,
          pep: pep,
          sanction: sanction,
        },
        createdAt: options1.createdAt,
      };
      data.masterType = "individual";
      data.nic = "INV";
      console.log(data, "data");

      let payload = {
        method: "POST",
        url: `/individual`,
        data: data,
      };
      let url = `/app/pre-incorp-resolution`;

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
          setIndividualClicked(false);
          setTimeout(() => {
            window.location.href = url;
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          sendNotify("error", error?.message);
          setIndividualClicked(false);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const checkAnalyzeValidity = () => {
    if (
      formRef.current.validFrom() ||
      riskFormRef.current.validFrom() ||
      adsFormRef.current.validFrom()
    ) {
      let options1 = formRef.current.getPayload();
      let options2 = adsFormRef.current.getPayload();
      let options3 = riskFormRef.current.getPayload();

      let data = {
        code: options1.code,
        title: options1.title,
        firstName: options1.firstName,
        middleName: options1.middleName,
        lastName: options1.lastName,
        DOB: options1.DOB,
        COB: options1.COB,
        nationality: options1.nationality,
        passportNo: options1.passportNo,
        passportIssue: options1.passportIssue,
        passportExp: options1.passportExp,
        idNo: options1.idNo,
        idIssue: options1.idIssue,
        idExp: options1.idExp,
        taxInfo: options1.taxInfo,
        residentialAddress: options2.residentialAddress,
        serviceAddress: options2.serviceAddress,
        associateBus: options1.associateBus,
        relatedEntities: null,
        contactNumber: options1.contactNumber,
        email: options1.email,
        details: {
          address: {
            line_1: options2.line_1,
            line_2: options2.line_2,
            line_3: options2.line_3,
            zipCode: options2.zipCode,
            country: options2.country,
          },
          riskRating: options3.riskRating,
          pep: pep,
          sanction: sanction,
        },
        createdAt: options1.createdAt,
      };
      data.masterType = "analysis";
      let payload = {
        method: "POST",
        url: `/nameCheck/search-initiate`,
        data: data,
      };
      //  let url = `/app/pre-incorp-resolution`

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
          if (res?.data?.data && res?.data?.success) {
            setNameCheck(res?.data?.data);
            setOpenRes(true);
          }
          // let data = await fetchApi(payload).then((res) => {
          //   return res?.data?.data
          // });
          // setTimeout(() => {
          //   window.location.href = url;
          // }, 1000);
        })
        .catch((error) => {
          console.log(error);
          sendNotify("error", error?.message);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  if (setselectedPage) {
    setselectedPage("individual");
  }
  const tableColumns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: "RAF",
      dataIndex: "raf",
      sorter: (a, b) => a.raf - b.raf,
      render: (_, { status }) => {
        return (
          <button
            className="outline-btn py-1"
            onClick={() => setOpenDocument(true)}
          >
            View
          </button>
        );
      },
    },
    {
      title: "Analysis Report",
      dataIndex: "analysisReport",
      sorter: (a, b) => a.analysisReport - b.analysisReport,
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy - b.updatedBy,
    },
  ];
  console.log(nameCheck, "nameCheck");
  const tableData = [
    {
      id: "001",
      date: "12/04/2024 - 03:24PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "002",
      date: "11/04/2024 - 02:53PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "003",
      date: "10/04/2024 - 10:01AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "004",
      date: "10/04/2024 - 09:24AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "005",
      date: "05/04/2024 - 11:12AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
  ];

  return (
    <div>
      {/* <button className="primary-btn"
        onClick={codeOnChange}

      >Code Check</button> */}
      <FormZ
        formSchema={formSchema}
        formData={schema1}
        onChange={codeOnChange}
        ref={formRef}
        formClass="custom-form row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      <FormZ
        formSchema={adsFormSchema}
        formData={schema2}
        ref={adsFormRef}
        formClass="custom-form row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="me-3 mb-0"
        inputClass="w-100"
      />
      <FormZ
        formSchema={riskFormSchema}
        ref={riskFormRef}
        formData={schema3}
        formClass="custom-form row mb-md-5 gy-3 align-items-end"
        childClass="col-md-3 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
        disabled={fileCreation ? true : false}
      />
      {actionShow && (
        <div>
          <div className="d-flex flex-wrap gap-3 mt-3 mb-5 mb-md-0">
            <button
              className="primary-btn"
              onClick={() => checkAnalyzeValidity("reservation/confirmation")}
            >
              Analyze
            </button>
            <button className="outline-btn">Web Search</button>
            <button className="outline-btn">Analysis Result</button>
            <button className="outline-btn">Store Result</button>
          </div>
          <div className="d-flex flex-wrap gap-3 mt-3">
            <button className="primary-btn">RAF</button>
            <button className="outline-btn">Approve RAF</button>
            <button className="outline-btn">Store RAF</button>
          </div>
        </div>
      )}
      <Modal
        title={"History"}
        className="custom-modal"
        open={openHistory}
        width={800}
        onOk={() => setOpenHistory(false)}
        okText="Submit"
        onCancel={() => setOpenHistory(false)}
      >
        <div>
          <Table
            className="custom-table"
            columns={tableColumns}
            dataSource={tableData}
            pagination={false}
          />
        </div>
      </Modal>
      <Modal
        title={"Raf Document"}
        className="custom-modal"
        open={openDocument}
        width={1000}
        onOk={() => setOpenDocument(false)}
        onCancel={() => setOpenDocument(false)}
      >
        <div className="document-view">
          <iframe
            src={SampleDoc}
            frameBorder="0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </Modal>

      <Modal
        title={"Individual Analysis Report"}
        className="custom-modal"
        open={openRes}
        width={1000}
        onOk={() => setOpenRes(false)}
        onCancel={() => setOpenRes(false)}
      >
        <div className="document-view" id="modal-content">
          <iframe
            title="HTML Content"
            width="100%"
            height="100%"
            srcDoc={nameCheck}
            frameBorder="0"
          >
            {" "}
          </iframe>
        </div>
        <button onClick={() => generatePDF(nameCheck)}>Generate PDF</button>
      </Modal>
    </div>
  );
}
