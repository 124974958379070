import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";

import FormZ from "../../../components/FormZ";

import { sendNotify, getOptions, fetchApi, generateRandom, getQuery } from "../../../helper";


const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function EndUserClient({ endUserClicked, setEndUserClicked, setselectedPage, refCode }) {
  const { data, isLoading } = useSelector((state) => state.country);
  const [masterId, setMasterId] = useState({})

  const formRef = useRef();

  useEffect(() => {
    updateMastercode()
    configureSocket()
  }, [])

  const configureSocket = () => {

    // socket.on("connection", () => {
    //   console.log(" Connected backend");
    // });
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode()
      }
    });

  }

  const updateMastercode = async () => {

    let payload = {
      method: "GET",
      url: `/admin/masterCode?name=endUser`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.data
    });
    let originalString = data.seq;
    let numericPart = originalString.match(/\d+$/)[0];
    let incrementedNumeric = parseInt(numericPart, 10) + 1;
    let newNumericPart = String(incrementedNumeric).padStart(numericPart.length, '0');
    let newString = originalString.replace(/\d+$/, newNumericPart);
    console.log(newString);
    setMasterId(newString)

  }
  let formSchema = [
    {
      name: "code",
      value: '',
      type: "text",
      label: "End User Client Code",
      placeholder: "Enter end user client code",
      disabled: true,

    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "End User Client Name",
      placeholder: "Enter end user client name",
    },
    {
      name: "type",
      value: "endUser",
      type: "select",
      label: "End User Client Type",
      placeholder: "Enter end user client type",
      options: [
        {
          value: "intermediary",
          label: "Intermediary Client",
        },
        {
          value: "endUser",
          label: "End User Client",
        },
      ],
      disabled: true,
    },
    {
      name: "relationshipManager",
      value: "",
      type: "text",
      label: "Relationship Manager",
      placeholder: "Enter relationship manager name",
    },
    {
      name: "administrator",
      value: "",
      type: "text",
      label: "Administrator",
      placeholder: "Enter administrator name",
    },
    {
      name: "adminManager",
      value: "",
      type: "text",
      label: "Admin Manager",
      placeholder: "Enter admin manager name",
    },
    {
      name: "jurisdiction",
      value: "BVI",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "feeType",
      value: "",
      type: "select",
      label: "Fee Type",
      placeholder: "Select fee type",
      options: [
        {
          value: "prePaid",
          label: "Pre-paid",
        },
        {
          value: "postPaid",
          label: "Post-paid",
        },
      ],
    },
    {
      name: "serviceFeeCategory",
      value: "",
      type: "select",
      label: "Service Fee Category",
      placeholder: "Select service fee category",
      options: [],
    },
    // {
    //   name: "name",
    //   value: "",
    //   type: "text",
    //   label: "Name",
    //   placeholder: "Enter name",
    // },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
    },
    {
      name: "contactNo",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number",
      customClass: "hide-arrows",
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of Creation",
      placeholder: "Select date",
    },
  ];
  useEffect(() => {
    if (endUserClicked) {
      onSubmit();
    }
  }, [endUserClicked]);

  const onSubmit = () => {
    if (formRef.current.validFrom()) {
      let options = formRef.current.getPayload();

      let obj = {
        options: Object.values(options),
      };
      options.masterType = 'end-user';
      options.nic = "EUC";
      options.pid = null;
      let payload = {
        method: "POST",
        url: `/client`,
        data: options,
      };
      let url = `/app/pre-incorp-resolution`

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
          setEndUserClicked(false)
          // setTimeout(() => {
          //   window.location.href = url;
          // }, 1000);

        })
        .catch((error) => {
          console.log(error);
          sendNotify("error", error?.message);
          setEndUserClicked(false)
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };
  setselectedPage('end-user-client')

  return (
    <div className="custom-form">
      <h2 className="sub-title mb-4">Master File Creation: Client</h2>
      <FormZ
        formSchema={formSchema}
        formData={{ code: masterId, type: 'endUser', jurisdiction: 'BVI' }}
        ref={formRef}
        formClass="row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      {/* <div className="d-flex gap-3">
                <button className='primary-btn'>Analyze</button>
                <button className='outline-btn'>Web Search</button>
            </div> */}
    </div>
  );
}
