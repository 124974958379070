import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Radio, Checkbox, Switch, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";
import TableSortArrows from "../components/tableSortArrows";
import TagZ from "../components/TagZ";
import RejectTask from "../components/RejectTask";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";
import { useSelector } from "react-redux";
import { useGeneralEmail } from "../hooks/useGeneralEmail";
import ServiceRequestEmailSender from "../components/ServiceRequestEmaiSendButtons";

export default function MaaNameChange() {
  let query = getQuery();

  const [docs, setDocs] = useState({});
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("client");

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        setData(res?.data?.rows?.[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const clientTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Availability Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <TagZ
          status={status ? "approved" : "rejected"}
          statusText={status ? "Available" : "Unavailable"}
        />
      ),
    },
    {
      title: "Reservation",
      dataIndex: "reservation",
      sorter: (a, b) => a.reservation - b.reservation,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status, reservation }) => {
        if (status) {
          return (
            <Radio.Group value={reservation}>
              <Radio className="custom-radio" value={"O"}>
                Ordinary
              </Radio>
              <Radio className="custom-radio" value={"P"}>
                Paid
              </Radio>
            </Radio.Group>
          );
        } else {
          return <>N/A</>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, { status }) => (
        <button
          className={`primary-btn py-2 ${false ? "completed-btn" : ""}`}
          disabled={!status}
        >
          {false ? "Reserved" : "Reserve"}
        </button>
      ),
    },
  ];

  const clientTableData = [
    {
      name: "First sample name",
      status: false,
      reservation: "O",
    },
    {
      name: "Second sample name",
      status: true,
      reservation: "P",
    },
    {
      name: "Third sample name",
      status: true,
      reservation: "O",
    },
  ];

  const adminTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Available",
      dataIndex: "available",
      render: (_, { status }) => <Switch className="custom-switch" />,
    },
  ];

  const adminTableData = [
    {
      name: "First sample name",
      status: false,
    },
    {
      name: "Second sample name",
      status: true,
    },
    {
      name: "Third sample name",
      status: true,
    },
  ];

  const onChangeApproval = (file, bool) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index].isApproved = bool;
    setDocs(arr);
  };

  const { isSendingEmail } = useGeneralEmail();
  const { userDetails: businessPartnerDetails } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSendingEmail) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSendingEmail]);

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> M&AA Name Change
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <Link
            to={`/app/entity-overview/list?id=${data?.entityId}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          {role == "admin" && (
            <>
              <Link className="outline-btn" to={"/app/mail"}>
                Check Mailbox
              </Link>
              <button className="primary-btn">Send to Client</button>
            </>
          )}
          <ServiceRequestEmailSender
            serviceRequestName="M&A Name Change"
            serviceRequestData={data}
            businessPartnerDetails={businessPartnerDetails}
          />
          <RejectTask id={query?.id} reason={data?.rejectReason} />
          <button className="primary-btn" disabled={true}>
            Submit
          </button>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
          </div>
          <h2 className="form-title mb-4">Name Availability</h2>
          <Table
            className={`custom-table mb-4 ${role == "admin" ? "w-50" : "w-75"}`}
            columns={role == "admin" ? adminTableColumns : clientTableColumns}
            dataSource={role == "admin" ? adminTableData : clientTableData}
            pagination={false}
          />
          <div className="row gy-2 gx-4">
            <Each
              array={data?.documents}
              render={(item, index) => (
                <div className="col-md-3">
                  <div>
                    <Checkbox className="custom-checkbox">
                      {item?.document}
                    </Checkbox>
                    <UploadZ
                      file={item}
                      downloadOnly={true}
                      showApprove={true}
                      onFileApprove={onChangeApproval}
                      showTranslation={true}
                      showExtraction={true}
                    />
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
