import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import FormZ from "../../../components/FormZ";
import { languagesArray } from "../../../store/language/languageSlice";
import { Select, Spin, TreeSelect, Modal, Table } from "antd";
import {
  sendNotify,
  getOptions,
  fetchApi,
  convertQueryParams,
  capitalizeFirstLetter,
  masterFileDownload,
} from "../../../helper";
import MasterFileCreation from "../../../helper/translations/MasterFileCreation.json";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);
const { confirm } = Modal;
export default function Entity({
  entityClicked,
  setEntityClicked,
  setselectedPage,
  refCode,
}) {
  const { data, isLoading } = useSelector((state) => state.country);
  const { language } = useSelector((state) => state.language);

  const [formData, setFormData] = useState({});
  const [feeCategoryArray, setFeeCategoryArray] = useState([]);
  const [feeSubCategoryArray, setFeeSubCategoryArray] = useState([]);
  const [entitylist, setEntitylist] = useState([]);
  const [clientlist, setClientlist] = useState([]);
  const [clientdata, setClientdata] = useState([]);
  const [clientNameList, setClientNameList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selctedLanguage, setSelctedLanguage] = useState("");
  const [duplicateRecordsView, setDuplicateRecordsView] = useState(false);
  const [duplicate, setDuplicate] = useState([]);
  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);

  let m =
    MasterFileCreation.entity[selctedLanguage] || MasterFileCreation.entity.en;
  let ma =
    MasterFileCreation.masterFile[selctedLanguage] ||
    MasterFileCreation.masterFile.en;

  useEffect(() => {
    updateMastercode();
    configureSocket();
    handleRefresh();
    //newDateCheck();
  }, []);

  const configureSocket = () => {
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode();
      }
    });
    return () => {
      disconnectSocket();
    };
  };

  const disconnectSocket = () => {
    socket.off("counter");
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  const newDateCheck = async () => {
    let newDate = [];
    await asyncForEach(data, async (h) => {});
    console.log(newDate, "wesrdtyfguhio");
  };

  const handleRefresh = async () => {
    setLoading(true);

    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res?.data?.rows);
    };

    const data = await fetchData(`/feeCategory?_start=0&_limit=1000`);
    const clientParams = convertQueryParams({
      isAll: true,
      // masterType: "client",
      alltype: ["client", "introducer-client", "end-user"],
      options: true,
    });
    const clientNameParams = convertQueryParams({
      isAll: true,
      //masterType: "client",
      alltype: ["client", "introducer-client", "end-user"],
      exportAll: true,
    });
    const entityParams = convertQueryParams({
      isAll: true,
      masterType: "entity",
      options: true,
    });

    const clientlist = await fetchData(`/client?${clientParams}`);
    const entitydata = await fetchData(`/client?${entityParams}`);

    const clientdata = await fetchData(`/client?${clientNameParams}`);
    // const clientName = clientdata.map((d) => ({
    //   value: d.name,
    //   label: d.name,
    // }));
    const clientNameList = clientdata?.map((d) => ({
      value: d.name,
      label: d.name,
    }));
    const feeCategorylist = data?.map((d) => ({
      value: d._id,
      label: `${d.code}-${d.name}`,
    }));
    setClientdata(clientdata);
    setClientlist(clientlist);
    setClientNameList(clientNameList);
    setEntitylist(entitydata);
    setFeeCategoryArray(feeCategorylist);
    setLoading(false);
  };

  const updateMastercode = async () => {
    const payload = { method: "GET", url: `/admin/masterCode?name=entity` };
    const data = await fetchApi(payload).then((res) => res?.data?.data);

    const originalString = data?.seq;
    const numericPart = originalString?.match(/\d+$/)[0];
    const incrementedNumeric = parseInt(numericPart, 10) + 1;
    const newNumericPart = String(incrementedNumeric)?.padStart(
      numericPart?.length,
      "0"
    );
    const newString = originalString?.replace(/\d+$/, newNumericPart);

    setFormData((prev) => ({
      ...prev,
      code: newString,
      countryOfIncorporation: "BVI",
      status: "prospect",
      createdAt: new Date(),
    }));
  };

  const formRef = useRef();

  const handleClientCodeChange = (clientId) => {
    const selectedClient = clientdata?.find(
      (client) => client._id === clientId
    );
    if (selectedClient) {
      setFormData((prev) => ({
        ...prev,
        clientId: clientId,
        clientName: selectedClient.name,
        clientType: selectedClient.type,
      }));
    }
  };

  const handleClientNameChange = (clientName) => {
    const selectedClient = clientdata?.find(
      (client) => client.name === clientName
    );
    if (selectedClient) {
      setFormData((prev) => ({
        ...prev,
        clientId: selectedClient._id,
        clientName: selectedClient.name,
        clientType: selectedClient.type,
      }));
    }
  };
  const fetchSubCategories = async (feeCategoryId) => {
    setLoading(true);

    setFormData((prev) => ({
      ...prev,
      feeCat: feeCategoryId,
    }));
    const payload = {
      method: "GET",
      url: `/feeCategory/sub/?catId=${feeCategoryId}`,
    };

    const data = await fetchApi(payload).then((res) => res?.data?.data);

    const feeSubCategoryList = data?.map((d) => ({
      value: d._id,
      label: d.catType,
    }));

    setFeeSubCategoryArray(feeSubCategoryList);
    setLoading(true);
  };

  const handleOnChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      name: name.name || "",
      code: name.code || "",
      type: name.type || "",
      status: name.status || "",
      clientId: name.clientId || "",
      clientName: name.clientName || "",
      clientType: name.clientType || "",
      incorporationNumber: name.incorporationNumber || "",
      countryOfIncorporation: name.countryOfIncorporation || "",
      feeCat: name.feeCat || "",
      feeType: name.feeType || "",
      subCat: name.subCat || [],
      relationshipManager: name.relationshipManager || "",
      administrator: name.administrator || "",
      adminManager: name.adminManager || "",
      email: name.email || "",
      incorporationDate: name.incorporationDate || "",
      contactNo: name.contactNo || "",
      relatedEntities: name.relatedEntities || [],
      authorisedShares: name.authorisedShares || "",
      YearOfEndDate: name.YearOfEndDate || "",
      createdAt: name.createdAt || "",
    }));

    if (value === "clientId") {
      handleClientCodeChange(name.clientId);
    } else if (value === "clientName") {
      handleClientNameChange(name.clientName);
    } else if (value === "feeCat") {
      fetchSubCategories(name.feeCat);
    }
  };

  const formSchema = [
    {
      name: "code",
      value: "",
      type: "text",
      label: m.code,
      placeholder: m.EnterEntityCode,
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: m.name,
      placeholder: m.EnterEntityName,
      required: true,
    },
    {
      name: "type",
      value: "",
      type: "select",
      label: m.type,
      placeholder: m.SelectEntityType,
      options: [
        { value: "BVIBC", label: "BVIBC" },
        { value: "trust", label: "Trust" },
        { value: "foundation", label: "Foundation" },
        { value: "general-partnership", label: "General Partnership" },
        { value: "limited-partnership", label: "Limited Partnership" },
      ],
      required: true,
    },
    {
      name: "status",
      value: "",
      type: "select",
      label: m.status,
      placeholder: m.SelectEntityStatus,
      options: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "closing", label: "Closing" },
        { value: "closed", label: "Closed" },
        { value: "dissolved", label: "Dissolved" },
        { value: "transferringOut", label: "Transferring Out" },
        { value: "prospect", label: "Prospect" },
        { value: "transferringIn", label: "Transferring In" },
        { value: "liquidated", label: "Liquidated" },
        { value: "struckOff", label: "Struck Off" },
        { value: "transferredOut", label: "Transferred Out" },
        { value: "transferredIn", label: "Transferred In" },
        { value: "resigned", label: "Resigned" },
        { value: "continuationOut", label: "Continuation Out" },
        { value: "continuationIn", label: "Continuation In" },
        { value: "continuedIn", label: "Continued In" },
        { value: "continuedOut", label: "Continued Out" },
      ],
      disabled: true,
    },
    {
      name: "clientId",
      value: "",
      type: "select",
      label: m.clientId,
      placeholder: m.EnterClientCode,
      required: true,
    },
    {
      name: "clientName",
      value: "",
      type: "select",
      label: m.clientName,
      placeholder: m.EnterClientName,
      required: true,
      showSearch: true,
      options: getOptions(clientNameList),
    },
    {
      name: "clientType",
      value: "",
      type: "select",
      label: m.clientType,
      placeholder: m.EnterClientType,
      options: [
        { value: "introducer", label: "Introducer Client" },
        { value: "endUser", label: "End User Client" },
      ],
      disabled: true,
    },
    {
      name: "incorporationDate",
      value: "",
      type: "date",
      upcoming: true,
      label: m.incorporationDate,
      placeholder: m.formet,
    },
    {
      name: "incorporationNumber",
      value: "",
      type: "text",
      label: m.incorporationNumber,
      placeholder: m.EnterIncorporationNumber,
    },
    {
      name: "countryOfIncorporation",
      value: "BVI",
      type: "select",
      label: m.countryOfIncorporation,
      placeholder: m.SelectCountryOfIncorporation,
      options: getOptions(data),
      loading: isLoading,
      disabled: true,
    },
    {
      name: "feeCat",
      value: "",
      type: "select",
      label: m.feeCat,
      placeholder: m.SelectFeeScheduleName,
      options: getOptions(feeCategoryArray),
      loading: isLoading,
    },
    {
      name: "feeType",
      value: "",
      type: "select",
      label: m.feeType,
      placeholder: m.SelectFeeType,
      options: [
        { value: "Pre-Paid", label: "Pre-paid" },
        { value: "Post-Paid", label: "Post-paid" },
      ],
    },
    {
      name: "subCat",
      value: null,
      type: "select",
      label: m.subCat,
      placeholder: m.SelectFeeSubCategory,
      //  mode: "multiple",
      inputClass: "ptb-1",
      allowClear: true,
    },
    {
      name: "relationshipManager",
      value: "",
      type: "text",
      label: m.relationshipManager,
      placeholder: m.EnterRelationshipManagerName,
    },
    {
      name: "administrator",
      value: "",
      type: "text",
      label: m.administrator,
      placeholder: m.EnterAdministratorName,
    },
    {
      name: "adminManager",
      value: "",
      type: "text",
      label: m.adminManager,
      placeholder: m.EnterAdminManagerName,
    },
    // {
    //   name: "name",
    //   value: "",
    //   type: "text",
    //   label: "Name",
    //   placeholder: "Enter name",
    // },

    // Vinoth 21-01-2025
    // {
    //   name: "email",
    //   value: "",
    //   type: "email",
    //   label: m.email,
    //   placeholder: m.EnterEmailAddress,
    //   //  required: true,
    // },
    // {
    //   name: "contactNo",
    //   value: "",
    //   type: "number",
    //   label: m.contactNo,
    //   placeholder: m.EnterContactNumber,
    //   customClass: "hide-arrows",
    //   //  required: true,
    // },
    // {
    //   name: "entityGrouping",
    //   value: "",
    //   type: "text",
    //   label: "Entity Grouping",
    //   placeholder: "Enter entity grouping",
    // },

    // {
    //   name: "relatedEntities",
    //   value: null,
    //   type: "select",
    //   label: "Related Entities",
    //   placeholder: "Select related entities",
    //   mode: "multiple",
    //   options: getOptions(entitylist),
    //   inputClass: "ptb-1",
    //   allowClear: true,
    // },
    {
      name: "authorisedShares",
      value: "",
      type: "number",
      label: m.authorisedShares,
      placeholder: m.EnterAuthorisedShares,
      customClass: "hide-arrows",
      required: true,
    },
    {
      name: "YearOfEndDate",
      value: "",
      type: "date",
      label: m.YearOfEndDate,
      placeholder: m.formet,
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: m.createdAt,
      placeholder: m.SelectDate,
      disabled: true,
    },
  ];

  useEffect(() => {
    if (entityClicked) {
      onSubmit();
    }
  }, [entityClicked]);

  const onSubmit = () => {
    let url = `/app/master-search?action=entity`;
    if (formRef.current.validForm()) {
      let options = formRef.current.getPayload();

      if (!options?.subCat?.length) {
        delete options.subCat;
      }
      if (!options?.feeCat?.length) {
        delete options.feeCat;
      }
      if (!options?.feeType) {
        delete options.feeType;
      }
      options.masterType = "entity";
      options.nic = "EN";
      options.pid = null;
      let payload = { method: "POST", url: `/entity`, data: options };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            setTimeout(() => {
              window.location.href = url;
            }, 1000);

            sendNotify("success", res?.message);
          } else if (res.status == 208) {
            sendNotify("error", res?.message);
            setDuplicate(res?.data);
            showConfirm();
          }
          setEntityClicked(false);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
          setEntityClicked(false);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const showConfirm = () => {
    confirm({
      title: <h5>Duplicate records</h5>,
      content: <p>Do you want to view or download the duplicate records?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "OK",
      onOk() {
        duplicateRecordsOnclick();
      },
      onCancel() {},
    });
  };
  const tableColumnsDuplicate = [
    {
      title: "MasterFile Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "MasterFile Name",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Masterfile Type",
      dataIndex: "masterType",
      filterType: "text",
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorporationDate",
      filterType: "date",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      filterType: "text",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
    },
    {
      title: "Incorporation Country",
      dataIndex: "incorporationCountry",
      filterType: "text",
      sorter: (a, b) => a.nationality - b.nationality,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];

  const onExportClick = async () => {
    const headersMap = {
      individual: [
        { header: "MasterFile Code", name: "code", required: true },
        { header: "Title", name: "title", required: true },
        { header: "First Name", name: "firstName", required: true },
        { header: "Middle Name", name: "middleName", required: true },
        { header: "Last Name", name: "lastName", required: true },
        { header: "Date of Birth", name: "DOB", required: true },
        { header: "Country of Birth", name: "COB", required: true },
        { header: "Nationality", name: "nationality", required: true },
        { header: "Tax Info Number", name: "taxInfo", required: true },
        { header: "Address", name: "address", required: true },
        { header: "Contact Number", name: "contactNo", required: true },
        { header: "Email Address", name: "email", required: true },
        { header: "Risk Level", name: "riskLevel", required: true },
        { header: "Pep", name: "pep", required: true },
        { header: "Sanction", name: "sanction", required: true },
      ],
    };
    const headersArray = headersMap["CorporateEntity"] || headersMap.individual;

    const capitalizeRows = (rows) => {
      return rows?.map((row) => ({
        ...row,
        type: row.type
          ? capitalizeFirstLetter(row.type)
          : capitalizeFirstLetter(row.masterType),
      }));
    };

    let tableData = capitalizeRows(duplicate);

    if (tableData) {
      let exportName = "CorporateEntity";

      masterFileDownload(
        tableData,
        `${exportName}-${new Date()}`,
        headersArray
      );
    }
  };
  const duplicateRecordsOnclick = () => {
    setDuplicateRecordsView(true);
  };
  setselectedPage("entity");

  return (
    <div className="custom-form">
      <h2 className="sub-title mb-4">Master File Creation: Entity</h2>
      <Spin spinning={loading} centered>
        <FormZ
          ref={formRef}
          formSchema={formSchema}
          formData={formData}
          onChange={handleOnChange}
          formClass="row mb-3 mb-md-4 gy-3 gx-4"
          formOptions={{ subCat: feeSubCategoryArray }}
          childClass="col-md-3 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
      </Spin>
      <Modal
        title={"Duplicate Records"}
        className="custom-modal"
        open={duplicateRecordsView}
        width={1000}
        onOk={onExportClick}
        okText="Download"
        onCancel={() => setDuplicateRecordsView(false)}
      >
        <div>
          <Table
            className="custom-table"
            columns={tableColumnsDuplicate}
            dataSource={duplicate}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
}
