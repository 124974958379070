import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, sendNotify } from "../../helper";

// Async thunk to send email
export const sendEmail = createAsyncThunk(
  "generalEmails/sendEmails",
  async (emailData) => {
    const payload = {
      method: "POST",
      url: "/general-email/send-general-email",
      data: emailData,
    };

    const res = await fetchApi(payload).then((res) => res.data);

    if (res) {
      sendNotify("success", "Email sent successfully!");
    } else {
      sendNotify("error", "Error occurred while sending email.");
    }

    return res;
  }
);

const emailSlice = createSlice({
  name: "generalEmails",
  initialState: {
    isLoading: false,
    success: false,
    isError: false,
  },
  reducers: {
    resetSuccess(state) {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(sendEmail.fulfilled, (state) => {
      state.isLoading = false;
      state.success = true;
      state.isError = false;
    });

    builder.addCase(sendEmail.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { resetSuccess } = emailSlice.actions;

export default emailSlice.reducer;
