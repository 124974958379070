import IndividualAnalysis from "../components/IndividualAnalysis.jsx";

export default function IndividualAnalysisPage() {
  return (
    <div>
      <h1 className="page-title mb-3 mb-md-4">Individual Analysis</h1>
      <IndividualAnalysis actionShow={true} />
    </div>
  );
}
