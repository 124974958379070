import React, { useState } from "react";
import { Table, Input, Button, Form, Popconfirm, message } from "antd";
import { SaveOutlined, CloseOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";

const EditableCell = ({ editing, dataIndex, children, ...restProps }) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0, width: "auto"  }}>
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({ columns, initialData = [], onSave, addButtonText }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(initialData);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        newData[index] = { ...newData[index], ...row };
      } else {
        newData.push(row);
      }
      setData(newData);
      setEditingKey("");

      if (onSave) {
        onSave(newData);
      }
    } catch (errInfo) {
      console.log("Validation Failed:", errInfo);
    }
  };

  const handleAdd = () => {
  const newRow = { key: Date.now().toString() };
  setData([...data, newRow]);
  setEditingKey(newRow.key);
  form.resetFields();
  form.setFieldsValue(newRow);
};

  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
    if (onSave) {
      onSave(newData);
    }
  };

  const modifiedColumns = [
    ...columns.map((col) => ({
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    })),
    {
          title: '',
          width: 'auto',
          dataIndex: 'operation',
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span className="button-group">
                <Button onClick={() => save(record.key)} style={{ marginRight: 8 }} icon={<SaveOutlined />}/>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <Button icon={<CloseOutlined />}/>
                </Popconfirm>
              </span>
            ) : (
              <span className="button-group">
                <Button disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }} icon={<EditOutlined />}/>
    
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                <Button type="danger" icon={<DeleteOutlined />} />
                </Popconfirm>
              </span>
            );
          },
        },
  ];

  return (
    <>
      {addButtonText && (
        <div className="input-table-add-row">
          <Button
            onClick={handleAdd}
            type="primary"
            className="add-row-button"
            icon={<PlusOutlined />}
          >
            {addButtonText}
          </Button>
        </div>
      )}
      <Form form={form} component={false}>
        <Table
          className="custom-table mb-4"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={modifiedColumns}
          rowClassName="editable-row"
          scroll={{ x: "max-content" }}
          pagination={false}
        />
      </Form>
    </>
  );
};

export default EditableTable;
