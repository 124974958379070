import { useState, useEffect, useRef } from "react";
import { Table, Switch, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";

import {
  getSignature,
  updateSignature,
  deleteSignature,
} from "../store/signature/signatureSlice";

import TableSortArrows from "../components/tableSortArrows";
import TabZ from "../components/TabZ";
import FormZ from "../components/FormZ";

import {
  customPagination,
  sendNotify,
  fileToBase64,
  fetchApi,
} from "../helper";

import DeleteConfirmationModal from "../components/DeleteSignatureConfirmationModal";

export default function ManageSignatures() {
  const signFormRef = useRef();
  const signImgRef = useRef();

  let signPad = {};

  const signature = useSelector((state) => state.signature);

  let signFormSchema = [
    {
      name: "fullName",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name",
      required: true,
    },
    {
      name: "jobTitle",
      value: "",
      type: "text",
      label: "Job Title",
      placeholder: "Enter job title",
      required: true,
    },
  ];

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [signatureToDelete, setSignatureToDelete] = useState(null);
  const [statusLoading, setStatusLoading] = useState(null);
  const [signModal, setSignModal] = useState(false);
  const [createSignModal, setCreateSignModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [signing, setSigning] = useState(false);
  const [signImgError, setSignImgError] = useState(false);
  const [signImgLoading, setSignImgLoading] = useState(false);
  const [newSign, setNewSign] = useState(null);
  const [previewSign, setPreviewSign] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSignature(queryParams));
  }, []);

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "fullName",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Signature",
      dataIndex: "url",
      render: (_, { url }) => (
        <img src={`data:image/png;base64, ${url}`} width={100} alt="" />
      ),
    },
    {
      title: "Designation",
      dataIndex: "jobTitle",
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { _id, status }) => (
        <div className="d-flex gap-2">
          <Switch
            loading={statusLoading == _id}
            defaultChecked={status == "active"}
            className="custom-switch"
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            onChange={(value) => updateStatus(_id, value)}
          />
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text) => moment(text).format("YYYY-MM-DD hh:mm A"),
    },
    // {
    //   title: "Created By",
    //   dataIndex: "createdBy",
    //   sorter: (a, b) => a.createdBy - b.createdBy,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, { _id, fullName }) => (
        <button
          className={`action-btn icon-btn color-red`}
          onClick={() => showDeleteConfirmation(_id, fullName)}
        >
          <i className="far fa-trash"></i>
        </button>
      ),
    },
  ];

  const onPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params._limit = pageSize;
    dispatch(getSignature(params));
    setQueryParams(params);
  };

  const showDeleteConfirmation = (signatureId, fullName) => {
    setSignatureToDelete({ id: signatureId, fullName: fullName });
    setDeleteConfirmationVisible(true);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteSignature(signatureToDelete.id));
      setDeleteConfirmationVisible(false);
      sendNotify("success", "Signature deleted successfully.");
    } catch (error) {
      sendNotify("error", "Error deleting signature.");
      setDeleteConfirmationVisible(false);
    }
  };

  const labels = [
    {
      key: 1,
      label: (
        <>
          <i className="far fa-pen"></i> DRAW
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <i className="far fa-arrow-to-top"></i> UPLOAD
        </>
      ),
    },
  ];

  const onTabChange = (value) => {
    clearSign();
    setActiveTab(value);
  };

  const clearSign = () => {
    if (activeTab == 1) {
      signPad.clear();
      setSigning(false);
    } else {
      setPreviewSign("");
    }
    setNewSign(null);
  };

  const saveSign = () => {
    let sign = signPad.toDataURL();
    console.log(sign, " :sign");
    setNewSign(sign);
  };

  const updateStatus = async (id, status) => {
    setStatusLoading(id);
    let payload = {
      id,
      data: { status: status ? "active" : "inactive" },
    };
    try {
      await dispatch(updateSignature(payload));
      setStatusLoading(null);
      sendNotify(
        "success",
        `Signature ${status ? "activate" : "inactivate"} successfully.`
      );
    } catch (error) {
      console.log(error);
      sendNotify("error", "Error update signature.");
    }
  };

  const base64ToBlob = (base64, type = "image/png") => {
    const byteCharacters = atob(base64.split(",")[1]); // Decode the base64 string
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: type });
  };

  const createSignature = async () => {
    const valid = signFormRef.current.validForm();
    if (!valid) {
      sendNotify("error", "Please enter required details");
      return;
    }
    const blob = base64ToBlob(newSign);
    const imgUrl = new File([blob], "image.png", { type: "image/png" });
    if (valid) {
      let formdata = signFormRef.current.getPayload();
      const bodyFormData = new FormData();
      bodyFormData.append("file", imgUrl);
      bodyFormData.append("folder", "test");
      bodyFormData.append("fullName", formdata.fullName);
      bodyFormData.append("jobTitle", formdata.jobTitle);

      let reportPayload = {
        method: "POST",
        url: `/signature`,
        data: bodyFormData,
      };

      fetchApi(reportPayload)
        .then((res) => {
          sendNotify("success", res?.message);
          dispatch(getSignature(queryParams));
          setCreateSignModal(false);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    }
  };

  const onImgUpload = (e) => {
    setSignImgError(false);
    let file = e.target.files[0];
    if (file) {
      if (file?.size > 5242880) {
        setSignImgError(true);
        sendNotify("error", "Image is too big!, Upload below 5MB file.");
      } else {
        let type = file?.name.substring(file?.name.lastIndexOf(".") + 1);
        var regex = new RegExp("(.*?)(png|jpg|jpeg|svg)$");
        if (regex.test(type)) {
          setSignImgLoading(true);
          setPreviewSign(URL.createObjectURL(file));
          fileToBase64(file)
            .then((data) => {
              setNewSign(data);
              sendNotify("success", "Image uploaded successfully.");
              setSignImgLoading(false);
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        } else {
          setSignImgError(true);
          sendNotify(
            "error",
            "Only PNG, JPG, JPEG, or SVG images are supported."
          );
        }
      }
    }
  };

  return (
    <div>
      <div className="page-header">
        <h1 className="page-title mb-3 mb-md-0">Manage Signatures</h1>
        <button
          className="primary-btn"
          onClick={() => setCreateSignModal(true)}
        >
          New Create
        </button>
      </div>
      <div className="page-content">
        <Table
          className="custom-table"
          loading={signature?.isLoading}
          rowKey={"_id"}
          columns={tableColumns}
          dataSource={signature?.data?.rows}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: signature?.data?.totalCount,
            onChange: onPagination,
            itemRender: customPagination,
          }}
        />
      </div>
      <DeleteConfirmationModal
        visible={deleteConfirmationVisible}
        onConfirm={handleDelete}
        onCancel={() => setDeleteConfirmationVisible(false)}
        signatureName={signatureToDelete?.fullName}
      />
      <Modal
        title={
          <>
            <h6>Create New Signature</h6>
            <p>Draw or upload an image of your signature.</p>
          </>
        }
        open={createSignModal}
        className="custom-modal-2"
        onOk={createSignature}
        okText={"Create Signature"}
        onCancel={() => setCreateSignModal(false)}
        width={500}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <TabZ
            labels={labels}
            defaultActive={activeTab}
            onChange={onTabChange}
          />
          <div
            className={`signature-wrapper mt-4 ${
              !signing && activeTab == 1 ? "sign-placeholder" : ""
            }`}
          >
            {activeTab == 1 ? (
              <SignatureCanvas
                onBegin={() => setSigning(true)}
                onEnd={saveSign}
                ref={(ref) => {
                  signPad = ref;
                }}
                canvasProps={{ width: 436, height: 120 }}
              />
            ) : previewSign ? (
              <img src={previewSign} className="sign-img" alt="sign" />
            ) : (
              <div className="sing-img-upload">
                <input
                  ref={signImgRef}
                  type="file"
                  name="sign-img"
                  onChange={onImgUpload}
                  accept=".png,.jpg,.jpeg,.svg"
                />
                <span className="icon">
                  {signImgLoading ? (
                    <i className="far fa-spinner-third fa-spin"></i>
                  ) : (
                    <>
                      {signImgError ? (
                        <i className="fal fa-times-circle color-red"></i>
                      ) : (
                        <i className="fal fa-arrow-from-bottom"></i>
                      )}
                    </>
                  )}
                </span>
                <p>PNG, JPG or SVG (max. 5MB)</p>
              </div>
            )}
            {(signing || previewSign) && (
              <button className="clear-btn" onClick={clearSign}>
                <i className="far fa-trash"></i>
              </button>
            )}
          </div>
          <FormZ
            formSchema={signFormSchema}
            ref={signFormRef}
            formClass="custom-form row mt-2 mb-4 gy-3 gx-4"
            childClass="col-md-12 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
        </div>
      </Modal>
    </div>
  );
}
