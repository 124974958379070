import React, { useState } from "react";
import { Checkbox, Modal } from "antd";
import { Link } from "react-router-dom";

import PDFIcon from "../assets/img/pdf-icon.svg";

import SampleDoc from "../assets/pdf/sample.pdf";
import AMLDoc from "../assets/pdf/aml-verification-guidelines.pdf";
import IncorporationDoc from "../assets/pdf/company-incorporation-application-form.pdf";
import DirectorsConsentDoc from "../assets/pdf/directors-consent-letter.pdf";
import TermsDoc from "../assets/pdf/terms-of-business-agreement.pdf";
import TransferDoc from "../assets/pdf/transfer-in-form-for-bvibc.pdf";
import UBODoc from "../assets/pdf/ubo-source-of-funds-declaration.pdf";
import WrittenConfirmationsDoc from "../assets/pdf/written-confirmations-virtual-assets.pdf";

import { Each, downloadFile, formatBytes } from "../helper";
import UploadZ from "../components/UploadZ";

export default function PreIncorpResolution() {
  const [docModal, setDocModal] = useState(false);
  const [viewDoc, setViewDoc] = useState(null);
  const [viewDocName, setViewDocName] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const viewDocument = (doc, docName) => {
    setViewDoc(doc);
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  const onSelectAll = (e) => {
    setSelectAll(e.target.checked);
  };

  const DownloadDoc = () => {
    downloadFile(SampleDoc, "sample_doc.pdf");
  };

  const documents = [
    {
      url: SampleDoc,
      name: "Inaugural Resolution",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Appointment of First Director",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Resolution of Share Allotment",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Corporate Seal Draft",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Directors Consent Letter",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Customized M&AA",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "UBO Source of Funds Declaration",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Incorporation Application Form",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "AML Verification Guidelines",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Transfer-In Form for BVIBC",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Written Confirmations-Virtual Assets",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Terms Of Business Agreement",
      size: formatBytes(293333),
      type: "pdf",
      base64: "",
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-3 mb-md-0">
          Pre-Incorp Supported Documents
        </h1>
        <Link to={"/app/relationship-manager"} className="primary-btn">
          Export
        </Link>
      </div>
      <p className="head-label mb-4">Select Documents to Export :</p>
      <div className="d-flex gap-3 align-items-start align-items-md-center flex-column flex-md-row mb-4">
        <Checkbox onChange={onSelectAll} className="custom-checkbox">
          Select All
        </Checkbox>
        <button className="outline-btn">
          <i className="far fa-arrow-to-bottom"></i> Download Selected
        </button>
      </div>
      <div className="row mb-4 gap-ms-2">
        <Each
          array={documents}
          render={(item) => (
            <div className="col-md-3">
              <Checkbox
                onChange={onChange}
                checked={selectAll}
                className="custom-checkbox mb-3"
              >
                {item.name}
              </Checkbox>
              <UploadZ file={item} downloadOnly={true} />
            </div>
          )}
        />
      </div>
      <Modal
        title={viewDocName}
        className="custom-modal"
        open={docModal}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="document-view">
          <iframe
            src={viewDoc}
            frameBorder="0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}
