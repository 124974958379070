import { Modal } from "antd";

const DeleteSignatureConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
  signatureName,
}) => {
  return (
    <Modal
      title="Delete Signature"
      open={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Delete"
      cancelText="Cancel"
    >
      <p>
        Are you sure you want to delete the signature of{" "}
        <strong>{signatureName}</strong>?
      </p>
    </Modal>
  );
};

export default DeleteSignatureConfirmationModal;
