import moment from "moment";

// Helper function to map client data to names
export const generateClientNameDataMap = (clientData) => {
  return new Map(
    clientData.map((d) => [
      d._id,
      d.name ||
        `${d.firstName || ""} ${
          d.middleName && d.middleName !== "." ? d.middleName + " " : ""
        }${d.lastName || ""}`.trim(),
    ])
  );
};

// Helper function to map client data to addresses
export const generateAddressMap = (clientData) => {
  return new Map(
    clientData.map((a) => {
      const serviceAddr = a?.serviceAddress ?? "N/A";
      const residentialAddr = a?.residentialAddress ?? "N/A";
      const copAddr = formatAddress(a?.details?.address) ?? "N/A";
      const entityAddr = formatAddress(a?.registers?.address) ?? "N/A";

      return [
        a._id,
        {
          individual: `Service Address: ${serviceAddr} , Residential Address: ${residentialAddr}`,
          corporate: `Address: ${copAddr}`,
          entity: `Address: ${entityAddr}`,
        },
      ];
    })
  );
};

// Helper function to format address
export const formatAddress = (details) => {
  if (!details?.address) return "Address not available";

  const { line_1, line_2, line_3, zipCode, country } = details.address;

  return [line_1, line_2, line_3, zipCode, country].filter(Boolean).join(", ");
};

// Helper function to filter and sort data
export const filterAndSortData = (allData) => {
  return allData
    .filter((item) => item.checkedList.directors || item.checkedList.reserve)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
};

// Helper function to get director type label
export const getLabel = (obj) => {
  if (obj.reserve) return "Reserve directors";
  if (obj.directors) return "Directors";
  return null;
};

// Helper function to map filtered data to director details
export const mapDirectorDetails = (filteredData, clientDataMap, addressMap) => {
  return filteredData.map((row) => {
    const masterId = row.invId || row.copId || row.relEntity;
    const masterType = row.invId
      ? "Individual"
      : row.copId
      ? "Corporate Entity"
      : "Related Entity";
    const addressData = addressMap.get(masterId) || {};

    return {
      _id: row._id,
      name: clientDataMap.get(masterId),
      dateOfAppointment: row.appointment
        ? moment(row.appointment).format("YYYY-MM-DD")
        : "-",
      address: row.invId
        ? addressData.individual
        : row.copId
        ? addressData.corporate
        : addressData.entity,
      directorType: getLabel(row.checkedList),
    };
  });
};

// Helper function to map history data to shareholder details
export const mapShareholderDetails = (
  historyData,
  clientIdToCodeMap,
  clientNameDataMap,
  shareholderTypeandInv
) => {

  return historyData
    .map((row) => {
      const shareholderType = shareholderTypeandInv.find(
        (item) =>
          item.invId === row.senderForm?.[0]?.masterId ||
          item.invId === row.allocationForm?.[0]?.masterId
      );
      return {
        ...row,
        senderMasterId: row.senderForm?.length
          ? clientIdToCodeMap.get(row.senderForm[0]?.masterId)
          : row.allocationForm?.length
          ? clientIdToCodeMap.get(row.allocationForm[0]?.masterId)
          : null,
        senderMasterName: row.senderForm?.length
          ? clientNameDataMap.get(row.senderForm[0]?.masterId)
          : row.allocationForm?.length
          ? clientNameDataMap.get(row.allocationForm[0]?.masterId)
          : null,
        shareNumber: row.senderForm?.length
          ? row.senderForm[0]?.certificateNumber
          : row.receiverForm?.length
          ? row.receiverForm[0]?.certificateNumber
          : row.allocationForm?.length
          ? row.allocationForm[0]?.certificateNumber
          : null,

        noShares: row.senderForm?.length
          ? row.senderForm[0]?.numberOfShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.numberOfShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.numberOfShares
          : null,

        sharesHeld: row.senderForm?.length
          ? row.senderForm[0]?.shareCapital
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareCapital
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareCapital
          : null,

        parValue: row.senderForm?.length
          ? `${row.senderForm[0]?.currency || ""} ${
              row.senderForm[0]?.parValue
            }`
          : row.receiverForm?.length
          ? `${row.receiverForm[0]?.currency || ""} ${
              row.receiverForm[0]?.parValue
            }`
          : row.allocationForm?.length
          ? `${row.allocationForm[0]?.currency || ""} ${
              row.allocationForm[0]?.parValue
            }`
          : null,

        ceasedDate: row.senderForm?.length
          ? row.senderForm[0]?.ceasedDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.ceasedDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.ceasedDate
          : null,

        effectiveDate: row.senderForm?.length
          ? row.senderForm[0]?.effectiveDate
          : row.receiverForm?.length
          ? row.receiverForm[0]?.effectiveDate
          : row.allocationForm?.length
          ? row.allocationForm[0]?.effectiveDate
          : null,

        totalShares: row.senderForm?.length
          ? row.senderForm[0]?.totalShares
          : row.receiverForm?.length
          ? row.receiverForm[0]?.totalShares
          : row.allocationForm?.length
          ? row.allocationForm[0]?.totalShares
          : null,

        percentage: row.senderForm?.length
          ? row.senderForm[0]?.percentage
          : row.receiverForm?.length
          ? row.receiverForm[0]?.percentage
          : row.allocationForm?.length
          ? row.allocationForm[0]?.percentage
          : null,

        comment: row.allocationForm?.length
          ? row.allocationForm[0]?.comment
          : null,

        currency: row.senderForm?.length
          ? row.senderForm[0]?.currency
          : row.receiverForm?.length
          ? row.receiverForm[0]?.currency
          : row.allocationForm?.length
          ? row.allocationForm[0]?.currency
          : null,

        shareType: row.senderForm?.length
          ? row.senderForm[0]?.shareType
          : row.receiverForm?.length
          ? row.receiverForm[0]?.shareType
          : row.allocationForm?.length
          ? row.allocationForm[0]?.shareType
          : null,

        shareholderType: shareholderType ? shareholderType.shareType : null,
        combinedForm: {
          sender: row.senderForm || [],
          receiver: row.receiverForm || [],
          allocation: row.allocationForm || [],
        },
      };
    })
    .sort((a, b) => Number(a.shareNumber) - Number(b.shareNumber));
};

export const extractShareTypeAndInvId = (data) => {
  try {
    if (!Array.isArray(data)) {
      throw new Error("Input data is not a valid array");
    }

    return data
      .map((item) => {
        if (!item.invId || !item.shareType) {
          console.warn(
            `Missing invId or shareType in item: ${JSON.stringify(item)}`
          );
          return null;
        }

        const filteredShareTypes = item.shareType
          .split(", ")
          .filter((type) => type.trim() !== "Directors")
          .join(", ");

        if (!filteredShareTypes) {
          return null;
        }

        return {
          invId: item.invId || null,
          shareType: filteredShareTypes,
        };
      })
      .filter((item) => item !== null);
  } catch (error) {
    console.error("Error in extractShareTypeAndInvId:", error);
    return [];
  }
};
