import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Select } from "antd";

import { generateRandom } from "../../helper";

import Client from "./components/client";
import IntroducerClient from "./components/introducer-client";
import ParentClient from "./components/parent-client";
import EndUserClient from "./components/end-user-client";
import Entity from "./components/entity";
import Individual from "../../components/IndividualAnalysis";
import CorporateEntity from "../../components/CorporateEntity";

export default function Creation() {
  const MFTOptions = [
    {
      value: "introducer-client",
      label: "Introducer Client",
    },
    {
      value: "end-user",
      label: "End User Client",
    },
    {
      value: "parent-client",
      label: "Parent Client",
    },
    {
      value: "client",
      label: "Client",
    },
    {
      value: "entity",
      label: "Entity",
    },
    {
      value: "individual",
      label: "Individual",
    },
    {
      value: "corporate-entity",
      label: "Corporate Entity",
    },
  ];

  const [selectedMFT, setSelectedMFT] = useState(null);
  const [clientClicked, setClientClicked] = useState(false);
  const [parentClicked, setParentClicked] = useState(false);
  const [endUserClicked, setEndUserClicked] = useState(false);
  const [entityClicked, setEntityClicked] = useState(false);
  const [copEntityClicked, setCopEntityClicked] = useState(false);
  const [individualClicked, setIndividualClicked] = useState(false);
  const [introducerClientClicked, setIntroducerClientClicked] = useState(false);
  const [selectedPage, setselectedPage] = useState('');
  const [refNumber, setRefNumber] = useState("");

  const handleButtonClick = () => {
    if (selectedPage == "client") {
      setClientClicked(true);
    } else if (selectedPage == 'introducer-client') {
      setIntroducerClientClicked(true);
    } else if (selectedPage == 'parent-client') {
      setParentClicked(true);
    } else if (selectedPage == "end-user-client") {
      setEndUserClicked(true)
    } else if (selectedPage == "entity") {
      setEntityClicked(true)
    } else if (selectedPage == 'corporate-entity') {
      setCopEntityClicked(true)
    } else if (selectedPage == 'individual') {
      setIndividualClicked(true)
    }
  };
  const getMFT = (value) => {
    let unix = generateRandom().toUpperCase();

    if (value == "client") {
      setRefNumber(`CL${unix}`);
    }else if(value == "introducer-client"){
      setRefNumber(`IC${unix}`);
    }else if(value == "individual"){
      setRefNumber(`IN${unix}`);
    }else if(value == "entity"){
      setRefNumber(`EN${unix}`);
    }else if(value == "end-user"){
      setRefNumber(`EU${unix}`);
    }else if(value == "parent-client"){
      setRefNumber(`PC${unix}`);
    }else if(value == "corporate-entity"){
      setRefNumber(`CO${unix}`);
    }
    setSelectedMFT(value);
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row mb-4">
        <h1 className="page-title mb-3 mb-md-0">Master File Creation</h1>
        {/* <Link to={"/app/pre-incorp-resolution"}> */}
        <div onClick={handleButtonClick} className="primary-btn">
          Create
        </div>
        {/* </Link> */}
      </div>
      <div className="custom-form">
        <h2 className="primary-title mb-4">Master File Type</h2>
        <div className="mb-4">
          <label htmlFor="" className="me-3 mb-1">
            Master File Type
          </label>
          <Select
            className="custom-select mw-300"
            placeholder="Select any"
            onChange={getMFT}
            options={MFTOptions}
          />
        </div>
      </div>
      {selectedMFT == "client" && <Client clientClicked={clientClicked}refCode={refNumber} setselectedPage={setselectedPage} setClientClicked={setClientClicked} />}
      {selectedMFT == "introducer-client" && <IntroducerClient introducerClientClicked={introducerClientClicked}refCode={refNumber}  setselectedPage={setselectedPage} setIntroducerClientClicked={setIntroducerClientClicked} />}
      {selectedMFT == "parent-client" && <ParentClient parentClicked={parentClicked} setselectedPage={setselectedPage}refCode={refNumber}  setParentClicked={setParentClicked} />}
      {selectedMFT == "end-user" && <EndUserClient endUserClicked={endUserClicked} setselectedPage={setselectedPage}refCode={refNumber}  setEndUserClicked={setEndUserClicked} />}
      {selectedMFT == "entity" && <Entity entityClicked={entityClicked} setselectedPage={setselectedPage}refCode={refNumber}  setEntityClicked={setEntityClicked} />}
      {selectedMFT == "individual" && (
        <div>
          <h2 className="sub-title mb-4">Master File Creation: Individual</h2>
          <Individual fileCreation={true} individualClicked={individualClicked} setselectedPage={setselectedPage}refCode={refNumber}  setIndividualClicked={setIndividualClicked} />
        </div>
      )}
      {selectedMFT == "corporate-entity" && (
        <div>
          <h2 className="sub-title mb-4">
            Master File Creation: Corporate Entity
          </h2>
          <CorporateEntity fileCreation={true} copEntityClicked={copEntityClicked} setselectedPage={setselectedPage}refCode={refNumber}  setCopEntityClicked={setCopEntityClicked} />
        </div>
      )}
    </div>
  );
}
