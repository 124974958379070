const extractDirectors = (details, isCopEntity = false) => {
  const directors = [];

  details.forEach((row) => {
    const { checkedList, individualName, appointment, copEntityName } = row;

    if (checkedList.directors) {
      directors.push({
        name: isCopEntity ? copEntityName : individualName,
        dateOfAppointment: appointment ? formatDate(appointment) : null,
      });
    }
  });

  return directors;
};

const extractShareholders = (details, isCopEntity = false) => {
  const shareholders = [];

  details.forEach((row) => {
    const { checkedList, individualName, noOfShares, copEntityName } = row;

    if (checkedList.shareholders) {
      shareholders.push({
        name: isCopEntity ? copEntityName : individualName,
        shares: noOfShares || "",
      });
    }
  });

  return shareholders;
};

const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  const day = dateObj.getUTCDate().toString().padStart(2, "0"); // Add leading zero if single digit
  const month = dateObj.toLocaleString("default", { month: "long" });
  const year = dateObj.getUTCFullYear();
  return `${day} ${month} ${year}`;
};

const getCompanyAddress = (register) => {
  if (register && register.address) {
    const { line_1, line_2, line_3 } = register.address;
    return [line_1, line_2, line_3].filter((line) => line).join(", ");
  }
  return "";
};

const createEmailTemplateParams = (
  registeredAgentName,
  registeredAgentAddress,
  entity,
  individualDetails,
  copEntityDetails,
  emailCategory,
  registerOfCharges,
  signature,
  recieverMail,
  directorDetails,
  shahreholderDetails
) => {
  const directors = [
    ...extractDirectors(individualDetails),
    ...extractDirectors(copEntityDetails, true),
  ];

  const shareholders = [
    ...extractShareholders(individualDetails),
    ...extractShareholders(copEntityDetails, true),
  ];

  const dateObj = new Date(entity?.incorporationDate || null);
  const currentDateTime = new Date(); // Current moment
  const currentDate = currentDateTime.getUTCDate();
  const currentMonth = currentDateTime.toLocaleString("default", {
    month: "long",
  });
  const currentYear = currentDateTime.getUTCFullYear();

  return {
    registeredAgentName: registeredAgentName,
    registeredAgentAddress: entity?.registeredAgentaddress ?? "",
    companyName: entity?.name,
    incorperatedIn: entity?.jurisdiction,
    incorporationDate: dateObj.getUTCDate(),
    incorporationMonth: dateObj.toLocaleString("default", {
      month: "long",
    }),
    incorporationYear: dateObj.getUTCFullYear(),
    companyNumber: entity?.incorporationNumber,
    registerOfCharges: registerOfCharges,
    directors: directorDetails,
    shareholders: shahreholderDetails,
    signatureDate: currentDate,
    signatureMonth: currentMonth,
    signatureYear: currentYear,
    authorizedSignatory: signature ?? [],
    recieverEmail: recieverMail || "",
    emailCategory: emailCategory,
    capNo: "",
    effectiveDate: currentDate,
    effectiveMonth: currentMonth,
    effectiveYear: currentYear,
    continueDate: currentDate,
    continueMonth: currentMonth,
    continueYear: currentYear,
    companyAddress: entity?.registeredOfficeaddress ?? "",
    clientName: entity?.clientName ?? "",
  };
};

const getEmailData = (
  serviceRequestName,
  type,
  serviceRequestData,
  businessPartnerDetails
) => {
  return {
    emailTemplate: getEmailTemplate(type),
    emailType: "Service Request",
    emailBody: getEmailBody(type, serviceRequestData, businessPartnerDetails),
    receiverEmail: getReceiverEmail(
      type,
      serviceRequestData,
      businessPartnerDetails
    ),
    emailHeading: getEmailHeading(serviceRequestName, serviceRequestData),
    attachments: getEmailAttachments(type, serviceRequestData),
  };
};

const getEmailTemplate = (type) =>
  type === "client" ? "service-request-client.html" : "service-request-RA.html";

const getEmailBody = (type, serviceRequestData, businessPartnerDetails) => {
  if (type === "client") {
    return {
      receiverName: serviceRequestData.requestedBy.name,
      businessPartnerName: businessPartnerDetails.otherDetails.companyName,
      entityName: serviceRequestData.entityName,
    };
  } else if (type === "RA") {
    return {
      businessPartnerName: businessPartnerDetails.otherDetails.companyName,
      entityName: serviceRequestData.entityName,
    };
  }
};

const getReceiverEmail = (type, serviceRequestData, businessPartnerDetails) => {
  if (type === "client") {
    return [serviceRequestData.requestedBy.email];
  } else if (type === "RA") {
    return [businessPartnerDetails.parentEmail];
  }
};

const getEmailHeading = (serviceRequestName, serviceRequestData) =>
  `${serviceRequestName}-${serviceRequestData.entityName}-${
    new Date().toISOString().split("T")[0]
  }`;

const getEmailAttachments = (type, serviceRequestData) => {
  const folder = "test";

  const files = serviceRequestData?.documents.map((doc) => ({
    fileName: doc.name,
    url: doc.url,
  }));

  return { folder, files };
};

const getEntityOnboardingEmailData = (
  incorporationDetails,
  businessPartnerDetails
) => {
  return {
    emailTemplate: "client-entity-onboarding.html",
    emailType: "Entity Onboard",
    emailBody: getEntityOnboardingEmailBody(
      incorporationDetails,
      businessPartnerDetails
    ),
    receiverEmail: [incorporationDetails?.entityContacts[0]?.data.email ?? ""],
    emailHeading: `Onboarding for ${incorporationDetails.entityName}`,
  };
};

const getEntityOnboardingEmailBody = (
  incorporationDetails,
  businessPartnerDetails
) => {
  return {
    entityName: incorporationDetails.entityName,
    businessPartnerName: businessPartnerDetails.otherDetails.companyName,
    contactEmail: businessPartnerDetails.parentEmail,
  };
};

export {
  extractDirectors,
  extractShareholders,
  formatDate,
  createEmailTemplateParams,
  getEmailData,
  getEntityOnboardingEmailData,
};
