import React, { useState } from "react";
import { Table, Tag, Modal, Select } from "antd";
import { Link } from "react-router-dom";

import SampleDoc from "../assets/pdf/sample.pdf";

import { downloadFile, formatBytes, Each } from "../helper";

import UploadZ from "../components/UploadZ";
import TableSortArrows from "../components/tableSortArrows";
import EntityRAF from "../components/EntityRAF";

export default function MLRO() {
  const [requestData, setRequestData] = useState(null);
  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");
  const [showRejectReason, setShowRejectReason] = useState(false);
  const [countryRiskFromData, setCountryRiskFromData] = useState({});
  const [entityDetailFormData, setEntityDetailFromData] = useState({});
  const [overallRiskFromData, setOverallRiskFromData] = useState({});
  const entityDetailsOnChange = async (value, name) => {
    setEntityDetailFromData(value);
  };
  const countryRiskDtaOnChange = async (value, name) => {
    setCountryRiskFromData(value);
  };
  const overallRiskDataOnChange = async (value, name) => {
    setOverallRiskFromData(value);
  };

  const [openRAF, setOpenRAF] = useState(false);
  const handleCloseRAF = () => {
    console.log("clicked cancel button");
    setOpenRAF(false);
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          value={status}
          style={{
            width: 160,
          }}
          options={[
            {
              value: "approved",
              label: "Approved",
            },
            {
              value: "rejected",
              label: "Rejected",
            },
          ]}
        />
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, data) => (
        <div className="custom-form">
          <input
            type="text"
            name="comments"
            className="w-100"
            id="comments"
            placeholder="Enter comment"
          />
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy - b.createdBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      key: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      key: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Requestor ID",
    //   dataIndex: "requestorID",
    //   sorter: (a, b) => a.requestorID - b.requestorID,
    //   sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      sorter: (a, b) => a.reviewStatus - b.reviewStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { reviewStatus }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          value={reviewStatus}
          style={{
            width: 160,
          }}
          options={[
            {
              value: "in-progress",
              label: "In Progress",
            },
            {
              value: "completed",
              label: "Completed",
            },
            {
              value: "pending-info",
              label: "Pending Info",
            },
          ]}
        />
      ),
    },
    {
      title: "Reviewer",
      dataIndex: "processor",
      sorter: (a, b) => a.processor - b.processor,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "RAF",
    //   dataIndex: "ref",
    //   sorter: (a, b) => a.ref - b.ref,
    //  sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    //   render: (_, data) => (
    //     <button className="transparent-btn">
    //       <i className="far fa-arrow-to-bottom"></i>
    //     </button>
    //   ),
    // },
    {
      title: "Signed RAF",
      dataIndex: "signedRAF",
      sorter: (a, b) => a.signedRAF - b.signedRAF,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, data) => (
        <div className="table-upload">
          <label
            htmlFor="doc"
            className="action-btn outline-btn"
            onClick={() => setOpenRAF(true)}
          >
            Execute
          </label>
        </div>
      ),
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Approved At",
      dataIndex: "approvedAt",
      sorter: (a, b) => a.approvedAt - b.approvedAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Actions",
      fixed: "right",
      width: 180,
      render: (_, data) => (
        <button onClick={() => viewRequest(data)} className="action-btn">
          View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i>
        </button>
      ),
    },
  ];
  const renderRAFModal = () => {
    return (
      openRAF && (
        <Modal
          title="Risk Assessment Form - Entity"
          className="custom-modal"
          open={openRAF}
          onCancel={handleCloseRAF}
          width={1300}
          centered
          footer={null}
        >
          <EntityRAF
            entityDetailFormData={entityDetailFormData}
            entityDetailsOnChange={entityDetailsOnChange}
            countryRiskFromData={countryRiskFromData}
            countryRiskDtaOnChange={countryRiskDtaOnChange}
            overallRiskFromData={overallRiskFromData}
            overallRiskDataOnChange={overallRiskDataOnChange}
            isCooperate={false}
          />
        </Modal>
      )
    );
  };

  const data = [
    {
      key: "1",
      id: "1",
      name: "Task #1",
      type: "Active",
      status: "approved",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "completed",
      approvedAt: "08/11/2023 04:56PM",
      processor: "Hari",
      approvedBy: "Guru",
    },
    {
      key: "2",
      id: "2",
      name: "Task #2",
      type: "Active",
      status: "rejected",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "pending-info",
      processor: "Hari",
      approvedAt: "08/11/2023 04:56PM",
      approvedBy: "Guru",
    },
    {
      key: "3",
      id: "3",
      name: "Task #3",
      type: "Active",
      status: "approved",
      createdAt: "07/11/2023 04:56PM",
      createdBy: "John Doe",
      entityName: "Trust",
      entityCode: "9012",
      requestorID: "2343",
      reviewStatus: "in-progress",
      processor: "Hari",
      approvedAt: "08/11/2023 04:56PM",
      approvedBy: "Guru",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const viewRequest = (data) => {
    setRequestData(data);
  };

  const viewDocument = (docName) => {
    setViewDocName(docName);
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
  };

  const DownloadDoc = () => {
    downloadFile(SampleDoc, "sample_doc.pdf");
  };

  // const documents = {
  //   url: SampleDoc,
  //   name: "Reviewed AML and STAT Pack.pdf",
  //   size: "500 KB",
  //   type: "pdf",
  //   base64: "",
  // };

  const documents = [
    {
      url: SampleDoc,
      name: "Inaugural Resolution",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Appointment of First Director",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Resolution of Share Allotment",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Corporate Seal Draft",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Directors Consent Letter",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "Customized M&AA",
      size: 293333,
      type: "pdf",
      base64: "",
    },
    {
      url: SampleDoc,
      name: "UBO Source of Funds Declaration",
      size: 293333,
      type: "pdf",
      base64: "",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h1>MLRO Sign Off</h1>
        <Link to={"/app/new-incorporation"} className="primary-btn">
          Submit
        </Link>
      </div>
      <div className="page-content">
        <div className="mb-3 d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
          <h2 className="primary-title mb-3 mb-md-0">MLRO Approval Status</h2>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <button className="outline-btn">
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn">
              <i className="far fa-paper-plane"></i> Send Feedback
            </button>
          </div>
        </div>
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: 3000 }}
        />
        {renderRAFModal()}
        {requestData && (
          <div className="mt-4">
            <h2 className="primary-title mb-3">Approval of MLRO</h2>
            <h4 className="sub-title mb-2">Task Name: {requestData?.name}</h4>
            <h4 className="sub-title">
              {" "}
              Current Status:{" "}
              <span
                className={
                  requestData?.status == "Y" ? "text-green" : "text-red"
                }
              >
                {requestData?.status == "Y" ? "Approved" : "Rejected"}
              </span>
            </h4>
            <div className="custom-form mt-4">
              <div className="row">
                {/* <div className="col-md-3">
                <label htmlFor="" className="me-3">
                  Reviewed AML and STAT Pack
                </label>
                <UploadZ file={documents} downloadOnly={true} />
              </div> */}
                <Each
                  array={documents}
                  render={(item) => (
                    <div className="col-md-3">
                      <label className="custom-checkbox mb-3">
                        {item.name}
                      </label>
                      <UploadZ file={item} downloadOnly={true} />
                    </div>
                  )}
                />
              </div>
              <div className="d-flex gap-3 my-4">
                <button className="outline-btn green-btn">Approve</button>
                <button
                  className="outline-btn red-btn"
                  onClick={() => setShowRejectReason(true)}
                >
                  Reject
                </button>
              </div>
              {showRejectReason && (
                <div>
                  <label htmlFor="" className="me-3">
                    Reason for Rejection
                  </label>
                  <textarea
                    name=""
                    id=""
                    className="w-100"
                    placeholder="Enter a description..."
                    rows="5"
                  ></textarea>
                </div>
              )}
            </div>
          </div>
        )}
        <Modal
          title={viewDocName}
          className="custom-modal"
          open={docModal}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="document-view">
            <iframe
              src={SampleDoc}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal>
      </div>
    </div>
  );
}
