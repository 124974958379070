import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Table, Tag, Modal } from "antd";
import dayjs from "dayjs";

import FormZ from "../components/FormZ";

import { sendNotify, getOptions, fetchApi, generateRandom, getQuery } from "../helper";

import { getIncorporation } from "../store/incorporation/incorpSlice";

import searchFilter from "../components/searchFilter";

export default function Incorporation() {
  let queryId = getQuery();

  const country = useSelector((state) => state.country);
  const incorp = useSelector((state) => state.incorp);
  let userDetails = useSelector((state) => state.auth.userDetails);

  const proposedFormRef = useRef();
  const jurisdictionFormRef = useRef();

  const [newNameModal, setNewNameModal] = useState(false);

  const [openedTask, setOpenedTask] = useState(null);
  const [viewNameModal, setViewNameModal] = useState(false);

  const [reservedNames, setReservedNames] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [refNumber, setRefNumber] = useState("");

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIncorporation(queryParams));
    if (queryId?.id) {
      readUpdate(queryId.id);
    }
  }, []);

  const handleSearch = (searchFilters) => {
    let query = { ...queryParams, ...searchFilters };
    dispatch(getIncorporation(query));
    setQueryParams(query);
  };

  const readUpdate = async (Id) => {
    let data = { ids: [Id] }

    let payload = {
      method: "put",
      url: `/notifications/readUpdate`,
      data,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        dispatch(getIncorporation(queryParams));
        setViewNameModal(false);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);
        sendNotify("error", error?.message);
        handleCancel();
      });

    // if (result && result.data) {
    //   dispatch(getUnreadMessageCountSuccess(result.data));
    // }
  };


  const handleReset = (key) => {
    let query = { ...queryParams };
    delete query[key];
    dispatch(getIncorporation(query));
    setQueryParams(query);
  };

  const tableColumns = [
    {
      title: "Reference Number",
      dataIndex: "refNumber",
      sorter: (a, b) => a.refNumber - b.refNumber,
      ...searchFilter({
        dataIndex: "refNumber",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: "Task Req Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (_, { createdAt }) => {
        return dayjs(createdAt).format("DD/MM/YYYY - hh:mm A");
      },
      ...searchFilter({
        dataIndex: "createdAt",
        inputType: "dateRange",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: "Requested By",
      dataIndex: "requestedBy",
      sorter: (a, b) => a.requestedBy - b.requestedBy,
      ...searchFilter({
        dataIndex: "requestedBy",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      ...searchFilter({
        dataIndex: "clientName",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: "Jurisdiction",
      dataIndex: "jurisdiction",
      sorter: (a, b) => a.jurisdiction - b.jurisdiction,
      ...searchFilter({
        dataIndex: "jurisdiction",
        inputType: "select",
        options: getOptions(country?.data),
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: "Current Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      ...searchFilter({
        dataIndex: "status",
        inputType: "select",
        options: [
          {
            label: "Pending",
            value: "Pending",
          },
          {
            label: "Submitted",
            value: "Submitted",
          },
        ],
        queryParams,
        handleSearch,
        handleReset,
      }),
      render: (_, { status }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={status == "Submitted" ? "green" : "gold"}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (_, { refNumber, status }) => (
        <button
          className="action-btn"
          disabled={status != "Submitted"}
          onClick={() => viewTask(_)}
        >
          <i className="far fa-eye"></i> View Name
        </button>
      ),
    },
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(incorp?.data?.rows);
  }, [incorp]);

  const openCreateNewReq = () => {
    let unix = generateRandom().toUpperCase();
    setRefNumber(`RF${unix}`);
    setNewNameModal(true);
  };

  const viewTask = (data) => {
    setOpenedTask(data);
    let names = [];
    let reservationStatus = [];
    Object.keys(data?.option).forEach(function (item, index) {
      names.push({
        key: index,
        id: index + 1,
        name: data?.option[item]?.name,
        status: data?.option[item]?.isValid,
        reservationType: data?.option[item]?.reservationType ?? null,
      });
      reservationStatus.push({
        key: "op_" + (index + 1),
        type: data?.option[item]?.reservationType,
      });
    });
    setNameData(names);
    setReservedNames(reservationStatus);
    setViewNameModal(true);
  };

  let proposedFormSchema = [
    {
      name: "option1",
      value: "",
      type: "text",
      label: "Option 01",
      placeholder: "Enter Name",
      required: true,
    },
    {
      name: "option2",
      value: "",
      type: "text",
      label: "Option 02",
      placeholder: "Enter Name",
    },
    {
      name: "option3",
      value: "",
      type: "text",
      label: "Option 03",
      placeholder: "Enter Name",
    },
  ];

  let jurisdictionFormSchema = [
    {
      name: "refNumber",
      value: "",
      type: "text",
      label: "Reference Number",
      disabled: true,
    },
    {
      name: "clientName",
      value: "",
      type: "text",
      label: "Client Name",
      disabled: true,
    },
    {
      name: "jurisdiction",
      value: "",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(country?.data),
      loading: country?.isLoading,
      disabled: true,
    },
    // {
    //   name: "entityType",
    //   value: "",
    //   type: "select",
    //   label: "Entity Type",
    //   placeholder: "Select entity type",
    //   options: [
    //     {
    //       value: "bvibc",
    //       label: "BVIBC",
    //     },
    //     {
    //       value: "trust",
    //       label: "Trust",
    //     },
    //     {
    //       value: "generalPartnership",
    //       label: "General Partnership",
    //     },
    //     {
    //       value: "limitedPartnership",
    //       label: "Limited Partnership",
    //     },
    //     {
    //       value: "foundation",
    //       label: "Foundation",
    //     },
    //   ],
    //   customClass: "col-md-3",
    // },
  ];

  const handleCancel = () => {
    setNewNameModal(false);
    setViewNameModal(false);
    proposedFormRef?.current?.clearForm();
    jurisdictionFormRef?.current?.clearForm();
    setRefNumber("");
    setReservedNames([]);
    setNameData([]);
  };

  const nameColumns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Proposed Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Current Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (_, { status }) => {
        return (
          <Tag
            icon={<i className="fad fa-dot-circle me-1"></i>}
            color={status ? "green" : "red"}
          >
            {status ? "Available" : "Rejected"}
          </Tag>
        );
      },
    },
    {
      title: "Reservation",
      dataIndex: "reservationType",
      render: (_, { status, reservationType }, index) => {
        let rType = reservedNames.find(
          (r) => r.key == `op_` + (index + 1)
        )?.type;
        return (
          <div className="d-flex gap-3">
            <button
              className={`action-btn secondary-btn ${"O" == rType ? "btn-checked" : ""
                }`}
              onClick={() => updateReservation(`op_` + (index + 1), "O", index)}
              disabled={!status || reservationType}
            >
              <i
                className={`far ${"O" == rType ? "fa-check" : "fa-money-check"
                  }`}
              ></i>
              Ordinary
            </button>
            <button
              className={`action-btn ${"P" == rType ? "btn-checked" : ""}`}
              disabled={!status || reservationType}
              onClick={() => updateReservation(`op_` + (index + 1), "P", index)}
            >
              <i
                className={`far ${"P" == rType ? "fa-check" : "fa-dollar-sign"
                  }`}
              ></i>
              Paid
            </button>
          </div>
        );
      },
    },
  ];

  const sendReq = () => {
    if (proposedFormRef.current.validFrom()) {
      let options = proposedFormRef.current.getPayload();
      let details = jurisdictionFormRef.current.getPayload();
      let obj = {
        options: Object.values(options),
        ...details,
      };
      let payload = {
        method: "POST",
        url: `/incorporation`,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
          dispatch(getIncorporation(queryParams));
          handleCancel();
        })
        .catch((error) => {
          console.log(error);
          sendNotify("error", error?.message);
          handleCancel();
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const updateReservation = (key, type) => {
    let arr = [...reservedNames];
    let find = arr.find((item) => item?.key == key);
    let findIndex = arr.findIndex((item) => item?.key == key);
    if (find) {
      if (find?.type == type) {
        arr.splice(findIndex, 1);
      } else {
        arr[findIndex] = {
          key,
          type,
        };
      }
    } else {
      arr.push({
        key,
        type,
      });
    }
    setReservedNames(arr);
  };

  const submitReservation = () => {
    let data = reservedNames.reduce((acc, curr) => {
      acc[curr.key] = curr.type;
      return acc;
    }, {});

    let payload = {
      method: "put",
      url: `/incorporation/reservation/${openedTask?._id}`,
      data,
    };
    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        dispatch(getIncorporation(queryParams));
        setViewNameModal(false);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);
        sendNotify("error", error?.message);
        handleCancel();
      });
  };

  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getIncorporation(params));
    setQueryParams(params);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-3 mb-md-0">Incorporation Name Check</h1>
        {/* <Link to={"/app/onboarding-team/edit/1"} className="primary-btn">
          Submit
        </Link> */}
        <button className="primary-btn" onClick={openCreateNewReq}>
          New Create
        </button>
      </div>
      <h2 className="primary-title mb-2">Proposed Name</h2>
      <Table
        className="custom-table"
        loading={incorp?.isLoading}
        rowKey={"_id"}
        columns={tableColumns}
        dataSource={tableData}
        pagination={{
          className: "custom-pagination",
          defaultCurrent: 1,
          pageSize: queryParams?._limit,
          total: incorp?.data?.totalCount,
          onChange: getPagination,
        }}
      />
      <Modal
        title={`Reference Number #${openedTask?.refNumber}`}
        className="custom-modal"
        open={viewNameModal}
        width={800}
        onOk={submitReservation}
        okText="Submit"
        onCancel={handleCancel}
      >
        <div>
          {/* <div className="d-flex gap-md-5 flex-column flex-md-row">
            <p className="head-label">
              <b>Requestor Ref:</b> John Doe
            </p>
            <p className="head-label">
              <b>Request Date:</b> 20/10/2023
            </p>
          </div> */}
          <Table
            className="custom-table"
            columns={nameColumns}
            dataSource={nameData}
            pagination={false}
          />
        </div>
      </Modal>
      <Modal
        title={"New Incorporation Name Check"}
        className="custom-modal"
        open={newNameModal}
        width={800}
        onOk={sendReq}
        onCancel={handleCancel}
        okText="Sent Request"
      >
        <div className="custom-form pt-2">
          <FormZ
            formSchema={proposedFormSchema}
            ref={proposedFormRef}
            formClass=""
            childClass="form-row mb-4"
            labelClass="me-3 mb-0"
            inputClass="w-100"
          />
          <h2 className="sub-title mb-4">Jurisdiction</h2>
          <FormZ
            formSchema={jurisdictionFormSchema}
            formData={{
              refNumber,
              clientName: userDetails?.parentName,
              jurisdiction: "BVI",
            }}
            ref={jurisdictionFormRef}
            formClass="row mb-4 gy-3 gx-4"
            childClass="col-md-4 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
        </div>
      </Modal>
    </div>
  );
}
