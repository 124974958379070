import React, { useState, useRef } from "react";
import { Table, Tag, Select, Modal, DatePicker, Checkbox } from "antd";
import dayjs from "dayjs";

import FormZ from "../components/FormZ";
import { Each, downloadAsCSV, isArrayEmpty } from "../helper";

const { RangePicker } = DatePicker;

export default function AuditTrail() {
  const [items, setItems] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);

  const searchFormRef = useRef();
  const filterFormRef = useRef();

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  let searchFormSchema = [
    {
      name: "parentClientName",
      value: "",
      type: "select",
      label: "Parent Client Name",
      placeholder: "Select parent client name",
      options: [
        {
          value: "parentClient1",
          label: "Parent Client 1",
        },
        {
          value: "parentClient2",
          label: "Parent Client 2",
        },
      ],
    },
    {
      name: "parentClientCode",
      value: "",
      type: "select",
      label: "Parent Client Code",
      placeholder: "Select parent client code",
      options: [
        {
          value: "00444",
          label: "00444",
        },
        {
          value: "00437",
          label: "00437",
        },
      ],
    },
    {
      name: "entityName",
      value: "",
      type: "select",
      label: "Entity Name",
      placeholder: "Select entity name",
      options: [
        {
          value: "entity1",
          label: "Entity 1",
        },
        {
          value: "entity2",
          label: "Entity 2",
        },
      ],
    },
    {
      name: "entityCode",
      value: "",
      type: "select",
      label: "Entity Code",
      placeholder: "Select entity code",
      options: [
        {
          value: "00444",
          label: "00444",
        },
        {
          value: "00437",
          label: "00437",
        },
      ],
    },
    {
      name: "entityStatus",
      value: "",
      type: "select",
      label: "Entity Status",
      placeholder: "Select entity status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
        {
          value: "resigned",
          label: "Resigned",
        },
        {
          value: "continuationOut",
          label: "Continuation Out",
        },
        {
          value: "continuationIn",
          label: "Continuation In",
        },
      ],
    },
    {
      name: "clientName",
      value: "",
      type: "select",
      label: "Client Name",
      placeholder: "Enter client name",
      options: [
        {
          value: "Client1",
          label: "Client 1",
        },
        {
          value: "Client2",
          label: "Client 2",
        },
      ],
    },
    {
      name: "clientCode",
      value: "",
      type: "select",
      label: "Client Code",
      placeholder: "Enter client code",
      options: [
        {
          value: "00444",
          label: "00444",
        },
        {
          value: "00437",
          label: "00437",
        },
      ],
    },
    {
      name: "user",
      value: "",
      type: "select",
      label: "User",
      placeholder: "Enter user",
      options: [
        {
          value: "user1",
          label: "User 1",
        },
        {
          value: "user2",
          label: "User 2",
        },
      ],
    },
    {
      label: "Date Stored",
      customClass: "col-md-3",
      customElement: (
        <RangePicker
          disabledDate={disabledDate}
          className="custom-datepicker"
        />
      ),
    },
    {
      label: "",
      customElement: (
        <button className="primary-btn mb-15">
          <i className="fal fa-search"></i> Initiate Search
        </button>
      ),
    },
  ];

  let filterFormSchema = [
    {
      name: "entityName",
      value: "",
      type: "select",
      label: "Entity Name",
      placeholder: "Select entity name",
      options: [
        {
          value: "entity1",
          label: "Entity 1",
        },
        {
          value: "entity2",
          label: "Entity 2",
        },
      ],
    },
    {
      name: "entityCode",
      value: "",
      type: "select",
      label: "Entity Code",
      placeholder: "Select entity code",
      options: [
        {
          value: "00444",
          label: "00444",
        },
        {
          value: "00437",
          label: "00437",
        },
      ],
    },
    {
      name: "entityStatus",
      value: "",
      type: "select",
      label: "Entity Status",
      placeholder: "Select entity status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
        {
          value: "resigned",
          label: "Resigned",
        },
        {
          value: "continuationOut",
          label: "Continuation Out",
        },
        {
          value: "continuationIn",
          label: "Continuation In",
        },
      ],
    },
    {
      name: "clientName",
      value: "",
      type: "select",
      label: "Client Name",
      placeholder: "Enter client name",
      options: [
        {
          value: "Client1",
          label: "Client 1",
        },
        {
          value: "Client2",
          label: "Client 2",
        },
      ],
    },
    {
      name: "clientCode",
      value: "",
      type: "select",
      label: "Client Code",
      placeholder: "Enter client code",
      options: [
        {
          value: "00444",
          label: "00444",
        },
        {
          value: "00437",
          label: "00437",
        },
      ],
    },
    {
      label: "",
      customElement: (
        <button className="primary-btn mb-15">
          <i className="fal fa-search"></i> Initiate Search
        </button>
      ),
    },
  ];

  const columns = [
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    },
    {
      title: "Entity Name",
      dataIndex: "Entity Name",
      sorter: (a, b) => a["Entity Name"] - b["Entity Name"],
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag color="green" className="custom-tag">
          Active
        </Tag>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
    },
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      sorter: (a, b) => a.dateTime - b.dateTime,
    },
    {
      title: "User",
      dataIndex: "user",
      sorter: (a, b) => a.user - b.user,
    },
    {
      title: "Item",
      dataIndex: "item",
      sorter: (a, b) => a.item - b.item,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, { action }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          {action}
        </Tag>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      task: "Change Of Director",
      user: "Guru",
      item: "Settled -> Pending",
      action: "Approval",
      dateTime: "23/04/2023",
    },
    {
      key: "2",
      id: "2",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      task: "Change Of Director",
      user: "Guru",
      item: "Settled -> Pending",
      action: "Review",
      dateTime: "23/04/2023",
    },
    {
      key: "3",
      id: "3",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      task: "Change Of Director",
      user: "Guru",
      item: "Settled -> Pending",
      action: "Request",
      dateTime: "23/04/2023",
    },
  ];

  const onExportClick = () => {
    downloadAsCSV(data, "master-search-data-export");
  };

  const plainOptions = [
    "Entity Name",
    "Entity Type",
    "Entity Code",
    "Entity Status",
    "Client Name",
    "Client Code",
    "Client Type",
    "Client Status",
    "Parent Client Code",
    "Parent Client Name",
    "Parent Client Status",
    "Incorporation Number",
    "Jurisdiction",
    "Incorporation Date",
    "Entity Grouping",
    "Related Entities",
    "Composite Risk Level",
    "Entity Risk Level",
    "RM Name",
    "Final Incorp pack",
    "Primary Contact Name",
    "Primary Contact Email",
    "Primary Contact Mobile",
    "Location of Registers",
    "Location of Financial Records",
    "Individual",
    "Corporate Entity",
    "Source of Wealth",
    "Source of Funds",
    "Purpose of Business",
    "Geography of Business",
    "Business Activity",
    "Industry",
    "Business Risk",
    "Entity Last Reviewed Date",
    "Entity Next Reviewed Date",
    "Overall Entity Risk Level",
    "Reminder",
  ];

  const checkAll = (e) => {
    setItems(e.target.checked ? plainOptions : []);
  };

  const onChange = (checkedValues) => {
    setItems(checkedValues);
  };

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    // let obj = { ...list };
    // const { draggingItem, items } = obj;

    if (!draggingItem) return;

    const currentIndex = items.indexOf(draggingItem);
    const targetIndex = items.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      items.splice(currentIndex, 1);
      items.splice(targetIndex, 0, draggingItem);
      setItems(items);
    }
    console.log(items);
  };

  const removeItem = (index) => {
    let arr = [...items];
    arr.splice(index, 1);
    setItems(arr);
  };

  return (
    <div>
      <h1 className="page-title mb-3 mb-md-5">Audit Trail</h1>
      <h6 className="primary-title mb-1 mb-md-4">Search</h6>
      <FormZ
        formClass="row custom-form mb-2 mb-md-3 align-items-end gy-3"
        childClass="col-md-2 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
        formSchema={searchFormSchema}
        ref={searchFormRef}
      />
      {/* <div className="d-flex justify-content-end">
        <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button>
      </div> */}
      <Table
        className="custom-table"
        columns={columns}
        dataSource={data}
        scroll={{ x: 1800 }}
      />
      <h6 className="primary-title mb-1 mb-md-4">Reporting</h6>
      <FormZ
        formClass="row custom-form mb-2 mb-md-4 align-items-end gy-3"
        childClass="col-md-2 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
        formSchema={filterFormSchema}
        ref={filterFormRef}
      />
      <div className="row">
        <div className="col-md-3">
          <div className="check-list scroll-500">
            <h2 className="list-title">Select column you need to export:</h2>
            <Checkbox onChange={checkAll} className="custom-checkbox mb-2">
              All
            </Checkbox>
            <Checkbox.Group
              options={plainOptions}
              value={items}
              onChange={onChange}
              className="custom-checkbox"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="selected-list scroll-500">
            <div className="d-flex justify-content-between mb-3">
              <h2 className="list-title mb-0">Rearrange column if needed:</h2>
              <button
                className="primary-btn py-2"
                disabled={isArrayEmpty(items)}
              >
                Export
              </button>
            </div>
            <Each
              array={items}
              render={(item, index) => (
                <div
                  key={index}
                  className={`item ${item === draggingItem ? "dragging" : ""}`}
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, item)}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, item)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-3">
                      <span>
                        <i className="far fa-bars"></i>
                      </span>
                      <span>{item}</span>
                    </div>
                    <button
                      className="remove-btn"
                      onClick={() => removeItem(index)}
                    >
                      <i className="fal fa-times"></i>
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
