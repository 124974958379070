import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams,sendNotify } from "../../helper"; 

export const saveRiskAssessmentData = createAsyncThunk(
    "riskAssessment/createRiskAssessmentData",
    async (formData) => {
      const payload = {
        method: "POST",
        url: `/riskAssessmentDetails`,
        data: formData,
      };
  
      const res = await fetchApi(payload).then((res) => res.data);
      if(res){
        sendNotify("success", "Successfully Saved");
      }else{
        sendNotify("error", "Error Occurred");
      }
      
      return res;
    }
  );

export const fetchRiskAssessmentDataByEntityId = createAsyncThunk(
  "riskAssessment/fetchRiskAssessmentData",
  async (entity) => {
    const payload = {
      method: "GET",
      url: `/riskAssessmentDetails/${entity.entityId}`,
    };

    const res = await fetchApi(payload).then((res) => {
    if(res?.data?.rows){
      return res.data.rows[0] ?? {};
    }else {
      return {};
    }
    });

    return res;
  }
);

const riskAssessmentSlice = createSlice({
  name: "riskAssessment",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    success: false,
  },
  reducers: {
    resetSuccess(state) {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRiskAssessmentDataByEntityId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchRiskAssessmentDataByEntityId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
    });

    builder.addCase(fetchRiskAssessmentDataByEntityId.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });

    builder.addCase(saveRiskAssessmentData.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(saveRiskAssessmentData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.isError = false;
        state.data = action.payload;
      });
      builder.addCase(saveRiskAssessmentData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const {saveSuccess, saveError, resetSuccess } = riskAssessmentSlice.actions;

export default riskAssessmentSlice.reducer;
