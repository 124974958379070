import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

const Jurisdiction = (props) => {
  let { className, handleChange, value, disabled, placeholder } = props;

  const { data, isLoading } = useSelector((state) => state.country);
  const [country, setCountry] = useState([]);

  const getCountryOptions = () => {
    let arr = [];
    let obj = {};
    data?.map((option) => {
      obj["label"] = `${option?.name} - ${option?.code}`;
      obj["value"] = option?.code;
      arr.push(obj);
      obj = {};
    });
    setCountry(arr);
  };

  useEffect(() => {
    getCountryOptions();
  }, [data]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      showSearch
      filterOption={filterOption}
      className={`custom-select ${className}`}
      placeholder={placeholder || "Select jurisdiction"}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      loading={isLoading}
      options={country}
    />
  );
};

export default Jurisdiction;
