import { useState, useEffect } from "react";
import { Modal } from "antd";

import { fetchApi, sendNotify } from "../helper";

export default function RejectTask({ id, reason, url }) {
  const [rejectModel, setRejectModel] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRejectReason(reason);
  }, [reason]);

  const rejectTask = () => {
    if (rejectReason) {
      setLoading(true);
      const payload = {
        method: "PUT",
        url: `/${url ?? "serviceRequest"}/update/${id}`,
        data: {
          status: "Reject",
          rejectReason,
        },
      };

      fetchApi(payload)
        .then((res) => {
          setLoading(false);
          setRejectModel(false);
          sendNotify("success", "Task rejected successfully.");
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error while reject task");
        });
    } else {
      sendNotify("error", "Reject reason is required!");
    }
  };

  return (
    <>
      <button
        className="outline-btn red-btn"
        onClick={() => setRejectModel(true)}
      >
        Reject
      </button>
      <Modal
        title="Reject task"
        open={rejectModel}
        onCancel={() => setRejectModel(false)}
        onOk={rejectTask}
        okText={"Reject"}
        okButtonProps={{ loading }}
        centered
        className="custom-modal"
      >
        <div className="custom-form">
          <textarea
            name=""
            id=""
            value={rejectReason}
            className="w-100"
            placeholder="Enter the reason here...."
            onChange={(e) => setRejectReason(e.target.value)}
          ></textarea>
        </div>
      </Modal>
    </>
  );
}
