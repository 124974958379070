import React, { useState, useRef } from "react";
import { Checkbox, Modal, Select, Input } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

import PDFIcon from "../assets/img/pdf-icon.svg";
import DOCIcon from "../assets/img/doc-icon.svg";

import SampleDoc from "../assets/pdf/sample.pdf";
import SampleDoc2 from "../assets/pdf/sample2.pdf";

import { downloadFile, Each } from "../helper";

import FormZ from "../components/FormZ";
import UploadZ from "../components/UploadZ";

let index = 0;

export default function Storage() {
  const [items, setItems] = useState(["EC032", "EC033"]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const formRef = useRef();

  let formSchema = [
    {
      name: "documentDate",
      value: dayjs("20-01-2024", "DD/MM/YYYY"),
      type: "date",
      label: "Document Date",
      placeholder: "Enter document date",
    },
    {
      name: "entityName",
      value: "Trust",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
    },
    {
      name: "entityCode",
      value: "EC9023",
      type: "text",
      label: "Entity Code",
      placeholder: "Enter entity code",
      disabled: true,
    },
    {
      name: "cabinet",
      value: "C2",
      type: "select",
      label: "Cabinet",
      placeholder: "Select cabinet",
    },
    {
      name: "Stored By",
      value: "User 1",
      type: "select",
      label: "Cabinet",
      placeholder: "Select cabinet",
    },
    {
      name: "dateStorage",
      value: dayjs("23-01-2024", "DD/MM/YYYY"),
      type: "date",
      label: "Date of Storage",
      disabled: true,
    },
    {
      name: "modifiedBy",
      value: "User1",
      type: "text",
      label: "Modified By",
      placeholder: "Enter modified by",
      disabled: true,
    },
    {
      name: "lastModifiedDate",
      value: dayjs("25-01-2024", "DD/MM/YYYY"),
      type: "date",
      label: "Last Modified Date",
      disabled: true,
    },
    // {
    //   name: 'taggedEntities',
    //   value: '',
    //   type: 'select',
    //   label: 'Tagged Entities',
    //   placeholder: 'Select tagged entities',
    // },
  ];

  const [docModal, setDocModal] = useState(false);
  const [viewDocName, setViewDocName] = useState("");
  const [activeDropdown, setActiveDropdown] = useState(0);
  const [activeChild, setActiveChild] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);

  let documents = [1, 2, 3, 4, 5, 6];

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const viewDocument = () => {
    setViewDocName("Document");
    setDocModal(true);
  };

  const handleOk = () => {
    setDocModal(false);
    setViewDocName("");
    setModifyModal(false);
  };
  const handleCancel = () => {
    setDocModal(false);
    setViewDocName("");
    setModifyModal(false);
  };

  const onSelectAll = (e) => {
    setSelectAll(e.target.checked);
  };

  const openDropdown = (index) => {
    setActiveDropdown(index);
    setActiveChild(0);
    setShowRes(false);
  };

  const openResolutions = (index) => {
    setActiveDropdown(index);
    setActiveChild(0);
    setShowRes(true);
  };

  const closeResolutions = (index) => {
    setActiveChild(index);
    // setShowRes(false);
  };

  const DownloadDoc = () => {
    downloadFile(SampleDoc, "sample_doc.pdf");
    // downloading()
  };

  // async function downloading(params) {
  //     await axios({
  //         url: SampleDoc2,
  //         method: "GET",
  //         responseType: "blob", // important
  //         onDownloadProgress: (progressEvent) => {
  //             let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //             console.log(percentCompleted);
  //         },
  //     }).then(res => {
  //         const url = window.URL.createObjectURL(new Blob([res.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'file_test.pdf'); //or any other extension
  //         document.body.appendChild(link);
  //         link.click();
  //     })
  // }

  const sampDocuments = {
    url: SampleDoc,
    name: "sample_document.pdf",
    size: "400 KB",
    type: "pdf",
    base64: "",
  };

  const sideMenu = [
    {
      icon: "fa-sparkles",
      label: "Pre-Incorp",
      child: ["Pre-Incorp"],
    },
    {
      icon: "fa-tasks-alt",
      label: "Post-Incorp",
      child: ["Post-Incorp Service"],
    },
    {
      icon: "fa-heart-rate",
      label: "Due Diligence",
      child: [
        "AML Pack",
        "STAT Pack",
        "Individual KYC",
        "Corporate KYC",
        "SOW/SOF",
        "RAF",
      ],
    },
    {
      icon: "fa-square",
      label: "Legal",
      child: [
        "Resolutions",
        "Statutory Documents",
        "M&AA/CI",
        "Registers",
        "Incorporation Pack",
      ],
    },
    {
      icon: "fa-analytics",
      label: "Correspondence",
      child: [
        "Email Correspondence",
        "Internal Communication",
        "Accounts/Finance",
      ],
    },
    {
      icon: "fa-history",
      label: "Miscellaneous",
      child: ["Documents", "Drafts", "Other"],
    },
    {
      icon: "fa-address-card",
      label: "Filings",
      child: [
        "Economic Substance",
        "Boss Filing",
        "Annual Return",
        "ROD Filling",
      ],
    },
    {
      icon: "fa-file-edit",
      label: "Actioned Documents",
      child: ["Translations", "OCR Extracts"],
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4 mb-md-5">
        <h1 className="page-title">Storage</h1>
        <button className="primary-btn">Export</button>
      </div>
      <div className="header-wrapper">
        <p>
          Entity Name:&nbsp;<b>Sample Entity</b>
        </p>
        <b className="mx-1">&nbsp; | &nbsp;</b>
        <p>
          Entity Type:&nbsp;<b>Foundation</b>
        </p>
        <b className="mx-1">&nbsp; | &nbsp;</b>
        <p>
          Entity Code:&nbsp;<b>E001</b>
        </p>
        <b className="mx-1">&nbsp; | &nbsp;</b>
        <p>
          RM Name:&nbsp;<b>John Doe</b>
        </p>
        <b className="mx-1">&nbsp; | &nbsp;</b>
        <p>
          RM Code:&nbsp;<b>R423</b>
        </p>
      </div>
      <div className="row mt-4">
        <div className="col-md-3 mb-4 mb-md-0">
          <ul className="custom-dropdown">
            <Each
              array={sideMenu}
              render={(item, index) => (
                <li>
                  <div
                    className={`list-item ${
                      activeDropdown == index ? "active" : ""
                    }`}
                    onClick={() =>
                      item?.label == "Legal"
                        ? openResolutions(index)
                        : openDropdown(index)
                    }
                  >
                    <p>
                      <i className={`far ${item?.icon}`}></i> {item?.label}
                    </p>
                    <p className="d-flex align-items-center">
                      <span>1</span>{" "}
                      <i
                        className={`far fa-chevron-down ${
                          activeDropdown == index ? "fa-rotate-180" : ""
                        }`}
                      ></i>
                    </p>
                  </div>
                  <ul
                    className={`child-dropdown ${
                      activeDropdown == index ? "show" : ""
                    }`}
                  >
                    <Each
                      array={item["child"]}
                      render={(child, i) => (
                        <li
                          className={activeChild == i ? "active" : ""}
                          onClick={() => closeResolutions(i)}
                        >
                          <p>
                            <i className="fas fa-circle fa-xs"></i> {child}
                          </p>
                        </li>
                      )}
                    />
                  </ul>
                </li>
              )}
            />
          </ul>
        </div>
        <div className="col-md-6">
          <div className="header-wrapper mb-4">
            <p>
              Document Name:&nbsp;<b>Sample Document</b>
            </p>
            <b className="mx-1">&nbsp; | &nbsp;</b>
            <p>
              Document Date:&nbsp;<b>22-01-2024</b>
            </p>
            <b className="mx-1">&nbsp; | &nbsp;</b>
            <p>
              Cabinet:&nbsp;<b>E001</b>
            </p>
            <b className="mx-1">&nbsp; | &nbsp;</b>
            <p>
              User:&nbsp;<b>John Doe</b>
            </p>
            <b className="mx-1">&nbsp; | &nbsp;</b>
            <p>
              Storage Date & Time:&nbsp;<b>22-01-2024</b>
            </p>
            <b className="mx-1">&nbsp; | &nbsp;</b>
            <p>
              Password:&nbsp;<b>!2$345</b>
            </p>
          </div>
          {showRes ? (
            <div className="row gy-2 gx-4">
              <div className="d-flex gap-3 flex-column flex-md-row align-items-start align-items-md-center mb-3">
                <Checkbox onChange={onSelectAll} className="custom-checkbox">
                  Select All
                </Checkbox>
                <button className="outline-btn">
                  <i className="far fa-arrow-to-bottom"></i> Download Selected
                </button>
              </div>
              <Each
                array={documents}
                render={(item) => (
                  <div className="col-md-6">
                    <Checkbox
                      onChange={onChange}
                      checked={selectAll}
                      className="custom-checkbox mb-3"
                    >
                      Document 0{item}
                    </Checkbox>
                    <UploadZ file={sampDocuments} downloadOnly={true} />
                  </div>
                )}
              />
            </div>
          ) : (
            <div>
              <div className="doc-upload-wrapper mb-4">
                <div className="doc-uploaded">
                  <div className="d-flex align-items-center">
                    <img className="file-icon" src={PDFIcon} alt="" />
                    <div className="ms-2">
                      <h6>Tech design requirements.pdf</h6>
                      <p>200 KB – 100% uploaded</p>
                    </div>
                  </div>
                  <i className="fas fa-check-circle upload-icon"></i>
                </div>
                <button className="doc-action" onClick={viewDocument}>
                  <i className="far fa-eye"></i>
                </button>
                <button className="doc-action" onClick={DownloadDoc}>
                  <i className="far fa-arrow-to-bottom"></i>
                </button>
              </div>
              <div className="doc-upload-wrapper mb-4">
                <div className="doc-uploaded" style={{ backgroundSize: "70%" }}>
                  <div className="d-flex align-items-center">
                    <img className="file-icon" src={DOCIcon} alt="" />
                    <div className="ms-2">
                      <h6>Dashboard prototype.docx</h6>
                      <p>16 MB – 70% uploaded</p>
                    </div>
                  </div>
                  <i className="fas fa-check-circle upload-icon"></i>
                </div>
                <button className="doc-action" onClick={viewDocument}>
                  <i className="far fa-eye"></i>
                </button>
                <button className="doc-action" onClick={DownloadDoc}>
                  <i className="far fa-arrow-to-bottom"></i>
                </button>
              </div>
              <div className="doc-upload-wrapper mb-4">
                <div className="doc-uploaded">
                  <div className="d-flex align-items-center">
                    <img className="file-icon" src={PDFIcon} alt="" />
                    <div className="ms-2">
                      <h6>Tech design requirements.pdf</h6>
                      <p>200 KB – 100% uploaded</p>
                    </div>
                  </div>
                  <i className="fas fa-check-circle upload-icon"></i>
                </div>
                <button className="doc-action" onClick={viewDocument}>
                  <i className="far fa-eye"></i>
                </button>
                <button className="doc-action" onClick={DownloadDoc}>
                  <i className="far fa-arrow-to-bottom"></i>
                </button>
              </div>
              <div className="doc-upload-wrapper mb-4">
                <div className="doc-uploaded">
                  <div className="d-flex align-items-center">
                    <img className="file-icon" src={PDFIcon} alt="" />
                    <div className="ms-2">
                      <h6>Tech design requirements.pdf</h6>
                      <p>200 KB – 100% uploaded</p>
                    </div>
                  </div>
                  <i className="fas fa-check-circle upload-icon"></i>
                </div>
                <button className="doc-action" onClick={viewDocument}>
                  <i className="far fa-eye"></i>
                </button>
                <button className="doc-action" onClick={DownloadDoc}>
                  <i className="far fa-arrow-to-bottom"></i>
                </button>
              </div>
              <div className="doc-upload-wrapper mb-4">
                <div className="doc-uploaded" style={{ backgroundSize: "70%" }}>
                  <div className="d-flex align-items-center">
                    <img className="file-icon" src={DOCIcon} alt="" />
                    <div className="ms-2">
                      <h6>Dashboard prototype.docx</h6>
                      <p>16 MB – 70% uploaded</p>
                    </div>
                  </div>
                  <i className="fas fa-check-circle upload-icon"></i>
                </div>
                <button className="doc-action" onClick={viewDocument}>
                  <i className="far fa-eye"></i>
                </button>
                <button className="doc-action" onClick={DownloadDoc}>
                  <i className="far fa-arrow-to-bottom"></i>
                </button>
              </div>
              <div className="doc-upload-wrapper mb-4">
                <div className="doc-uploaded">
                  <div className="d-flex align-items-center">
                    <img className="file-icon" src={PDFIcon} alt="" />
                    <div className="ms-2">
                      <h6>Tech design requirements.pdf</h6>
                      <p>200 KB – 100% uploaded</p>
                    </div>
                  </div>
                  <i className="fas fa-check-circle upload-icon"></i>
                </div>
                <button className="doc-action" onClick={viewDocument}>
                  <i className="far fa-eye"></i>
                </button>
                <button className="doc-action" onClick={DownloadDoc}>
                  <i className="far fa-arrow-to-bottom"></i>
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-3">
          <div className="summary-wrapper">
            <div className="d-flex flex-column h-100 justify-content-between">
              <div>
                <h6>Summary</h6>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Libero repellat praesentium porro dolor minima cupiditate ipsa
                  repudiandae perspiciatis repellendus. Commodi at eaque
                  voluptatibus in, voluptatem esse consequuntur tempore autem
                  nulla?
                </p>
              </div>
              <div>
                <div className="d-flex w-100 gap-3 mb-3">
                  <button className="outline-btn justify-content-center red-btn w-100">
                    <i className="fal fa-trash-alt"></i> Delete
                  </button>
                  <button
                    className="outline-btn justify-content-center w-100"
                    onClick={() => setModifyModal(true)}
                  >
                    <i className="fal fa-sync"></i> Modify
                  </button>
                </div>

                <div className="custom-form w-100">
                  <label htmlFor="">Tag:</label>
                  <Select
                    className="custom-select w-100 ptb-1"
                    placeholder="Add tags"
                    mode="multiple"
                    showSearch
                    allowClear
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <div className="line-primary"></div>
                        <div className="custom-form d-flex pb-2 px-2 gap-2">
                          <Input
                            className="custom-input"
                            placeholder="Enter tag name"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <button
                            className="primary-btn"
                            type="text"
                            onClick={addItem}
                          >
                            <i className="fal fa-plus"></i>
                            Add&nbsp;tag
                          </button>
                        </div>
                      </div>
                    )}
                    options={items.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </div>

                <button className="primary-btn mt-3 w-100 justify-content-center">
                  <i className="fal fa-arrow-to-top"></i> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={viewDocName}
        className="custom-modal"
        open={docModal}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="document-view">
          <iframe
            src={SampleDoc}
            frameBorder="0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </Modal>
      <Modal
        title={"Document Modification"}
        className="custom-modal"
        open={modifyModal}
        width={800}
        onOk={handleOk}
        okText="Change"
        onCancel={handleCancel}
      >
        <div className="custom-form">
          <FormZ
            formSchema={formSchema}
            ref={formRef}
            formClass="row mb-4 gy-3 gx-4"
            childClass="col-md-6 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
        </div>
      </Modal>
    </div>
  );
}
