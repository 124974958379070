import React, { useState, useRef, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Select } from "antd";

import Individual from "./components/individual";
import CorporateKYC from "./components/corporate-kyc";
import RelatedEntityKYC from "./components/relatedEntity-kyc";
import Trusts from "./components/trusts";
import Foundations from "./components/foundations";
import Others from "./components/others";

import UploadZ from "../../components/UploadZ";
import FormZ from "../../components/FormZ";

import {
  Each,
  focusOn,
  fetchApi,
  sendNotify,
  convertQueryParams,
  getOptions,
} from "../../helper";

export default function RelationshipManager() {
  let { isLogged, userDetails } = useSelector((state) => state.auth);

  const [activeTab, setActiveTab] = useState("individual");
  const [documents, setDocuments] = useState({
    basicDoc: [],
    individual: [],
    corporateEntity: [],
    incorpDocuments: [],
    relatedEntity: [],
  });
  const [upload, setUpload] = useState([]);
  const [mode, setMode] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [clearDocuments, setClearDocuments] = useState(false);
  const [clientObjArray, setClientObjArray] = useState([]);
  const [clientArray, setClientArray] = useState([]);
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({});
  const [objData, setObjData] = useState({});
  const [masterCode, setMasterCode] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");

  useEffect(() => {
    let mode = searchParams.get("mode");
    setMode(mode);
    if (mode == "edit") {
      setActiveTab("trusts");
      focusOn("tab");
    }
    handleRefresh();
  }, []);
  const handleRefresh = async () => {
    console.log(userDetails, "w4esdryguio");

    if (userDetails.role == "client") {
      let params = {
        isAll: true,
        alltype: ["introducer-client", "end-user"],
        email: userDetails.useremail,
      };

      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.rows;
      });
      setClientArray(data);

      let list = data.map((d) => {
        let obj = {
          value: d?._id,
          label: d?.code,
        };
        return obj;
      });
      setClientObjArray(list);

      setFormData({});
    } else {
      let params = {
        isAll: true,
        alltype: ["introducer-client", "end-user"],
        //options: true,
      };

      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/client?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.rows;
      });
      setClientArray(data);

      let list = data.map((d) => {
        let obj = {
          value: d?._id,
          label: d?.code,
        };
        return obj;
      });
      setClientObjArray(list);
    }
  };
  const clientdropDownOnClick = async (value) => {
    setFormData(value);
    let data = { ...objData };
    let { masterId } = value;
    if (data.masterId !== masterId || !data.masterId) {
      if (masterId) {
        let clientData = clientArray.filter((e) => e._id == masterId);
        setMasterCode(clientData?.[0]?.code);
        value["masterName"] = clientData?.[0]?.name;
        // setFormData(value);
      }
    }
    setObjData(value);
  };
  const clientOnChange = async (value) => {
    setFormData(value);
    let params = { isAll: true, code: value };

    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });
    setClientArray(data);
    let list = data.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });
    setClientObjArray(list);
  };

  function storeDocuments(file) {
    let doc = { ...documents };
    doc["basicDoc"].push(file);
    console.log(doc);
    setDocuments(doc);
  }

  function removeDocument(docName) {
    if (docName) {
      let allDoc = { ...documents };
      let doc = allDoc["basicDoc"];
      let index = doc?.findIndex((d) => d?.document == docName);
      if (index != -1) {
        doc.splice(index, 1);
        allDoc["basicDoc"] = doc;
        setDocuments(allDoc);
      }
    }
  }

  let documentsArr = [
    "Inaugural Resolution",
    "Appointment of First Director",
    "Resolution of Share Allotment",
    "Corporate Seal Draft",
    // "Directors Consent Letter",
    "Customized M&AA",
    "UBO Source of Funds Declaration",
    "Incorporation Application Form",
    "Transfer-In Form for BVIBC",
    "Written Confirmations-Virtual Assets",
    <>
      Passwords <span>(In case there is any password used)</span>
    </>,
  ];

  const addNewUpload = () => {
    const newArr = [...upload];
    let newName = `Enter document name ${newArr.length + 1}`;
    const nameExists = newArr.includes(newName);

    if (nameExists) {
      let count = 1;
      let uniq = `Enter document name ${count}`;

      while (newArr.includes(uniq)) {
        count++;
        uniq = `Enter document name ${count}`;
      }

      newName = uniq;
    }

    newArr.push(newName);

    setUpload(newArr);
  };

  const removeUpload = (index, docName) => {
    let arr = [...upload];
    arr.splice(index, 1);
    removeDocument(docName);
    setUpload(arr);
  };
  const removeUploadOnIndex = (index, docName) => {
    let arr = [...selectedFiles];
    arr.splice(index, 1);
    removeDocument(docName);
    setSelectedFiles(arr);
  };
  const formRef = useRef();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  let formSchema = [
    {
      name: "entityName",
      value: "",
      type: "text",
      label: (
        <>
          Entity Name <span>(With any special characters)</span>
        </>
      ),
      placeholder: "Enter entity name",
    },
    {
      name: "masterId",
      value: "",
      type: "select",
      label: "Client Code",
      options: getOptions(clientObjArray),
      placeholder: "Enter client code",
      showSearch: true,
      disabled: userDetails.role == "client" ? true : false,
    },
    {
      name: "masterName",
      value: "",
      type: "text",
      label: (
        <>
          Client Name <span>(With any special characters)</span>
        </>
      ),
      placeholder: "Enter Masterfile name",
      disabled: true,
    },

    // {
    //   name: "title",
    //   value: "",
    //   type: "select",
    //   label: "Title",
    //   placeholder: "Enter title",
    //   options: [
    //     {
    //       value: "mr",
    //       label: "Mr",
    //     },
    //     {
    //       value: "mrs",
    //       label: "Mrs",
    //     },
    //     {
    //       value: "miss",
    //       label: "Miss",
    //     },
    //     {
    //       value: "ms",
    //       label: "Ms",
    //     },
    //     {
    //       value: "dr",
    //       label: "Dr",
    //     },
    //     {
    //       value: "rev",
    //       label: "Rev",
    //     },
    //   ],
    // },
    // {
    //   name: "firstName",
    //   value: "",
    //   type: "text",
    //   label: "First Name",
    //   placeholder: "Enter first name",
    // },
    // {
    //   name: "middleName",
    //   value: "",
    //   type: "text",
    //   label: "Middle Name",
    //   placeholder: "Enter Middle name",
    // },
    // {
    //   name: "lastName",
    //   value: "",
    //   type: "text",
    //   label: "Last Name",
    //   placeholder: "Enter last name",
    // },
  ];

  const getLabelName = (value, index) => {
    let arr = [...upload];
    arr[index] = value;
    setUpload(arr);
  };

  const onUploadDoc = (docs, name, key) => {
    console.log(docs, "QEWRTYU");
    let arr = { ...documents };
    let list = [...arr[name]];
    let obj = list.find((l) => l.index == key);
    let index = list.findIndex((l) => l.index == key);
    console.log(obj);
    if (!obj) {
      obj = {};
      obj["index"] = key;
      obj["documents"] = docs;
      list.push(obj);
    } else {
      obj["documents"] = docs;
      list[index] = obj;
    }

    arr[name] = list;
    console.log(arr);
    setDocuments(arr);
  };

  const onNameChange = (value, name, key) => {
    let arr = { ...documents };
    let list = [...arr[name]];
    let obj = list.find((l) => l.index == key);
    let index = list.findIndex((l) => l.index == key);
    if (obj) {
      obj["name"] = value;
      list[index] = obj;
    } else {
      obj = {};
      obj["index"] = key;
      obj["name"] = value;
      list.push(obj);
    }
    arr[name] = list;
    console.log(arr);
    setDocuments(arr);
  };

  const removeElement = (name, key) => {
    let arr = { ...documents };
    let list = [...arr[name]];
    list.splice(key, 1);
    arr[name] = list;
    console.log(arr);

    setDocuments(arr);
  };

  const getIncorpDocs = (data) => {
    console.log(data, "awresetyuy");
    let arr = { ...documents };
    arr["incorpDocuments"] = data;
    setDocuments(arr);
    console.log(arr);
  };

  const onSubmitHandle = () => {
    setSubmitLoading(true);
    let data = {};
    let from = formRef.current.getPayload();
    data = { ...from, ...documents };
    data.masterCode = masterCode;
    data.type = "Incorporation";

    let payload = {
      method: "POST",
      url: `/relationshipManager`,
      data,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        setSubmitLoading(false);
        formRef.current.clearForm();
        setClearDocuments(true);
        setDocuments({
          basicDoc: [],
          individual: [],
          corporateEntity: [],
          relatedEntity: [],
          incorpDocuments: [],
        });
      })
      .catch((error) => {
        sendNotify("error", error?.message);
        setSubmitLoading(false);
      });
    setClearDocuments(false);
  };

  return (
    <div>
      <div className="page-header">
        <h1>
          {/* End User / Relationship Manager */}
          Incorporation Request
        </h1>
        <button
          className={`primary-btn ${submitLoading ? "btn-loading" : ""}`}
          onClick={onSubmitHandle}
        >
          {submitLoading ? (
            <i className="far fa-spinner-third fa-spin"></i>
          ) : (
            ""
          )}
          Submit
        </button>
      </div>
      <div className="page-content">
        <FormZ
          ref={formRef}
          formSchema={formSchema}
          formData={formData}
          formClass="custom-form row mb-4 gy-3"
          childClass="col-md-3 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
          onKeyUp={(e) => clientOnChange(e.target.value)}
          onChange={clientdropDownOnClick}
        />
        <h2 className="primary-title mb-4">Documents</h2>
        <div className="custom-form">
          <div className="row gy-2 gx-4">
            <Each
              array={documentsArr}
              render={(item) => (
                <div className="col-md-3">
                  <label htmlFor="inaugural-resolution" className="me-3">
                    {item}
                  </label>
                  <UploadZ
                    onStoreFile={storeDocuments}
                    onDeleteFile={removeDocument}
                    clearUpload={clearDocuments}
                    fileName={item}
                  />
                </div>
              )}
            />
            {selectedFiles.length > 0 && (
              <>
                {/* {selectedFiles.map((file, i) => ( */}
                <Each
                  array={selectedFiles}
                  render={(item, i) => (
                    <div className="col-md-3">
                      <div className="position-relative">
                        <div className="d-flex justify-content-between align-items-center mb-1px">
                          <label
                            contentEditable={true}
                            key={i}
                            id={i}
                            onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                            className="mb-0"
                            suppressContentEditableWarning={true}
                          >
                            {item.name}
                          </label>
                          <button
                            className="del-upload-btn"
                            onClick={() => removeUploadOnIndex(i, item)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                        <UploadZ
                          onStoreFile={storeDocuments}
                          onDeleteFile={removeDocument}
                          file={item}
                          clearUpload={clearDocuments}
                          fileName={item.name}
                        />{" "}
                      </div>
                    </div>
                  )}
                />
              </>
            )}
            <Each
              array={upload}
              render={(item, i) => (
                <div className="col-md-3">
                  <div className="position-relative">
                    <div className="d-flex justify-content-between align-items-center mb-1px">
                      <label
                        contentEditable={true}
                        key={i}
                        id={i}
                        onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                        className="mb-0"
                        suppressContentEditableWarning={true}
                      >
                        {item}
                      </label>
                      <button
                        className="del-upload-btn"
                        onClick={() => removeUpload(i, item)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </div>
                    <UploadZ
                      onStoreFile={storeDocuments}
                      onDeleteFile={removeDocument}
                      clearUpload={clearDocuments}
                      file={documents["basicDoc"].find(
                        (d) => d.document == item
                      )}
                      isMultiple={true}
                      fileName={item}
                      setSelectedFiles={setSelectedFiles}
                    />
                  </div>
                </div>
              )}
            />
            <div className="col-md-3 mb-4">
              <label htmlFor="">&nbsp;</label>
              <div className="add-new-upload" onClick={addNewUpload}>
                <span className="icon">
                  <i className="fal fa-plus-circle"></i>
                </span>
                <h6>Add New Upload</h6>
                {/* <p>Click to create new upload field</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tab-wrapper my-3 my-md-4" id="tab">
          <button
            className={`tab-btn ${activeTab == "individual" ? "active" : ""}`}
            onClick={() => setActiveTab("individual")}
          >
            Individual
          </button>
          <button
            className={`tab-btn ${activeTab == "corporateKYC" ? "active" : ""}`}
            onClick={() => setActiveTab("corporateKYC")}
          >
            Corporate Entity
          </button>
          <button
            className={`tab-btn ${
              activeTab == "relatedEntityKYC" ? "active" : ""
            }`}
            onClick={() => setActiveTab("relatedEntityKYC")}
          >
            Related Entity
          </button>
          <button
            className={`tab-btn ${activeTab == "trusts" ? "active" : ""}`}
            onClick={() => setActiveTab("trusts")}
          >
            Incorp Documents
          </button>
          {/* <button
          className={`tab-btn ${activeTab == "foundations" ? "active" : ""}`}
          onClick={() => setActiveTab("foundations")}
        >
          Foundations
        </button>
        <button
          className={`tab-btn ${activeTab == "others" ? "active" : ""}`}
          onClick={() => setActiveTab("others")}
        >
          Others
        </button> */}
        </div>
        <div
          className={`hide-content ${activeTab == "individual" ? "show" : ""}`}
        >
          <Individual
            data={documents["individual"]}
            getName={onNameChange}
            clearUpload={clearDocuments}
            getDocument={onUploadDoc}
            removeElement={removeElement}
          />
        </div>
        <div
          className={`hide-content ${
            activeTab == "corporateKYC" ? "show" : ""
          }`}
        >
          <CorporateKYC
            data={documents["corporateEntity"]}
            getName={onNameChange}
            clearUpload={clearDocuments}
            getDocument={onUploadDoc}
            removeElement={removeElement}
          />
        </div>
        <div
          className={`hide-content ${
            activeTab == "relatedEntityKYC" ? "show" : ""
          }`}
        >
          <RelatedEntityKYC
            data={documents["relatedEntity"]}
            getName={onNameChange}
            clearUpload={clearDocuments}
            getDocument={onUploadDoc}
            removeElement={removeElement}
          />
        </div>
        <div className={`hide-content ${activeTab == "trusts" ? "show" : ""}`}>
          <Trusts
            mode={mode}
            clearUpload={clearDocuments}
            getDocument={getIncorpDocs}
          />
        </div>
        <div
          className={`hide-content ${activeTab == "foundations" ? "show" : ""}`}
        >
          <Foundations />
        </div>
        <div className={`hide-content ${activeTab == "others" ? "show" : ""}`}>
          <Others />
        </div>
      </div>
    </div>
  );
}
