import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Index() {
  const { isLogged, userDetails } = useSelector((state) => state.auth);
  let location = useLocation();

  let from = location.state?.from?.pathname || null;

  if (isLogged) {
    if (from) {
      return <Navigate to={from} replace />;
    } else {
      return <Navigate to="/app/home" replace />;
    }
  }

  return (
    <div className="auth-layout">
      <div className="auth-card">
        <Outlet />
        <p className="auth-copyright">© Galactic 2024</p>
      </div>
      <div className="auth-bg"></div>
    </div>
  );
}
