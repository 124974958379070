import { useState, useEffect } from "react";
import { Select, Table, Switch, Tag } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import { getPreIncorpRequests } from "../store/preIncorpRequests/preIncorpSlice";
import { getPostIncorpRequests } from "../store/postIncorpRequests/postIncorpSlice";

import { showCount } from "../helper";

import searchFilter from "../components/searchFilter";

export default function ActiveRequests() {
  const preIncorp = useSelector((state) => state.preIncorp);
  const postIncorp = useSelector((state) => state.postIncorp);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [preData, setPreData] = useState([]);
  // const [postData, setPostData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPreIncorpRequests(queryParams));
  }, []);

  useEffect(() => {
    setPreData(preIncorp?.data?.rows);
  }, [preIncorp]);

  const handleSearch = (searchFilters) => {
    let query = { ...queryParams, ...searchFilters };
    dispatch(getPreIncorpRequests(query));
    setQueryParams(query);
  };

  const handleReset = (key) => {
    let query = { ...queryParams };
    delete query[key];
    dispatch(getPreIncorpRequests(query));
    setQueryParams(query);
  };

  const preColumns = [
    {
      title: "Request Ref",
      dataIndex: "refNumber",
      sorter: (a, b) => a.refNumber - b.refNumber,
      ...searchFilter({
        dataIndex: "refNumber",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: "Requestor Name",
      dataIndex: "requestedBy",
      sorter: (a, b) => a.requestedBy - b.requestedBy,
      ...searchFilter({
        dataIndex: "requestedBy",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    // {
    //   title: "Entity Name",
    //   dataIndex: "entityName",
    //   sorter: (a, b) => a.entityName - b.entityName,
    // },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      ...searchFilter({
        dataIndex: "clientName",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      ...searchFilter({
        dataIndex: "status",
        inputType: "select",
        options: [
          {
            label: "Pending",
            value: "Pending",
          },
          {
            label: "Submitted",
            value: "Submitted",
          },
        ],
        queryParams,
        handleSearch,
        handleReset,
      }),
      render: (_, { status }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={status == "Submitted" ? "green" : "gold"}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Request type",
      dataIndex: "requestType",
      sorter: (a, b) => a.requestType - b.requestType,
      ...searchFilter({
        dataIndex: "requestType",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
    },
    // {
    //   title: "Pending received",
    //   dataIndex: "received",
    //   sorter: (a, b) => a.received - b.received,
    // },
    {
      title: "Resubmission Status",
      dataIndex: "resubmission",
      sorter: (a, b) => a.resubmission - b.resubmission,
      // ...searchFilter({
      //   dataIndex: "resubmission",
      //   inputType: "text",
      //   options: [
      //     {
      //       label: "Pending",
      //       value: "Pending",
      //     },
      //     {
      //       label: "Submitted",
      //       value: "Submitted",
      //     },
      //   ],
      //   queryParams,
      //   handleSearch,
      //   handleReset,
      // }),
      render: (_, { resubmission }) => (

        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={resubmission == "Submitted" ? "green" : resubmission == "Pending" ? "gold" :resubmission =="In Progress" ? "processing":"processing"}
        >
          {resubmission}
        </Tag>
      ),
    },
    {
      title: "Origin Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      ...searchFilter({
        dataIndex: "requestedBy",
        inputType: "text",
        queryParams,
        handleSearch,
        handleReset,
      }),
      render: (_, { createdAt }) => {
        return dayjs(createdAt).format("DD/MM/YYYY - hh:mm A");
      },
    },
    {
      title: "Assignee 1",
      dataIndex: "assignee1",
      sorter: (a, b) => a.assignee1 - b.assignee1,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 100 }}
          placeholder="Select Assignee"
          defaultValue={"user1"}
          options={[
            {
              value: "user1",
              label: "User 1",
            },
            {
              value: "user2",
              label: "User 2",
            },
            {
              value: "user3",
              label: "User 3",
            },
          ]}
        />
      ),
    },
    {
      title: "Assignee 2",
      dataIndex: "assignee2",
      sorter: (a, b) => a.assignee2 - b.assignee2,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 100 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "user1",
              label: "User 1",
            },
            {
              value: "user2",
              label: "User 2",
            },
            {
              value: "user3",
              label: "User 3",
            },
          ]}
        />
      ),
    },
    {
      title: "Actions",
      render: (_, { _id, requestType }) => (
        <div className="d-flex justify-content-end align-items-center gap-2">
          <button className="action-btn">Assign</button>
          <button className="action-btn">Re-assign</button>
          <Link
            to={
              requestType == "Name Check"
                ? `/app/onboarding-team/edit/${_id}`
                : "/app/onboarding-team/download"
            }
            className="action-btn"
          >
            {requestType == "Name Check" ? "Name Check" : "View Request"} &nbsp;{" "}
            <i className="far fa-long-arrow-right me-0"></i>
          </Link>
        </div>
      ),
    },
  ];
  const postColumns = [
    {
      title: "Request Ref",
      dataIndex: "ref",
      sorter: (a, b) => a.ref - b.ref,
    },
    {
      title: "Requestor Name",
      dataIndex: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Request type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (_, { status }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={status == "Y" ? "gold" : "processing"}
        >
          {status == "Y" ? "Pending" : "In Progress"}
        </Tag>
      ),
    },
    // {
    //   title: "Pending received",
    //   dataIndex: "received",
    //   sorter: (a, b) => a.received - b.received,
    // },
    {
      title: "Resubmission Status",
      dataIndex: "resubmission",
      sorter: (a, b) => a.resubmission - b.resubmission,
      render: (_, { resubmission }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={resubmission == "Y" ? "gold" : "processing"}
        >
          {resubmission == "Y" ? "Pending" : "In Progress"}
        </Tag>
      ),
    },
    {
      title: "Origin Date",
      dataIndex: "originDate",
      sorter: (a, b) => a.originDate - b.originDate,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
      sorter: (a, b) => a.approvedBy - b.approvedBy,
    },
    {
      title: "Approved Date & Time",
      dataIndex: "approvedDateTime",
      sorter: (a, b) => a.approvedDateTime - b.approvedDateTime,
      width: 220,
    },
    {
      title: "Approved Status",
      dataIndex: "approvedStatus",
      sorter: (a, b) => a.approvedStatus - b.approvedStatus,
    },
    {
      title: "Update By",
      dataIndex: "UpdateBy",
      sorter: (a, b) => a.UpdateBy - b.UpdateBy,
    },
    {
      title: "Verified",
      dataIndex: "verified",
      sorter: (a, b) => a.verified - b.verified,
      render: (_, { verified }) => (
        <div className="d-flex gap-2">
          <Switch
            checked={verified}
            className="custom-switch"
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </div>
      ),
    },
    {
      title: "Assignee 1",
      dataIndex: "assignee1",
      sorter: (a, b) => a.assignee1 - b.assignee1,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 100 }}
          placeholder="Select Assignee"
          defaultValue={"user1"}
          options={[
            {
              value: "user1",
              label: "User 1",
            },
            {
              value: "user2",
              label: "User 2",
            },
            {
              value: "user3",
              label: "User 3",
            },
          ]}
        />
      ),
    },
    {
      title: "Assignee 2",
      dataIndex: "assignee2",
      sorter: (a, b) => a.assignee2 - b.assignee2,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 100 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "user1",
              label: "User 1",
            },
            {
              value: "user2",
              label: "User 2",
            },
            {
              value: "user3",
              label: "User 3",
            },
          ]}
        />
      ),
    },
    {
      title: "Actions",
      render: (_, { id }) => (
        <div className="d-flex justify-content-end align-items-center gap-2">
          <button className="action-btn">Assign</button>
          <button className="action-btn">Re-assign</button>
          <Link to="/app/onboarding-team/download" className="action-btn">
            View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i>
          </Link>
        </div>
      ),
    },
  ];

  // const preData = [
  //   {
  //     key: "1",
  //     id: 1,
  //     ref: "Sample",
  //     name: "Request #1",
  //     entityName: "E571",
  //     clientName: "C783",
  //     type: "Name Check",
  //     status: "Y",
  //     received: "Sample",
  //     resubmission: "N",
  //     originDate: "08/10/2023 02:33PM",
  //   },
  //   {
  //     key: "2",
  //     id: 2,
  //     ref: "Sample",
  //     name: "Request #2",
  //     entityName: "E571",
  //     clientName: "C783",
  //     type: "Sample",
  //     status: "Y",
  //     received: "Sample",
  //     resubmission: "Y",
  //     originDate: "03/11/2023 04:31PM",
  //   },
  //   {
  //     key: "3",
  //     id: 3,
  //     ref: "Sample",
  //     name: "Request #3",
  //     entityName: "E571",
  //     clientName: "C783",
  //     type: "Sample",
  //     status: "N",
  //     received: "Sample",
  //     resubmission: "N",
  //     originDate: "10/11/2023 06:11PM",
  //   },
  // ];
  const postData = [
    {
      key: "1",
      id: "1",
      ref: "Sample",
      name: "Request #1",
      type: "Sample",
      status: "N",
      received: "Sample",
      resubmission: "N",
      originDate: "07/11/2023 04:56PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: true,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      UpdateBy: "Hari",
    },
    {
      key: "2",
      id: "2",
      ref: "Sample",
      name: "Request #2",
      type: "Sample",
      status: "N",
      received: "Sample",
      resubmission: "N",
      originDate: "06/11/2023 06:08PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      UpdateBy: "Hari",
    },
    {
      key: "3",
      id: "3",
      ref: "Sample",
      name: "Request #3",
      type: "Sample",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      originDate: "06/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: true,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      UpdateBy: "Hari",
    },
    {
      key: "4",
      id: "4",
      ref: "Sample",
      name: "Request #4",
      type: "Sample",
      status: "N",
      received: "Sample",
      resubmission: "Y",
      originDate: "08/11/2023 06:08PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      UpdateBy: "Hari",
    },
    {
      key: "5",
      id: "5",
      ref: "Sample",
      name: "Request #5",
      type: "Sample",
      status: "Y",
      received: "Sample",
      resubmission: "Y",
      originDate: "10/11/2023 02:23PM",
      entityName: "Trust",
      entityCode: "E783",
      verified: false,
      clientName: "Sample",
      clientCode: "C038",
      approvedBy: "Guru",
      approvedDateTime: "12/12/2023 03:12PM",
      approvedStatus: "pending",
      UpdateBy: "Hari",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getPreIncorpRequests(params));
    setQueryParams(params);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-3 mb-md-0">Active Requests</h1>
        <Link to={"/app/onboarding-team/download"} className="primary-btn">
          Submit
        </Link>
      </div>
      <div>
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h4 className="primary-title text-capitalize mb-3 mb-md-0">
            Pre-Incorp Requests ({showCount(preIncorp?.data?.totalCount)})
          </h4>
          <div className="d-flex flex-column flex-md-row align-items-start gap-3">
            <button className="outline-btn">
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn">
              <i className="far fa-check-double"></i> Assign Selected
            </button>
          </div>
        </div>
        <Table
          rowKey={"_id"}
          className="custom-table mb-3"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={preColumns}
          dataSource={preData}
          scroll={{ x: 2000 }}
          loading={preIncorp?.isLoading}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: preIncorp?.data?.totalCount,
            onChange: getPagination,
          }}
        />

        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h4 className="primary-title text-capitalize mb-3 mb-md-0">
            Post-Incorp Requests (03)
          </h4>
          <div className="d-flex flex-column flex-md-row align-items-start gap-3">
            <button className="outline-btn">
              <i className="far fa-arrow-to-bottom"></i> Download Selected
            </button>
            <button className="outline-btn">
              <i className="far fa-check-double"></i> Assign Selected
            </button>
          </div>
        </div>
        <Table
          className="custom-table"
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={postColumns}
          dataSource={postData}
          scroll={{ x: 3000 }}
        />
      </div>
    </div>
  );
}
