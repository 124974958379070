import React, { useState, useEffect, useRef } from "react";
import { Table, Tag, Select, Modal, DatePicker, Checkbox } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import FormZ from "../../components/FormZ";
import TableSortArrows from "../../components/tableSortArrows";

import {
  Each,
  getOptions,
  sendNotify,
  fetchApi,
  downloadAsCSV,
  isArrayEmpty,
  convertQueryParams,
  sharedOnCell,
} from "../../helper";

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export default function FeeScheduleCreate() {
  const [editMode, setEditMode] = useState(false);
  const [clientArray, setClientArray] = useState([]);
  const [isDiscounted, setIsDiscounted] = useState(true);
  const [isStandard, setIsStandard] = useState(true);
  const [isHighRisk, setIsHighRisk] = useState(true);
  const [isStandardPlus, setIsStandardPlus] = useState(true);

  const [formData, setFormData] = useState({});
  const [column, setColumn] = useState([]);
  useEffect(() => {
    getClient();
  }, []);
  const getClient = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res.data.rows);
    };

    const params = convertQueryParams({
      isAll: true,
      alltype: ["client", "introducer-client", "end-user"],
      options: true,
    });
    const clientlist = await fetchData(`/client?${params}`);

    setClientArray(clientlist);
  };
  const [checkedList, setCheckedList] = useState({
    standard: {
      checked: false,
      child: { up: false, above: false },
    },
    discounted: {
      checked: false,
      child: { up: false, above: false },
    },
    highRisk: {
      checked: false,
      child: { up: false, above: false },
    },
    standardPlus: {
      checked: false,
      child: { up: false, above: false },
    },
  });

  const searchFormRef = useRef();

  let searchFormSchema = [
    {
      name: "clientCode",
      value: "",
      type: "select",
      label: "End User/Introducer Client",
      placeholder: "Select end user client",
      showSearch: true,
      //options: clientArray,
    },
    {
      name: "feeScheduleName",
      value: "",
      type: "text",
      label: "Fee Schedule Name",
      placeholder: "Select schedule name",
    },
    {
      name: "paymentCategory",
      value: "",
      type: "select",
      label: "Payment Category",
      placeholder: "Select payment category",
      showSearch: true,
      allowClear: true,
      mode: "multiple",
      maxTagCount: "responsive",
      options: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
    },
    {
      name: "feeStructureTypes",
      value: "",
      type: "select",
      label: "Fee Structure Types",
      placeholder: "Select structure types",
      options: [
        {
          value: "standard",
          label: "Standard Fee",
        },
        {
          value: "discounted",
          label: "Discounted Fee",
        },
        {
          value: "highRisk",
          label: "High Risk Fee",
        },
        {
          value: "standardPlus",
          label: "Standard+ Fee",
        },
      ],
      showSearch: true,
      allowClear: true,
      mode: "multiple",
      maxTagCount: "responsive",
    },
  ];

  const [shares, setShares] = useState([
    {
      title: "Incorporation",
      header: true,
    },
    {
      task: "Government fee ($550 + $100) 0r ($1350 + $100)",
      type: "Pre-incrop",
      code: "GOV1",
      standardUpTo: 650.0,
      standardAbove: 1450.0,
      discountedUpTo: 650.0,
      discountedAbove: 1450.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registered Office Registered Agent fee",
      type: "Pre-incrop",
      code: "RORA",
      standardUpTo: 375.0,
      standardAbove: 375.0,
      discountedUpTo: 300.0,
      discountedAbove: 300.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Filing Register of Directors",
      type: "Pre-incrop",
      code: "FDIR",
      standardUpTo: 175.0,
      standardAbove: 175.0,
      discountedUpTo: 150.0,
      discountedAbove: 150.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Filing BOSS",
      type: "Pre-incrop",
      code: "FBOSS",
      standardUpTo: 175.0,
      standardAbove: 175.0,
      discountedUpTo: 150.0,
      discountedAbove: 150.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Compliance / Administrative and Sundry Charges",
      type: "Pre-incrop",
      code: "COADSUN",
      standardUpTo: 225.0,
      standardAbove: 225.0,
      discountedUpTo: 225.0,
      discountedAbove: 225.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      title: "Optional Charges",
      header: true,
    },

    {
      task: "Courier charges (Variable -destination/weight sensitive)",
      type: "Pre-incrop",
      code: "COURIER",
      standardUpTo: 200.0,
      standardAbove: 200.0,
      discountedUpTo: 150.0,
      discountedAbove: 150.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Physical Copy of Certificate of Incorporation",
      type: "Pre-incrop",
      code: "PHYSCCI",
      standardUpTo: 50.0,
      standardAbove: 50.0,
      discountedUpTo: 50.0,
      discountedAbove: 50.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Corporate Seal",
      type: "Pre-incrop",
      code: "SEAL",
      standardUpTo: 70.0,
      standardAbove: 70.0,
      discountedUpTo: 60.0,
      discountedAbove: 60.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      title: "Service B - Other Corporate Services List",
      header: true,
    },
    {
      title: "Annual Renewals",
      header: true,
    },

    {
      task: "Government licence fee",
      type: "Pre-incrop",
      code: "ANGOV1",
      standardUpTo: 550.0,
      standardAbove: 1350.0,
      discountedUpTo: 550.0,
      discountedAbove: 1350.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registered Office Registered Agent fee",
      type: "Pre-incrop",
      code: "RORA",
      standardUpTo: 375.0,
      standardAbove: 375.0,
      discountedUpTo: 275.0,
      discountedAbove: 275.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Economic Substance Administration & Filing Fees",
      type: "Pre-incrop",
      code: "ESSADMN",
      standardUpTo: 350.0,
      standardAbove: 350.0,
      discountedUpTo: 300.0,
      discountedAbove: 300.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Annual Return Administration & Maintenance Fees",
      type: "Pre-incrop",
      code: "ANNRTN",
      standardUpTo: 350.0,
      standardAbove: 350.0,
      discountedUpTo: 350.0,
      discountedAbove: 350.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Compliance/Administrative & Sundry charges",
      type: "Pre-incrop",
      code: "COADSUN",
      standardUpTo: 225.0,
      standardAbove: 225.0,
      discountedUpTo: 225.0,
      discountedAbove: 225.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      title: "Incorporation of Limited Partnerships",
      header: true,
    },
    {
      task: "Government fee- Limited Partnerships",
      type: "Pre-incrop",
      code: "GOVLP",
      standardUpTo: 500.0,
      standardAbove: 0.0,
      discountedUpTo: 600.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "CCP Service Fee- Limited Partnerships",
      type: "Pre-incrop",
      code: "SVCLP",
      standardUpTo: 875.0,
      standardAbove: 0.0,
      discountedUpTo: 600.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registered Office Registered Agent fee",
      type: "Pre-incrop",
      code: "RORALP",
      standardUpTo: 900.0,
      standardAbove: 0.0,
      discountedUpTo: 650.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },

    {
      title: "Post Incorporation Services",
      header: true,
    },

    {
      task: "Alteration to the Memorandum & Articles of Association",
      type: "Post-incrop",
      code: "ALTMA",
      standardUpTo: 300.0,
      standardAbove: 0.0,
      discountedUpTo: 250.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Amended and Restated Memorandum & Articles",
      type: "Post-incrop",
      code: "AMDMA",
      standardUpTo: 1500.0,
      standardAbove: 0.0,
      discountedUpTo: 1250.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Apostilles",
      type: "Post-incrop",
      code: "APSTLLE",
      standardUpTo: 250.0,
      standardAbove: 0.0,
      discountedUpTo: 200.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Certificate of Good Standing",
      type: "Post-incrop",
      code: "COGS",
      standardUpTo: 300.0,
      standardAbove: 0.0,
      discountedUpTo: 250.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registry Certified Copy of Any Document",
      type: "Post-incrop",
      code: "REGCC",
      standardUpTo: 250.0,
      standardAbove: 0.0,
      discountedUpTo: 200.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registry Uncertified or Duplicate Copy of Any Document",
      type: "Post-incrop",
      code: "REGUCC",
      standardUpTo: 175.0,
      standardAbove: 0.0,
      discountedUpTo: 150.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registry Physical Copy of any Electronic Certificate",
      type: "Post-incrop",
      code: "REGPCC",
      standardUpTo: 175.0,
      standardAbove: 0.0,
      discountedUpTo: 150.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Certificate of Incumbency",
      type: "Post-incrop",
      code: "CINCMB",
      standardUpTo: 200.0,
      standardAbove: 0.0,
      discountedUpTo: 175.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "CCP Certified or Duplicate copies of documents",
      type: "Post-incrop",
      code: "CCPCC",
      standardUpTo: 150.0,
      standardAbove: 0.0,
      discountedUpTo: 125.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Amendments to Register of Directors",
      type: "Post-incrop",
      code: "AMDROD",
      standardUpTo: 350.0,
      standardAbove: 0.0,
      discountedUpTo: 300.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Amendments to Register of Members & Issuing New Share",
      type: "Post-incrop",
      code: "AMDROS",
      standardUpTo: 450.0,
      standardAbove: 0.0,
      discountedUpTo: 400.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    // {
    //   task: "Certificate(s)",
    //   type: "Pre-incrop",
    //   code: "0030",
    //   standardUpTo: 0.0,
    //   standardAbove: 0.0,
    //   discountedUpTo: 0.0,
    //   discountedAbove: 0.0,
    //   highRiskUpTo: 0.0,
    //   highRiskAbove: 0.0,
    //   standardPlusUpTo: 0.0,
    //   standardPlusAbove: 0.0,
    // },

    {
      task: "Continuations",
      type: "Post-incrop",
      code: "CONTN",
      standardUpTo: 3500.0,
      standardAbove: 0.0,
      discountedUpTo: 3000.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Drafting Resolutions",
      type: "Post-incrop",
      code: "DRFTRES",
      standardUpTo: 350.0,
      standardAbove: 0.0,
      discountedUpTo: 300.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Filing Register of Mortgage & Charges",
      type: "Post-incrop",
      code: "FILROMC",
      standardUpTo: 650.0,
      standardAbove: 0.0,
      discountedUpTo: 550.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },

    {
      task: "Filing Appointment of RO/RA",
      type: "Post-incrop",
      code: "FILRORA",
      standardUpTo: 350.0,
      standardAbove: 0.0,
      discountedUpTo: 300.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Filing Transfer Out",
      type: "Post-incrop",
      code: "FILTOUT",
      standardUpTo: 650.0,
      standardAbove: 0.0,
      discountedUpTo: 600.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Indemnity Agreements",
      type: "Post-incrop",
      code: "INDEMAG",
      standardUpTo: 450.0,
      standardAbove: 0.0,
      discountedUpTo: 400.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Notarizations",
      type: "Post-incrop",
      code: "NTRZTN",
      standardUpTo: 250.0,
      standardAbove: 0.0,
      discountedUpTo: 200.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },

    {
      task: "Powers of Attorney",
      type: "Post-incrop",
      code: "POATTN",
      standardUpTo: 500.0,
      standardAbove: 0.0,
      discountedUpTo: 450.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Reactivation of struckoff companies",
      type: "Post-incrop",
      code: "REACTVN",
      standardUpTo: 650.0,
      standardAbove: 0.0,
      discountedUpTo: 550.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registration of Notice of Resignation as Registered Agent",
      type: "Post-incrop",
      code: "RESRO",
      standardUpTo: 300.0,
      standardAbove: 0.0,
      discountedUpTo: 250.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Filing notice of Recission as Registered Agent",
      type: "Post-incrop",
      code: "RECRO",
      standardUpTo: 250.0,
      standardAbove: 0.0,
      discountedUpTo: 200.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Registration as a Foreign Company in the BVI",
      type: "Post-incrop",
      code: "RGFCO",
      standardUpTo: 3000.0,
      standardAbove: 0.0,
      discountedUpTo: 2500.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Filing Notice of Foreign Company Ceasing to Do Business in the BVI",
      type: "Post-incrop",
      code: "FRGNCO",
      standardUpTo: 500.0,
      standardAbove: 0.0,
      discountedUpTo: 400.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Expedite Fee for Fast Track Due Diligence and Incorporation",
      type: "Post-incrop",
      code: "EXPDFEE",
      standardUpTo: 500.0,
      standardAbove: 0.0,
      discountedUpTo: 400.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Corporate Director Fee",
      type: "Post-incrop",
      code: "CORPDIR1",
      standardUpTo: 3000.0,
      standardAbove: 0.0,
      discountedUpTo: 1500.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Personal Director Fee",
      type: "Post-incrop",
      code: "PERSDIR1",
      standardUpTo: 7500.0,
      standardAbove: 0.0,
      discountedUpTo: 4500.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Nominee Corp Shareholder",
      type: "Post-incrop",
      code: "NCORPSH1",
      standardUpTo: 3000.0,
      standardAbove: 0.0,
      discountedUpTo: 1500.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Opening a Bank Account",
      type: "Post-incrop",
      code: "OPENBAN1",
      standardUpTo: 3500.0,
      standardAbove: 0.0,
      discountedUpTo: 2500.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },

    {
      task: "Provision of Post Box Services",
      type: "Post-incrop",
      code: "PRVPBS",
      standardUpTo: 3500.0,
      standardAbove: 0.0,
      discountedUpTo: 2500.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Provision of Share Space Services",
      type: "Post-incrop",
      code: "PRVSSS",
      standardUpTo: 3500.0,
      standardAbove: 0.0,
      discountedUpTo: 2500.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      title: "Other Services",
      header: true,
    },
    {
      task: "Assisting with process to open a bank account",
      type: "Post-incrop",
      code: "OPBANK",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Assisting with Work Permit applications",
      type: "Post-incrop",
      code: "WPAPP",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Photocopying",
      type: "Post-incrop",
      code: "COPIES",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Assisting with Trade Licence applications",
      type: "Post-incrop",
      code: "TLAPP",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Time and attention charges - Directors",
      type: "Post-incrop",
      code: "TADIR",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Time and attention charges - Junior staff",
      type: "Post-incrop",
      code: "TASTAFF",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
    {
      task: "Wire Transfer Loss Recovery Fee (3)",
      type: "Post-incrop",
      code: "WTLR",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },

    {
      task: "Specialised Services",
      type: "Post-incrop",
      code: "SPECSVC",
      standardUpTo: 0.0,
      standardAbove: 0.0,
      discountedUpTo: 0.0,
      discountedAbove: 0.0,
      highRiskUpTo: 0.0,
      highRiskAbove: 0.0,
      standardPlusUpTo: 0.0,
      standardPlusAbove: 0.0,
    },
  ]);
  const handleOnChange = async (value, field) => {
    console.log();
    if (field == "feeStructureTypes") {
      updatecolumn(value.feeStructureTypes);
      let find = value.feeStructureTypes;
      console.log(find, "dafsghjk");
      console.log(find.includes("standard"), "standard dafsghjk");
      if (find.includes("standard")) {
        setIsStandard(false);
      } else {
        setIsStandard(true);
      }
      if (find.includes("discounted")) {
        setIsDiscounted(false);
      } else {
        setIsDiscounted(true);
      }
      if (find.includes("highRisk")) {
        setIsHighRisk(false);
      } else {
        setIsHighRisk(true);
      }
      if (find.includes("standardPlus")) {
        setIsStandardPlus(false);
      } else {
        setIsStandardPlus(true);
      }
    }
    setFormData(value);
  };
  const getShareChange = (index, key, value) => {
    let arr = [...shares];
    arr[index][key] = value;
    setShares(arr);
  };

  const getChecked = (checked, key, child) => {
    console.log(checked, key, child, " QWAERDTFY;L");
    if (key == "standard") {
      setIsStandard(!isStandard);
    } else if (key == "discounted") {
      setIsDiscounted(!isDiscounted);
    } else if (key == "highRisk") {
      setIsHighRisk(!isHighRisk);
    } else if (key == "standardPlus") {
      setIsStandardPlus(!isStandardPlus);
    }
    let obj = { ...checkedList };
    if (child) {
      obj[key]["child"][child] = checked;
    } else {
      obj[key]["checked"] = checked;
      if (!checked) {
        obj[key]["child"]["up"] = false;
        obj[key]["child"]["above"] = false;
      }
    }
    setCheckedList(obj);
  };

  const columns = [
    {
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (a) => <h4 className="primary-title">{a.title}</h4>,
      onCell: (_, rowIndex) => ({
        colSpan: _.header ? 1 : 1,
        //className: _.header ? "row-header" : "",
      }),
    },
    {
      title: "Request Type",
      dataIndex: "reqType",
      sorter: (a, b) => a.reqType - b.reqType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 130,
      onCell: sharedOnCell,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 160,
      onCell: sharedOnCell,
    },
    {
      title: "Service Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      width: 160,
      onCell: sharedOnCell,
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["standard"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "standard")}
          disabled={isStandard}
        >
          Standard Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standard"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standard"]["child"]["up"]}
                onChange={(e) => getChecked(e.target.checked, "standard", "up")}
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardUpTo",
          key: "standardUpTo",
          render: (_, { id, standardUpTo }) => (
            <div className="custom-form">
              {checkedList["standard"]["child"]["up"] ? (
                <input
                  type="number"
                  // className="w-75"
                  value={shares[id].standardUpTo}
                  onChange={(e) =>
                    getShareChange(id, "standardUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standard"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standard"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "standard", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardAbove",
          key: "standardAbove",
          render: (_, { id, standardAbove }) => (
            <div className="custom-form">
              {checkedList["standard"]["child"]["above"] ? (
                <input
                  type="number"
                  // className="w-75"
                  value={shares[id].standardAbove}
                  onChange={(e) =>
                    getShareChange(id, "standardAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
      ],
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["discounted"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "discounted")}
          disabled={isDiscounted}
        >
          Discounted Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["discounted"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["discounted"]["child"]["up"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "discounted", "up")
                }
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "discountedUpTo",
          key: "discountedUpTo",
          render: (_, { id, discountedUpTo }) => (
            <div className="custom-form">
              {checkedList["discounted"]["child"]["up"] ? (
                <input
                  type="number"
                  //className="w-75"
                  value={shares[id].discountedUpTo}
                  onChange={(e) =>
                    getShareChange(id, "discountedUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["discounted"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["discounted"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "discounted", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "discountedAbove",
          key: "discountedAbove",
          render: (_, { id, discountedAbove }) => (
            <div className="custom-form">
              {checkedList["discounted"]["child"]["above"] ? (
                <input
                  type="number"
                  //className="w-75"
                  value={shares[id].discountedAbove}
                  onChange={(e) =>
                    getShareChange(id, "discountedAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
      ],
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["highRisk"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "highRisk")}
          disabled={isHighRisk}
        >
          High Risk Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["highRisk"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["highRisk"]["child"]["up"]}
                onChange={(e) => getChecked(e.target.checked, "highRisk", "up")}
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "highRiskUpTo",
          key: "highRiskUpTo",
          render: (_, { id, highRiskUpTo }) => (
            <div className="custom-form">
              {checkedList["highRisk"]["child"]["up"] ? (
                <input
                  type="number"
                  // className="w-75"
                  value={shares[id].highRiskUpTo}
                  onChange={(e) =>
                    getShareChange(id, "highRiskUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["highRisk"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["highRisk"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "highRisk", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "highRiskAbove",
          key: "highRiskAbove",
          render: (_, { id, highRiskAbove }) => (
            <div className="custom-form">
              {checkedList["highRisk"]["child"]["above"] ? (
                <input
                  type="number"
                  // className="w-75"
                  value={shares[id].highRiskAbove}
                  onChange={(e) =>
                    getShareChange(id, "highRiskAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
      ],
    },
    {
      title: (
        <Checkbox
          className="custom-checkbox"
          checked={checkedList["standardPlus"]["checked"]}
          onChange={(e) => getChecked(e.target.checked, "standardPlus")}
          disabled={isStandardPlus}
        >
          Standard+ Fee
        </Checkbox>
      ),
      children: [
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standardPlus"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standardPlus"]["child"]["up"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "standardPlus", "up")
                }
              >
                Up to 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardPlusUpTo",
          key: "standardPlusUpTo",
          render: (_, { id, standardPlusUpTo }) => (
            <div className="custom-form">
              {checkedList["standardPlus"]["child"]["up"] ? (
                <input
                  type="number"
                  //  className="w-75"
                  value={shares[id].standardPlusUpTo}
                  onChange={(e) =>
                    getShareChange(id, "standardPlusUpTo", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
        {
          title: (
            <div className="text-center">
              <Checkbox
                disabled={!checkedList["standardPlus"]["checked"]}
                className="custom-checkbox"
                checked={checkedList["standardPlus"]["child"]["above"]}
                onChange={(e) =>
                  getChecked(e.target.checked, "standardPlus", "above")
                }
              >
                Above 50,000 Shares
              </Checkbox>
            </div>
          ),
          dataIndex: "standardPlusAbove",
          key: "standardPlusAbove",
          render: (_, { id, standardAbove }) => (
            <div className="custom-form">
              {checkedList["standardPlus"]["child"]["above"] ? (
                <input
                  type="number"
                  // className="w-75"
                  value={shares[id].standardPlusAbove}
                  onChange={(e) =>
                    getShareChange(id, "standardPlusAbove", e.target.value)
                  }
                />
              ) : (
                <div></div>
              )}
            </div>
          ),
          onCell: sharedOnCell,
        },
      ],
    },
  ];

  const data = [
    {
      key: "1",
      id: 0,
      ...shares[0],
    },
    {
      key: "2",
      id: 1,
      reqType: "Pre-incrop",
      task: "Certificate of Incumbency",
      serviceCode: "GOV1",
      ...shares[1],
    },
    {
      key: "3",
      id: 2,
      reqType: "Pre-incrop",
      task: "Good Standing",
      serviceCode: "RORA",
      ...shares[2],
    },
    {
      key: "4",
      id: 3,
      reqType: "Pre-incrop",
      task: "Change of Director",
      serviceCode: "FDIR",
      ...shares[3],
    },
    {
      key: "5",
      id: 4,
      reqType: "Pre-incrop",
      task: "Change of Shareholders",
      serviceCode: "FBOSS",
      ...shares[4],
    },
    {
      key: "6",
      id: 5,
      reqType: "Pre-incrop",
      task: "Alteration to M&A",
      serviceCode: "COADSUN",
      ...shares[5],
    },

    {
      key: "7",
      id: 6,
      ...shares[6],
    },
    {
      key: "8",
      id: 7,
      reqType: "Pre-incrop",
      task: "Notarization of a documents",
      serviceCode: "COURIER",
      ...shares[7],
    },
    {
      key: "9",
      id: 8,
      reqType: "Pre-incrop",
      task: "Apostille of a document",
      serviceCode: "PHYSCCI",
      ...shares[8],
    },
    {
      key: "10",
      id: 9,
      reqType: "Pre-incrop",
      task: "Drafting Resolution",
      serviceCode: "SEAL",
      ...shares[9],
    },
    {
      key: "11",
      id: 10,
      ...shares[10],
    },
    {
      key: "12",
      id: 11,
      ...shares[11],
    },

    {
      key: "13",
      id: 12,
      reqType: "Pre-incrop",
      task: "Resignation of a company",
      serviceCode: "ANGOV1",
      ...shares[12],
    },

    {
      key: "14",
      id: 13,
      reqType: "Pre-incrop",
      task: "Good Standing",
      serviceCode: "RORA",
      ...shares[13],
    },
    {
      key: "15",
      id: 14,
      reqType: "Pre-incrop",
      task: "Change of Director",
      serviceCode: "ESSADMN",
      ...shares[14],
    },
    {
      key: "16",
      id: 15,
      reqType: "Pre-incrop",
      task: "Change of Shareholders",
      serviceCode: "ANNRTN",
      ...shares[15],
    },
    {
      key: "17",
      id: 16,
      reqType: "Pre-incrop",
      task: "Alteration to M&A",
      serviceCode: "COADSUN",
      ...shares[16],
    },
    {
      key: "18",
      id: 17,
      ...shares[17],
    },
    {
      key: "19",
      id: 18,
      reqType: "Pre-incrop",
      task: "M&A Name Change",
      serviceCode: "GOVLP",
      ...shares[18],
    },
    {
      key: "20",
      id: 19,
      reqType: "Pre-incrop",
      task: "Notarization of a documents",
      serviceCode: "SVCLP",
      ...shares[19],
    },
    {
      key: "21",
      id: 20,
      reqType: "Pre-incrop",
      task: "Notarization of a documents",
      serviceCode: "RORALP",
      ...shares[20],
    },
    {
      key: "22",
      id: 21,
      ...shares[21],
    },
    {
      key: "23",
      id: 22,
      reqType: "Post-incrop",
      task: "Apostille of a document",
      serviceCode: "ALTMA",
      ...shares[22],
    },
    {
      key: "24",
      id: 23,
      reqType: "Post-incrop",
      task: "Drafting Resolution",
      serviceCode: "AMDMA",
      ...shares[23],
    },
    {
      key: "25",
      id: 24,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "APSTLLE",
      ...shares[24],
    },
    {
      key: "26",
      id: 25,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "COGS",
      ...shares[25],
    },
    {
      key: "27",
      id: 26,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "REGCC",
      ...shares[26],
    },

    {
      key: "28",
      id: 27,
      reqType: "Post-incrop",
      task: "Good Standing",
      serviceCode: "REGUCC",
      ...shares[27],
    },
    {
      key: "29",
      id: 28,
      reqType: "Post-incrop",
      task: "Change of Director",
      serviceCode: "REGPCC",
      ...shares[28],
    },
    {
      key: "30",
      id: 29,
      reqType: "Post-incrop",
      task: "Change of Shareholders",
      serviceCode: "CINCMB",
      ...shares[29],
    },
    {
      key: "31",
      id: 30,
      reqType: "Post-incrop",
      task: "Alteration to M&A",
      serviceCode: "CCPCC",
      ...shares[30],
    },
    {
      key: "32",
      id: 31,
      reqType: "Post-incrop",
      task: "M&A Name Change",
      serviceCode: "AMDROD",
      ...shares[31],
    },
    {
      key: "33",
      id: 32,
      reqType: "Post-incrop",
      task: "Notarization of a documents",
      serviceCode: "AMDROS",
      ...shares[32],
    },
    {
      key: "34",
      id: 33,
      reqType: "Post-incrop",
      task: "Apostille of a document",
      serviceCode: "CONTN",
      ...shares[33],
    },
    {
      key: "35",
      id: 34,
      reqType: "Post-incrop",
      task: "Drafting Resolution",
      serviceCode: "DRFTRES",
      ...shares[34],
    },
    {
      key: "36",
      id: 35,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "FILROMC",
      ...shares[35],
    },
    {
      key: "37",
      id: 36,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "FILRORA",
      ...shares[36],
    },
    {
      key: "38",
      id: 37,
      reqType: "Post-incrop",
      task: "Request to provide Corporate documents certified",
      serviceCode: "FILTOUT",
      ...shares[37],
    },
    {
      key: "39",
      id: 38,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "INDEMAG",
      ...shares[38],
    },
    {
      key: "40",
      id: 39,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "NTRZTN",
      ...shares[39],
    },
    {
      key: "41",
      id: 40,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "POATTN",
      ...shares[40],
    },
    {
      key: "42",
      id: 41,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "REACTVN",
      ...shares[41],
    },
    {
      key: "43",
      id: 42,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "RESRO",
      ...shares[42],
    },
    {
      key: "44",
      id: 43,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "RECRO",
      ...shares[43],
    },
    {
      key: "45",
      id: 44,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "RGFCO",
      ...shares[44],
    },
    {
      key: "46",
      id: 45,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "FRGNCO",
      ...shares[45],
    },
    {
      key: "47",
      id: 46,
      reqType: "Post-incrop",
      task: "Resignation of a company",
      serviceCode: "EXPDFEE",
      ...shares[46],
    },
    {
      key: "48",
      id: 47,
      reqType: "Post-incrop",
      task: "Corporate Director Fee",
      serviceCode: "CORPDIR1",
      ...shares[47],
    },
    {
      key: "49",
      id: 48,
      reqType: "Post-incrop",
      task: "Personal Director Fee",
      serviceCode: "PERSDIR1",
      ...shares[48],
    },
    {
      key: "50",
      id: 49,
      reqType: "Post-incrop",
      task: "Nominee Corp Shareholder",
      serviceCode: "NCORPSH1",
      ...shares[49],
    },
    {
      key: "51",
      id: 50,
      reqType: "Post-incrop",
      task: "Opening a Bank Account",
      serviceCode: "OPENBAN1",
      ...shares[50],
    },
    {
      key: "52",
      id: 51,
      reqType: "Post-incrop",
      task: "Provision of Post Box Services",
      serviceCode: "PRVPBS",
      ...shares[51],
    },
    {
      key: "53",
      id: 52,
      reqType: "Post-incrop",
      task: "Provision of Share Space Services",
      serviceCode: "PRVSSS",
      ...shares[52],
    },

    {
      key: "54",
      id: 53,
      ...shares[53],
    },
    {
      key: "55",
      id: 54,
      reqType: "Post-incrop",
      task: "Assisting with process to open a bank account",
      serviceCode: "OPBANK",
      ...shares[54],
    },
    {
      key: "56",
      id: 55,
      reqType: "Post-incrop",
      task: "Assisting with Work Permit applications",
      serviceCode: "WPAPP",
      ...shares[55],
    },
    {
      key: "57",
      id: 56,
      reqType: "Post-incrop",
      task: "Photocopying",
      serviceCode: "COPIES",
      ...shares[56],
    },
    {
      key: "58",
      id: 57,
      reqType: "Post-incrop",
      task: "Assisting with Trade Licence applications",
      serviceCode: "TLAPP",
      ...shares[57],
    },
    {
      key: "59",
      id: 58,
      reqType: "Post-incrop",
      task: "Time and attention charges - Directors",
      serviceCode: "TADIR",
      ...shares[58],
    },
    {
      key: "60",
      id: 59,
      reqType: "Post-incrop",
      task: "Time and attention charges - Junior staff",
      serviceCode: "TASTAFF",
      ...shares[59],
    },
    {
      key: "61",
      id: 60,
      reqType: "Post-incrop",
      task: "Wire Transfer Loss Recovery Fee (3)",
      serviceCode: "WTLR",
      ...shares[60],
    },

    {
      key: "62",
      id: 61,
      reqType: "Post-incrop",
      task: "Specialised Services",
      serviceCode: "SPECSVC",
      ...shares[61],
    },
  ];
  const updatecolumn = (value) => {
    let newColumn = [];

    if (value.includes("standard")) {
      newColumn.push({
        title: (
          <Checkbox
            className="custom-checkbox"
            checked={checkedList["standard"]["checked"]}
            onChange={(e) => getChecked(e.target.checked, "standard")}
            //  disabled={isStandard}
          >
            Standard Fee
          </Checkbox>
        ),
        children: [
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["standard"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["standard"]["child"]["up"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "standard", "up")
                  }
                >
                  Up to 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "standardUpTo",
            key: "standardUpTo",
            render: (_, { id, standardUpTo }) => (
              <div className="custom-form">
                {checkedList["standard"]["child"]["up"] ? (
                  <input
                    type="number"
                    // className="w-75"
                    value={shares[id].standardUpTo}
                    onChange={(e) =>
                      getShareChange(id, "standardUpTo", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["standard"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["standard"]["child"]["above"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "standard", "above")
                  }
                >
                  Above 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "standardAbove",
            key: "standardAbove",
            render: (_, { id, standardAbove }) => (
              <div className="custom-form">
                {checkedList["standard"]["child"]["above"] ? (
                  <input
                    type="number"
                    // className="w-75"
                    value={shares[id].standardAbove}
                    onChange={(e) =>
                      getShareChange(id, "standardAbove", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
        ],
      });
    }
    if (value.includes("discounted")) {
      newColumn.push({
        title: (
          <Checkbox
            className="custom-checkbox"
            checked={checkedList["discounted"]["checked"]}
            onChange={(e) => getChecked(e.target.checked, "discounted")}
            //disabled={isDiscounted}
          >
            Discounted Fee
          </Checkbox>
        ),
        children: [
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["discounted"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["discounted"]["child"]["up"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "discounted", "up")
                  }
                >
                  Up to 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "discountedUpTo",
            key: "discountedUpTo",
            render: (_, { id, discountedUpTo }) => (
              <div className="custom-form">
                {checkedList["discounted"]["child"]["up"] ? (
                  <input
                    type="number"
                    //  className="w-75"
                    value={shares[id].discountedUpTo}
                    onChange={(e) =>
                      getShareChange(id, "discountedUpTo", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["discounted"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["discounted"]["child"]["above"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "discounted", "above")
                  }
                >
                  Above 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "discountedAbove",
            key: "discountedAbove",
            render: (_, { id, discountedAbove }) => (
              <div className="custom-form">
                {checkedList["discounted"]["child"]["above"] ? (
                  <input
                    type="number"
                    // className="w-75"
                    value={shares[id].discountedAbove}
                    onChange={(e) =>
                      getShareChange(id, "discountedAbove", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
        ],
      });
    }
    if (value.includes("highRisk")) {
      newColumn.push({
        title: (
          <Checkbox
            className="custom-checkbox"
            checked={checkedList["highRisk"]["checked"]}
            onChange={(e) => getChecked(e.target.checked, "highRisk")}
            //  disabled={isHighRisk}
          >
            High Risk Fee
          </Checkbox>
        ),
        children: [
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["highRisk"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["highRisk"]["child"]["up"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "highRisk", "up")
                  }
                >
                  Up to 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "highRiskUpTo",
            key: "highRiskUpTo",
            render: (_, { id, highRiskUpTo }) => (
              <div className="custom-form">
                {checkedList["highRisk"]["child"]["up"] ? (
                  <input
                    type="number"
                    // className="w-75"
                    value={shares[id].highRiskUpTo}
                    onChange={(e) =>
                      getShareChange(id, "highRiskUpTo", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["highRisk"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["highRisk"]["child"]["above"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "highRisk", "above")
                  }
                >
                  Above 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "highRiskAbove",
            key: "highRiskAbove",
            render: (_, { id, highRiskAbove }) => (
              <div className="custom-form">
                {checkedList["highRisk"]["child"]["above"] ? (
                  <input
                    type="number"
                    //className="w-75"
                    value={shares[id].highRiskAbove}
                    onChange={(e) =>
                      getShareChange(id, "highRiskAbove", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
        ],
      });
    }
    if (value.includes("standardPlus")) {
      newColumn.push({
        title: (
          <Checkbox
            className="custom-checkbox"
            checked={checkedList["standardPlus"]["checked"]}
            onChange={(e) => getChecked(e.target.checked, "standardPlus")}
            // disabled={isStandardPlus}
          >
            Standard+ Fee
          </Checkbox>
        ),
        children: [
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["standardPlus"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["standardPlus"]["child"]["up"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "standardPlus", "up")
                  }
                >
                  Up to 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "standardPlusUpTo",
            key: "standardPlusUpTo",
            render: (_, { id, standardPlusUpTo }) => (
              <div className="custom-form">
                {checkedList["standardPlus"]["child"]["up"] ? (
                  <input
                    type="number"
                    //className="w-75"
                    value={shares[id].standardPlusUpTo}
                    onChange={(e) =>
                      getShareChange(id, "standardPlusUpTo", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
          {
            title: (
              <div className="text-center">
                <Checkbox
                  disabled={!checkedList["standardPlus"]["checked"]}
                  className="custom-checkbox"
                  checked={checkedList["standardPlus"]["child"]["above"]}
                  onChange={(e) =>
                    getChecked(e.target.checked, "standardPlus", "above")
                  }
                >
                  Above 50,000 Shares
                </Checkbox>
              </div>
            ),
            dataIndex: "standardPlusAbove",
            key: "standardPlusAbove",
            render: (_, { id, standardAbove }) => (
              <div className="custom-form">
                {checkedList["standardPlus"]["child"]["above"] ? (
                  <input
                    type="number"
                    // className="w-75"
                    value={shares[id].standardPlusAbove}
                    onChange={(e) =>
                      getShareChange(id, "standardPlusAbove", e.target.value)
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            ),
            onCell: sharedOnCell,
          },
        ],
      });
    }
    console.log("newColumn;");

    setColumn([...columns, ...newColumn]);
  };
  const onSubmit = async () => {
    if (searchFormRef.current.validForm()) {
      let options = searchFormRef.current.getPayload();

      let oldArray = [
        {
          key: "1",
          id: 0,
          header: true,
          ...shares[0],
        },
        {
          key: "2",
          id: 1,
          reqType: "Pre-incrop",
          task: "Certificate of Incumbency",
          serviceCode: "GOV1",
          ...shares[1],
          header: false,
        },
        {
          key: "3",
          id: 2,
          reqType: "Pre-incrop",
          task: "Good Standing",
          serviceCode: "RORA",
          ...shares[2],
          header: false,
        },
        {
          key: "4",
          id: 3,
          reqType: "Pre-incrop",
          task: "Change of Director",
          serviceCode: "FDIR",
          ...shares[3],
          header: false,
        },
        {
          key: "5",
          id: 4,
          reqType: "Pre-incrop",
          task: "Change of Shareholders",
          serviceCode: "FBOSS",
          ...shares[4],
          header: false,
        },
        {
          key: "6",
          id: 5,
          reqType: "Pre-incrop",
          task: "Alteration to M&A",
          serviceCode: "COADSUN",
          ...shares[5],
          header: false,
        },
        {
          key: "7",
          id: 6,
          header: true,
          ...shares[6],
        },
        {
          key: "8",
          id: 7,
          reqType: "Pre-incrop",
          task: "Notarization of a documents",
          serviceCode: "COURIER",
          ...shares[7],
          header: false,
        },
        {
          key: "9",
          id: 8,
          reqType: "Pre-incrop",
          task: "Apostille of a document",
          serviceCode: "PHYSCCI",
          ...shares[8],
          header: false,
        },
        {
          key: "10",
          id: 9,
          reqType: "Pre-incrop",
          task: "Drafting Resolution",
          serviceCode: "SEAL",
          ...shares[9],
          header: false,
        },
        {
          key: "11",
          id: 10,
          header: true,
          ...shares[10],
        },
        {
          key: "12",
          id: 11,
          header: true,
          ...shares[11],
        },

        {
          key: "13",
          id: 12,
          reqType: "Pre-incrop",
          task: "Resignation of a company",
          serviceCode: "ANGOV1",
          ...shares[12],
          header: false,
        },
        {
          key: "14",
          id: 13,
          reqType: "Pre-incrop",
          task: "Good Standing",
          serviceCode: "RORA",
          ...shares[13],
          header: false,
        },
        {
          key: "15",
          id: 14,
          reqType: "Pre-incrop",
          task: "Change of Director",
          serviceCode: "ESSADMN",
          ...shares[14],
          header: false,
        },
        {
          key: "16",
          id: 15,
          reqType: "Pre-incrop",
          task: "Change of Shareholders",
          serviceCode: "ANNRTN",
          ...shares[15],
          header: false,
        },
        {
          key: "17",
          id: 16,
          reqType: "Pre-incrop",
          task: "Alteration to M&A",
          serviceCode: "COADSUN",
          ...shares[16],
          header: false,
        },
        {
          key: "18",
          id: 17,
          header: true,
          ...shares[17],
        },
        {
          key: "19",
          id: 18,
          reqType: "Pre-incrop",
          task: "M&A Name Change",
          serviceCode: "GOVLP",
          ...shares[18],
          header: false,
        },
        {
          key: "20",
          id: 19,
          reqType: "Pre-incrop",
          task: "Notarization of a documents",
          serviceCode: "SVCLP",
          ...shares[19],
          header: false,
        },
        {
          key: "21",
          id: 20,
          reqType: "Pre-incrop",
          task: "Notarization of a documents",
          serviceCode: "RORALP",
          ...shares[20],
          header: false,
        },
        {
          key: "22",
          id: 21,
          ...shares[21],
          header: true,
        },
        {
          key: "23",
          id: 22,
          reqType: "Post-incrop",
          task: "Apostille of a document",
          serviceCode: "ALTMA",
          ...shares[22],
          header: false,
        },
        {
          key: "24",
          id: 23,
          reqType: "Post-incrop",
          task: "Drafting Resolution",
          serviceCode: "AMDMA",
          ...shares[23],
          header: false,
        },
        {
          key: "25",
          id: 24,
          reqType: "Post-incrop",
          task: "Request to provide Corporate documents certified",
          serviceCode: "APSTLLE",
          ...shares[24],
          header: false,
        },
        {
          key: "26",
          id: 25,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "COGS",
          ...shares[25],
          header: false,
        },
        {
          key: "27",
          id: 26,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "REGCC",
          ...shares[26],
          header: false,
        },
        {
          key: "28",
          id: 27,
          reqType: "Post-incrop",
          task: "Good Standing",
          serviceCode: "REGUCC",
          ...shares[27],
          header: false,
        },
        {
          key: "29",
          id: 28,
          reqType: "Post-incrop",
          task: "Change of Director",
          serviceCode: "REGPCC",
          ...shares[28],
          header: false,
        },
        {
          key: "30",
          id: 29,
          reqType: "Post-incrop",
          task: "Change of Shareholders",
          serviceCode: "CINCMB",
          ...shares[29],
          header: false,
        },
        {
          key: "31",
          id: 30,
          reqType: "Post-incrop",
          task: "Alteration to M&A",
          serviceCode: "CCPCC",
          ...shares[30],
          header: false,
        },
        {
          key: "32",
          id: 31,
          reqType: "Post-incrop",
          task: "M&A Name Change",
          serviceCode: "AMDROD",
          ...shares[31],
          header: false,
        },
        {
          key: "33",
          id: 32,
          reqType: "Post-incrop",
          task: "Notarization of a documents",
          serviceCode: "AMDROS",
          ...shares[32],
          header: false,
        },
        {
          key: "34",
          id: 33,
          reqType: "Post-incrop",
          task: "Apostille of a document",
          serviceCode: "CONTN",
          ...shares[33],
          header: false,
        },
        {
          key: "35",
          id: 34,
          reqType: "Post-incrop",
          task: "Drafting Resolution",
          serviceCode: "DRFTRES",
          ...shares[34],
          header: false,
        },
        {
          key: "36",
          id: 35,
          reqType: "Post-incrop",
          task: "Request to provide Corporate documents certified",
          serviceCode: "FILROMC",
          ...shares[35],
          header: false,
        },
        {
          key: "37",
          id: 36,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "FILRORA",
          ...shares[36],
          header: false,
        },
        {
          key: "38",
          id: 37,
          reqType: "Post-incrop",
          task: "Request to provide Corporate documents certified",
          serviceCode: "FILTOUT",
          ...shares[37],
          header: false,
        },
        {
          key: "39",
          id: 38,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "INDEMAG",
          ...shares[38],
          header: false,
        },
        {
          key: "40",
          id: 39,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "NTRZTN",
          ...shares[39],
          header: false,
        },
        {
          key: "41",
          id: 40,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "POATTN",
          ...shares[40],
          header: false,
        },
        {
          key: "42",
          id: 41,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "REACTVN",
          ...shares[41],
          header: false,
        },
        {
          key: "43",
          id: 42,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "RESRO",
          ...shares[42],
          header: false,
        },
        {
          key: "44",
          id: 43,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "RECRO",
          ...shares[43],
          header: false,
        },
        {
          key: "45",
          id: 44,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "RGFCO",
          ...shares[44],
          header: false,
        },
        {
          key: "46",
          id: 45,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "FRGNCO",
          ...shares[45],
          header: false,
        },
        {
          key: "47",
          id: 46,
          reqType: "Post-incrop",
          task: "Resignation of a company",
          serviceCode: "EXPDFEE",
          ...shares[46],
          header: false,
        },
        {
          key: "48",
          id: 47,
          reqType: "Post-incrop",
          task: "Corporate Director Fee",
          serviceCode: "CORPDIR1",
          ...shares[47],
          header: false,
        },
        {
          key: "49",
          id: 48,
          reqType: "Post-incrop",
          task: "Personal Director Fee",
          serviceCode: "PERSDIR1",
          ...shares[48],
          header: false,
        },
        {
          key: "50",
          id: 49,
          reqType: "Post-incrop",
          task: "Nominee Corp Shareholder",
          serviceCode: "NCORPSH1",
          ...shares[49],
          header: false,
        },
        {
          key: "51",
          id: 50,
          reqType: "Post-incrop",
          task: "Opening a Bank Account",
          serviceCode: "OPENBAN1",
          ...shares[50],
          header: false,
        },
        {
          key: "52",
          id: 51,
          reqType: "Post-incrop",
          task: "Provision of Post Box Services",
          serviceCode: "PRVPBS",
          ...shares[51],
          header: false,
        },
        {
          key: "53",
          id: 52,
          reqType: "Post-incrop",
          task: "Provision of Share Space Services",
          serviceCode: "PRVSSS",
          ...shares[52],
          header: false,
        },
        {
          key: "54",
          id: 53,
          ...shares[53],
          header: true,
        },
        {
          key: "55",
          id: 54,
          reqType: "Post-incrop",
          task: "Assisting with process to open a bank account",
          serviceCode: "OPBANK",
          ...shares[54],
          header: false,
        },

        {
          key: "56",
          id: 55,
          reqType: "Post-incrop",
          task: "Assisting with Work Permit applications",
          serviceCode: "WPAPP",
          ...shares[55],
          header: false,
        },
        {
          key: "57",
          id: 56,
          reqType: "Post-incrop",
          task: "Photocopying",
          serviceCode: "COPIES",
          ...shares[56],
          header: false,
        },
        {
          key: "58",
          id: 57,
          reqType: "Post-incrop",
          task: "Assisting with Trade Licence applications",
          serviceCode: "TLAPP",
          ...shares[57],
          header: false,
        },
        {
          key: "59",
          id: 58,
          reqType: "Post-incrop",
          task: "Time and attention charges - Directors",
          serviceCode: "TADIR",
          ...shares[58],
          header: false,
        },
        {
          key: "60",
          id: 59,
          reqType: "Post-incrop",
          task: "Time and attention charges - Junior staff",
          serviceCode: "TASTAFF",
          ...shares[59],
          header: false,
        },
        {
          key: "61",
          id: 60,
          reqType: "Post-incrop",
          task: "Wire Transfer Loss Recovery Fee (3)",
          serviceCode: "WTLR",
          ...shares[60],
          header: false,
        },

        {
          key: "62",
          id: 61,
          reqType: "Post-incrop",
          task: "Specialised Services",
          serviceCode: "SPECSVC",
          ...shares[61],
          header: false,
        },
      ];
      // let oldArray = value.filter((a) => a.header == false);
      // console.log(oldArray, "ertdyfugihl");
      let isSplus = false;
      let updateType = [];
      shares.forEach((newObj, newIndex) => {
        const oldObj = oldArray[newIndex];
        const changedKeys = [];
        for (const key in newObj) {
          if (newObj[key] !== oldObj[key] && oldObj[key] < newObj[key]) {
            changedKeys.push(key);
          }
        }

        if (changedKeys.length > 0) {
          isSplus = true;
          if (!newObj.sPlus) {
            newObj.sPlus = [];
          }
          newObj.sPlus.push({
            column: changedKeys,
            value: true,
          });
        }
        updateType.push(changedKeys);
      });

      let filteredArray = [];
      let seenArrays = new Set();
      updateType.forEach((array, index) => {
        const sortedArray = array.slice().sort();
        if (array.length > 0 && !seenArrays.has(sortedArray.join(","))) {
          filteredArray = sortedArray;
          seenArrays.add(sortedArray.join(","));
        }
      });
      let fee = [];
      let header = [];
      await asyncForEach(shares, async (onedata) => {
        if (onedata.header) {
          header.push(onedata);
        } else {
          fee.push(onedata);
        }
      });
      let obj = {
        clientId: options.clientCode,
        name: options.feeScheduleName,
        requestType: options.paymentCategory,
        fee: fee,
        isSplus: isSplus,
        standard: checkedList.standard,
        discounted: checkedList.discounted,
        standardPlus: checkedList.standardPlus,
        highRisk: checkedList.highRisk,
        changedSplus: filteredArray,
      };

      let payload = {
        method: "POST",
        url: `/feeCategory`,
        data: obj,
      };
      let url = `/app/fee-schedule`;

      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message ? res?.message : "success");
        })
        .catch((error) => {
          sendNotify("error", error?.message); // setTimeout(() => {
          //   window.location.href = url;
          // }, 500);
        });
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  ////
  return (
    <div>
      <div className="page-header">
        <h1>Create Fee Schedule</h1>
        <Link to={"/app/fee-schedule"} className="primary-btn">
          <i className="fal fa-backward"></i> Back to list
        </Link>
      </div>
      <div className="page-content">
        <FormZ
          formClass="row custom-form mb-2 mb-md-5 align-items-end gy-3"
          childClass="col-md-2 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
          formData={formData}
          formOptions={{ clientCode: clientArray }}
          onChange={handleOnChange}
          formSchema={searchFormSchema}
          ref={searchFormRef}
        />
        <div className="table-box">
          <Table
            className="custom-table"
            columns={columns}
            dataSource={data}
            scroll={{
              x: 1400,
              y: 240,
            }}
            pagination={false}
          />
          <div className="d-flex justify-content-end align-items-center mt-md-4">
            <button className="primary-btn px-4" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
