import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

//Action
export const getMasterCode = createAsyncThunk(
    "getMasterCode",
    async (params) => {
        let query = convertQueryParams(params);

        let payload = {
            method: "get",
            url: `/admin/masterCode?${query}`,

        };

        let res = await fetchApi(payload).then((res) => {
            return res.data;
        });
        return res;
    }
);

const incorpSlice = createSlice({
    name: "masterCode",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getMasterCode.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(getMasterCode.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });

        builder.addCase(getMasterCode.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        });
    },
});

export default incorpSlice.reducer;
