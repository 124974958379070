import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";
import RejectTask from "../components/RejectTask";

import { Each, fetchApi, getQuery } from "../helper";
import { useSelector } from "react-redux";
import { useGeneralEmail } from "../hooks/useGeneralEmail";
import ServiceRequestEmailSender from "../components/ServiceRequestEmaiSendButtons";

export default function ResignationRegisteredAgent() {
  const [docs, setDocs] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  let query = getQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  function getData() {
    setLoading(true);
    let payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload).then((res) => {
      setData(res?.data?.rows?.[0]);
      setDocs(res?.data?.rows?.[0]?.documents);
      setLoading(false);
    });
  }

  const { isSendingEmail } = useGeneralEmail();
  const { userDetails: businessPartnerDetails } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSendingEmail) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSendingEmail]);

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Share Registry
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <Link
            to={`/app/entity-overview/list?id=${data?.entityId}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          <RejectTask id={query?.id} reason={data?.rejectReason} />
          <Link className="outline-btn" to={"/app/mail"}>
            Check Mailbox
          </Link>
          <ServiceRequestEmailSender
            serviceRequestName="NOD"
            serviceRequestData={data}
            businessPartnerDetails={businessPartnerDetails}
          />
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <div className="page-content">
        <Spin spinning={loading}>
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
          </div>
          <h2 className="form-title mb-4">Documents</h2>
          <div className="row gy-2 gx-4">
            <Each
              array={docs}
              render={(item, index) => (
                <div className="col-md-3">
                  <div>
                    <UploadZ file={item} />
                  </div>
                </div>
              )}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
}
