import { useState, useRef } from "react";
import { Checkbox, Select, Modal } from "antd";

import FormZ from "../components/FormZ";
import { sendNotify } from "../helper";

export default function AccessControl() {
  const formRef = useRef();

  const [userModal, setUserModal] = useState(false);

  let formSchema = [
    {
      name: "firstName",
      value: "",
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
      required: true,
    },
    {
      name: "lastName",
      value: "",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
      required: true,
    },
    {
      name: "clientName",
      value: "",
      type: "text",
      label: "Client Name",
      placeholder: "Enter client name",
      required: true,
    },
    {
      name: "username",
      value: "",
      type: "text",
      label: "Preferred Username",
      placeholder: "Enter preferred username",
      required: true,
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
      required: true,
    },
    {
      name: "contactNumber",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number",
      customClass: "hide-arrows",
      required: true,
    },
    {
      name: "group",
      value: "",
      type: "select",
      label: "Assigned User Group",
      placeholder: "Select group",
      required: true,
    },
  ];

  const handleOk = () => {
    let valid = formRef.current.validFrom();
    if (valid) {
      setUserModal(false);
      let payload = formRef.current.getPayload();
      formRef.current.clearForm();
    } else {
      sendNotify("error", "Some fields are missing!");
    }
  };
  const handleCancel = () => {
    setUserModal(false);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-3 mb-md-0">Access Control</h1>
        <div className="d-flex gap-3 flex-column flex-md-row">
          <button className="outline-btn">Export Report</button>
          <button className="primary-btn" onClick={() => setUserModal(true)}>
            Create User
          </button>
        </div>
      </div>
      <h2 className="sub-title mb-2">Access List</h2>
      <div className="d-flex gap-3 flex-wrap mb-4">
        <Checkbox className="custom-checkbox">In-House Translation</Checkbox>
        <Checkbox className="custom-checkbox">Manual Translation</Checkbox>
        <Checkbox className="custom-checkbox">In-House OCR</Checkbox>
        <Checkbox className="custom-checkbox">Manual OCR</Checkbox>
        <Checkbox className="custom-checkbox">Storage</Checkbox>
        <Checkbox className="custom-checkbox">OCR</Checkbox>
        <Checkbox className="custom-checkbox">RAF</Checkbox>
        <Checkbox className="custom-checkbox">Web Search</Checkbox>
        <Checkbox className="custom-checkbox">Risk Analysis</Checkbox>
        <Checkbox className="custom-checkbox">CDD Screen</Checkbox>
        <Checkbox className="custom-checkbox">Document Review</Checkbox>
        <Checkbox className="custom-checkbox">
          New Incorporation Name Check
        </Checkbox>
        <Checkbox className="custom-checkbox">MLRO Sign Off Dashboard</Checkbox>
        <Checkbox className="custom-checkbox">New Business Onboard</Checkbox>
        <Checkbox className="custom-checkbox">Send to Approval</Checkbox>
        <Checkbox className="custom-checkbox">
          End User / Relationship Manager
        </Checkbox>
        <Checkbox className="custom-checkbox">Active Requests</Checkbox>
        <Checkbox className="custom-checkbox">
          Incorporation Confirmation
        </Checkbox>
        <Checkbox className="custom-checkbox">Incorporation Feedback</Checkbox>
        <Checkbox className="custom-checkbox">Incorporation Pack</Checkbox>
        <Checkbox className="custom-checkbox">Master Search</Checkbox>
        <Checkbox className="custom-checkbox">MLRO Sign Off</Checkbox>
        <Checkbox className="custom-checkbox">New Incorporation</Checkbox>
        <Checkbox className="custom-checkbox">Pending Information</Checkbox>
        <Checkbox className="custom-checkbox">Post Incorp</Checkbox>
        <Checkbox className="custom-checkbox">
          Pre-Incorp Supported Documents
        </Checkbox>
        <Checkbox className="custom-checkbox">Entity Invoicing</Checkbox>
        <Checkbox className="custom-checkbox">
          Entity Overview - Update
        </Checkbox>
        <Checkbox className="custom-checkbox">Entity Overview - List</Checkbox>
        <Checkbox className="custom-checkbox">Director Screen</Checkbox>
        <Checkbox className="custom-checkbox">Shareholder</Checkbox>
        <Checkbox className="custom-checkbox">Trust</Checkbox>
        <Checkbox className="custom-checkbox">Partnership</Checkbox>
        <Checkbox className="custom-checkbox">General Partnership</Checkbox>
        <Checkbox className="custom-checkbox">Limited Partnership</Checkbox>
        <Checkbox className="custom-checkbox">Parent Client</Checkbox>
        <Checkbox className="custom-checkbox">Client</Checkbox>
        <Checkbox className="custom-checkbox">Crypto KYC</Checkbox>
        <Checkbox className="custom-checkbox">Finance</Checkbox>
        <Checkbox className="custom-checkbox">Individual Analysis</Checkbox>
        <Checkbox className="custom-checkbox">My Portfolio</Checkbox>
      </div>
      <h2 className="sub-title mb-2">User Access</h2>
      <div className="row custom-form gap-ms-2">
        <div className="col-md-3">
          <label htmlFor="" className="me-3">
            Select User
          </label>
          <Select
            className="custom-select w-100 ptb-1"
            placeholder="Select industry"
            showSearch
            allowClear
            // onChange={handleChange}
            mode="multiple"
            options={[
              {
                value: "user1",
                label: "User 1",
              },
              {
                value: "user2",
                label: "User 2",
              },
              {
                value: "user3",
                label: "User 3",
              },
            ]}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="" className="me-3">
            User Group
          </label>
          <Select
            className="custom-select w-100"
            placeholder="Select group"
            options={[
              {
                value: "Group1",
                label: "Group 1",
              },
              {
                value: "Group2",
                label: "Group 2",
              },
              {
                value: "Group3",
                label: "Group 3",
              },
            ]}
          />
        </div>
      </div>
      <div className="d-flex gap-3 flex-column flex-md-row my-4">
        <button className="outline-btn w-sm-100 justify-content-center green-btn">
          Enable Access
        </button>
        <button className="outline-btn w-sm-100 justify-content-center red-btn">
          Revoke Access
        </button>
      </div>
      <Modal
        title={"Create New User"}
        className="custom-modal"
        open={userModal}
        width={600}
        onOk={handleOk}
        okText="Create"
        onCancel={handleCancel}
      >
        <FormZ formSchema={formSchema} formClass="custom-form" ref={formRef} />
      </Modal>
    </div>
  );
}
