import React, { useState, useRef } from "react";
import { Table, Tag, Select, Modal, DatePicker } from "antd";
import dayjs from "dayjs";

import FormZ from "../components/FormZ";
import { downloadAsCSV } from "../helper";

export default function AnnualFilings() {
  const columns = [
    {
      title: "Entity Name",
      dataIndex: "Entity Name",
      sorter: (a, b) => a["Entity Name"] - b["Entity Name"],
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        // <Tag color="green" className="custom-tag">
        //   Active
        // </Tag>
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
            {
              value: "closing",
              label: "Closing",
            },
            {
              value: "closed",
              label: "Closed",
            },
            {
              value: "prospect",
              label: "Prospect",
            },
            {
              value: "transferringOut",
              label: "Transferring Out",
            },
            {
              value: "transferringIn",
              label: "Transferring In",
            },
            {
              value: "liquidated",
              label: "Liquidated",
            },
            {
              value: "struckOff",
              label: "Struck Off",
            },
            {
              value: "resigned",
              label: "Resigned",
            },
            {
              value: "continuationOut",
              label: "Continuation Out",
            },
            {
              value: "continuationIn",
              label: "Continuation In",
            },
          ]}
        />
      ),
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "firstHalf",
              label: "First Half",
            },
            {
              value: "secondHalf",
              label: "Second Half",
            },
          ]}
        />
      ),
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "firstHalf",
              label: "First Half",
            },
            {
              value: "secondHalf",
              label: "Second Half",
            },
          ]}
        />
      ),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    },
    {
      title: "Filing type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType - b.filingType,
    },
    {
      title: "Count Of Reminders",
      dataIndex: "countOfReminders",
      sorter: (a, b) => a.countOfReminders - b.countOfReminders,
    },
    {
      title: "Deadline Breach",
      dataIndex: "deadlineBreach",
      sorter: (a, b) => a.deadlineBreach - b.deadlineBreach,
      render: (_, { deadlineBreach }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={deadlineBreach == "Y" ? "green" : "red"}
        >
          {deadlineBreach == "Y" ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Filing Status",
      dataIndex: "filingStatus",
      sorter: (a, b) => a.filingStatus - b.filingStatus,
      render: (_, { filingStatus }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={filingStatus == "Y" ? "green" : "red"}
        >
          {filingStatus == "Y" ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "FSC Notification Status",
      dataIndex: "fscStatus",
      sorter: (a, b) => a.fscStatus - b.fscStatus,
      render: (_, { fscStatus }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={fscStatus == "Y" ? "green" : "gold"}
        >
          {fscStatus == "Y" ? "Submitted" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "View FSC Update",
      render: (_, data) => <button className="action-btn">View</button>,
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      incorpDate: "09-02-2023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      filingType: "Annual Returns",
      countOfReminders: 4,
      deadlineBreach: "Y",
      filingStatus: "N",
      fscStatus: "Y",
    },
    {
      key: "2",
      id: "2",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      incorpDate: "09-02-2023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      filingType: "Annual Returns",
      countOfReminders: 2,
      deadlineBreach: "N",
      filingStatus: "N",
      fscStatus: "Y",
    },
    {
      key: "3",
      id: "3",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      incorpDate: "09-02-2023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      filingType: "Annual Returns",
      countOfReminders: 1,
      deadlineBreach: "Y",
      filingStatus: "Y",
      fscStatus: "N",
    },
  ];

  const onExportClick = (fileName) => {
    downloadAsCSV(data, fileName);
  };

  return (
    <div>
      <h1 className="page-title mb-3 mb-md-5">Annual Filing</h1>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-1">
        <h6 className="primary-title mb-0">Annual Return Filing</h6>
        <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button>
      </div>
      <Table
        className="custom-table mb-3"
        columns={columns}
        dataSource={data}
        scroll={{ x: 2400 }}
      />

      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-1">
        <h6 className="primary-title mb-0">Boss Filing Summary</h6>
        <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button>
      </div>
      <Table
        className="custom-table mb-3"
        columns={columns}
        dataSource={data}
        scroll={{ x: 2400 }}
      />

      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-1">
        <h6 className="primary-title mb-0">ROD Filing</h6>
        <button className="primary-btn" onClick={onExportClick}>
          <i className="fal fa-file-download"></i> Export
        </button>
      </div>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={data}
        scroll={{ x: 2400 }}
      />
    </div>
  );
}
