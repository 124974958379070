import { createSlice } from "@reduxjs/toolkit";

import { parseJwt } from "../../helper";

const initialState = {
  isLogged: false,
  authToken: "",
  userDetails: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkLogged: (state) => {
      // let local = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)
      // let details = local ? JSON.parse(local) : null
      // console.log(details,"dfvbdfhb")
      // let token = details?.data?.accessToken
      // state.isLogged = token ? true : false
      // state.authToken = token ? token : ''
      // state.userDetails = token ? parseJwt(token) : {}
      let token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
      //  let details = local ? JSON.parse(local) : null
      //   let token = details?.data?.accessToken
      state.isLogged = token ? true : false;
      state.authToken = token ? token : "";
      state.userDetails = token ? parseJwt(token) : {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { checkLogged } = authSlice.actions;

export default authSlice.reducer;
