import { useState, useEffect } from "react";
import { Table, Tag, Tabs } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { percentageOf } from "../helper";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PeriodicReview() {
  const [periodicData, setPeriodicData] = useState([]);

  useEffect(() => {
    setPeriodicData(data);
  }, []);

  const onChange = (key) => {
    console.log(key);
    let arr = [];
    if (key == 1) {
      arr = data;
    } else if (key == 2) {
      arr = data.filter((val) => val.riskLevel == "High");
    } else if (key == 3) {
      arr = data.filter((val) => val.riskLevel == "Medium");
    } else if (key == 4) {
      arr = data.filter((val) => val.riskLevel == "Low");
    }

    setPeriodicData(arr);
  };

  const pieData = {
    labels: ["High Risk", "Medium Risk", "Low Risk"],
    datasets: [
      {
        label: "Periodic Review",
        data: [20, 10, 30],
        backgroundColor: ["#db0015", "#ff9b00", "#ffef00"],
        borderColor: ["#db0015", "#ff9b00", "#ffef00"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Inter",
          },
        },
      },
      datalabels: {
        color: "#FFF",
        font: {
          family: "Inter",
          weight: "600",
          size: 16,
        },
        textStrokeColor: "#000",
        textStrokeWidth: 2,
        formatter: (value, context) => {
          let val = percentageOf(value, 60) + "%";
          // let val = `${percentageOf(value, 60)}% (${value})`;
          return val;
        },
      },
    },
  };

  const items = [
    {
      key: 1,
      label: <span>All Periodic</span>,
    },
    {
      key: 2,
      label: (
        <span>
          <i className="fad fa-dot-circle color-red me-1"></i> High Risk
        </span>
      ),
    },
    {
      key: 3,
      label: (
        <span>
          <i className="fad fa-dot-circle color-origin me-1"></i> Medium Risk
        </span>
      ),
    },
    {
      key: 4,
      label: (
        <span>
          <i className="fad fa-dot-circle color-yellow me-1"></i> Low Risk
        </span>
      ),
    },
  ];

  const columns = [
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
    },
    {
      title: "Incorporated Date",
      dataIndex: "incorporatedDate",
      sorter: (a, b) => a.incorporatedDate - b.incorporatedDate,
    },
    {
      title: "Risk Level",
      dataIndex: "riskLevel",
      sorter: (a, b) => a.riskLevel - b.riskLevel,
      render: (_, { riskLevel }) => {
        let color;
        switch (riskLevel) {
          case "Low":
            color = "gold";
            break;
          case "Medium":
            color = "volcano";
            break;
          case "High":
            color = "red";
            break;
        }
        return (
          <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={color}>
            {riskLevel}
          </Tag>
        );
      },
    },
    {
      title: "Last Reviewed By",
      dataIndex: "lastReviewedBy",
      sorter: (a, b) => a.lastReviewedBy - b.lastReviewedBy,
    },
    {
      title: "Last Reviewed Date",
      dataIndex: "lastReviewedDate",
      sorter: (a, b) => a.lastReviewedDate - b.lastReviewedDate,
    },
    {
      title: "Next Review Date",
      dataIndex: "nextReviewDate",
      sorter: (a, b) => a.nextReviewDate - b.nextReviewDate,
    },
    {
      title: "Remaining Count Of Days",
      dataIndex: "remainingCountOfDays",
      sorter: (a, b) => a.remainingCountOfDays - b.remainingCountOfDays,
    },
  ];

  const data = [
    {
      key: 0,
      id: "0",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "001",
      incorporatedDate: "15/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "High",
      lastReviewedBy: "Guru",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 1,
      id: "1",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "High",
      lastReviewedBy: "Guru",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 2,
      id: "2",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "High",
      lastReviewedBy: "Guru",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 4,
      id: "4",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 5,
      id: "5",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 6,
      id: "6",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 7,
      id: "7",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 8,
      id: "8",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Medium",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 9,
      id: "9",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Low",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
    {
      key: 10,
      id: "10",
      entityName: "Sample",
      entityCode: "9023",
      clientName: "COI",
      clientCode: "839",
      incorporationNumber: "002",
      incorporatedDate: "16/04/2023",
      parentClientName: "Hari",
      parentClientCode: "632",
      riskLevel: "Low",
      lastReviewedBy: "23/04/2023",
      lastReviewedDate: "20/06/2024",
      nextReviewDate: "23/04/2023",
      remainingCountOfDays: "20",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <div className="d-flex my-3 justify-content-between flex-column flex-md-row align-items-md-center align-items-start">
        <h2 className="page-title mb-3 mb-md-0">Periodic Review</h2>
        <button className="primary-btn">Bulk Export</button>
      </div>
      <div className="w-25 mx-auto">
        <Pie data={pieData} plugins={[ChartDataLabels]} options={options} />
      </div>
      <div className="position-relative">
        <Tabs
          className="custom-tabs"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
        <button className="outline-btn sticky-right ms-auto">Export</button>
      </div>
      <Table
        className="custom-table mb-4"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={columns}
        dataSource={periodicData}
        scroll={{ x: 2000 }}
      />
    </div>
  );
}
