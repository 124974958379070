import React, { useState } from "react";
import { Table, Modal, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { Each } from "../helper";
import UploadZ from "../components/UploadZ";

import SampleDoc from "../assets/pdf/sample.pdf";

export default function IncorporationFeedback() {
  const navigate = useNavigate();

  const [documents, setDocuments] = useState([]);
  const [uploadDocModal, setUploadDocModal] = useState(false);
  const [viewDocModal, setViewDocModal] = useState(false);
  const [docMode, setDocMode] = useState("Update");

  const columns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
    },
    {
      title: "Entity Type",
      dataIndex: "entityType",
      sorter: (a, b) => a.entityType - b.entityType,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    },
    {
      title: "Incorp Status",
      dataIndex: "incorpStatus",
      sorter: (a, b) => a.incorpStatus - b.incorpStatus,
      render: (_, { incorpStatus }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={incorpStatus == "Y" ? "green" : "gold"}
        >
          {incorpStatus == "Y" ? "Active" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "Registry Date & Time",
      dataIndex: "registryDate",
      sorter: (a, b) => a.registryDate - b.registryDate,
    },
    {
      title: "Reviewed By",
      dataIndex: "reviewedBy",
      sorter: (a, b) => a.reviewedBy - b.reviewedBy,
    },
    {
      title: "Approved Date",
      dataIndex: "approvedDate",
      sorter: (a, b) => a.approvedDate - b.approvedDate,
    },
    {
      title: "Approver",
      dataIndex: "approver",
      sorter: (a, b) => a.approver - b.approver,
    },
    {
      title: "Actions",
      dataIndex: "incorpPack",
      render: (_, data) => (
        <div className="d-flex gap-3">
          <button
            className="outline-btn py-2"
            onClick={() => setUploadDocModal(true)}
          >
            View
          </button>
          <button className="outline-btn py-2">Submit</button>
          <button className="outline-btn py-2">Feedback</button>
          <button
            className="outline-btn py-2"
            onClick={() => (setViewDocModal(true), setDocMode("Update"))}
          >
            Incorp Doc Draft
          </button>
          <button
            className="outline-btn py-2"
            onClick={() => (setViewDocModal(true), setDocMode("View"))}
          >
            Executed From Client
          </button>
          <button className="primary-btn py-2">Onboard</button>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      incorpDate: "12/04/2023",
      incorpNumber: "8237",
      incorpStatus: "Y",
      registryDate: "14/04/2023 - 04:00PM",
      reviewedBy: "Hari",
      approvedDate: "15/04/2023 - 12:00PM",
      approver: "Guru",
    },
    {
      key: "2",
      id: "2",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      incorpDate: "14/04/2023",
      incorpNumber: "2342",
      incorpStatus: "N",
      registryDate: "16/04/2023 - 05:00AM",
      reviewedBy: "Hari",
      approvedDate: "15/04/2023 - 12:00PM",
      approver: "Guru",
    },
    {
      key: "3",
      id: "3",
      entityName: "Sample",
      entityType: "Trust",
      entityCode: "9023",
      rmName: "Sample",
      incorpDate: "16/04/2023",
      incorpNumber: "7324",
      incorpStatus: "Y",
      registryDate: "18/04/2023 - 06:00PM",
      reviewedBy: "Hari",
      approvedDate: "15/04/2023 - 12:00PM",
      approver: "Guru",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    // console.log(doc);
    setDocuments(doc);
  }

  const uploadDocs = [
    "Certificate of Incorporation",
    "M&AA",
    "Seal",
    "VIRRGIN Report",
    "COGS",
  ];

  const [customDocs, setCustomDocs] = useState([]);

  const addNewUpload = () => {
    let arr = [...customDocs];
    let index = arr.length + 1;
    arr.push(`Enter Document Name ${index}`);
    setCustomDocs(arr);
  };

  const removeUpload = (index) => {
    let arr = [...customDocs];
    arr.splice(index, 1);
    setCustomDocs(arr);
  };

  const getLabelName = (value, index) => {
    let arr = [...customDocs];
    arr[index] = value;
    setCustomDocs(arr);
  };

  const handleOk = () => {
    setUploadDocModal(false);
    setViewDocModal(false);
  };
  const handleCancel = () => {
    setUploadDocModal(false);
    setViewDocModal(false);
  };

  const sampDocuments = {
    url: SampleDoc,
    name: "Sample Document.pdf",
    size: "200 KB",
    type: "pdf",
    base64: "",
  };

  function redirectToUpload() {
    navigate("/app/relationship-manager?mode=edit");
  }

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
        <h1 className="page-title mb-3 mb-md-0">Incorporation Feedback</h1>
        <Link to={"/app/onboarding-team/review-upload"} className="primary-btn">
          Complete and Submit
        </Link>
      </div>
      <Table
        className="custom-table"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 1800 }}
      />

      <Modal
        title={"Upload Documents"}
        className="custom-modal"
        open={uploadDocModal}
        width={1000}
        onOk={handleOk}
        okText="Upload"
        onCancel={handleCancel}
      >
        <div className="custom-form">
          <div className="row gy-2 gx-4">
            <Each
              array={uploadDocs}
              render={(item) => (
                <div className="col-md-4">
                  <label htmlFor={item}>{item}</label>
                  <UploadZ onStoreFile={storeDocuments} fileName={item} />
                </div>
              )}
            />
            <Each
              array={customDocs}
              warning={false}
              render={(item, i) => (
                <div className="col-md-4">
                  <div className="d-flex justify-content-between align-items-center mb-1px">
                    <label
                      contentEditable={true}
                      key={i}
                      id={i}
                      onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                      className="mb-0"
                      suppressContentEditableWarning={true}
                    >
                      {item}
                    </label>
                    <button
                      className="del-upload-btn"
                      onClick={() => removeUpload(i)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </button>
                  </div>
                  <UploadZ onStoreFile={storeDocuments} fileName={item} />
                </div>
              )}
            />
            <div className="col-md-4">
              <label htmlFor="">&nbsp;</label>
              <div className="add-new-upload" onClick={addNewUpload}>
                <span className="icon">
                  <i className="fal fa-plus-circle"></i>
                </span>
                <h6>Add New Upload</h6>
                <p>Click to create new upload field</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={`${docMode} Documents`}
        className="custom-modal"
        open={viewDocModal}
        width={1000}
        onOk={docMode == "Update" ? redirectToUpload : handleOk}
        okText="Send"
        onCancel={handleCancel}
      >
        <div className="custom-form">
          <div className="row gy-2 gx-4">
            <Each
              array={uploadDocs}
              render={(item) => (
                <div className="col-md-4">
                  <label htmlFor={item}>{item}</label>
                  <UploadZ
                    file={sampDocuments}
                    onStoreFile={storeDocuments}
                    fileName={item}
                    downloadOnly={docMode == "View"}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
