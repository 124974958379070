import { useState, useRef } from "react";
import { Checkbox, Select } from "antd";

import { Each, focusOn } from "../../../helper";
import UploadZ from "../../../components/UploadZ";
import FormZ from "../../../components/FormZ";

export default function CorporateKYC() {
  const formRef = useRef();

  const [documents, setDocuments] = useState([]);
  const [individuals, setIndividuals] = useState([1]);
  const [docList, setDocList] = useState([
    "Incorporation Certificate",
    "M&AA",
    "Share Registry",
    "ROD",
    "Seal",
  ]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    //   console.log(doc);
    setDocuments(doc);
  }

  const addNewIndividual = () => {
    let arr = [...individuals];
    arr.push(individuals.length + 1);
    setIndividuals(arr);
  };

  const [cKyc, setCKyc] = useState([1]);

  const addNewCol = ({ focus }) => {
    let arr = [...cKyc];
    arr.push(arr.length + 1);
    setCKyc(arr);
    if (focus) {
      focusOn("item" + (arr.length - 1));
    }
  };

  const removeNewCol = (index) => {
    let arr = [...cKyc];
    arr.splice(index, 1);
    setCKyc(arr);
  };

  const directors = [
    "Consent to Act",
    "Resolution of Appointment",
    "Resolution of Resignation",
    "Letter of Resignation",
  ];

  const shareholders = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
  ];

  const ubos = ["Resolution of Shareholding", "Source of Wealth/Founds"];

  const poc = ["Contact Details"];

  const nominee = ["Nominee Agreement"];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i] === values[index]) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const addNewChange = (values, checkStatus, name) => {
    let arr = [...docList];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setDocList(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    let arr = [...docList];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(nominee, arr);
        setShareholderChecked(true);
        setNomineeChecked(false);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }
    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        removeArrItem(shareholders, arr);
        setNomineeChecked(true);
        setShareholderChecked(false);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }

    setDocList(arr);
  };

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }

  const handleChange = (value) => {
    setSanction(value);
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  let formSchema = [
    {
      name: "masterfileCode",
      value: "",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      customClass: "col-md-2",
    },
    {
      name: "salutation",
      value: "",
      type: "select",
      label: "Salutation",
      placeholder: "Select salutation",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "fullName",
      value: "",
      type: "text",
      label: "Full Name",
      placeholder: "Enter full name",
      customClass: "col-md-2",
    },
    {
      name: "riskLevel",
      value: "low",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input type="text" className="w-100" placeholder="Enter pep" />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input type="text" className="w-100" placeholder="Enter sanction" />
          )}
        </div>
      ),
    },
  ];

  const [corporateCustom, setCorporateCustom] = useState([]);

  const addNewIndividualDoc = () => {
    let arr = [...corporateCustom];
    let index = arr.length + 1;
    arr.push(`Enter Document Name ${index}`);
    setCorporateCustom(arr);
  };

  const removeIndividualDoc = (index) => {
    let arr = [...corporateCustom];
    arr.splice(index, 1);
    setCorporateCustom(arr);
  };

  const getLabelName = (value, index) => {
    let arr = [...corporateCustom];
    arr[index] = value;
    setCorporateCustom(arr);
  };

  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-4 mb-4">
        <h2 className="primary-title mb-3 mb-md-0">Corporate Entity</h2>
        <button
          className="primary-btn"
          onClick={() => addNewCol({ focus: true })}
        >
          <i className="fal fa-plus-circle"></i> Add New
        </button>
        <Checkbox
          className="custom-checkbox"
          checked={directorChecked && !reserveChecked}
          onChange={(e) => addNewRow(directors, e.target.checked, "directors")}
        >
          Directors
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          checked={shareholderChecked && !nomineeChecked}
          onChange={(e) =>
            addNewRow(shareholders, e.target.checked, "shareholders")
          }
        >
          Shareholders
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) => addNewChange(ubos, e.target.checked)}
        >
          UBOs
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          onChange={(e) => addNewChange(poc, e.target.checked)}
        >
          POC
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          checked={nomineeChecked && !shareholderChecked}
          onChange={(e) => addNewRow(nominee, e.target.checked, "nominee")}
        >
          Nominee Shareholder
        </Checkbox>
        <Checkbox
          className="custom-checkbox"
          checked={reserveChecked && !directorChecked}
          onChange={(e) => addNewRow(directors, e.target.checked, "reserve")}
        >
          Reserve Director
        </Checkbox>
      </div>
      <Each
        array={cKyc}
        render={(item, i) => (
          <div className="custom-form my-4" id={`item${i}`}>
            <div className="d-flex gap-3 mb-3 align-items-center">
              <h3 className="sub-title">
                Corporate Entity {i + 1} &nbsp;
                <i className="fal fa-question-circle"></i>
              </h3>
              {i !== 0 && (
                <button
                  className="outline-btn red-btn"
                  onClick={() => removeNewCol(i)}
                >
                  <i className="fal fa-trash-alt"></i> Remove
                </button>
              )}
            </div>
            <FormZ
              ref={formRef}
              formSchema={formSchema}
              formClass="row mt-3 mb-4"
              childClass="col-md-1 mb-0"
              labelClass="mb-1"
              inputClass="w-100"
            />
            <div className="row gy-2 gx-4">
              <Each
                array={docList}
                render={(item) => (
                  <div className="col-md-3">
                    <label htmlFor={item}>{item}</label>
                    <UploadZ onStoreFile={storeDocuments} fileName={item} />
                  </div>
                )}
              />
              {corporateCustom.length > 0 && (
                <Each
                  array={corporateCustom}
                  render={(item, i) => (
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between align-items-center mb-1px">
                        <label
                          contentEditable={true}
                          key={i}
                          id={i}
                          onBlur={(e) => getLabelName(e.target.innerHTML, i)}
                          className="mb-0"
                          suppressContentEditableWarning={true}
                        >
                          {item}
                        </label>
                        <button
                          className="del-upload-btn"
                          onClick={() => removeIndividualDoc(i)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                      <UploadZ onStoreFile={storeDocuments} fileName={item} />
                    </div>
                  )}
                />
              )}
              <div className="col-md-3 mb-4">
                <label htmlFor="">&nbsp;</label>
                <div className="add-new-upload" onClick={addNewIndividualDoc}>
                  <span className="icon">
                    <i className="fal fa-plus-circle"></i>
                  </span>
                  <h6>Add New Upload</h6>
                  <p>Click to create new upload field</p>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}
