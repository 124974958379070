import React, { useState } from "react";
import { getEmailData } from "../helper/emailTemplateHelpers";
import { useGeneralEmail } from "../hooks/useGeneralEmail";
import { Link } from "react-router-dom";
import { sendNotify } from "../helper";

const ServiceRequestEmaiSendButtons = ({
  serviceRequestName,
  serviceRequestData,
  businessPartnerDetails,
}) => {
  const { isSendingEmail, handleSendEmail } = useGeneralEmail();
  const [isRAEmailSent, setIsRAEmailSent] = useState(false);


  const handleEmail = (type) => {
    try {
      const emailData = getEmailData(
        serviceRequestName,
        type,
        serviceRequestData,
        businessPartnerDetails
      );
      console.log("Email Data:", emailData);
      handleSendEmail(emailData);

      if (type === "RA") {
        setIsRAEmailSent(true);
      }

    } catch {
      console.log("error occurred while getting email parameters");
      sendNotify("error", "Email sending failed due to invalid data")
    }
  };

  return (
    <>
      <button className="outline-btn" onClick={() => handleEmail("RA")} disabled={isSendingEmail} >
        Send to RA
      </button>
      <Link className="outline-btn" to={"/app/mail"}>
        Check Mailbox
      </Link>
      <button className="outline-btn" onClick={() => handleEmail("client")} disabled={isSendingEmail || !isRAEmailSent} >
        Send to Client
      </button>
    </>
  );
};

export default ServiceRequestEmaiSendButtons;
