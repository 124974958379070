import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Table, Tag, Select, Modal, DatePicker, Checkbox } from "antd";
import dayjs from "dayjs";
import FormZ from "../../components/FormZ";
import TableSortArrows from "../../components/tableSortArrows";

import {
  Each,
  getOptions,
  downloadAsCSV,
  fetchApi,
  sendNotify,
  isArrayEmpty,
  customPagination,
  convertQueryParams,
} from "../../helper";
import { Link } from "react-router-dom";
import { getFeeCategory } from "../../store/feeSchedule/feeScheduleSlice";
import TableFilterZ from "../../components/TableFilterZ";
import TabZ from "../../components/TabZ";
import InProgressIcon from "../../assets/img/in-progress-icon.svg";
import ApprovedIcon from "../../assets/img/approved-icon.svg";

export default function FeeSchedule() {
  const [editMode, setEditMode] = useState(false);
  const [clientArray, setClientArray] = useState([]);
  const feeCategory = useSelector((state) => state.feeCategory);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const searchFormRef = useRef();
  const [totalCount, setTotalCount] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedCatIds, setSelectedCatIds] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeeCategory(queryParams));
  }, []);

  useEffect(() => {
    getClient();
  }, [feeCategory]);

  const getClient = async () => {
    const fetchData = async (url) => {
      const payload = { method: "GET", url };
      return fetchApi(payload).then((res) => res.data.rows);
    };

    const params = convertQueryParams({
      isAll: true,
      alltype: ["client", "introducerClient", "end-user"],
      options: true,
    });
    const clientlist = await fetchData(`/client?${params}`);

    setClientArray(clientlist);
  };

  const handleChange = async (value) => {
    let params = { ...queryParams };
    let { paymentCategory, feeSchedule, endUserClient } = value;

    if (endUserClient) params.clientId = endUserClient;
    if (feeSchedule) params.name = feeSchedule;
    if (paymentCategory) params.type = paymentCategory;

    dispatch(getFeeCategory(params));
  };

  let searchFormSchema = [
    {
      title: "End User Client/Introducer Client",
      dataIndex: "clientId",

      filterType: "select",
      filterOptions: clientArray,
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      filterType: "text",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   name: "entityName",
    //   value: "",
    //   type: "text",
    //   label: "Client/Entity Name",
    //   placeholder: "Select entity name",
    // },
    // {
    //   name: "feeSchedule",
    //   value: "",
    //   type: "text",
    //   label: "Fee Schedule Name",
    //   placeholder: "Fee Schedule Name",
    // },

    // Payment Category

    // {
    //   name: "entityCode",
    //   value: "",
    //   type: "text",
    //   label: "Client/Entity Code",
    //   placeholder: "Select entity code",
    //   //   options: [
    //   //     {
    //   //       value: "00444",
    //   //       label: "00444",
    //   //     },
    //   //     {
    //   //       value: "00437",
    //   //       label: "00437",
    //   //     },
    //   //   ],
    // },

    // {
    //   name: "paymentCategory",
    //   value: "",
    //   type: "select",
    //   label: "Payment Category",
    //   placeholder: "Select payment category",
    //   options: [
    //     {
    //       value: "Pre-Paid",
    //       label: "Pre-paid",
    //     },
    //     {
    //       value: "Post-Paid",
    //       label: "Post-paid",
    //     },
    //   ],
    // },

    // {
    //   label: "",
    //   customElement: (
    //     <button className="primary-btn mb-15">
    //       <i className="fal fa-search"></i> Initiate Search
    //     </button>
    //   ),
    // },
  ];

  const [shares, setShares] = useState([
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
    {
      standardUpTo: 150.0,
      standardAbove: 150.0,
      discountedUpTo: 140.0,
      discountedAbove: 140.0,
    },
  ]);

  const getShareChange = (index, key, value) => {
    // console.log(key);
    let arr = [...shares];
    arr[index][key] = value;
    setShares(arr);
  };

  const columns = [
    {
      title: "Request Type",
      dataIndex: "reqType",
      sorter: (a, b) => a.reqType - b.reqType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task - b.task,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Standard Fee",
      children: [
        {
          title: <div className="text-center">Up to 50,000 Shares</div>,
          dataIndex: "standardUpTo",
          key: "standardUpTo",
          sorter: (a, b) => a.standardUpTo - b.standardUpTo,
          sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
          render: (_, { id, standardUpTo }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].standardUpTo}
                onChange={(e) =>
                  getShareChange(id, "standardUpTo", e.target.value)
                }
              />
            ) : (
              `$ ${standardUpTo}`
            ),
        },
        {
          title: <div className="text-center">Above 50,000 Shares</div>,
          dataIndex: "standardAbove",
          key: "standardAbove",
          sorter: (a, b) => a.standardAbove - b.standardAbove,
          sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
          render: (_, { id, standardAbove }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].standardAbove}
                onChange={(e) =>
                  getShareChange(id, "standardAbove", e.target.value)
                }
              />
            ) : (
              `$ ${standardAbove}`
            ),
        },
      ],
    },
    {
      title: "Discounted Fee",
      children: [
        {
          title: <div className="text-center">Up to 50,000 Shares</div>,
          dataIndex: "discountedUpTo",
          key: "discountedUpTo",
          sorter: (a, b) => a.discountedUpTo - b.discountedUpTo,
          sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
          render: (_, { id, discountedUpTo }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].discountedUpTo}
                onChange={(e) =>
                  getShareChange(id, "discountedUpTo", e.target.value)
                }
              />
            ) : (
              `$ ${discountedUpTo}`
            ),
        },
        {
          title: <div className="text-center">Above 50,000 Shares</div>,
          dataIndex: "discountedAbove",
          key: "discountedAbove",
          sorter: (a, b) => a.discountedAbove - b.discountedAbove,
          sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
          render: (_, { id, discountedAbove }) =>
            editMode ? (
              <input
                type="number"
                className="w-75"
                value={shares[id].discountedAbove}
                onChange={(e) =>
                  getShareChange(id, "discountedAbove", e.target.value)
                }
              />
            ) : (
              `$ ${discountedAbove}`
            ),
        },
      ],
    },
  ];

  const data = [
    {
      key: "1",
      id: 0,
      reqType: "Pre-incrop",
      task: "Change Of Director",
      serviceCode: "0028",
      ...shares[0],
    },
    {
      key: "2",
      id: 1,
      reqType: "Post-incrop",
      task: "Name Check",
      serviceCode: "0028",
      ...shares[1],
    },
    {
      key: "3",
      id: 2,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[2],
    },
    {
      key: "4",
      id: 3,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[3],
    },
    {
      key: "5",
      id: 4,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[4],
    },
    {
      key: "6",
      id: 5,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[5],
    },
    {
      key: "7",
      id: 6,
      reqType: "Post-incrop",
      task: "Sample Task",
      serviceCode: "0028",
      ...shares[6],
    },
  ];
  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    dispatch(getFeeCategory(params));
    setQueryParams(params);
  };

  const clientColumns = [
    {
      title: "Code",
      dataIndex: "code",
      filterType: "text",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Fee Category",
      dataIndex: "name",
      filterType: "text",
      sorter: (a, b) => a.name - b.name,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Fee Type",
      dataIndex: "requestType",
      filterType: "select",
      filterOptions: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
      sorter: (a, b) => a.requestType - b.requestType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "SubCategory Type",
      dataIndex: "subCatName",
      sorter: (a, b) => a.subCatName - b.subCatName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Created By",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy - b.createdBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Created Date",
      dataIndex: "createdAt",
      filterType: "date",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Details",
      dataIndex: "detailsview",
      fixed: "right",
      width: 150,

      render: (_, { _id }) => (
        <Link to={`/app/fee-schedule-details?id=${_id}`} className="action-btn">
          <i className="far fa-eye"></i> View
        </Link>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCatIds(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
        "568t54"
      );
    },

    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const updateStatusOnChange = () => {
    if (selectedCatIds.length) {
      let obj = {
        ids: selectedCatIds,
        isDelete: true,
      };
      let payload = {
        method: "PUT",
        url: `/feeCategory/updateStatus/`,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message ? res?.message : "success");
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
      dispatch(getFeeCategory(queryParams));
    } else {
      sendNotify("warning", "Please select atleast one row");
    }
  };
  // console.log(rowSelection, "rowSelection");

  const labels = [
    {
      key: "Active",
      label: (
        <>
          <img src={ApprovedIcon} alt="" /> Active
        </>
      ),
    },
    {
      key: "Inactive",
      label: (
        <>
          <img src={InProgressIcon} alt="" /> Inactive
        </>
      ),
    },
  ];
  const getFilter = (value) => {
    let params = { ...queryParams };
    const filters = encodeURIComponent(JSON.stringify(value));
    params.filters = filters;
    dispatch(getFeeCategory(params));
    setQueryParams(params);
  };
  let filterobj = [...searchFormSchema, ...clientColumns];

  return (
    <div>
      <div className="page-header">
        <h1>Fee Schedule</h1>
        <Link to={"/app/fee-schedule-create"} className="primary-btn">
          <i className="fal fa-plus"></i> Create New
        </Link>
      </div>
      <div className="page-content">
        {/* <FormZ
          formClass="row custom-form mb-2 mb-md-5 align-items-end gy-3"
          childClass="col-md-3 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
          onChange={handleChange}
          formSchema={searchFormSchema}
          ref={searchFormRef}
        /> */}
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
          <h1 className="primary-title mb-0">
            {/* Introducer Client/End User Client */}
            End User Client/Introducer Client
          </h1>
          {/* <div className="d-flex gap-4">
          <h6>Fee Category</h6>
          <h6>Post Paid/Pre Paid</h6>
        </div> */}
          <div className="d-flex gap-3 justify-content-end align-items-center">
            {/* <TabZ
            labels={labels}
            defaultActive={"2"}
            customClass={"table-tabs"}
          /> */}
            <button
              className="primary-btn"
              disabled={!selectedCatIds.length ? true : false}
              onClick={updateStatusOnChange}
            >
              <i className="fas fa-trash"></i> Delete
            </button>
            <TableFilterZ tableCol={filterobj} onChange={getFilter} />
          </div>
        </div>

        <Table
          className="custom-table"
          loading={feeCategory?.isLoading}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={clientColumns}
          rowKey={"_id"}
          scroll={{ x: 1400 }}
          dataSource={feeCategory?.data?.rows}
          pagination={{
            className: "custom-pagination",
            defaultCurrent: 1,
            pageSize: queryParams?._limit,
            total: feeCategory?.data?.totalCount,
            onChange: getPagination,
            itemRender: customPagination,
          }}
        />

        {/* <div className="table-box custom-form">
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          scroll={{
            y: 240,
          }}
          pagination={false}
        />
        <div className="d-flex justify-content-between align-items-center mt-md-4">
          <p>
            Last Changed By: <span>Guru</span>
          </p>
          <p>
            Last Changed Date: <span>09-03-2024</span>
          </p>
          <p>
            Initial Onboarding Date: <span>12-03-2024</span>
          </p>
          <button
            className={editMode ? "primary-btn" : "outline-btn"}
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? "Update" : "Modify"}
          </button>
        </div>
      </div> */}
      </div>
    </div>
  );
}
