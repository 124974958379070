import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover, Modal } from "antd";
import { NavLink } from "react-router-dom";
import axios from "axios";

import Logo from "../../assets/img/logo-icon.png";

import { checkLogged } from "../../store/auth/authSlice";
import { sendNotify } from "../../helper";
import routes from "../../helper/routes";

const { confirm } = Modal;

export default function Sidebar() {
  const dispatch = useDispatch();
  let details = useSelector((state) => state.auth.userDetails);

  const [menuShow, setMenuShow] = useState(null);

  const parentRoutes = [
    {
      id: 0,
      name: "Masterfile Setup & Analysis",
      icon: "fa-file-plus",
    },
    {
      id: 1,
      name: "New Incorporation",
      icon: "fa-newspaper",
    },
    {
      id: 2,
      name: "MLRO",
      icon: "fa-ticket-alt",
    },
    {
      id: 3,
      name: "Post Incorporation",
      icon: "fa-file-import",
    },
    {
      id: 4,
      name: "Finance/Filings",
      icon: "fa-file-invoice-dollar",
    },
    {
      id: 5,
      name: "Access Control",
      icon: "fa-users-cog",
    },
    {
      id: 6,
      name: "Audits & Reporting",
      icon: "fa-chart-pie",
    },
  ];

  // useEffect(() => {}, []);

  const toggleMenu = (index) => {
    console.log(index);
    if (index == menuShow) {
      setMenuShow(null);
    } else {
      setMenuShow(index);
    }
  };

  const confirmLogout = () => {
    confirm({
      title: <h5>Confirm Logout</h5>,
      content: <p>Are you sure you want to log out?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Logout",
      onOk() {
        logout();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const logout = () => {
    sendNotify("success", "Logout successfully!");
    localStorage.clear(process.env.REACT_APP_JWT_TOKEN);
    axios.defaults.headers.common["Authorization"] = null;
    dispatch(checkLogged());
  };

  return (
    <div className={`sidebar`}>
      <div>
        <img src={Logo} className="logo" alt="North Lark" />
        <ul className="sidebar-list">
          <li className="sidebar-parent">
            <Popover placement="left" className="sidebar-popover" title="Home">
              <NavLink to={"/app/home"}>
                <i className="fal fa-home"></i>
              </NavLink>
            </Popover>
          </li>
          {parentRoutes.map((pr, index) => {
            return (
              <li key={pr?.id} className="sidebar-parent">
                <Popover
                  placement="left"
                  className="sidebar-popover"
                  title={pr?.name}
                >
                  <a
                    className="sidebar-menu"
                    onClick={() => toggleMenu(pr?.id)}
                  >
                    <i className={`fal ${pr.icon}`}></i>
                    <i
                      className={`fas fa-chevron-up ${
                        menuShow == pr?.id ? "" : "fa-rotate-180"
                      }`}
                    ></i>
                  </a>
                </Popover>
                {routes
                  .filter((r) => r.showInMenu)
                  .map((route, i) => {
                    if (route.permissions.find((r) => r === details?.role)) {
                      return (
                        <ul
                          className={menuShow == pr?.id ? "show" : ""}
                          key={i}
                        >
                          {(route.parentId == pr?.id ||
                            route.duplicate == pr?.id) && (
                            <li key={route.path}>
                              <Popover
                                placement="left"
                                className="sidebar-popover"
                                title={route.name}
                              >
                                <NavLink to={route.path}>
                                  <i className={`fal ${route.icon}`}></i>
                                </NavLink>
                              </Popover>
                            </li>
                          )}
                        </ul>
                      );
                    }
                  })}
              </li>
            );
          })}
        </ul>
      </div>
      <ul>
        <li>
          <Popover placement="left" className="sidebar-popover" title={"Help"}>
            <NavLink to={"/app/help"}>
              <i className="fal fa-life-ring"></i>
            </NavLink>
          </Popover>
        </li>
        <li>
          <Popover
            placement="left"
            className="sidebar-popover"
            title={"Setting"}
          >
            <NavLink to={"/app/settings"}>
              <i className="fal fa-cog"></i>
            </NavLink>
          </Popover>
        </li>
        <li className="profile">
          <Popover
            placement="right"
            title={""}
            content={
              <div className="profile-wrapper">
                <div>
                  <h6>{details?.userName}</h6>
                  <p>{details?.role}</p>
                </div>
                <button
                  className="nav-btn-secondary ms-md-3"
                  onClick={confirmLogout}
                  title="Logout"
                >
                  <i className="far fa-power-off"></i>
                </button>
              </div>
            }
          >
            <img src="https://www.w3schools.com/w3images/avatar3.png" alt="" />
          </Popover>
        </li>
      </ul>
    </div>
  );
}
