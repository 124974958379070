import { useState } from "react";
import { Select } from "antd";

import { Each } from "../../../helper";
import UploadZ from "../../../components/UploadZ";

export default function Others() {
  const [documents, setDocuments] = useState([]);

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  let arr = [1, 2, 3];
  return (
    <div className="custom-form">
      <div className="mb-4">
        <label htmlFor="" className="me-3 mb-1">
          Role
        </label>
        <Select
          className="custom-select mw-300"
          placeholder="Select role"
          onChange={handleChange}
          options={[
            {
              value: "director",
              label: "Director",
            },
            {
              value: "shareholder",
              label: "Shareholder",
            },
            {
              value: "ubo",
              label: "UBO",
            },
          ]}
        />
      </div>
      <h6 className="primary-title mb-4">Others</h6>
      <div className="row gy-2 gx-4">
        <Each
          array={arr}
          render={(item) => (
            <div className="col-md-3">
              <label htmlFor={item} className="me-3">
                Pack 0{item}
              </label>
              <UploadZ onStoreFile={storeDocuments} fileName={item} />
            </div>
          )}
        />
      </div>
    </div>
  );
}
