const SignatureItem = ({
  signature,
  index,
  deleteSignature,
  registeredAgent,
}) => {
  return (
    <div className="signature-content w-75" key={index}>
      <div className="signature-wrapper signed-img">
        <img src={`data:image/png;base64, ${signature.url}`} alt="Signature" />
      </div>
      <button
        className="sign-delete-btn"
        onClick={() => deleteSignature(index)}
      >
        <i className="far fa-trash-alt"></i>
      </button>
      <hr />
      <p>
        By: <b>{signature.fullName ?? ""}</b>
        <br />
        Designation: <b>{signature.jobTitle ?? ""}</b>
        <br />
        Authorized Signatory: <b>{registeredAgent ?? ""}</b>
        <br /> Registered Agent
      </p>
    </div>
  );
};

export default SignatureItem;
