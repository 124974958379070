import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import UploadZ from "../components/UploadZ";
import RequestResubmission from "../components/RequestResubmission";
import RejectTask from "../components/RejectTask";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";

export default function Other() {
  let query = getQuery();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState({});
  const [explainModel, setExplainModel] = useState(false);
  const [rejectModel, setRejectModel] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        let data = res?.data?.rows?.[0];
        setData(data);
        setDocs(data?.documents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const getRejectDocs = (checked, docId) => {
    let arr = [...docs];
    let index = arr.findIndex((doc) => doc._id === docId);
    if (checked) {
      arr[index]["rejected"] = true;
    } else {
      delete arr[index]["rejected"];
    }
    setDocs(arr);
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Other
          </h1>
        </Link>
        <div className="d-flex gap-3 align-items-center">
          <RequestResubmission id={query?.id} documents={docs} />
          <Link
            to={`/app/entity-overview/list?id=${data?.entityId}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          <RejectTask id={query?.id} reason={data?.rejectReason} />
          <Link className="outline-btn" to={"/app/mail"}>
            Check Mailbox
          </Link>
          <button className="primary-btn">Update Entity Overview</button>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-5">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Service Type(s)</label>
              {/* <div className="d-flex gap-2 flex-wrap">
                <span className="light-badge">Reinstatement</span>
                <span className="light-badge">Document Certifications</span>
              </div> */}
            </div>
          </div>
          <div className="row gy-2 gx-4">
            <Each
              array={data?.documents}
              render={(item, index) => (
                <div className="col-md-3">
                  <div>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        getRejectDocs(e.target.checked, item?._id)
                      }
                    >
                      {item?.document}
                    </Checkbox>
                    <UploadZ file={item} />
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
