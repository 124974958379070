import React, { useState, useRef } from "react";
import { Table, Select, Tag, Input } from "antd";

export default function ServiceInvoice() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div className="custom-form p-3" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className="mb-2 w-100"
        />
        <div className="d-flex gap-2 align-items-center">
          <button
            className="primary-btn w-100 justify-content-center py-2"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          >
            Search
          </button>
          <button
            onClick={() => clearFilters && handleReset(clearFilters)}
            className="outline-btn py-2"
          >
            Reset
          </button>
          {/* <button
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                        className='outline-btn'
                    >
                        Filter
                    </button> */}
          <button
            onClick={() => {
              close();
            }}
            className="outline-btn red-btn py-2"
          >
            Close
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => <i className="far fa-search"></i>,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const serviceColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      ...getColumnSearchProps("entityName"),
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      ...getColumnSearchProps("entityCode"),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Active
        </Tag>
      ),
    },
    {
      title: "Incorp Number",
      dataIndex: "incorpNumber",
      sorter: (a, b) => a.incorpNumber - b.incorpNumber,
    },
    {
      title: "Incorporation Date",
      dataIndex: "incorporationDate",
      sorter: (a, b) => a.incorporationDate - b.incorporationDate,
    },
    {
      title: "Service Req Date",
      dataIndex: "reqDate",
      sorter: (a, b) => a.reqDate - b.reqDate,
    },
    {
      title: "Service Type",
      dataIndex: "type",
      sorter: (a, b) => a.type - b.type,
      render: (_, { type }) => (
        <Select
          className="custom-select"
          placeholder="Select type"
          value={type}
          disabled
          style={{
            width: 160,
          }}
          options={[
            {
              value: "newIncorporation",
              label: "New Incorporation",
            },
            {
              value: "nameCheck",
              label: "Name Check",
            },
            {
              value: "renewalInvoice",
              label: "Renewal Invoice (Annual)",
            },
            {
              value: "penalty",
              label: "Penalty",
            },
            {
              value: "misc",
              label: "Misc",
            },
            {
              value: "filing",
              label: "Filing",
            },
            {
              value: "periodicReviewKYCCheck",
              label: "Periodic Review/KYC Check",
            },
          ]}
        />
      ),
    },
    {
      title: "Service  Code",
      dataIndex: "serviceCode",
      sorter: (a, b) => a.serviceCode - b.serviceCode,
    },
    {
      title: "Value $",
      dataIndex: "value",
      sorter: (a, b) => a.value - b.value,
    },
    {
      title: "Bank reference",
      dataIndex: "bankReference",
      sorter: (a, b) => a.bankReference - b.bankReference,
    },
    {
      title: "Billing Category",
      dataIndex: "billingCategory",
      sorter: (a, b) => a.billingCategory - b.billingCategory,
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a, b) => a.dueDate - b.dueDate,
    },
    {
      title: "Remittance Date",
      dataIndex: "remittanceDate",
      sorter: (a, b) => a.remittanceDate - b.remittanceDate,
    },
    {
      title: "Receipt Status",
      dataIndex: "receiptStatus",
      sorter: (a, b) => a.receiptStatus - b.receiptStatus,
      render: (_, { receiptStatus }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={receiptStatus ? "green" : "warning"}
        >
          {receiptStatus ? "Settled" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "Service Status",
      dataIndex: "serviceStatus",
      sorter: (a, b) => a.serviceStatus - b.serviceStatus,
      render: (_, { serviceStatus }) => (
        <Tag
          icon={<i className="fad fa-dot-circle me-1"></i>}
          color={serviceStatus ? "green" : "warning"}
        >
          {serviceStatus ? "Completed" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "Service Closed Date",
      dataIndex: "serviceClosedDate",
      sorter: (a, b) => a.serviceClosedDate - b.serviceClosedDate,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, { id }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Update</button>
        </div>
      ),
    },
  ];

  const serviceData = [
    {
      key: "1",
      id: "1",
      entityName: "Trust",
      entityCode: "9023",
      reqDate: "12/04/2023 - 05:30PM",
      type: "newIncorporation",
      serviceCode: "9034",
      incorpNumber: "001",
      incorporationDate: "18/05/2023",
      value: "$ 100.0",
      bankReference: "ICIC",
      billingCategory: "Postpaid",
      invoiceNumber: "90234",
      dueDate: "12/05/2023 - 12:00PM",
      remittanceDate: "18/06/2023 - 05:30PM",
      receiptStatus: true,
      serviceStatus: false,
      serviceClosedDate: "19/07/2023 - 10:00AM",
    },
    {
      key: "2",
      id: "2",
      entityName: "Found",
      entityCode: "9023",
      reqDate: "12/04/2023 - 05:30PM",
      type: "nameCheck",
      serviceCode: "9034",
      incorpNumber: "002",
      incorporationDate: "18/05/2023",
      value: "$ 100.0",
      bankReference: "ICIC",
      billingCategory: "Postpaid",
      invoiceNumber: "90234",
      dueDate: "12/05/2023 - 12:00PM",
      remittanceDate: "18/06/2023 - 05:30PM",
      receiptStatus: true,
      serviceStatus: false,
      serviceClosedDate: "19/07/2023 - 10:00AM",
    },
    {
      key: "3",
      id: "3",
      entityName: "CCD",
      entityCode: "9023",
      reqDate: "12/04/2023 - 05:30PM",
      type: "renewalInvoice",
      serviceCode: "9034",
      incorpNumber: "003",
      incorporationDate: "18/05/2023",
      value: "$ 100.0",
      bankReference: "ICIC",
      billingCategory: "Postpaid",
      invoiceNumber: "90234",
      dueDate: "12/05/2023 - 12:00PM",
      remittanceDate: "18/06/2023 - 05:30PM",
      receiptStatus: true,
      serviceStatus: false,
      serviceClosedDate: "19/07/2023 - 10:00AM",
    },
  ];

  const fundsColumns = [
    {
      title: "Date of Receipt",
      dataIndex: "dateOfReceipt",
      sorter: (a, b) => a.dateOfReceipt - b.dateOfReceipt,
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Bank reference",
      dataIndex: "bankReference",
      sorter: (a, b) => a.bankReference - b.bankReference,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, { id }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Update</button>
        </div>
      ),
    },
  ];

  const fundsData = [
    {
      key: 1,
      dateOfReceipt: "19/07/2023 - 10:00AM",
      amount: "$ 100.00",
      bankReference: "HDFC",
    },
    {
      key: 2,
      dateOfReceipt: "21/07/2023 - 02:00PM",
      amount: "$ 70.00",
      bankReference: "HDFC",
    },
    {
      key: 3,
      dateOfReceipt: "23/07/2023 - 05:00PM",
      amount: "$ 90.00",
      bankReference: "HDFC",
    },
  ];

  const annualColumns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      ...getColumnSearchProps("entityName"),
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      ...getColumnSearchProps("entityCode"),
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      render: (_, { status }) => (
        <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={"green"}>
          Active
        </Tag>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    {
      title: "Client Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: (a, b) => a.clientType - b.clientType,
    },
    {
      title: "Fee Schedule",
      dataIndex: "feeSchedule",
      sorter: (a, b) => a.feeSchedule - b.feeSchedule,
    },
    {
      title: "Service Fee Type",
      dataIndex: "serviceFeeType",
      sorter: (a, b) => a.serviceFeeType - b.serviceFeeType,
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      sorter: (a, b) => a.invoiceType - b.invoiceType,
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      sorter: (a, b) => a.dueDate - b.dueDate,
    },
    {
      title: "Penalty (USD)",
      dataIndex: "penalty",
      sorter: (a, b) => a.penalty - b.penalty,
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      render: (_) => (
        <Select
          className="custom-select"
          placeholder="Select incorp batch"
          options={[
            {
              value: "1",
              label: "First Half",
            },
            {
              value: "2",
              label: "Second Half",
            },
          ]}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (_) => (
        <Select
          className="custom-select"
          placeholder="Select incorp batch"
          options={[
            {
              value: "settled",
              label: "Settled",
            },
            {
              value: "outstanding",
              label: "Outstanding",
            },
          ]}
        />
      ),
    },
    {
      title: "Settlement date",
      dataIndex: "settlementDate",
      sorter: (a, b) => a.settlementDate - b.settlementDate,
    },
    {
      title: "Bank reference",
      dataIndex: "bankReference",
      sorter: (a, b) => a.bankReference - b.bankReference,
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy - b.updatedBy,
    },
    {
      title: "Updated on",
      dataIndex: "updatedOn",
      sorter: (a, b) => a.updatedOn - b.updatedOn,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, { id }) => (
        <div className="d-flex align-items-center gap-2">
          <button className="action-btn">Update</button>
        </div>
      ),
    },
  ];

  const annualData = [
    {
      key: 1,
      entityName: "Trust",
      entityCode: "C9324",
      clientName: "KN",
      clientCode: "812",
      clientType: "End User Client",
      feeSchedule: "Sample",
      serviceFeeType: "OCR",
      invoiceType: "Sample",
      amount: "$ 100.00",
      invoiceNumber: "90234",
      dueDate: "19/07/2023",
      penalty: "$ 15.00",
      incorpDate: "23/04/2023",
      settlementDate: "22/07/2023",
      bankReference: "HDFC",
      updatedBy: "Guru",
      updatedOn: "26/07/2023",
    },
    {
      key: 2,
      entityName: "Trust",
      entityCode: "C9324",
      clientName: "KN",
      clientCode: "812",
      clientType: "Intermediary Client",
      feeSchedule: "Sample",
      serviceFeeType: "OCR",
      invoiceType: "Sample",
      amount: "$ 100.00",
      invoiceNumber: "90234",
      dueDate: "19/07/2023",
      penalty: "$ 15.00",
      incorpDate: "23/04/2023",
      settlementDate: "22/07/2023",
      bankReference: "HDFC",
      updatedBy: "Guru",
      updatedOn: "26/07/2023",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <h1 className="page-title mb-4">Entity Invoicing</h1>
      <div className="d-flex mt-3 justify-content-between flex-column flex-md-row align-items-md-center align-items-start">
        <h2 className="primary-title mb-3 mb-md-0">Service Invoicing</h2>
        <button className="primary-btn">Bulk Export</button>
      </div>
      <Table
        className="custom-table"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={serviceColumns}
        dataSource={serviceData}
        scroll={{ x: 2000 }}
      />
      <div className="d-flex mt-3 justify-content-between flex-column flex-md-row align-items-md-center align-items-start">
        <h2 className="primary-title mb-3 mb-md-0">Un-allocated Funds</h2>
        <button className="primary-btn">Bulk Export</button>
      </div>
      <Table
        className="custom-table"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={fundsColumns}
        dataSource={fundsData}
      />
      <div className="d-flex mt-3 justify-content-between flex-column flex-md-row align-items-md-center align-items-start">
        <h2 className="primary-title mb-3 mb-md-0">Annual Invoicing</h2>
        <button className="primary-btn">Bulk Export</button>
      </div>
      <Table
        className="custom-table"
        rowSelection={{ type: "checkbox", ...rowSelection }}
        columns={annualColumns}
        dataSource={annualData}
        scroll={{ x: 2000 }}
      />
      <button className="primary-btn">Update</button>
    </div>
  );
}
