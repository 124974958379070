import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Select,
  DatePicker,
  Checkbox,
  Switch,
  Modal,
  TreeSelect,
} from "antd";
import { useSelector } from "react-redux";

import FormZ from "../../components/FormZ";
import CorporateEntity from "./components/corporate-entity";

import { sendNotify, getOptions, focusOn, Each } from "../../helper";

import dayjs from "dayjs";

import EntityOverviewList from "./list";

import countries from "../../helper/countries.json";

const { SHOW_PARENT } = TreeSelect;

export default function EntityOverview() {
  const navigate = useNavigate();

  const { data, isLoading } = useSelector((state) => state.country);

  const [reminderCount, setReminderCount] = useState([0]);
  const [bossFilingCount, setBossFilingCount] = useState([0]);

  const [checkedList, setCheckedList] = useState({
    shareholders: false,
    ubo: false,
    nominee: false,
  });
  const [historyShow, setHistoryShow] = useState([]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);

  const [corporateEntityShow, setCorporateEntityShow] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);

  const [uboHistory, setUboHistory] = useState(false);

  const [entityType, setEntityType] = useState("trust");

  const [viewModal, setViewModal] = useState(false);
  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");

  const [countriesOption, setCountriesOption] = useState([]);
  const [value, setValue] = useState([]);

  const [customContact, setCustomContact] = useState([
    {
      id: "CC1",
      data: {
        primaryContactName: "John Wick",
        primaryContactEmail: "john@example.com",
        primaryContactMobile: "+94 77 123 4567",
      },
    },
  ]);

  const [customFinancial, setCustomFinancial] = useState([
    {
      id: "FD1",
      data: {
        title: "Mr",
        financialRecordHolder: "Sample",
        email: "john@example.com",
        mobile: "+94 77 123 4567",
      },
    },
  ]);

  const handleChange = (value) => {
    setSanction(value);
  };

  const handleChangePep = (value) => {
    // console.log(value);
    setPep(value);
  };

  useEffect(() => {
    convertToOptions();
  }, []);

  const convertToOptions = () => {
    let options = [
      {
        title: "Worldwide",
        value: "0",
        key: "0",
        children: [],
      },
    ];

    Object.keys(countries).forEach(function (key, index) {
      let continents = {
        title: key,
        value: `0-${index}`,
        key: `0-${index}`,
        children: [],
      };
      countries[key].forEach((country, i) => {
        let cat = {
          title: country?.name,
          value: `0-${index}-${i}`,
          key: `0-${index}-${i}`,
        };
        continents.children.push(cat);
      });

      options[0]?.children.push(continents);
    });

    // console.log(options);
    setCountriesOption(options);
  };

  const onChange = (newValue) => {
    console.log("onChange ", newValue);
    setValue(newValue);
  };
  const tProps = {
    treeData: countriesOption,
    value,
    onChange,
    className: "custom-select w-100 ptb-1",
    treeCheckable: true,
    showSearch: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Select geography of business",
    allowClear: true,
  };

  const toggleHistory = (index) => {
    let arr = [...historyShow];
    let item = arr.findIndex((hs) => hs === index);
    if (item !== -1) {
      arr.splice(item, 1);
    } else {
      arr.push(index);
    }
    setHistoryShow(arr);
  };

  const [forms, setForms] = useState({
    individual: [1],
    directors: [1],
    shareholders: [1],
    ubo: [1],
    financialRecord: [],
  });

  const columns = [
    {
      title: "Share Certificate Number",
      dataIndex: "shareNumber",
      sorter: (a, b) => a.shareNumber - b.shareNumber,
    },
    {
      title: "Number of Shares",
      dataIndex: "noShares",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Share Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Issued Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "issuedDate",
      sorter: (a, b) => a.issuedDate - b.issuedDate,
    },
    {
      title: "Total No. of Shares held",
      dataIndex: "sharesHeld",
      sorter: (a, b) => a.sharesHeld - b.sharesHeld,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          Transfer Date&nbsp;<i className="fal fa-question-circle"></i>
        </div>
      ),
      dataIndex: "transferDate",
      sorter: (a, b) => a.transferDate - b.transferDate,
    },
    {
      title: "Transfer Amount",
      dataIndex: "transferAmount",
      sorter: (a, b) => a.transferAmount - b.transferAmount,
    },
    {
      title: "Par value",
      dataIndex: "parValue",
      sorter: (a, b) => a.parValue - b.parValue,
    },
    {
      title: "Date Ceased to be a Member",
      dataIndex: "dateCeased",
      sorter: (a, b) => a.dateCeased - b.dateCeased,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: (a, b) => a.comments - b.comments,
    },
    {
      title: "",
      render: (_, { id }) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button className="action-btn delete-btn">
            <i className="far fa-trash-alt"></i>
          </button>
          <button className="action-btn">
            <i className="far fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];
  const tableData = [
    {
      key: "1",
      id: "1",
      shareNumber: 55,
      noShares: 100,
      shareType: "Type 1",
      issuedDate: "11-12-2023",
      sharesHeld: "02",
      transferDate: "12-12-2023",
      transferAmount: 500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "2",
      id: "2",
      shareNumber: 56,
      noShares: 500,
      shareType: "Type 2",
      issuedDate: "13-12-2023",
      sharesHeld: "05",
      transferDate: "14-12-2023",
      transferAmount: 800,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "3",
      id: "3",
      shareNumber: 57,
      noShares: 250,
      shareType: "Type 1",
      issuedDate: "15-12-2023",
      sharesHeld: "05",
      transferDate: "17-12-2023",
      transferAmount: 400,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
    {
      key: "4",
      id: "4",
      shareNumber: 58,
      noShares: 120,
      shareType: "Type 2",
      issuedDate: "18-12-2023",
      sharesHeld: "05",
      transferDate: "19-12-2023",
      transferAmount: 1500,
      parValue: 100,
      dateCeased: "Sample",
      comments: "Sample",
    },
  ];

  const getReminderCount = (value) => {
    let arr = [];

    for (var i = 0; i < value; i++) {
      arr.push(i);
    }
    setReminderCount(arr);
  };

  const getBossFilingCount = (value) => {
    let arr = [];

    for (var i = 0; i < value; i++) {
      arr.push(i);
    }
    setBossFilingCount(arr);
  };

  const addNewCol = (key, { focus }) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.push(arr.length + 1);
    obj[key] = arr;
    setForms(obj);
    console.log(focus);
    if (focus) {
      focusOn(key + (arr.length - 1));
    }
  };

  const removeNewCol = (key, index) => {
    let obj = { ...forms };
    let arr = [...obj[key]];
    arr.splice(index, 1);
    obj[key] = arr;
    setForms(obj);
  };

  const entityFormRef = useRef();
  const primaryContactFormRef = useRef();
  const customContactFormRef = useRef([]);
  const customFinancialFormRef = useRef([]);
  const locationRegistersFormRef = useRef();
  const locationFinancialFormRef = useRef();
  const contactFormRef = useRef();
  const cddInformationFormRef = useRef();
  const individualFormRef = useRef([]);

  let entityFormSchema = [
    {
      name: "entityName",
      value: "Sample Entity",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
    },
    {
      name: "entityType",
      value: "trust",
      type: "select",
      label: "Entity Type",
      placeholder: "Select entity type",
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "entityCode",
      value: "E001",
      type: "text",
      label: "Entity Code",
      placeholder: "Enter entity code",
    },
    {
      name: "entityStatus",
      value: "active",
      type: "select",
      label: "Entity Status",
      placeholder: "Select entity status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
        {
          value: "resigned",
          label: "Resigned",
        },
        {
          value: "continuationOut",
          label: "Continuation Out",
        },
        {
          value: "continuationIn",
          label: "Continuation In",
        },
      ],
    },
    {
      name: "feeScheduleName",
      value: "Sample",
      type: "text",
      label: "Fee Schedule Name",
      placeholder: "Enter fee schedule name",
    },
    {
      name: "feeScheduleSubType",
      value: ["s1", "d1"],
      type: "select",
      label: "Fee Schedule Sub Type",
      placeholder: "Enter fee schedule sub type",
      options: [
        {
          value: "s1",
          label: "S1",
        },
        {
          value: "s2",
          label: "S2",
        },
        {
          value: "d1",
          label: "D1",
        },
        {
          value: "d2",
          label: "D2",
        },
      ],
      mode: "multiple",
      inputClass: "ptb-1",
      allowClear: true,
    },
    {
      name: "paymentCategory",
      value: "prePaid",
      type: "select",
      label: "Payment Category",
      placeholder: "Enter payment category",
      options: [
        {
          value: "prePaid",
          label: "Pre-paid",
        },
        {
          value: "postPaid",
          label: "Post-paid",
        },
      ],
    },
    {
      name: "clientName",
      value: "John Doe",
      type: "text",
      label: "Client Name",
      placeholder: "Enter client name",
    },
    {
      name: "clientCode",
      value: "C040",
      type: "text",
      label: "Client Code",
      placeholder: "Enter client code",
    },
    {
      name: "clientType",
      value: "Trust",
      type: "select",
      label: "Client Type",
      placeholder: "Enter client type",
      options: [
        {
          value: "IntermediaryClient",
          label: "Intermediary Client",
        },
        {
          value: "endUserClient",
          label: "End User Client",
        },
      ],
    },
    {
      name: "clientStatus",
      value: "active",
      type: "select",
      label: "Client Status",
      placeholder: "Select client status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
    },
    {
      name: "parentClientCode",
      value: "John Wick",
      type: "text",
      label: "Parent Client Code",
      placeholder: "Enter parent client code",
    },
    {
      name: "parentClientName",
      value: "PC020",
      type: "text",
      label: "Parent Client Name",
      placeholder: "Enter parent client name",
    },
    {
      name: "parentClientStatus",
      value: "active",
      type: "select",
      label: "Parent Client Status",
      placeholder: "Select parent client status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
    },
    {
      name: "incorporationNumber",
      value: "04638274",
      type: "text",
      label: "Incorporation Number",
      placeholder: "Enter incorporation number",
    },
    {
      name: "jurisdiction",
      value: "BVI",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(data),
      loading: isLoading,
      disabled: true,
    },
    {
      name: "incorporationDate",
      value: dayjs("22/12/2023", "DD/MM/YYYY"),
      type: "date",
      label: "Incorporation Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "entityGrouping",
      value: "Random",
      type: "text",
      label: "Entity Grouping",
      placeholder: "Enter entity grouping",
    },
    {
      name: "relatedEntities",
      value: "E002, E003",
      type: "text",
      label: "Related Entities",
      placeholder: "Enter related entities",
    },
    // {
    //   name: "compositeRiskLevel",
    //   value: "high",
    //   type: "select",
    //   label: "Composite Risk Level",
    //   placeholder: "Select composite risk level",
    //   options: [
    //     {
    //       value: "low",
    //       label: "Low",
    //     },
    //     {
    //       value: "medium",
    //       label: "Medium",
    //     },
    //     {
    //       value: "high",
    //       label: "High",
    //     },
    //   ],
    // },
    // {
    //   name: "corporateEntity",
    //   value: "yes",
    //   type: "select",
    //   label: "Corporate Entity",
    //   placeholder: "Select corporate entity",
    //   options: [
    //     {
    //       value: "yes",
    //       label: "Yes",
    //     },
    //     {
    //       value: "no",
    //       label: "No",
    //     },
    //   ],
    // },
  ];

  let customContactFormSchema = [
    {
      name: "primaryContactName",
      value: "",
      type: "text",
      label: "Primary Contact Name",
      placeholder: "Enter primary contact name",
    },
    {
      name: "primaryContactEmail",
      value: "",
      type: "email",
      label: "Primary Contact Email",
      placeholder: "Enter primary contact email",
    },
    {
      name: "primaryContactMobile",
      value: "",
      type: "text",
      label: "Primary Contact Mobile",
      placeholder: "Enter primary contact mobile",
    },
  ];

  let locationRegistersFormSchema = [
    {
      name: "addressLine1",
      value: "No. 32",
      type: "textarea",
      label: "Address Line 01",
      placeholder: "Enter address here...",
    },
    {
      name: "addressLine2",
      value: "5th cross street",
      type: "textarea",
      label: "Address Line 02",
      placeholder: "Enter address here...",
    },
    {
      name: "addressLine3",
      value: "Colombo 02, Sri Lanka.",
      type: "textarea",
      label: "Address Line 03",
      placeholder: "Enter address here...",
    },
  ];

  let locationFinancialFormSchema = [
    {
      name: "addressLine1",
      value: "No. 32",
      type: "textarea",
      label: "Address Line 01",
      placeholder: "Enter address here...",
    },
    {
      name: "addressLine2",
      value: "5th cross street",
      type: "textarea",
      label: "Address Line 02",
      placeholder: "Enter address here...",
    },
    {
      name: "addressLine3",
      value: "Colombo 02, Sri Lanka.",
      type: "textarea",
      label: "Address Line 03",
      placeholder: "Enter address here...",
    },
  ];

  let contactFormSchema = [
    {
      name: "title",
      value: "",
      type: "select",
      label: "Title",
      placeholder: "Select title",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
      customClass: "col-md-2",
    },
    {
      name: "financialRecordHolder",
      value: "",
      type: "text",
      label: "Name of Financial Record Holder",
      placeholder: "Enter name",
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email",
    },
    {
      name: "mobile",
      value: "",
      type: "text",
      label: "Mobile",
      placeholder: "Enter mobile",
    },
    // {
    //   customElement: (
    //     <button
    //       className="primary-btn mb-15 justify-content-center w-70"
    //       onClick={() => addNewCol("financialRecord", { focus: false })}
    //     >
    //       <i className="fal fa-plus-circle"></i> Add New
    //     </button>
    //   ),
    // },
  ];

  let cddInformationFormSchema = [
    {
      name: "sourceWealth",
      value: "Sample text",
      type: "textarea",
      label: "Source of Wealth",
      placeholder: "Enter here...",
      customClass: "col-md-10",
    },
    {
      name: "sourceFunds",
      value: "Sample text",
      type: "textarea",
      label: "Source of Funds",
      placeholder: "Enter here...",
      customClass: "col-md-10",
    },
    {
      name: "purposeBusiness",
      value: "Sample text",
      type: "textarea",
      label: "Purpose of Business",
      placeholder: "Enter here...",
      customClass: "col-md-10",
    },
    {
      label: "Geography of Business",
      customElement: <TreeSelect {...tProps} placement="topLeft" />,
    },
    {
      name: "businessActivity",
      value: "",
      type: "select",
      label: "Business Activity",
      placeholder: "Select",
      options: [
        {
          value: "manufacturing",
          label: "Manufacturing",
        },
        {
          value: "retail",
          label: "Retail",
        },
        {
          value: "wholesale",
          label: "Wholesale",
        },
        {
          value: "services",
          label: "Services",
        },
        {
          value: "agriculture",
          label: "Agriculture",
        },
        {
          value: "realEstate",
          label: "Real Estate",
        },
        {
          value: "construction",
          label: "Construction",
        },
        {
          value: "transportation",
          label: "Transportation",
        },
        {
          value: "healthcare",
          label: "Healthcare",
        },
        {
          value: "education",
          label: "Education",
        },
        {
          value: "financialServices",
          label: "Financial Services",
        },
        {
          value: "informationTechnology",
          label: "Information Technology",
        },
        {
          value: "entertainment",
          label: "Entertainment",
        },
        {
          value: "hospitality",
          label: "Hospitality",
        },
        {
          value: "utilities",
          label: "Utilities",
        },
        {
          value: "miningAndDrilling",
          label: "Mining and Drilling",
        },
        {
          value: "rAndD",
          label: "R&D",
        },
        {
          value: "nonProfit",
          label: "Non-Profit",
        },
        {
          value: "eCommerce",
          label: "E-commerce",
        },
        {
          value: "freelancing",
          label: "Freelancing",
        },
        {
          value: "gambling",
          label: "Gambling",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
    },
    {
      name: "descriptionBusiness",
      value: "",
      type: "text",
      label: "Description of Business",
      placeholder: "Enter description of your business",
    },
    // {
    //   name: "industry",
    //   value: "",
    //   type: "select",
    //   label: "Industry",
    //   placeholder: "Select industry",
    //   options: [],
    // },
    // {
    //   name: "businessRisk",
    //   value: "",
    //   type: "select",
    //   label: "Business Risk",
    //   placeholder: "Select risk level",
    //   options: [
    //     {
    //       value: "low",
    //       label: "Low",
    //     },
    //     {
    //       value: "medium",
    //       label: "Medium",
    //     },
    //     {
    //       value: "high",
    //       label: "High",
    //     },
    //   ],
    // },
    {
      name: "",
      value: "",
      type: "",
      label: "",
      customElement: <></>,
    },
    {
      name: "entityLastReviewedDate",
      value: "",
      type: "date",
      label: "Entity Last Reviewed Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "entityNextReviewedDate",
      value: "",
      type: "date",
      label: "Entity Next Reviewed Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "overallEntityRiskLevel",
      value: "",
      type: "select",
      label: "Overall Entity Risk Level",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
  ];

  const [individualFormSchema, setIndividualFormSchema] = useState([
    {
      name: "masterfileCode",
      value: "D002",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
    },
    {
      name: "salutation",
      value: "mr",
      type: "select",
      label: "Salutation",
      placeholder: "Enter salutation",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "firstName",
      value: "Jacob",
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
    },
    {
      name: "middleName",
      value: "Smith",
      type: "text",
      label: "Middle Name",
      placeholder: "Enter Middle name",
    },
    {
      name: "lastName",
      value: "Jackson",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
    },
    {
      name: "dateOfBirth",
      value: dayjs("23-08-1986", "DD/MM/YYYY"),
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "countryOfBirth",
      value: "IND",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "nationality",
      value: "LKA",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "taxInfoNumber",
      value: "04563218",
      type: "text",
      label: "Tax Info Number",
      placeholder: "Enter tax info number",
    },
    {
      name: "passportNumber",
      value: "021545879654",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
    },
    {
      name: "expiryDate",
      value: dayjs("12-12-2028", "DD/MM/YYYY"),
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "riskLevel",
      value: "low",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      // name: "pep",
      // value: "Y",
      // type: "select",
      // label: "PEP",
      // placeholder: "Enter pep",
      // options: [
      //   {
      //     value: "Y",
      //     label: "Yes",
      //   },
      //   {
      //     value: "N",
      //     label: "No",
      //   },
      // ],
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input type="text" className="w-100" placeholder="Enter pep" />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input type="text" className="w-100" placeholder="Enter sanction" />
          )}
        </div>
      ),
    },
    {
      name: "contactDetails",
      value: "9023423423",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
    },
    {
      name: "email",
      value: "info@gmail.com",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
    },
  ]);

  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: dayjs("04-12-2022", "DD/MM/YYYY"),
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "dateResignation",
      value: dayjs("16-08-2023", "DD/MM/YYYY"),
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: 2,
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
  ];

  let uboFormSchema = [
    {
      name: "ownershipPercentage",
      value: "46%",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
    },
    {
      name: "totalSharesHeld",
      value: 10,
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
  ];

  let pocFormSchema = [
    {
      name: "contactDetails",
      value: "9023423423",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
    },
    {
      name: "email",
      value: "info@gmail.com",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeSharesHeld",
      value: 2,
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "nomineeAgreementDate",
      value: dayjs("16-08-2023", "DD/MM/YYYY"),
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
  ];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }

  function updateChecked(key, checked) {
    let obj = { ...checkedList };
    if (key == "nominee" && checked) {
      obj["shareholders"] = false;
    }
    if (key == "shareholders" && checked) {
      obj["nominee"] = false;
    }
    obj[key] = checked;
    console.log(obj);
    setCheckedList(obj);
  }

  const addNewChange = (values, checkStatus, name) => {
    updateChecked("ubo", checkStatus);
    let arr = [...individualFormSchema];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setIndividualFormSchema(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    let arr = [...individualFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualFormSchema];

    updateChecked(name, checkStatus);

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        removeArrItem(shareholderFormSchema, arr);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const onUpdateClick = () => {
    setViewModal(true);
  };

  const handleOk = () => {
    setViewModal(false);
    navigate("/app/entity-overview/list");
  };

  const handleCancel = () => {
    setViewModal(false);
  };

  const addNewContact = () => {
    let arr = [...customContact];
    arr.push({ id: `CC${arr.length + 1}` });
    setCustomContact(arr);
  };

  const deleteCustomContact = (item) => {
    let arr = [...customContact];
    let index = arr.findIndex((cc) => cc.id == item);
    arr.splice(index, 1);
    setCustomContact(arr);
  };

  function getFormOnChange(value, item) {
    let arr = [...customContact];
    let index = arr.findIndex((cc) => cc.id == item);
    arr[index] = {
      id: item,
      data: value,
    };
    console.log(arr);
    setCustomContact(arr);
  }

  const addNewFinancial = () => {
    let arr = [...customFinancial];
    arr.push({ id: `FD${arr.length + 1}` });
    setCustomFinancial(arr);
  };

  const deleteCustomFinancial = (item) => {
    let arr = [...customFinancial];
    let index = arr.findIndex((cc) => cc.id == item);
    arr.splice(index, 1);
    setCustomFinancial(arr);
  };

  function getFinancialOnChange(value, item) {
    let arr = [...customFinancial];
    let index = arr.findIndex((cc) => cc.id == item);
    arr[index] = {
      id: item,
      data: value,
    };
    console.log(arr);
    setCustomFinancial(arr);
  }

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
        <h1 className="page-title mb-3 mb-md-0">Entity Overview</h1>
        {/* <Link to={"/app/entity-overview/list"} className="primary-btn">
          Update
        </Link> */}
      </div>
      <div className="row">
        <div className="col-lg-9">
          <h4 className="primary-title mb-4">Entity</h4>
          <FormZ
            ref={entityFormRef}
            formSchema={entityFormSchema}
            formClass="row custom-form mb-4 mb-md-5 gy-3 gx-4"
            childClass="col-md-4 mb-0"
            labelClass="mb-1"
            inputClass="w-100"
          />
          <div className="mb-4">
            <h4 className="primary-title">Primary Contact</h4>
            {/* <div className="row align-items-end my-3 gap-ms-2">
              <div className="col-md-9">
                <FormZ
                  ref={primaryContactFormRef}
                  formSchema={primaryContactFormSchema}
                  formClass="row custom-form gy-3"
                  childClass="col-md-4 mb-0"
                  labelClass="mb-1"
                  inputClass="w-100"
                />
              </div>
              <div className="col-md-3">
                <div className="d-flex gap-3">
                  <button className="primary-btn mb-15">Update</button>
                  <button className="outline-btn red-btn">Delete</button>
                </div>
              </div>
            </div> */}
            <Each
              array={customContact}
              render={(item, index) => (
                <div className="row align-items-end my-3 gap-ms-2" key={index}>
                  <div className="col-md-9">
                    <FormZ
                      ref={(el) => (customContactFormRef.current[index] = el)}
                      formSchema={customContactFormSchema}
                      formData={customContact[index]?.data}
                      formClass="row custom-form gy-3"
                      onChange={(value) => getFormOnChange(value, item?.id)}
                      childClass="col-md-4 mb-0"
                      labelClass="mb-1"
                      inputClass="w-100"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex gap-3">
                      <button className="primary-btn mb-15">Update</button>
                      <button
                        className="outline-btn red-btn"
                        onClick={() => deleteCustomContact(item?.id)}
                        disabled={index == 0 && customContact.length == 1}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            />
            <button className="primary-btn" onClick={addNewContact}>
              <i className="fal fa-plus"></i> Add New Contact
            </button>
          </div>
          <h4 className="primary-title mb-4">Location of Registers</h4>
          <FormZ
            ref={locationRegistersFormRef}
            formSchema={locationRegistersFormSchema}
            formClass="custom-form mb-5"
            labelClass="mb-1"
            inputClass="w-100"
          />
          <h4 className="primary-title mb-4">Location of Financial Records</h4>
          <FormZ
            ref={locationFinancialFormRef}
            formSchema={locationFinancialFormSchema}
            formClass="custom-form"
            labelClass="mb-1"
            inputClass="w-100"
          />
        </div>
        <div className="mb-3">
          <Each
            array={customFinancial}
            render={(item, index) => (
              <div className="row mt-3 align-items-center gap-ms-2" key={index}>
                <div className="col-md-9">
                  <FormZ
                    ref={(el) => (customFinancialFormRef.current[index] = el)}
                    formSchema={contactFormSchema}
                    formData={customFinancial[index]?.data}
                    formClass="row custom-form gy-3"
                    onChange={(value) => getFinancialOnChange(value, item?.id)}
                    childClass="col-md-3"
                    labelClass="mb-1"
                    inputClass="w-100"
                  />
                </div>
                <div className="col-md-3">
                  <div className="d-flex gap-3">
                    <button className="primary-btn mb-15">Update</button>
                    <button
                      className="outline-btn red-btn"
                      onClick={() => deleteCustomFinancial(item?.id)}
                      disabled={index == 0 && customFinancial.length == 1}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          />
          <button className="primary-btn" onClick={addNewFinancial}>
            <i className="fal fa-plus"></i> Add New Contact
          </button>
        </div>
        {/* {forms.financialRecord.length > 0 &&
          forms.financialRecord.map((fr, i) => {
            return (
              <div
                className="custom-form row align-items-end mb-3"
                id={`financialRecord${i}`}
                key={i}
              >
                <div className="col-md-2 mb-3">
                  <label htmlFor="">Title</label>
                  <Select
                    className="custom-select w-100"
                    placeholder="Select title"
                    // onChange={}
                    options={[
                      {
                        value: "mr",
                        label: "Mr",
                      },
                      {
                        value: "mrs",
                        label: "Mrs",
                      },
                      {
                        value: "miss",
                        label: "Miss",
                      },
                      {
                        value: "ms",
                        label: "Ms",
                      },
                      {
                        value: "dr",
                        label: "Dr",
                      },
                      {
                        value: "rev",
                        label: "Rev",
                      },
                    ]}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Name of Financial Record Holder</label>
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Email:</label>
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Enter Email"
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="">Primary Contact Mobile:</label>
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Enter contact mobile"
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <button
                    className="outline-btn red-btn justify-content-center w-70"
                    onClick={() => removeNewCol("financialRecord", i)}
                  >
                    <i className="fal fa-trash-alt"></i> Remove
                  </button>
                </div>
              </div>
            );
          })} */}
        <div className="d-flex my-4 gap-3 align-items-center">
          <h4 className="primary-title">Individuals</h4>
          <button
            className="primary-btn"
            onClick={() => addNewCol("individual", { focus: true })}
          >
            <i className="fal fa-plus-circle"></i> Add New
          </button>

          <Checkbox
            className="custom-checkbox"
            checked={directorChecked && !reserveChecked}
            onChange={(e) =>
              addNewRow(directorFormSchema, e.target.checked, "directors")
            }
          >
            Directors
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            checked={shareholderChecked && !nomineeChecked}
            onChange={(e) =>
              addNewCustom(
                shareholderFormSchema,
                "shareholders",
                e.target.checked
              )
            }
          >
            Shareholders
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            onChange={(e) => addNewChange(uboFormSchema, e.target.checked)}
          >
            UBOs
          </Checkbox>
          {/* <Checkbox
            className="custom-checkbox"
            onChange={(e) => addNewChange(pocFormSchema, e.target.checked)}
          >
            POC
          </Checkbox> */}
          <Checkbox
            className="custom-checkbox"
            checked={nomineeChecked && !shareholderChecked}
            onChange={(e) =>
              addNewCustom(nomineeShareholder, "nominee", e.target.checked)
            }
          >
            Nominee Shareholder
          </Checkbox>
          <Checkbox
            className="custom-checkbox"
            checked={reserveChecked && !directorChecked}
            onChange={(e) =>
              addNewRow(directorFormSchema, e.target.checked, "reserve")
            }
          >
            Reserve Director
          </Checkbox>
        </div>
        {forms.individual.map((a, i) => {
          return (
            <div key={i} id={`individual${i}`}>
              <div className="d-flex gap-3 mb-3 align-items-center">
                <h3 className="sub-title">
                  Individual 0{i + 1} &nbsp;
                  <i className="fal fa-question-circle"></i>
                </h3>
                {i !== 0 && (
                  <button
                    className="outline-btn red-btn"
                    onClick={() => removeNewCol("individual", i)}
                  >
                    <i className="fal fa-trash-alt"></i> Remove
                  </button>
                )}
              </div>
              <FormZ
                ref={(el) => (individualFormRef.current[i] = el)}
                formSchema={individualFormSchema}
                formClass="row col-md-10 custom-form mb-3 gy-3 gx-4"
                childClass="col-md-4 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <button className="primary-btn mb-2">Update</button>
              {Object.keys(checkedList).some((k) => checkedList[k]) && (
                <div className="d-flex justify-content-between align-items-center">
                  <h6
                    className="hyper-link mb-0"
                    onClick={() => toggleHistory(a)}
                  >
                    Detailed History{" "}
                    <i
                      className={`fas fa-chevron-down ${
                        historyShow.findIndex((hs) => hs === a) !== -1
                          ? "fa-rotate-180"
                          : ""
                      }`}
                    ></i>
                  </h6>
                  {historyShow.findIndex((hs) => hs === a) !== -1 ? (
                    <button className="primary-btn ms-auto">Export</button>
                  ) : (
                    <span className="py-2">&nbsp;</span>
                  )}
                </div>
              )}
              {historyShow.findIndex((hs) => hs === a) !== -1 ? (
                <Table
                  className="custom-table-light mt-md-1"
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                  scroll={{ x: 1800 }}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
        {/* <div className="d-flex gap-3 align-items-center">
          <h4 className="primary-title">Shareholders</h4>
          <button
            className="primary-btn"
            onClick={() => addNewCol("shareholders", { focus: true })}
          >
            <i className="fal fa-plus-circle"></i> Add New
          </button>
        </div>
        {forms.shareholders.map((a, i) => {
          return (
            <div key={i} className="my-4" id={`shareholders${i}`}>
              <div className="d-flex mb-3 gap-3 align-items-center">
                <h3 className="sub-title">
                  Shareholder 0{i + 1} &nbsp;
                  <i className="fal fa-question-circle"></i>
                </h3>
                {i !== 0 && (
                  <button
                    className="outline-btn red-btn"
                    onClick={() => removeNewCol("shareholders", i)}
                  >
                    <i className="fal fa-trash-alt"></i> Remove
                  </button>
                )}
              </div>
              <FormZ
                ref={(el) => (shareholderFormRef.current[i] = el)}
                formSchema={shareholderFormSchema}
                formClass="row col-md-10 custom-form mb-3 gy-3 gx-4"
                childClass="col-md-4 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <button className="primary-btn mb-4">Update</button>
              <div className='d-flex justify-content-between align-items-center'>
                <h6 className='hyper-link mb-0' onClick={() => toggleHistory(a)}>Detailed History <i className={`fas fa-chevron-down ${historyShow.findIndex(hs => hs === a) !== -1 ? 'fa-rotate-180' : ''}`}></i></h6>
                {historyShow.findIndex(hs => hs === a) !== -1 ?
                    <button className='primary-btn ms-auto'>Export</button>
                    :
                    <span className='py-2'>&nbsp;</span>
                }
              </div>
              {historyShow.findIndex((hs) => hs === a) !== -1 ? (
                <Table
                  className="custom-table-light mt-md-1"
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })} */}
        {/* <div className="d-flex gap-3 align-items-center">
          <h4 className="primary-title">UBO</h4>
          <button
            className="primary-btn"
            onClick={() => addNewCol("ubo", { focus: true })}
          >
            <i className="fal fa-plus-circle"></i> Add New
          </button>
        </div>
        {forms.ubo.map((a, i) => {
          return (
            <div key={i} className="my-4" id={`ubo${i}`}>
              <div className="d-flex mb-3 gap-3 align-items-center">
                <h3 className="sub-title">
                  UBO 0{i + 1} &nbsp;<i className="fal fa-question-circle"></i>
                </h3>
                {i !== 0 && (
                  <button
                    className="outline-btn red-btn"
                    onClick={() => removeNewCol("ubo", i)}
                  >
                    <i className="fal fa-trash-alt"></i> Remove
                  </button>
                )}
              </div>
              <FormZ
                ref={(el) => (uboFormRef.current[i] = el)}
                formSchema={uboFormSchema}
                formClass="row col-md-10 custom-form mb-3 gy-3 gx-4"
                childClass="col-md-4 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <button className="primary-btn mb-4">Update</button>
              <div className='d-flex justify-content-between align-items-center'>
                <h6 className='hyper-link mb-0' onClick={() => setUboHistory(!uboHistory)}>Detailed History <i className={`fas fa-chevron-down ${uboHistory ? 'fa-rotate-180' : ''}`}></i></h6>
                {uboHistory ?
                    <button className='primary-btn ms-auto'>Export</button>
                    :
                    <span className='py-2'>&nbsp;</span>
                }
              </div>
              {uboHistory ? (
                <Table
                  className="custom-table-light mt-md-1"
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })} */}
        <CorporateEntity data={data} isLoading={isLoading} />

        <h4 className="primary-title mb-4">CDD Information</h4>
        <FormZ
          ref={cddInformationFormRef}
          formSchema={cddInformationFormSchema}
          formClass="custom-form row gy-2"
          childClass="col-md-3"
          labelClass="mb-1"
          inputClass="w-100"
        />
        <div className="custom-form row mt-0 mb-4 gy-2">
          <div className="col-md-3">
            <div className="input-box w-100">
              <label htmlFor="" className="mb-1">
                Reminder
              </label>
              <Select
                className="custom-select w-100"
                placeholder="Select"
                defaultValue={1}
                onChange={getReminderCount}
                options={[
                  {
                    value: 1,
                    label: 1,
                  },
                  {
                    value: 2,
                    label: 2,
                  },
                  {
                    value: 3,
                    label: 3,
                  },
                  {
                    value: 4,
                    label: 4,
                  },
                ]}
              />
            </div>
            {reminderCount.map((reminder, index) => {
              return (
                <div className="w-100 mb-4" key={index}>
                  <label htmlFor="" className="mb-1">
                    Reminder {reminder + 1} Date
                  </label>
                  <DatePicker
                    className="custom-datepicker w-100"
                    placeholder="Enter Date (DD/MM/YYYY)"
                    format={"DD/MM/YYYY"}
                  />
                </div>
              );
            })}
          </div>
          <div className="col-md-3">
            <div className="input-box w-100">
              <label htmlFor="" className="mb-1">
                Boss Filing Date
              </label>
              <Select
                className="custom-select w-100"
                placeholder="Select"
                defaultValue={1}
                onChange={getBossFilingCount}
                options={[
                  {
                    value: 1,
                    label: 1,
                  },
                  {
                    value: 2,
                    label: 2,
                  },
                  {
                    value: 3,
                    label: 3,
                  },
                  {
                    value: 4,
                    label: 4,
                  },
                ]}
              />
            </div>
            {bossFilingCount.map((reminder, index) => {
              return (
                <div className="w-100 mb-4" key={index}>
                  <label htmlFor="" className="mb-1">
                    Boss Filing {reminder + 1} Date
                  </label>
                  <DatePicker
                    className="custom-datepicker w-100"
                    placeholder="Enter Date (DD/MM/YYYY)"
                    format={"DD/MM/YYYY"}
                  />
                </div>
              );
            })}
          </div>
          <div className="col-md-12">
            <button className="primary-btn" onClick={onUpdateClick}>
              Update
            </button>
          </div>
        </div>
      </div>
      <Modal
        title={"Entity Overview"}
        className="custom-modal"
        open={viewModal}
        width={1000}
        onOk={handleOk}
        okText="Confirm Changes"
        onCancel={handleCancel}
      >
        <EntityOverviewList iframe={true} />
      </Modal>
    </div>
  );
}
