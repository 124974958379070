import { useDispatch, useSelector } from "react-redux";
import { sendEmail, resetSuccess } from "../store/generalEmail/generalEmailSlice";

export const useGeneralEmail = () => {
  const dispatch = useDispatch();
  const { isLoading: isSendingEmail, success, isError } = useSelector((state) => state.generalEmails);

  const handleSendEmail = async (emailData) => {
    try {
      await dispatch(sendEmail(emailData));
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const resetEmailStatus = () => {
    dispatch(resetSuccess());
  };

  return {
    isSendingEmail,
    success,
    isError,
    handleSendEmail,
    resetEmailStatus,
  };
};
