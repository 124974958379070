// Auth
import Login from "../pages/auth/login";

//Private
import Home from "../pages/home";
import Incorporation from "../pages/incorporation";
import PostIncorp from "../pages/post-incorp";
import OnboardingTeamListing from "../pages/onboarding-team/listing";
import OnboardingTeamEdit from "../pages/onboarding-team/edit";
import OnboardingTeamDownload from "../pages/onboarding-team/download";
import OnboardingTeamUpload from "../pages/onboarding-team/upload";
import OnboardingTeamReviewList from "../pages/onboarding-team/review-listing";
import OnboardingTeamReviewUpload from "../pages/onboarding-team/review-upload";
import MasterFileCreation from "../pages/master-file/creation";
import PreIncorpResolution from "../pages/pre-incorp-resolution";
import RelationshipManager from "../pages/relationship-manager";
import ActiveRequests from "../pages/active-requests";
import MLRO from "../pages/mlro";
import NewIncorporation from "../pages/new-incorporation";
import IncorporationConfirmation from "../pages/incorporation-confirmation";
import IncorporationFeedback from "../pages/incorporation-feedback";
import EntityOverviewEdit from "../pages/entity-overview/edit";
import EntityOverviewList from "../pages/entity-overview/list";
import IncorporationPack from "../pages/incorporation-pack";

import AdditionalScreens from "../pages/additional-screens";
import CDD from "../pages/additional-screens/cdd";
import Client from "../pages/additional-screens/client";
import Shareholder from "../pages/additional-screens/shareholder";
import Director from "../pages/additional-screens/director";
import Crypto from "../pages/additional-screens/crypto";

import Storage from "../pages/storage";
import OcrExtraction from "../pages/ocr-extraction";
import DocTranslation from "../pages/doc-translation";
import ServiceInvoice from "../pages/service-invoice";
import AccessControl from "../pages/access-control";
import PendingRequests from "../pages/pending-requests";
import MasterSearch from "../pages/master-search";
import IndividualAnalysis from "../pages/individual-analysis";
import CorporateEntityAnalysis from "../pages/corporate-entity-analysis";
import MyPortfolio from "../pages/my-portfolio";

import PeriodicReview from "../pages/periodic-review";
import ManualStorage from "../pages/manual-storage";
import AuditTrail from "../pages/audit-trail";
import AnnualFilings from "../pages/annual-filings";
import FeeSchedule from "../pages/fee-schedule";
import FeeScheduleCreate from "../pages/fee-schedule/create";
import Mail from "../pages/mail";
import Notifications from "../pages/notifications";

import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    icon: "",
    component: <Login />,
    type: "public",
    showInMenu: false,
  },
  {
    name: "Login",
    path: "/login",
    icon: "",
    component: <Login />,
    type: "public",
    showInMenu: false,
  },
  {
    name: "Home",
    path: "home",
    component: <Home />,
    icon: "fa-home",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "Master Search/MF Creation",
    path: "master-search",
    component: <MasterSearch />,
    icon: "fa-file-search",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "lead"],
    showInMenu: true,
    parentId: 0,
  },
  {
    name: "Individual Analysis",
    path: "individual-analysis",
    component: <IndividualAnalysis />,
    icon: "fa-user-circle",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "mlro"],
    showInMenu: true,
    parentId: 0,
    duplicate: 2,
  },
  {
    name: "Periodic Review",
    path: "periodic-review",
    component: <PeriodicReview />,
    icon: "fa-file-chart-pie",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client", "lead", "mlro"],
    showInMenu: true,
    parentId: 0,
  },
  {
    name: "Corporate Entity Analysis",
    path: "corporate-entity-analysis",
    component: <CorporateEntityAnalysis />,
    icon: "fa-analytics",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 0,
    duplicate: 2,
  },
  {
    name: "Pre-Incorp Documentation",
    path: "pre-incorp-resolution",
    component: <PreIncorpResolution />,
    type: "private",
    icon: "fa-file-download",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "Name Check",
    path: "incorporation",
    component: <Incorporation />,
    icon: "fa-paper-plane",
    type: "private",
    permissions: ["super-admin", "admin", "client"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "Active Requests",
    path: "active-requests",
    component: <ActiveRequests />,
    icon: "fa-tasks",
    type: "private",
    permissions: ["super-admin", "admin", "client", "lead", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "New Incorporation Request",
    path: "relationship-manager",
    component: <RelationshipManager />,
    icon: "fa-tasks-alt",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "New Incorporation Review",
    path: "onboarding-team/download",
    component: <OnboardingTeamDownload />,
    type: "private",
    icon: "fa-folder-plus",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "New Incorporation Approval Req",
    path: "onboarding-team/upload",
    component: <OnboardingTeamUpload />,
    icon: "fa-flag-alt",
    type: "private",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "MLRO Approval Request",
    path: "onboarding-team/review",
    component: <OnboardingTeamReviewList />,
    icon: "fa-clipboard-check",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "lead", "mlro"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "MLRO Approval",
    path: "mlro",
    component: <MLRO />,
    icon: "fa-check-square",
    type: "private",
    permissions: ["super-admin", "admin", "mlro"],
    showInMenu: true,
    parentId: 2,
  },
  {
    name: "New Incorporation Submission to Registry",
    path: "new-incorporation",
    component: <NewIncorporation />,
    icon: "fa-external-link-square",
    type: "private",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "New Business Onboard",
    path: "onboarding-team/review-upload",
    component: <OnboardingTeamReviewUpload />,
    icon: "fa-building",
    type: "private",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: true,
    parentId: 1,
  },
  {
    name: "Entity Overview Update",
    path: "entity-overview/edit",
    component: <EntityOverviewEdit />,
    icon: "fa-file-edit",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "lead", "nb"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "Post Incorp Service Request",
    path: "post-incorp",
    component: <PostIncorp />,
    icon: "fa-plus-circle",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "OCR Extraction",
    path: "ocr-extraction",
    component: <OcrExtraction />,
    icon: "fa-print-search",
    type: "private",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "Translation",
    path: "doc-translation",
    component: <DocTranslation />,
    icon: "fa-language",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "My Portfolio – Client",
    path: "my-portfolio",
    component: <MyPortfolio />,
    icon: "fa-briefcase",
    type: "private",
    permissions: ["super-admin", "admin", "client"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "Pending/Resubmission",
    path: "pending-requests",
    component: <PendingRequests />,
    icon: "fa-exclamation-circle",
    type: "private",
    permissions: ["super-admin", "admin", "client"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "Manual Storage",
    path: "manual-storage",
    component: <ManualStorage />,
    icon: "fa-server",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 3,
  },
  {
    name: "Annual Filings",
    path: "annual-filings",
    component: <AnnualFilings />,
    icon: "fa-clipboard-list-check",
    type: "private",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: true,
    parentId: 4,
  },
  {
    name: "Invoicing",
    path: "service-invoice",
    component: <ServiceInvoice />,
    icon: "fa-money-check-edit-alt",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client", "lead"],
    showInMenu: true,
    parentId: 4,
  },
  {
    name: "Fee Schedule",
    path: "fee-schedule",
    component: <FeeSchedule />,
    icon: "fa-money-check-alt",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 5,
  },
  {
    path: "fee-schedule-create",
    component: <FeeScheduleCreate />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Access Control",
    path: "access-control",
    component: <AccessControl />,
    icon: "fa-sliders-v",
    type: "private",
    permissions: ["super-admin", "admin"],
    showInMenu: true,
    parentId: 5,
  },
  {
    name: "Audit Trail & Reporting",
    path: "audit-trail",
    component: <AuditTrail />,
    icon: "fa-file-chart-line",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 6,
  },
  {
    name: "Notifications",
    path: "notifications",
    component: <Notifications />,
    icon: "fa-bell",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 6,
  },
  {
    name: "CDD",
    path: "additional-screens/cdd",
    component: <CDD />,
    icon: "fa-calendar-alt",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Mailbox",
    path: "mail",
    icon: "fa-envelope",
    component: <Mail />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: true,
    parentId: 6,
  },
  {
    name: "Crypto TM",
    path: "additional-screens/crypto",
    component: <Crypto />,
    icon: "fa-usd-circle",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "VASP",
    path: "vasp",
    component: <Home />,
    icon: "fa-server",
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "onboarding-team",
    component: <OnboardingTeamListing />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "onboarding-team/edit/:taskId",
    component: <OnboardingTeamEdit />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "MasterFile Creation",
    path: "master-file-creation",
    component: <MasterFileCreation />,
    icon: "fa-file-check",
    type: "private",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: false,
  },
  {
    path: "incorporation-confirmation",
    component: <IncorporationConfirmation />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "incorporation-feedback",
    component: <IncorporationFeedback />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst"],
    showInMenu: false,
  },
  {
    path: "entity-overview/list",
    component: <EntityOverviewList />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client", "lead", "nb"],
    showInMenu: false,
  },
  {
    path: "incorporation-pack",
    component: <IncorporationPack />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "additional-screens",
    component: <AdditionalScreens />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "additional-screens/client",
    component: <Client />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "additional-screens/shareholder",
    component: <Shareholder />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "additional-screens/director",
    component: <Director />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "storage",
    component: <Storage />,
    type: "private",
    permissions: ["super-admin", "admin", "analyst", "client"],
    showInMenu: false,
  },
  // {
  //   name: "Error 404",
  //   path: "*",
  //   icon: "",
  //   component: <NotFound />,
  //   type: "private",
  //   permissions: [],
  //   showInMenu: false,
  // },
];

export default routes;
