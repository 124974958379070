import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getSignature = createAsyncThunk("getSignature", async (params) => {
  let query = convertQueryParams(params);

  let payload = {
    method: "get",
    url: `/signature?${query}`,
  };

  let res = await fetchApi(payload).then((res) => {
    return res.data;
  });
  return res;
});

export const updateSignature = createAsyncThunk(
  "updateSignature",
  async (params) => {
    console.log(params);

    let payload = {
      method: "PUT",
      url: `/signature/update/${params?.id}`,
      data: params?.data,
    };

    await fetchApi(payload);
    return params?.id;
  }
);

export const deleteSignature = createAsyncThunk(
  "deleteSignature",
  async (id) => {
    let payload = {
      method: "DELETE",
      url: `/signature?id=${id}`,
    };

    await fetchApi(payload);
    return id;
  }
);

const incorpSlice = createSlice({
  name: "getSignature",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getSignature.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getSignature.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getSignature.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });

    builder.addCase(deleteSignature.fulfilled, (state, action) => {
      state.data.rows = state.data.rows.filter(
        (signature) => signature._id !== action.payload
      );
    });
  },
});

export default incorpSlice.reducer;
