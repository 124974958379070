import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox, Spin } from "antd";

import UploadZ from "../components/UploadZ";
import RequestResubmission from "../components/RequestResubmission";
import RejectTask from "../components/RejectTask";

import { Each, getQuery, fetchApi, sendNotify } from "../helper";

import { useSelector } from "react-redux";
import { useGeneralEmail } from "../hooks/useGeneralEmail";
import ServiceRequestEmailSender from "../components/ServiceRequestEmaiSendButtons";

export default function TransferOut() {
  let query = getQuery();

  const [docs, setDocs] = useState({});
  const [rejectModel, setRejectModel] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customUpload, setCustomUpload] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    const payload = {
      method: "get",
      url: `/serviceRequest?id=${query?.id}`,
    };

    fetchApi(payload)
      .then((res) => {
        let data = res?.data?.rows?.[0];

        setData(data);
        setDocs(data?.documents);
        setCustomUpload(data?.customDocuments ?? []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        sendNotify("error", "Failed to load data");
      });
  };

  const getRejectDocs = (checked, docId) => {
    let arr = [...docs, ...customUpload];
    let index = arr.findIndex((doc) => doc._id === docId);
    if (checked) {
      arr[index]["rejected"] = true;
    } else {
      delete arr[index]["rejected"];
    }
    setDocs(arr);
  };

  const onChangeApproval = (file, bool) => {
    console.log(file, bool);
    let arr = [...docs, ...customUpload];
    let index = arr.findIndex((doc) => doc.file == file);
    arr[index]["isApproved"] = bool;
    console.log(arr);
    setDocs(arr);
  };

  function saveChanges(data) {
    setLoading(true);
    const payload = {
      method: "PUT",
      url: `/serviceRequest/update/${query?.id}`,
      data,
    };

    fetchApi(payload)
      .then((res) => {
        setLoading(false);
        handleRefresh();
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        console.log("Error while reject task");
      });
  }
  const { isSendingEmail } = useGeneralEmail();
  const { userDetails: businessPartnerDetails } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSendingEmail) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSendingEmail]);

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Transfer Out
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <RequestResubmission id={query?.id} documents={docs} />

          <Link
            to={`/app/entity-overview/list?id=${data?.entityId}`}
            className="outline-btn"
          >
            Refer Entity Overview
          </Link>
          <ServiceRequestEmailSender
            serviceRequestName="Transfer Out"
            serviceRequestData={data}
            businessPartnerDetails={businessPartnerDetails}
          />
          {/* <Link className="outline-btn" to={"/app/mail"}>
            Check Mailbox
          </Link> */}
          <RejectTask id={query?.id} reason={data?.rejectReason} />
          <button className="outline-btn">Save</button>
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <div className="custom-form row gy-4 mb-4">
            <div className="col-md-3">
              <label htmlFor="">Entity Code</label>
              <span className="light-badge">{data?.entityCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Entity Name</label>
              <span className="light-badge">{data?.entityName ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Code</label>
              <span className="light-badge">{data?.clientCode ?? "N/A"}</span>
            </div>
            <div className="col-md-3">
              <label htmlFor="">Client Name</label>
              <span className="light-badge">{data?.clientName ?? "N/A"}</span>
            </div>
          </div>
          <div className="row gy-2 gx-4">
            <Each
              array={data?.documents}
              render={(item, index) => (
                <div className="col-md-3" key={index}>
                  <div>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        getRejectDocs(e.target.checked, item?._id)
                      }
                    >
                      {item?.document}
                    </Checkbox>
                    <UploadZ
                      file={item}
                      downloadOnly={true}
                      showApprove={true}
                      onFileApprove={onChangeApproval}
                      showTranslation={true}
                      showExtraction={true}
                    />
                  </div>
                </div>
              )}
            />
            <Each
              array={data?.customDocuments}
              render={(item, index) => (
                <div className="col-md-3" key={index}>
                  <div>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        getRejectDocs(e.target.checked, item?._id)
                      }
                    >
                      {item?.document}
                    </Checkbox>
                    <UploadZ
                      file={item}
                      downloadOnly={true}
                      showApprove={true}
                      onFileApprove={onChangeApproval}
                      showTranslation={true}
                      showExtraction={true}
                    />
                  </div>
                </div>
              )}
            />
          </div>
          <h4 className="form-title my-4">Request Resubmission</h4>
          <div className="custom-form">
            <div className="col-md-3">
              <div>
                <label htmlFor="">Guidelines</label>
                <UploadZ customClass={"mb-0"} />
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <Modal
        className="custom-modal-2"
        open={rejectModel}
        width={800}
        // onOk={handleOk}
        // onCancel={handleCancel}
        okText="Reject"
        okButtonProps={{ className: "reject-btn" }}
        cancelButtonProps={{ className: "d-none" }}
      >
        <div className="mt-4 pt-2">
          <h6 className="input-title">Reject Request</h6>
          <p className="description mb-3">
            Select reason(s) as to why this request should be rejected.
          </p>
          <Checkbox className="custom-checkbox py-1 font-md">
            Incomplete or inaccurate information provided in the documents
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Insufficient supporting documentation or evidence
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            The requesting entity does not have the legal authority to do so
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Lack of authorization or signature from the appropriate authority
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Failure to pay required fees or charges associated with the request
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Issues with the authenticity or validity of the requesting entity or
            individuals involved
          </Checkbox>
          <Checkbox className="custom-checkbox py-1 font-md">
            Suspected fraud or misrepresentation in the application
          </Checkbox>
        </div>
      </Modal>
    </div>
  );
}
