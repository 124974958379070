import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Table, Checkbox, Modal, Spin } from "antd";
import moment from "moment";

import SampleDoc from "../../assets/pdf/sample.pdf";

import RejectTask from "../../components/RejectTask";
import UploadZ from "../../components/UploadZ";
import TableSortArrows from "../../components/tableSortArrows";

import { Each, fetchApi, sendNotify } from "../../helper";

export default function Review() {
  let { taskId } = useParams();

  const [loading, setLoading] = useState(false);
  const [pendingView, setPendingView] = useState(false);
  const [pendingPDF, setPendingPDF] = useState({
    individual: [],
    Directors: [],
    Shareholders: [],
    UBOs: [],
    basicDoc: [],
    Reserve_Director_Appointments: [],
    corporateEntity: [],
  });
  const [documents, setDocuments] = useState({ uploadPack: [] });
  const [data, setData] = useState([]);
  const [taskData, setTaskData] = useState(null);
  const [inDocuments, setInDocuments] = useState([]);
  const [corpDocuments, setCorpDocuments] = useState([]);
  const [relatedENDocuments, setRelatedENDocuments] = useState([]);

  const [statutoryDocs, setStatutoryDocs] = useState([]);
  const [statutoryDocuments, setStatutoryDocuments] = useState([]);
  const [formState, setFormState] = useState([]);
  useEffect(() => {
    if (taskId) {
      getTaskData(taskId);
    }
  }, []);

  const getTaskData = async (id) => {
    setLoading(true);
    let payload = {
      method: "get",
      url: `/incorporation?_id=${id}`,
    };
    let dataArray = [];
    let data = [];
    fetchApi(payload)
      .then(async (res) => {
        data = res?.data?.rows[0];
        if (data) {
          dataArray = [
            {
              key: data._id,
              id: data._id,
              serviceType: data.type,
              serviceRef: data.code,
              reqID: "2346",
              entityName: data.entityName,
              entityCode: data.masterCode,
              clientName: "Sample",
              clientCode: "8022",
              date: data.createdAt,
              assignee: data.requested.name,
            },
          ];
          setTaskData(data);
          setData(dataArray);
          data.individual?.map(async (a, i) => {
            let documentArray = a.documents.map(async (c) => {
              const result = await documentUrl(c.url);
              return {
                ...c,
                result,
              };
            });
            const document = await Promise.all(documentArray);
            setInDocuments(document);
          });
          data.corporateEntity?.map(async (a, i) => {
            let documentArray = a.documents.map(async (c) => {
              const result = await documentUrl(c.url);
              return {
                ...c,
                result,
              };
            });
            const document = await Promise.all(documentArray);
            setCorpDocuments(document);
          });
          data.relatedEntity?.map(async (a, i) => {
            let documentArray = a.documents.map(async (c) => {
              const result = await documentUrl(c.url);
              return {
                ...c,
                result,
              };
            });
            const document = await Promise.all(documentArray);
            setRelatedENDocuments(document);
          });
          const stDocs = data?.basicDoc.map((obj) => obj?.document);
          let stDocsArray = data?.basicDoc.map(async (c) => {
            const result = await documentUrl(c?.url);
            return {
              ...c,
              result,
            };
          });
          const document = await Promise.all(stDocsArray);
          let documentArray = document?.map((c) => {
            const documents = {
              _id: c._id,
              url: `data:application/pdf;base64,${c.result}`,
              name: `${c.document}.pdf`,
              size: c.size,
              type: "pdf",
              base64: "",
              isApprove: c.isApprove,
            };
            return documents;
          });
          setStatutoryDocuments(documentArray);
          setStatutoryDocs(stDocs);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "esdryiu");
        setLoading(false);
        sendNotify("error", error?.message);
      });
  };

  const handleChange = (setIdentifierState, event, id, keys) => {
    const newState = {
      ...formState,
      [id]: { ...formState[id], [setIdentifierState]: event, arrayName: keys },
    };

    setFormState(newState);
  };

  const columns = [
    {
      title: "Service Type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType - b.serviceType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Service Ref",
      dataIndex: "serviceRef",
      sorter: (a, b) => a.serviceRef - b.serviceRef,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Requestor ID",
      dataIndex: "reqID",
      sorter: (a, b) => a.reqID - b.reqID,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Entity Code",
    //   dataIndex: "entityCode",
    //   sorter: (a, b) => a.entityCode - b.entityCode,
    // sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    // {
    //   title: "Client Code",
    //   dataIndex: "clientCode",
    //   sorter: (a, b) => a.clientCode - b.clientCode,
    // sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    // },
    {
      title: "Request Date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date) - moment(b.date),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { date }) => {
        return <span>{moment(date).format("YYYY-MM-DD hh:mm A")}</span>;
      },
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      sorter: (a, b) => a.assignee - b.assignee,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const getPending = (obj, pdf, id, checked) => {
    let object = { ...pendingPDF };
    let arr = object[obj];
    let value = `${pdf}&${id}`;
    let index = arr.findIndex((a) => a == value);

    if (checked) {
      if (index !== -1) {
        arr[index] = value;
      } else {
        arr.push(value);
      }
    } else {
      arr.splice(index, 1);
    }

    object[obj] = arr;
    setPendingPDF(object);
  };

  const handleOk = () => {
    const formedData = Object.entries(formState).map(([id, item]) => {
      const title = Object.keys(item).find((key) => key !== "arrayName");
      const value = item[title];
      return {
        _id: id,
        title: title,
        value: value,
        arrayName: item.arrayName,
      };
    });
    if (formedData.length) {
      let obj = {
        confirmation: formedData,
        _id: taskId,
      };

      let payload = {
        method: "PUT",
        url: `/relationshipManager/updateInformation`,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
        })
        .catch((error) => {
          console.log(error, "esdryiu1");

          sendNotify("error", error?.message);
        });

      setPendingView(false);
    } else {
      sendNotify("error", "Please enter at least one document");
    }
  };

  const handleCancel = () => {
    setPendingView(false);
  };

  const fetchLiveUrl = async (url) => {
    try {
      const data = { url: url };
      const obj = {
        method: "POST",
        url: `/file/get`,
        data: data,
      };
      return await fetchApi(obj);
    } catch (error) {
      console.error("Error fetching live URL:", error);
      throw error;
    }
  };

  const documentUrl = async (data) => {
    let res = await fetchLiveUrl(data);
    return res.data;
  };

  const individualDocss = [
    "Passport / ID",
    "Address Proof",
    "Consent to Act",
    "Resolution of Appointment",
    "Resolution of Resignation",
  ];
  const directorDocs = [
    "Resolution of Appointment",
    "Resolution of Resignation",
  ];

  const shareholderDocs = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
    "Nominee Agreement",
  ];

  const uboDocs = ["Resolution of Shareholding", "Source of Wealth/Founds"];

  // const statutoryDocs = [
  //   "Client Information Sheet",
  //   "Sample Document",
  //   "Appointment of First Director(s)",
  //   "Initial Share Allotment",
  //   "Corporate Seal",
  // ];

  const reserveDirectorDocs = ["Passport / ID", "Address Proof", "Resolutions"];

  const corporateDocs = [
    "Corporate Entity Pack 01",
    "Corporate Entity Pack 02",
    "Corporate Entity Pack 03",
  ];

  const sampDocuments = {
    url: SampleDoc,
    name: "Sample Document.pdf",
    size: "200 KB",
    type: "pdf",
    base64: "",
    // isApproved: true,
  };

  const staticArrays = {
    directorDocs,
    individualDocss,
    shareholderDocs,
    uboDocs,
  };
  const checkMatches = (value, staticArrays) => {
    let result = {};

    for (let [key, docs] of Object.entries(staticArrays)) {
      result[key] = value.some((item) => docs.includes(item));
    }

    return result;
  };
  const onChangeApproval = (file, bool) => {
    // this is not working
    // console.log("cvbkl");
    // let arr = [...docs];
    // let index = arr.findIndex((doc) => doc.file == file);
    // arr[index].isApproved = bool;
    console.log(file, bool, "jgyuhk");
    //setDocs(arr);
  };

  const showPendingView = () => {
    const validate = Object.values(pendingPDF).some(
      (arr) => Array.isArray(arr) && arr.length > 0
    );
    if (validate) {
      setPendingView(true);
    } else {
      sendNotify("error", "Select any document before send request");
    }
  };

  const saveReview = () => {
    console.log("qwfefb");
    const data = Object.entries(pendingPDF).flatMap(([key, values]) => {
      return values.map((n) => {
        const parts = n.split("&");
        return {
          name: parts[0],
          _id: parts[1],
          approve: true,
          arrayName: key,
        };
      });
    });
    if (data.length) {
      let obj = {
        confirmation: data,
        resubmissionComment: data,
        resubmissionStatus: "Pending",
        resubmissionOriginAt: new Date(),
        _id: taskId,
      };

      let payload = {
        method: "PUT",
        url: `/relationshipManager/updateApprove`,
        data: obj,
      };
      fetchApi(payload)
        .then((res) => {
          sendNotify("success", res?.message);
        })
        .catch((error) => {
          console.log(error, "esdryiu2");

          sendNotify("error", error?.message);
        });

      setPendingView(false);
    } else {
      sendNotify("error", "Please enter at least one document");
    }
  };
  const getPreLink = (type, _id) => {
    if (type === "Incorporation") {
      return `/app/send-to-approval?id=${taskId}`;
    }
    if (type === "Transfer-In") {
      return `/app/transfer-in-send-to-approval?id=${taskId}`;
    }
  };

  return (
    <div>
      <div className="page-header">
        <h1>
          {taskData?.type === "Incorporation"
            ? `Incorporation Document Review`
            : `Transfer-In Document Review`}
        </h1>
        <div className="d-flex flex-column flex-md-row gap-3">
          <button className="outline-btn" onClick={showPendingView}>
            Request Pending Information
          </button>
          <RejectTask
            id={taskId}
            reason={taskData?.rejectReason}
            url={"relationshipManager"}
          />
          <button className="outline-btn" onClick={saveReview}>
            Save Review
          </button>
          <Link
            to={getPreLink(taskData?.type, taskData?._id)}
            className="primary-btn"
          >
            Complete Review and Submit
          </Link>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="page-content">
          <Table
            className="custom-table"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          {taskData
            ? taskData.individual?.map((a, i) => {
                let docs = a.documents.map((b) => {
                  return b.document;
                });

                const checkedList = checkMatches(docs, staticArrays);
                let documentArray = inDocuments.map((c) => {
                  const documents = {
                    _id: c._id,
                    isApprove: c.isApprove,
                    url: `data:application/pdf;base64,${c.result}`,
                    name: `${c.document}.pdf`,
                    size: c.size,
                    type: "pdf",
                    base64: "",
                  };
                  return documents;
                });

                return (
                  <>
                    <div className="mt-4">
                      <div className="d-flex my-4 gap-3 align-items-center">
                        <h4 className="primary-title">Individuals</h4>
                        <Checkbox
                          className="custom-checkbox"
                          disabled
                          checked={checkedList.individualDocss ?? false}
                        >
                          Directors
                        </Checkbox>
                        <Checkbox
                          className="custom-checkbox"
                          disabled
                          checked={checkedList.shareholderDocs ?? false}
                        >
                          Shareholders
                        </Checkbox>
                        <Checkbox
                          className="custom-checkbox"
                          checked={checkedList.uboDocs ?? false}
                          disabled
                        >
                          UBOs
                        </Checkbox>

                        <Checkbox className="custom-checkbox" disabled>
                          Nominee Shareholder
                        </Checkbox>
                        <Checkbox className="custom-checkbox" disabled>
                          Reserve Director
                        </Checkbox>
                        <Checkbox className="custom-checkbox" disabled>
                          President
                        </Checkbox>
                        <Checkbox className="custom-checkbox" disabled>
                          Secretary
                        </Checkbox>
                      </div>

                      <div className="col-md-9 my-4">
                        <h6 className="sub-title mb-4">Individual 0{i + 1}</h6>
                        <div className="row gy-2 gx-4">
                          <Each
                            array={docs}
                            render={(item, index) => (
                              <div className="col-md-4">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <Checkbox
                                    className="custom-checkbox"
                                    onChange={(e) =>
                                      getPending(
                                        "individual",
                                        item,
                                        documentArray[index]._id,
                                        e.target.checked
                                      )
                                    }
                                  >
                                    {item}
                                  </Checkbox>
                                </div>

                                <UploadZ
                                  approveClicked={saveReview}
                                  file={documentArray[index]}
                                  showTranslation={true}
                                  showExtraction={true}
                                  showApprove={true}
                                  downloadOnly={true}
                                  onFileApprove={onChangeApproval}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : []}
          {taskData
            ? taskData.corporateEntity?.map((a, i) => {
                let docs = a.documents.map((b) => {
                  return b.document;
                });

                const checkedList = checkMatches(docs, staticArrays);
                let documentArray = corpDocuments.map((c) => {
                  const documents = {
                    _id: c._id,
                    isApprove: c.isApprove,
                    url: `data:application/pdf;base64,${c.result}`,
                    name: `${c.document}.pdf`,
                    size: c.size,
                    type: "pdf",
                    base64: "",
                  };
                  return documents;
                });

                return (
                  <div className="mt-4">
                    <div className="d-flex my-4 gap-3 align-items-center">
                      <h4 className="primary-title">Corporate Entity</h4>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={checkedList.individualDocss ?? false}
                      >
                        Directors
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={checkedList.shareholderDocs ?? false}
                      >
                        Shareholders
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        checked={checkedList.uboDocs ?? false}
                        disabled
                      >
                        UBOs
                      </Checkbox>

                      <Checkbox className="custom-checkbox" disabled>
                        Nominee Shareholder
                      </Checkbox>
                      <Checkbox className="custom-checkbox" disabled>
                        Reserve Director
                      </Checkbox>
                      <Checkbox className="custom-checkbox" disabled>
                        President
                      </Checkbox>
                      <Checkbox className="custom-checkbox" disabled>
                        Secretary
                      </Checkbox>
                    </div>

                    <div className="col-md-9 my-4">
                      <h6 className="sub-title mb-4">
                        Corporate Entity 0{i + 1}
                      </h6>
                      <div className="row gy-2 gx-4">
                        <Each
                          array={docs}
                          render={(item, index) => (
                            <div className="col-md-4">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <Checkbox
                                  className="custom-checkbox"
                                  onChange={(e) =>
                                    getPending(
                                      "individual",
                                      item,
                                      documentArray[index]._id,
                                      e.target.checked
                                    )
                                  }
                                >
                                  {item}
                                </Checkbox>
                              </div>
                              <UploadZ
                                approveClicked={saveReview}
                                file={documentArray[index]}
                                downloadOnly={true}
                                showApprove={true}
                                onFileApprove={onChangeApproval}
                                showTranslation={true}
                                showExtraction={true}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : []}
          {taskData
            ? taskData.relatedEntity?.map((a, i) => {
                let docs = a.documents.map((b) => {
                  return b.document;
                });

                const checkedList = checkMatches(docs, staticArrays);
                let documentArray = relatedENDocuments.map((c) => {
                  const documents = {
                    _id: c._id,
                    isApprove: c.isApprove,
                    url: `data:application/pdf;base64,${c.result}`,
                    name: `${c.document}.pdf`,
                    size: c.size,
                    type: "pdf",
                    base64: "",
                  };
                  return documents;
                });

                return (
                  <div className="mt-4">
                    <div className="d-flex my-4 gap-3 align-items-center">
                      <h4 className="primary-title">Related Entity</h4>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={checkedList.individualDocss ?? false}
                      >
                        Directors
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        disabled
                        checked={checkedList.shareholderDocs ?? false}
                      >
                        Shareholders
                      </Checkbox>
                      <Checkbox
                        className="custom-checkbox"
                        checked={checkedList.uboDocs ?? false}
                        disabled
                      >
                        UBOs
                      </Checkbox>

                      <Checkbox className="custom-checkbox" disabled>
                        Nominee Shareholder
                      </Checkbox>
                      <Checkbox className="custom-checkbox" disabled>
                        Reserve Director
                      </Checkbox>
                      <Checkbox className="custom-checkbox" disabled>
                        President
                      </Checkbox>
                      <Checkbox className="custom-checkbox" disabled>
                        Secretary
                      </Checkbox>
                    </div>

                    <div className="col-md-9 my-4">
                      <h6 className="sub-title mb-4">
                        Related Entity 0{i + 1}
                      </h6>
                      <div className="row gy-2 gx-4">
                        <Each
                          array={docs}
                          render={(item, index) => (
                            <div className="col-md-4">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <Checkbox
                                  className="custom-checkbox"
                                  onChange={(e) =>
                                    getPending(
                                      "individual",
                                      item,
                                      documentArray[index]._id,
                                      e.target.checked
                                    )
                                  }
                                >
                                  {item}
                                </Checkbox>
                              </div>

                              <UploadZ
                                // approveClicked={saveReview}
                                // file={documentArray[index]}
                                // downloadOnly={true}
                                // showApprove={true}
                                // onFileApprove={onChangeApproval}
                                // showTranslation={true}
                                // showExtraction={true}

                                file={documentArray[index]}
                                downloadOnly={true}
                                showApprove={true}
                                onFileApprove={onChangeApproval}
                                showTranslation={true}
                                showExtraction={true}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : []}
          <div className="mt-4">
            <h2 className="primary-title">Statutory Documents</h2>

            <div className="my-4">
              <div className="row gy-2 gx-4">
                <Each
                  array={statutoryDocs}
                  render={(item, index) => (
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <Checkbox
                          className="custom-checkbox"
                          onChange={(e) =>
                            getPending(
                              "basicDoc",
                              item,
                              statutoryDocuments[index]._id,
                              e.target.checked
                            )
                          }
                        >
                          {item}
                        </Checkbox>
                      </div>
                      <UploadZ
                        approveClicked={saveReview}
                        file={statutoryDocuments[index]}
                        showTranslation={true}
                        showExtraction={true}
                        showApprove={true}
                        downloadOnly={true}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          {/* <div>
          <h2 className="primary-title">Reserve Director Appointments</h2>
          <div className="my-4">
            <div className="row gy-2 gx-4">
              <Each
                array={reserveDirectorDocs}
                render={(item) => (
                  <div className="col-md-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <Checkbox
                        className="custom-checkbox"
                        onChange={() =>
                          getPending("Reserve_Director_Appointments", item)
                        }
                      >
                        {item}
                      </Checkbox>
                     
                    </div>
                    <UploadZ
                      file={sampDocuments}
                      showTranslation={true}
                      showExtraction={true}
                      showApprove={true}
                      downloadOnly={true}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div> */}

          {/* <div>
          <h2 className="primary-title">Corporate Entity</h2>
          <div className="my-4">
            <div className="row gy-2 gx-4">
              <Each
                array={corporateDocs}
                render={(item) => (
                  <div className="col-md-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <Checkbox
                        className="custom-checkbox"
                        onChange={() => getPending("corporateEntity", item)}
                      >
                        {item}
                      </Checkbox>
                    </div>
                    <UploadZ
                      file={sampDocuments}
                      showTranslation={true}
                      showExtraction={true}
                      showApprove={true}
                      downloadOnly={true}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div> */}
          <Modal
            title={"Request Pending Information"}
            className="custom-modal"
            open={pendingView}
            width={600}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="custom-form">
              {Object.entries(pendingPDF).map((p, i) => {
                let keys = Object.keys(pendingPDF);

                return (
                  <div key={i}>
                    {pendingPDF[keys[i]]?.length > 0 && (
                      <div>
                        <div className="primary-title mb-2">
                          {keys[i].replaceAll("_", " ")}
                        </div>
                        <div>
                          {pendingPDF[keys[i]].map((n) => {
                            const parts = n.split("&");
                            return (
                              <div className="mb-3" key={i}>
                                <label htmlFor="">{parts[0]}</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  placeholder={`Enter ${parts[0]}`}
                                  onChange={(event) =>
                                    handleChange(
                                      parts[0],
                                      event.target.value,
                                      parts[1],
                                      keys[i]
                                    )
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Modal>
        </div>
      </Spin>
    </div>
  );
}
