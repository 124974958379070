import React, { useState, useEffect } from "react";

import { Select } from "antd";
import { useSelector } from "react-redux";

import { generateRandom, fetchApi } from "../../helper";

import Client from "./components/client";
import IntroducerClient from "./components/introducer-client";
import ParentClient from "./components/parent-client";
import EndUserClient from "./components/end-user-client";
import Entity from "./components/entity";
import Individual from "../../components/IndividualAnalysis";
import CorporateEntity from "../../components/CorporateEntity";
import { languagesArray } from "../../store/language/languageSlice";

import MasterFileCreation from "../../helper/translations/MasterFileCreation.json";

export default function Creation() {
  const [selectedMFT, setSelectedMFT] = useState(null);
  const { language } = useSelector((state) => state.language);

  const [clientClicked, setClientClicked] = useState(false);
  const [parentClicked, setParentClicked] = useState(false);
  const [endUserClicked, setEndUserClicked] = useState(false);
  const [entityClicked, setEntityClicked] = useState(false);
  const [copEntityClicked, setCopEntityClicked] = useState(false);
  const [individualClicked, setIndividualClicked] = useState(false);
  const [introducerClientClicked, setIntroducerClientClicked] = useState(false);
  const [selectedPage, setselectedPage] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [items, setItems] = useState([]);
  const [selctedLanguage, setSelctedLanguage] = useState("");

  useEffect(() => {
    let index = languagesArray.find((tc) => tc.value == language);
    setSelctedLanguage(index.value);
  }, [language]);
  let o =
    MasterFileCreation.options[selctedLanguage] ||
    MasterFileCreation.options.en;
  let m =
    MasterFileCreation.masterFile[selctedLanguage] ||
    MasterFileCreation.masterFile.en;

  const MFTOptions = [
    {
      value: "introducer-client",
      label: m.IntroducerClient,
    },
    {
      value: "end-user",
      label: m.EndUserClient,
    },
    {
      value: "parent-client",
      label: m.ParentClient,
    },
    {
      value: "client",
      label: m.Client,
    },
    {
      value: "entity",
      label: m.Entity,
    },
    {
      value: "individual",
      label: m.Individual,
    },
    {
      value: "corporate-entity",
      label: m.CorporateEntity,
    },
  ];

  const handleButtonClick = () => {
    if (selectedPage == "client") {
      setClientClicked(true);
    } else if (selectedPage == "introducer-client") {
      setIntroducerClientClicked(true);
    } else if (selectedPage == "parent-client") {
      setParentClicked(true);
    } else if (selectedPage == "end-user-client") {
      setEndUserClicked(true);
    } else if (selectedPage == "entity") {
      setEntityClicked(true);
    } else if (selectedPage == "corporate-entity") {
      setCopEntityClicked(true);
    } else if (selectedPage == "individual") {
      setIndividualClicked(true);
    }
  };

  const getMFT = (value) => {
    let unix = generateRandom().toUpperCase();

    if (value == "client") {
      setRefNumber(`CL${unix}`);
    } else if (value == "introducer-client") {
      setRefNumber(`IC${unix}`);
    } else if (value == "individual") {
      setRefNumber(`IN${unix}`);
    } else if (value == "entity") {
      setRefNumber(`EN${unix}`);
    } else if (value == "end-user") {
      setRefNumber(`EU${unix}`);
    } else if (value == "parent-client") {
      setRefNumber(`PC${unix}`);
    } else if (value == "corporate-entity") {
      setRefNumber(`CO${unix}`);
    }
    setSelectedMFT(value);
  };
  let listing = [
    {
      id: 1,
      title: "",
      description: "Name Check",
      subTitle: "Name Check",
      link: "/app/name-check",
    },
    {
      id: 2,
      title: "",
      description: "Incorporation",
      subTitle: "Incorporation",
      link: "/app/incorporation-request",
    },
    {
      id: 3,
      title: "Name Check",
      description: "3",
      subTitle: "3",
      link: "/app/name-check",
    },
    {
      id: 4,
      title: "Name Check",
      description: "4",
      subTitle: "4",
      link: "/app/name-check",
    },
    {
      id: 5,
      title: "Name Check",
      description: "5",
      subTitle: "5",
      link: "/app/name-check",
    },
    {
      id: 6,
      title: "Name Check",
      description: "6",
      subTitle: "6",
      link: "/app/name-check",
    },
    {
      id: 7,
      title: "Name Check",
      description: "7",
      subTitle: "7",
      link: "/app/name-check",
    },
    {
      id: 8,
      title: "Name Check",
      description: "8",
      subTitle: "8",
      link: "/app/name-check",
    },
  ];

  useEffect(() => {
    const newItems = listing.map((item) => ({
      title: item.title,
      description: <a href={item.link}>{item.description}</a>,
      subTitle: item.subTitle,
    }));

    setItems(newItems);
  }, []);
  return (
    <div>
      {/* {workflowChart(items, 1)} */}
      <div className="page-header">
        <h1>{m.MasterFileCreation}</h1>

        {/* <Link to={"/app/pre-incorp-documents"}> */}
        <div onClick={handleButtonClick} className="primary-btn">
          {m.Create}
        </div>
        {/* </Link> */}
      </div>
      <div className="page-content">
        <div className="custom-form">
          {/* <h2 className="primary-title mb-4">Master File Type</h2> */}
          <div className="mb-4">
            <label htmlFor="" className="me-3 mb-1">
              {m.MasterfileType}
            </label>
            <Select
              className="custom-select mw-300"
              placeholder={m.SelectAny}
              onChange={getMFT}
              options={MFTOptions}
            />
          </div>
        </div>
        {selectedMFT == "client" && (
          <Client
            clientClicked={clientClicked}
            refCode={refNumber}
            setselectedPage={setselectedPage}
            setClientClicked={setClientClicked}
          />
        )}
        {selectedMFT == "introducer-client" && (
          <IntroducerClient
            introducerClientClicked={introducerClientClicked}
            refCode={refNumber}
            setselectedPage={setselectedPage}
            setIntroducerClientClicked={setIntroducerClientClicked}
          />
        )}
        {selectedMFT == "parent-client" && (
          <ParentClient
            parentClicked={parentClicked}
            setselectedPage={setselectedPage}
            refCode={refNumber}
            setParentClicked={setParentClicked}
          />
        )}
        {selectedMFT == "end-user" && (
          <EndUserClient
            endUserClicked={endUserClicked}
            setselectedPage={setselectedPage}
            refCode={refNumber}
            setEndUserClicked={setEndUserClicked}
          />
        )}
        {selectedMFT == "entity" && (
          <Entity
            entityClicked={entityClicked}
            setselectedPage={setselectedPage}
            refCode={refNumber}
            setEntityClicked={setEntityClicked}
          />
        )}
        {selectedMFT == "individual" && (
          <div>
            <h2 className="sub-title mb-4">{`${m.MasterFileCreation}: ${m.Individual}`}</h2>
            <Individual
              fileCreation={true}
              individualClicked={individualClicked}
              setselectedPage={setselectedPage}
              refCode={refNumber}
              setIndividualClicked={setIndividualClicked}
            />
          </div>
        )}
        {selectedMFT == "corporate-entity" && (
          <div>
            <h2 className="sub-title mb-4">
              {`${m.MasterFileCreation}: ${m.CorporateEntity}`}
            </h2>
            <CorporateEntity
              fileCreation={true}
              copEntityClicked={copEntityClicked}
              setselectedPage={setselectedPage}
              refCode={refNumber}
              setCopEntityClicked={setCopEntityClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
}
