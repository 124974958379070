import { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import FormZ from "../../components/FormZ";

import { sendNotify, getOptions } from "../../helper";

export default function Director() {
  const { data, isLoading } = useSelector((state) => state.country);

  const directorFormRef = useRef();

  let directorFormSchema = [
    {
      name: "title",
      value: "",
      type: "select",
      label: "Title",
      placeholder: "Enter title",
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "firstName",
      value: "",
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
    },
    {
      name: "middleName",
      value: "",
      type: "text",
      label: "Middle Name",
      placeholder: "Enter Middle name",
    },
    {
      name: "lastName",
      value: "",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
    },
    {
      name: "gender",
      value: "",
      type: "select",
      label: "Gender",
      placeholder: "Select gender",
      options: [
        {
          value: "male",
          label: "Male",
        },
        {
          value: "female",
          label: "Female",
        },
      ],
    },
    {
      name: "nationality",
      value: "",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "dateOfBirth",
      value: "",
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "countryOfBirth",
      value: "",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "riskLevel",
      value: "low",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      name: "passportNumber",
      value: "",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
    },
    {
      name: "expiryDate",
      value: "",
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "pep",
      value: "",
      type: "text",
      label: "PEP",
      placeholder: "Enter pep",
    },
    {
      name: "relatedEntities",
      value: "",
      type: "text",
      label: "Related Entities",
      placeholder: "Enter related entities",
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4 mb-md-5">
        <h1 className="page-title">Director</h1>
        <Link to={"/app/incorporation-pack"} className="primary-btn">
          Export
        </Link>
      </div>
      <div className="custom-form">
        <div className="d-flex gap-3 flex-column flex-md-row align-items-start align-items-md-center mb-4">
          <h4 className="primary-title">Director</h4>
          <Link to={"/app/"} className="primary-btn">
            Analyze
          </Link>
          <button className="outline-btn">Web Search</button>
        </div>
        <FormZ
          ref={directorFormRef}
          formSchema={directorFormSchema}
          formClass="row mb-4 mb-md-5 gy-3"
          childClass="col-md-3 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
      </div>
    </div>
  );
}
