import { useState, useRef } from "react";
import { Link } from "react-router-dom";

import { Each } from "../helper";

import FormZ from "../components/FormZ";
import UploadZ from "../components/UploadZ";

export default function NewIncorporation() {
  const [uploadPack, setUploadPack] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [documents, setDocuments] = useState([]);

  const formRef = useRef();

  let formSchema = [
    {
      name: "entityName",
      value: "",
      type: "text",
      label: "Entity Name",
      placeholder: "Enter entity name",
    },
    {
      name: "entityType",
      value: "",
      type: "select",
      label: "Entity Type",
      placeholder: "Enter entity type",
      options: [
        {
          value: "BVIBC",
          label: "BVIBC",
        },
        {
          value: "trust",
          label: "Trust",
        },
        {
          value: "foundation",
          label: "Foundation",
        },
        {
          value: "general-partnership",
          label: "General Partnership",
        },
        {
          value: "limited-partnership",
          label: "Limited Partnership",
        },
      ],
    },
    {
      name: "entityCode",
      value: "",
      type: "text",
      label: "Entity Code",
      placeholder: "Enter entity code",
    },
    {
      name: "feeSchedule",
      value: "",
      type: "text",
      label: "Fee Schedule",
      placeholder: "Enter fee schedule",
    },
    {
      name: "clientName",
      value: "",
      type: "text",
      label: "Client Name",
      placeholder: "Enter client name",
    },
    {
      name: "clientCode",
      value: "",
      type: "text",
      label: "Client Code",
      placeholder: "Enter client code",
    },
    {
      name: "clientType",
      value: "",
      type: "select",
      label: "Client Type",
      placeholder: "Enter client type",
      options: [
        {
          value: "IntermediaryClient",
          label: "Intermediary Client",
        },
        {
          value: "endUserClient",
          label: "End User Client",
        },
      ],
    },
    {
      name: "parentClientCode",
      value: "",
      type: "text",
      label: "Parent Client Code",
      placeholder: "Enter parent client code",
    },
    {
      name: "parentClientName",
      value: "",
      type: "text",
      label: "Parent Client Name",
      placeholder: "Enter parent client name",
    },
    {
      name: "rmName",
      value: "",
      type: "text",
      label: "RM Name",
      placeholder: "Enter rm name",
    },
    {
      name: "rmCode",
      value: "",
      type: "text",
      label: "RM Code",
      placeholder: "Enter rm code",
    },
    {
      name: "approvedBy",
      value: "",
      type: "text",
      label: "Approved by Name",
      placeholder: "Enter approved by name",
    },
  ];

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
        <h1 className="page-title mb-3 mb-md-0">New Incorporation</h1>
        <div className="d-flex gap-3 flex-column flex-md-row">
          <button className="outline-btn">
            <i className="fal fa-plus"></i> Add New Upload Pack
          </button>
          <Link to={"/app/incorporation-feedback"} className="primary-btn">
            Store and Submit
          </Link>
        </div>
      </div>
      <div className="custom-form">
        <FormZ
          formSchema={formSchema}
          ref={formRef}
          formClass="row mb-4 mb-md-5 gy-3 gx-4"
          childClass="col-md-3 mb-0"
          labelClass="mb-1"
          inputClass="w-100"
        />
        <h4 className="primary-title mb-4">Upload Pack</h4>
        <div className="row gy-2 gx-4">
          <Each
            array={uploadPack}
            render={(item) => (
              <div className="col-md-3">
                <label htmlFor={item}>0{item}</label>
                <UploadZ onStoreFile={storeDocuments} fileName={item} />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}
