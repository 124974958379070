import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Checkbox, Modal } from "antd";

import SampleDoc from "../../assets/pdf/sample.pdf";

import SelectLanguage from "../../components/Language";
import UploadZ from "../../components/UploadZ";

import { Each } from "../../helper";

export default function Review() {
  const directors = [1, 2, 3];
  const shareholders = [1, 2, 3];
  const ubos = [1, 2, 3];

  const [pendingView, setPendingView] = useState(false);
  const [pendingPDF, setPendingPDF] = useState({
    Directors: [],
    Shareholders: [],
    UBOs: [],
    Statutory_Documents: [],
    Reserve_Director_Appointments: [],
    Corporate_KYC: [],
  });

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const columns = [
    {
      title: "Service Type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType - b.serviceType,
    },
    {
      title: "Service Ref",
      dataIndex: "serviceRef",
      sorter: (a, b) => a.serviceRef - b.serviceRef,
    },
    {
      title: "Requestor ID",
      dataIndex: "reqID",
      sorter: (a, b) => a.reqID - b.reqID,
    },
    {
      title: "Entity Name",
      dataIndex: "entityName",
      sorter: (a, b) => a.entityName - b.entityName,
    },
    // {
    //   title: "Entity Code",
    //   dataIndex: "entityCode",
    //   sorter: (a, b) => a.entityCode - b.entityCode,
    // },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
    },
    // {
    //   title: "Client Code",
    //   dataIndex: "clientCode",
    //   sorter: (a, b) => a.clientCode - b.clientCode,
    // },
    {
      title: "Request Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      sorter: (a, b) => a.assignee - b.assignee,
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      serviceType: "OCR",
      serviceRef: "SR001",
      reqID: "2346",
      entityName: "Trust",
      entityCode: "CE34",
      clientName: "Sample",
      clientCode: "8022",
      date: "20/10/2023",
      assignee: "OCR",
    },
  ];

  const getPending = (obj, pdf) => {
    let arr = { ...pendingPDF };
    arr[obj].push(pdf);
    setPendingPDF(arr);
  };

  const handleOk = () => {
    setPendingView(false);
  };

  const handleCancel = () => {
    setPendingView(false);
  };

  const individualDocs = [
    "Passport / ID",
    "Address Proof",
    "Consent to Act",
    "Resolution of Appointment",
    "Resolution of Resignation",
  ];

  const directorDocs = [
    "Resolution of Appointment",
    "Resolution of Resignation",
  ];

  const shareholderDocs = [
    "Resolution of Share Allotment",
    "Resolution of Share Transfer",
    "Nominee Agreement",
  ];

  const uboDocs = ["Resolution of Shareholding", "Source of Wealth/Founds"];

  const statutoryDocs = [
    "Client Information Sheet",
    "Sample Document",
    "Appointment of First Director(s)",
    "Initial Share Allotment",
    "Corporate Seal",
  ];

  const reserveDirectorDocs = ["Passport / ID", "Address Proof", "Resolutions"];

  const corporateDocs = [
    "Corporate Entity Pack 01",
    "Corporate Entity Pack 02",
    "Corporate Entity Pack 03",
  ];

  const sampDocuments = {
    url: SampleDoc,
    name: "Sample Document.pdf",
    size: "200 KB",
    type: "pdf",
    base64: "",
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className="page-title mb-3 mb-md-0">Document Review</h1>
        <div className="d-flex flex-column flex-md-row gap-3">
          <button className="outline-btn" onClick={() => setPendingView(true)}>
            Request Pending Information
          </button>
          <button className="outline-btn">Save Review</button>
          <Link to={"/app/onboarding-team/upload"} className="primary-btn">
            Complete Review and Submit
          </Link>
        </div>
      </div>
      {/* <h6 className='sub-title mb-4'>Assign</h6>
            <div className='mb-5'>
                <label htmlFor="" className="me-3 mb-1">Assign to</label>
                <div className="d-flex">
                    <Select
                        className="custom-select mw-300 mw-sm-65"
                        placeholder="Select"
                        onChange={handleChange}
                        options={[]}
                    />
                    <button className='primary-btn ms-3'>Submit</button>
                </div>
            </div> */}
      <Table
        className="custom-table"
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <div className="mt-4">
        <div className="d-flex my-4 gap-3 align-items-center">
          <h4 className="primary-title">Individuals</h4>
          <Checkbox className="custom-checkbox" disabled checked>
            Directors
          </Checkbox>
          <Checkbox className="custom-checkbox" disabled>
            Shareholders
          </Checkbox>
          <Checkbox className="custom-checkbox" disabled>
            UBOs
          </Checkbox>
          <Checkbox className="custom-checkbox" disabled>
            POC
          </Checkbox>
          <Checkbox className="custom-checkbox" disabled>
            Nominee Shareholder
          </Checkbox>
          <Checkbox className="custom-checkbox" disabled>
            Reserve Director
          </Checkbox>
        </div>
        <Each
          array={directors}
          render={(director) => (
            <div className="col-md-9 my-4">
              <h6 className="sub-title mb-4">Individual 0{director}</h6>
              <div className="row gy-2 gx-4">
                <Each
                  array={individualDocs}
                  render={(item) => (
                    <div className="col-md-4">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <Checkbox
                          className="custom-checkbox"
                          onChange={() => getPending("Directors", item)}
                        >
                          {item}
                        </Checkbox>
                        <button className="outline-btn btn-small">
                          <i className="far fa-check"></i> Approved
                        </button>
                      </div>
                      <UploadZ
                        file={sampDocuments}
                        showTranslation={true}
                        showExtraction={true}
                        downloadOnly={true}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        />
      </div>
      {/* <div>
        <h2 className="primary-title">Shareholders</h2>
        <Each
          array={shareholders}
          render={(shareholder) => (
            <div className="col-md-9 my-4">
              <h6 className="sub-title mb-4">Shareholder 0{shareholder}</h6>
              <div className="row gy-2 gx-4">
                <Each
                  array={shareholderDocs}
                  render={(item) => (
                    <div className="col-md-4">
                      <Checkbox
                        className="custom-checkbox mb-3"
                        onChange={() => getPending("Shareholders", item)}
                      >
                        {item}
                      </Checkbox>
                      <UploadZ
                        file={sampDocuments}
                        showTranslation={true}
                        showExtraction={true}
                        downloadOnly={true}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        />
      </div>
      <div>
        <h2 className="primary-title">UBOs</h2>
        <Each
          array={ubos}
          render={(ubo) => (
            <div className="col-md-9 my-4">
              <h6 className="sub-title mb-4">UBO 0{ubo}</h6>
              <div className="row gy-2 gx-4">
                <Each
                  array={uboDocs}
                  render={(item) => (
                    <div className="col-md-4">
                      <Checkbox
                        className="custom-checkbox mb-3"
                        onChange={() => getPending("UBOs", item)}
                      >
                        {item}
                      </Checkbox>
                      <UploadZ
                        file={sampDocuments}
                        showTranslation={true}
                        showExtraction={true}
                        downloadOnly={true}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        />
      </div> */}
      <div>
        <h2 className="primary-title">Statutory Documents</h2>
        <div className="my-4">
          <div className="row gy-2 gx-4">
            <Each
              array={statutoryDocs}
              render={(item) => (
                <div className="col-md-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Checkbox
                      className="custom-checkbox"
                      onChange={() => getPending("Statutory_Documents", item)}
                    >
                      {item}
                    </Checkbox>
                    <button className="outline-btn btn-small">
                      <i className="far fa-check"></i> Approved
                    </button>
                  </div>
                  <UploadZ
                    file={sampDocuments}
                    showTranslation={true}
                    showExtraction={true}
                    downloadOnly={true}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className="primary-title">Reserve Director Appointments</h2>
        <div className="my-4">
          <div className="row gy-2 gx-4">
            <Each
              array={reserveDirectorDocs}
              render={(item) => (
                <div className="col-md-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Checkbox
                      className="custom-checkbox"
                      onChange={() =>
                        getPending("Reserve_Director_Appointments", item)
                      }
                    >
                      {item}
                    </Checkbox>
                    <button className="outline-btn btn-small">
                      <i className="far fa-check"></i> Approved
                    </button>
                  </div>
                  <UploadZ
                    file={sampDocuments}
                    showTranslation={true}
                    showExtraction={true}
                    downloadOnly={true}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className="primary-title">Corporate Entity</h2>
        <div className="my-4">
          <div className="row gy-2 gx-4">
            <Each
              array={corporateDocs}
              render={(item) => (
                <div className="col-md-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Checkbox
                      className="custom-checkbox"
                      onChange={() => getPending("Corporate_KYC", item)}
                    >
                      {item}
                    </Checkbox>
                    <button className="outline-btn btn-small">
                      <i className="far fa-check"></i> Approved
                    </button>
                  </div>
                  <UploadZ
                    file={sampDocuments}
                    showTranslation={true}
                    showExtraction={true}
                    downloadOnly={true}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <Modal
        title={"Request Pending Information"}
        className="custom-modal"
        open={pendingView}
        width={600}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="custom-form">
          {Object.entries(pendingPDF).map((p, i) => {
            let keys = Object.keys(pendingPDF);
            return (
              <div key={i}>
                {pendingPDF[keys[i]]?.length > 0 && (
                  <div>
                    <div className="primary-title mb-2">
                      {keys[i].replaceAll("_", " ")}
                    </div>
                    <div>
                      {pendingPDF[keys[i]].map((n) => {
                        return (
                          <div className="mb-3" key={i}>
                            <label htmlFor="">{n}</label>
                            <input
                              type="text"
                              className="w-100"
                              placeholder={`Enter ${n}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
