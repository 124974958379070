import { useState } from "react";

import { Each } from "../../../helper";
import UploadZ from "../../../components/UploadZ";

export default function Foundations() {
  const [documents, setDocuments] = useState([]);

  function storeDocuments(file) {
    let doc = [...documents];
    doc.push(file);
    console.log(doc);
    setDocuments(doc);
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  let arr = [1, 2, 3, 4, 5, 6, 7];

  return (
    <div className="custom-form">
      <h6 className="primary-title mb-4">Foundations</h6>
      <div className="info-box mb-4">
        <h6>
          Where the beneficial owner, director, shareholder or officer is a
          Foundation, the following will need to be provided in support of the
          duly completed application form:
        </h6>
        <ol>
          <li>The full name of the foundation</li>
          <li>
            The official registration or other identification number of the
            foundation
          </li>
          <li>
            The date and place of formation and registration of the foundation
          </li>
          <li>The physical and mailing addresses of the foundation’s office</li>
          <li>
            The physical and mailing addresses of the authorized representative
            of the foundation to whom correspondence may be sent, if different
            from (d) above
          </li>
          <li>
            The foundations principal place of business and the purpose of the
            foundation, the source of the foundations’ funding
          </li>
          <li>
            The identity of each individual acting on behalf of the foundation,
            including each individual on the Board of Trustees, Committee
            Members, Executive Director or President of the Foundation
          </li>
        </ol>
      </div>
      <div className="row gy-2 gx-4">
        <Each
          array={arr}
          render={(item) => (
            <div className="col-md-3">
              <label htmlFor={item} className="me-3">
                Foundation Pack 0{item}
              </label>
              <UploadZ onStoreFile={storeDocuments} fileName={item} />
            </div>
          )}
        />
      </div>
    </div>
  );
}
